<template>
  <div>
    <button
      class="display-none"
      ref="showLoginBtn"
      data-toggle="modal"
      data-target="#loginModal"
    ></button>
    <div
      class="modal fade text-center py-5"
      style="top: 30px"
      id="loginModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <!----language select bar--->
              <transition name="slideFromRight">
                <div class="language-wrapper" v-if="showLanguageSidebar">
                  <div class="langForm col-12">
                    <div
                      v-for="(language, index) in languageList"
                      :key="language.code"
                      class="row"
                    >
                      <div class="float-left">
                        <span class="langSel" @click="onSelectLanguage(index)">
                          <img :src="language.iconUrl" width="35" />
                          <div class="langFlagText">{{ language.label }}</div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <!---end language select bar--->
              <div class="">
                <div class="close-right">
                  <div
                    ref="closeModalBtn"
                    class="close-right-img"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      class="cursor-pointer"
                      src="../../../assets/icons/close.png"
                      width="32"
                    />
                  </div>
                  <!-- <router-link to="/landing">
              <div class="close-right-img">
                <img src="../../../assets/icons/close.png" width="15" />
              </div>
            </router-link> -->
                </div>
                <div class="img-big-vs-logo mt-5">
                  <img src="../../../assets/images/logo.png"  />
                </div>
                <div class="LoginForm">
                  <div class="Line-26"></div>
                  <div class="lbl-control">{{ $t("UserName2") }}</div>
                  <input
                    name="inputUserName"
                    type="text"
                    class="f-control"
                    maxlength="50"
                    v-on:keyup.enter="focusInputPassword()"
                    :placeholder="$t('UserName2')"
                    v-model.trim="inputUsername"
                    v-on:input="onChangeLogin()"
                  />
                  <div class="lbl-control">{{ $t("Password2") }}</div>
                  <input
                    name="password"
                    type="password"
                    ref="inputPassword"
                    class="f-control"
                    maxlength="16"
                    :placeholder="$t('Password2')"
                    v-model.trim="inputPassword"
                    v-on:keyup.enter="onClickLogin"
                    v-on:input="onChangeLogin()"
                  />
                  <!---langguage setion --->

                  <div class="f-control2 mx-4 mt-3">
                    <div
                      style="display: inline-block"
                      class="cursor-pointer"
                      id="languageDropdownBtn"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      @click="onClickLanguageDropdown"
                    >
                      <img :src="selectedLanguage.icn" width="20" />
                      <div class="LangText text-uppercase">
                        {{ selectedLanguage.name }}
                      </div>

                      <span v-show="!isLanguageDropdownOpen">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                      <span v-show="isLanguageDropdownOpen">
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div
                      id="language-dropdown"
                      class="dropdown-menu lang-dropdown"
                      aria-labelledby="languageDropdownBtn"
                    >
                      <!----lanuage tab  ---->
                      <template v-for="(lang, index) in languageList" :key="lang.id">
                        <a
                          class="dropdown-item lang-dropdown-item"
                          href="#"
                          @click="onClickLanguageTab(index)"
                        >
                          <img
                            :src="lang.icn"
                            class="img-responsive"
                            alt="Responsive image"
                            width="18"
                            height="18"
                          />
                          <span class="lang-dropdown-text text-uppercase"
                            >{{ lang.name }}
                          </span>
                          <!-- <span
                            class="text-success float-right"
                            v-if="selectedLanguageId == lang.id"
                          >
                            <i class="fa fa-check"> </i>
                          </span> -->
                        </a>
                      </template>
                      <!---- end lanuage tab  ---->
                    </div>
                  </div>

                  <!----end language section --->
                  <div class="login_error container">{{ errMessage }} &nbsp;</div>
                  <a id="btnSignIn" class="btnlogin cursor-pointer" @click="onClickLogin">
                    <span class="login-text">
                      {{ $t("Login2") }}
                    </span>
                  </a>
                </div>
              </div>
              <!---end template --->
            </div>
          </div>
          <loading-spinner v-if="isLoading"></loading-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import languageList from "../../../assets/script/languageList";

import i18n from "./../../../i18n";
const { t } = i18n.global;
export default {
  // emits: ['toggle-danger'],
  props: ["errMessage"],
  data() {
    return {
      inputUsername: "",
      inputPassword: "",
      showLanguageSidebar: false,
      isLoading: false,
      languageList: languageList,
      selectedLanguageIndex: 0,
      isLanguageDropdownOpen: false,
    };
  },
  methods: {
    // toggleLanguageSidebar() {
    //   this.showLanguageSidebar = true;
    // },
    focusInputPassword() {
      this.$refs.inputPassword.focus();
    },
    onChangeLogin() {
      this.$emit("set-err-messsage", "");
    },
    onSelectLanguage(index) {
      this.selectedLanguageIndex = index;
      this.showLanguageSidebar = false;
    },

    async onClickLogin() {
      if (this.inputUsername == "" || this.inputPassword == "") {
        this.$emit("set-err-messsage", t("InvalidUsernameOrPassword"));
        return;
      }

      this.$emit("set-err-messsage", "");
      this.isLoading = true;
      const json = {
        username: this.inputUsername,
        password: this.inputPassword,
      };
      try {
        await this.$store.dispatch("userLogin", json);
        //  this.isLoading = false;
        this.$refs.closeModalBtn.click();
        //   this.$i18n.locale = this.$store.getters.langId.toString();
        this.$router.replace("/agreement");
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        // console.log(error);
        this.isLoading = false;
        let tempError = error.message;
        tempError = tempError.replace("Error:", "").trim();
        tempError = t(tempError);

        // if (this.$store.getters.langId != 16) {
        //   tempError = t("InvalidUsernameOrPassword");
        // }

        this.$emit("set-err-messsage", tempError);
        //this.$emit('toggle-danger', 'error');
      }
    },
    showModal() {
      this.inputUsername = "";
      this.inputPassword = "";
      this.$refs.showLoginBtn.click();
    },
    onClickLanguageDropdown() {
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
    },
    onClickLanguageTab(index) {
      const tempLangId = this.languageList[index].id;
      this.$store.dispatch("setLangId", tempLangId);
    },
  },
  computed: {
    selectedLanguage() {
      let selectedLanguage = languageList.find((l) => l.id == this.$store.getters.langId);
      if (selectedLanguage) {
        return selectedLanguage;
      } else {
        return languageList[0];
      }
    },
    selectedLanguageId() {
      return this.$store.getters.langId;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-content {
  background: #2f4798;
  color: white;
  width: 100%;
  background-position: bottom;
  flex: 1 1 auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#loginModal .img-big-vs-logo {
  width: 100%;
  margin: auto;
}
#loginModal .img-big-vs-logo img {
  width: 200px;
}

#loginModal .LoginForm {
  margin-bottom: 40px !important;
  width: 67% !important;
  margin-top: 0;
}

.modal-body {
  padding: 0rem !important;
}

.login-text {
  color: #fff;
}

.btnlogin {
  padding: 10px;
  width: 83%;
  background: #ff9611;
}

.lang-dropdown-text {
  width: 95px;
  height: 21px;
  margin: 0 0 26px 9px;
  font-size: 11px;
  letter-spacing: 0.08px;
  text-align: left;
  color: #fff6dd;
}

.lang-dropdown-item:hover {
  background-color: #3a3a3a !important;
  border-radius: 5px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: inherit;
  text-decoration: none;
  background-color: inherit;
}

.dropdown-item {
  padding: 0.5rem 1rem;
}

.lang-dropdown {
  width: 160px;
  padding: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.38);
  background-color: #21201c;
  left: 40rem;
  color: #ffffff;
  border-radius: 5px;
}

.lang-dropdown-item:hover {
  background-color: #3a3a3a !important;
  border-radius: 5px;
}

#language-dropdown {
  top: 5px !important;
  left: -19px !important;
  min-width: 9rem;
}
</style>
