<template>
  <!-- side bar-->
  <div class="wrapper">
    <loading-spinner v-if="isLoading"></loading-spinner>
    <transition name="fadeIn">
      <div class="dark-backdrop" v-if="sideOpen"></div>
    </transition>
    <transition name="slideFromRight">
      <section class="more-menu-wrapper" v-if="sideOpen">
        <nav class="more-menu-close" @click="toggleSidebar()"></nav>
        <nav class="more-menu menu_mt">
           <div class="col-spaced mx-3">
              <span>More</span>
              <span class="largest pointer" @click="toggleSidebar()" style="color:#ff9611">×</span>
            </div> 
            <br>
          <div
            class="more-menu-item"
            @click="setActiveComponent('screen-account')"
          >
            <a href="#">
              <img
                class="mr-3"
                src="./../../../assets/icons/sidebar/icn-myaccount.png"
              />
              {{ $t('MyAccount') }}
            </a>  
          </div>
          <router-link to="/history" @click="clearHistory">
            <div class="more-menu-item">
              <img
                class="mr-3"
                src="./../../../assets/icons/sidebar/icn-history.png"
              />
              {{ $t('History') }}
            </div>
          </router-link>
          <div
            class="more-menu-item"
            @click="setActiveComponent('screen-statement')"
          >
            <a href="#">
              <img
                class="mr-3"
                src="./../../../assets/icons/sidebar/icn-statement.png"
              />
              {{ $t('Statement3') }}
            </a>
          </div>
          <div
            class="more-menu-item"
            @click="setActiveComponent('screen-result')"
          >
            <a href="#">
              <img
                class="mr-3"
                src="./../../../assets/icons/sidebar/icn-result.png"
              />
              {{ $t('Result') }}
            </a>
          </div>
          <div
            class="more-menu-item"
            @click="setActiveComponent('screen-deposit')"
          >
            <a href="#">
              <img
                class="mr-3"
                src="./../../../assets/icons/sidebar/icn-deposit.png"
              />
              {{ $t('Deposit') }}
            </a>
          </div>
          <div
            class="more-menu-item"
            @click="setActiveComponent('screen-withdraw')"
          >
            <a href="#">
              <img
                class="mr-3"
                src="./../../../assets/icons/sidebar/icn-withdraw.png"
              />
              {{ $t('dep_withdraw') }}
            </a>
          </div>
          <div
            class="more-menu-item"
            @click="setActiveComponent('screen-setting')"
          >
            <a href="#">
              <img
                class="mr-3"
                src="./../../../assets/icons/sidebar/icn-settings.png"
              />
              {{ $t('Setting') }}
            </a>
          </div>
          <div class="more-menu-item" @click="onClickLogout">
            <a href="#">
              <img
                class="mr-3"
                src="./../../../assets/icons/sidebar/icn-logout.png"
              />
              {{ $t('Logout') }}
            </a>
          </div>
        </nav>
      </section>
    </transition>
  </div>
  <!-- end side bar-->
</template>

<script>
import CustomStorage from '../../../CustomStorage';
export default {
  props: ['sideOpen'],
  emits: ['close-collapse', 'toggle-sidebar', 'set-component'],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async onClickLogout() {
      this.isLoading = true;
      CustomStorage.setItem('logoutUser', this.$store.getters.username);
      CustomStorage.setItem('mobileLogout', true);
      // this.$emit("toggle-sidebar");
      // this.$emit("set-component", "screen-landing");
      window.location.reload();

      //this.$store.dispatch('userLogoutWithApi');
      //  this.$router.replace('/');
      // this.isLoading = false;
    },
    clearHistory(){
      sessionStorage.setItem("provider",'');
      sessionStorage.setItem("providerName",'');
    },
    toggleSidebar() {
      this.$emit('toggle-sidebar');
    },
    closeCollapse() {
      this.$emit('close-collapse');
    },
    setActiveComponent(componentName) {
      this.$emit('set-component', componentName);
    },
  },
  computed: {
    enablePaymentGateway() {
      return this.$store.getters.enablePaymentGateway;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.col-center {
  display: flex;
  align-items: center;
}

img {
  width: 24px !important;
  height: 24px !important;
}

.heigh-70 {
  height: 70px;
}

.heigh-25 {
  height: 25px;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.wrapper {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
</style>
