import gameImport from './../game';

import spadefish from './../../images/game2/fishing/img-spadegaming-fish.png';
import jilifish from './../../images/game2/fishing/img-jili-fish.png';
import fachaifish from './../../images/game2/fishing/img-fachai-fish.png';
import play8fish from './../../images/game2/fishing/img-play8-fish.png';

var gameMenu = {
  name: 'FISHING',
  items: [
    {
      name: 'Spade Gaming',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-302',
      img: [{ langId: 0, src: spadefish }],
      img2: [{ langId: 0, src: spadefish }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Jili',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-305',
      img: [{ langId: 0, src: jilifish }],
      img2: [{ langId: 0, src: jilifish }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Fa Chai',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-304',
      img: [{ langId: 0, src: fachaifish }],
      img2: [{ langId: 0, src: fachaifish }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Play8',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-307',
      img: [{ langId: 0, src: play8fish }],
      img2: [{ langId: 0, src: play8fish }],
      isNew: false,
      labelName: '',
      parentName: '',
    }
  ],
};

export default gameMenu;
