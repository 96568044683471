<template>
  <!-------bottom menu bar--->
  <section v-if="isLoggedIn">
    <!-- <hr class="divider" /> -->
    <nav class="navbar bottom-nav col-spaced smaller">
        <div class="full-center">
          <div class="col px-0 text-center">
            <div
              class="cursor-pointer"
              @click="setActiveComponent('screen-landing','home')"
            >
              <img v-if="activeMenu == 'home' && (footerMenuActive == '' || footerMenuActive == undefined)" src="../../../assets/icons/home-active.png" width="32" />
              <img v-else src="../../../assets/icons/home.png" width="32" />
              <br />
              <div class="lh footer-menu-text mt-1" :class="(activeMenu == 'home' && (footerMenuActive == '' || footerMenuActive == undefined)) ? 'menu-highlight' : ''">{{ $t('Home') }}</div>
            </div>
          </div>

          <div class="col px-0 text-center">
            <div
              class="cursor-pointer"
              @click="setActiveComponent('screen-statement','statement')"
            >
              <img v-if="activeMenu == 'statement'" src="../../../assets/icons/statement-active.png" width="32" />
              <img v-else src="../../../assets/icons/statement.png" width="32" />
              <br />
              <div class="lh footer-menu-text mt-1" :class="(activeMenu == 'statement') ? 'menu-highlight' : ''">{{ $t('Statement') }}</div>
            </div>
          </div>
          <div class="col px-0 text-center">
            <div
              class="cursor-pointer"
              @click="setActiveComponent('screen-cash-out','cashout')"
            >
              <img v-if="activeMenu == 'cashout' || footerMenuActive == 'screen-cash-out'" src="../../../assets/icons/cash-active.png" width="32" />
              <img v-else src="../../../assets/icons/cash.png" width="32" />
              <br />
              <div class="lh footer-menu-text mt-1" :class="(activeMenu == 'cashout' || footerMenuActive == 'screen-cash-out') ? 'menu-highlight' : ''">{{ $t('CashOut') }}</div>
            </div>
          </div>
          <div class="col px-0 text-center">
            <div class="cursor-pointer" @click="toggleSidebar();">
              <img v-if="activeMenu == 'more'" src="../../../assets/icons/more-active.png" width="32" />
              <img v-else src="../../../assets/icons/more.png" width="32" />
              <br />
              <div class="lh footer-menu-text mt-1">{{ $t('More') }}</div>
            </div>
          </div>
        </div>
    </nav>
  </section>
  <!-----end bottom nav bar ---->
</template>

<script>
export default {
  data() {
    return {
        activeMenu : 'home',
      }
  },
  props: ['footerMenuActive'],
  emits: ['toggle-sidebar', 'set-component'],
  methods: {
    toggleSidebar() {
      this.$emit('toggle-sidebar');
      this.activeMenu = 'more';
    },
    setActiveComponent(componentName,menuName) {
      this.$emit('set-component', componentName);
      this.activeMenu = menuName;
    },
  }, //end methods
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    suspend() {
      return this.$store.getters.suspend;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bottom-nav {
  background: linear-gradient(to bottom, #253c8b, #4856a7);
  padding-top: 0.5em;
  flex: 0;
  justify-content: space-around;
  padding: 0.75em 0.5em;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  box-shadow: 3px -4px 10px rgb(0 0 0 / 30%);
}

.bottom-nav img {
  width: 25px;
  height: 25px;
}

.col-center {
  display: flex;
  align-items: center;
}

.smaller {
  font-size: 0.75rem;
}

.divider {
  border: opx;
  padding: 11px 0px;
}
.menu-highlight{
  color: #ff9611;
}
</style>
