import '@fortawesome/fontawesome-free/css/all.css';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import 'vue-multiselect/dist/vue-multiselect.css';
import i18n from './i18n';

import { createApp } from 'vue';
import App from './App';
import router from './router/index.js';
import store from './store/index.js';
import CustomHttp from './CustomHttp';
import axios from 'axios';
import LoadingSpinner from './components/global/elements/loading-spinner.vue';
import ProgressSpinner from './components/global/elements/progress-spinner.vue';
import WhiteProgressSpinner from './components/global/elements/white-progress-spinner.vue';
import SuccessAlert from './components/global/elements/success-alert.vue';
import SuccessAlertMobile from './components/global/elements/success-alert-mobile.vue';
import SuccessAlertCashout from './components/global/elements/success-alert-cashout.vue';
import ErrorAlert from './components/global/elements/error-alert.vue';
import ErrorAlertMobile from './components/global/elements/error-alert-mobile.vue';
import LoginModal from './components/global/elements/login-modal.vue';
import GameModal from './components/global/elements/game-modal.vue';
import DesktopHeader from './components/global/elements/desktop-header.vue';
import DesktopFooter from './components/global/elements/desktop-footer.vue';
// Vue.config.productionTip = false;
import VueSweetalert2 from 'vue-sweetalert2';
import MarqueeText from 'vue-marquee-text-component';
import VPagination from '@hennge/vue3-pagination';
// import './registerServiceWorker'
// import runtime from "serviceworker-webpack-plugin/lib/runtime";

// if ("serviceWorker" in navigator) {
//   runtime.register();
// }
const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(store);
app.use(VueSweetalert2);
app.component('v-pagination', VPagination);
app.component('loading-spinner', LoadingSpinner);
app.component('progress-spinner', ProgressSpinner);
app.component('white-progress-spinner', WhiteProgressSpinner);
app.component('success-alert', SuccessAlert);
app.component('success-alert-mobile', SuccessAlertMobile);
app.component('success-alert-cashout', SuccessAlertCashout);
app.component('danger-alert', ErrorAlert);
app.component('danger-alert-mobile', ErrorAlertMobile);
app.component('login-modal', LoginModal);
app.component('game-modal', GameModal);
app.component('desktop-header', DesktopHeader);
app.component('desktop-footer', DesktopFooter);
app.component('marquee-text', MarqueeText);
// app.use(VueCookies, {
//     expireTimes: "30d",
//     path: "/",
//     domain: "",
//     secure: true,
//     sameSite: "None"
// });
app.config.globalProperties.$http = CustomHttp;
app.config.globalProperties.$axios = axios;
app.mount('#app');

app.config.globalProperties.$filters = {
  currency(value) {
    if (value || value == 0) {
      return Number(
        ((value >= 0 || -1) * Math.round(Number(Math.abs(value)) * 100)) / 100
      )
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    } else {
      return 0;
    }
  },
  currencyDecimal(value) {
    if (value || value == 0) {
      // value = 111100.0;
      let tempValue = parseFloat(value);
      if (Number(tempValue) % 1 != 0) {
        // tempValue = Number(tempValue).toFixed(2).toString();
        tempValue = (Math.round(Number(tempValue) * 100) / 100)
          .toFixed(2)
          .toString();
      }
      tempValue = tempValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return tempValue;
    } else {
      return 0;
    }
  },
};
