// game menu icon
import hot from './../icons/menu2/icn-hot.png';
import hotActive from './../icons/menu2/icn-hot-checked.png';
import sb from './../icons/menu2/icn-sportsbook.png';
import sbActive from './../icons/menu2/icn-sportsbook-check.png';
import lc from './../icons/menu2/icn-lc.png';
import lcActive from './../icons/menu2/icn-lc-checked.png';
import slot from './../icons/menu2/icn-slot.png';
import slotActive from './../icons/menu2/icn-slot-checked.png';
import cards from './../icons/menu2/icn-card.png';
import cardsActive from './../icons/menu2/icn-card-checked.png';
import fishing from './../icons/menu2/icn-fishing.png';
import fishingActive from './../icons/menu2/icn-fishing-checked.png';
import cockft from './../icons/menu2/icn-cockft.png';
import cockftActive from './../icons/menu2/icn-cockft-checked.png';

import gameHot from './../../assets/script/catalogMobile/hot';
import gameSb from './../../assets/script/catalogMobile/sportsbook';
import gameLive from './../../assets/script/catalogMobile/live';
import gameSlot from './../../assets/script/catalogMobile/slot';
import gameCards from './../../assets/script/catalogMobile/cards';
import gameFishing from './../../assets/script/catalogMobile/fish';
import gameCock from './../../assets/script/catalogMobile/eports';
var mobileImport = {
  menuIcons: [
    {
      name: 'HOT2',
      isActive: false,
      iconUrl: hot,
      iconUrlActive: hotActive,
      games: gameHot.items,
    },
    {
      name: 'SPORTSBOOK2',
      isActive: false,
      iconUrl: sb,
      iconUrlActive: sbActive,
      games: gameSb.items,
    },
    {
      name: 'LIVECASINO2',
      isActive: false,
      iconUrl: lc,
      iconUrlActive: lcActive,
      games: gameLive.items,
    },
    {
      name: 'SLOT2',
      isActive: false,
      iconUrl: slot,
      iconUrlActive: slotActive,
      games: gameSlot.items,
    },
    {
      name: 'FISHING2',
      isActive: false,
      iconUrl: fishing,
      iconUrlActive: fishingActive,
      games: gameFishing.items,
    },
    {
      name: 'CARDS2',
      isActive: false,
      iconUrl: cards,
      iconUrlActive: cardsActive,
      games: gameCards.items,
    },
    {
      name: 'COCKFT',
      isActive: false,
      iconUrl: cockft,
      iconUrlActive: cockftActive,
      games: gameCock.items,
    },
  ],
};

export default mobileImport;
