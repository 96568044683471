import gameImport from './../game';

import king from './../../images/game2/cards/img-thaihilo.png';
import sicbo from './../../images/game2/cards/img-sicbo.png';


var gameMenu = {
  name: 'CARDS',
  items: [
    {
      name: 'Thai Hi-Lo',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-204',
      img: [{ langId: 0, src: king }],
      img2: [{ langId: 0, src: king }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Sic Bo',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-204',
      img: [{ langId: 0, src: sicbo }],
      img2: [{ langId: 0, src: sicbo }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
  ],
};

export default gameMenu;
