<template>
  <div class="body-wrap">
<!--     <div class="container1">
      <div class="main-img"></div>
      <div class="centered">{{ $t('FAQ') }}</div>
    </div> -->
    <h1 class="mt-3">Help & Faq</h1>
    <hr style="color:#c2c9e6" class="mx-4"><br />

    <!-- Small Thumbnail -->
    <div class="content-wrapper" v-if="langId == '0' || langId == '3'">
      <dl class="faq">
        <dt>How can I become a member?</dt>
        <dd>
          Please contact us to find out how to become a member. Welcome to join
          us!
        </dd>
        <dt>How can I place bet?</dt>
        <dd>
          You can place your bet with us via our website on the Internet or
          Mobile Wap Service and in accordance to our rules and regulations
          stated.
        </dd>
        <dt>When can I place bet?</dt>
        <dd>
          You are welcome to place your bet with us anytime at your convenience.
        </dd>
        <dt>What is the minimum and maximum bet amount?</dt>
        <dd>
          At YBET, the minimum and maximum varies for different users and
          different matches. You will see the acceptable bet range in the place
          bet panel.
        </dd>
        <dt>How do I deposit money into my YBET Account?</dt>
        <dd>
          We offer different methods for depositing money into your account at
          YBET. You may contact our Customer Service for further assistance.
        </dd>
        <dt>Can I cancel my bet?</dt>
        <dd>
          You cannot cancel a bet once it has been accepted by system. If you
          suspect any problem, please contact our customer service immediately.
        </dd>
        <dt>What if I cannot login?</dt>
        <dd>
          If you are certain that you cannot login using the correct username
          and password, your account may be suspended for various reasons.
          Please contact our YBET Customer Service to resolve the issue.
        </dd>
        <dt>What if I forgotten my password?</dt>
        <dd>
          If forget your username or password, please contact our YBET
          Customer Service for assistance.
        </dd>
        <dt>Can I let someone else bet with my account?</dt>
        <dd>
          For the security of your account and personal information, we suggest
          that you do NOT disclose your Username or Password to any other
          person.
        </dd>
        <dt>Is my personal information protected safely?</dt>
        <dd>
          Sure, all your personal information are securely protected with strict
          confidentiality which in accordance with our Privacy Policy.
        </dd>
      </dl>
    </div>
    <div class="content-wrapper" v-if="langId == '1'">
      <dl class="faq">
        <dt>How can I become a member?</dt>
        <dd>
          Please contact us to find out how to become a member. Welcome to join
          us!
        </dd>
        <dt>How can I place bet?</dt>
        <dd>
          You can place your bet with us via our website on the Internet or
          Mobile Wap Service and in accordance to our rules and regulations
          stated.
        </dd>
        <dt>When can I place bet?</dt>
        <dd>
          You are welcome to place your bet with us anytime at your convenience.
        </dd>
        <dt>What is the minimum and maximum bet amount?</dt>
        <dd>
          At YBET, the minimum and maximum varies for different users and
          different matches. You will see the acceptable bet range in the place
          bet panel.
        </dd>
        <dt>How do I deposit money into my YBET Account?</dt>
        <dd>
          We offer different methods for depositing money into your account at
          YBET. You may contact our Customer Service for further assistance.
        </dd>
        <dt>Can I cancel my bet?</dt>
        <dd>
          You cannot cancel a bet once it has been accepted by system. If you
          suspect any problem, please contact our customer service immediately.
        </dd>
        <dt>What if I cannot login?</dt>
        <dd>
          If you are certain that you cannot login using the correct username
          and password, your account may be suspended for various reasons.
          Please contact our YBET Customer Service to resolve the issue.
        </dd>
        <dt>What if I forgotten my password?</dt>
        <dd>
          If forget your username or password, please contact our YBET
          Customer Service for assistance.
        </dd>
        <dt>Can I let someone else bet with my account?</dt>
        <dd>
          For the security of your account and personal information, we suggest
          that you do NOT disclose your Username or Password to any other
          person.
        </dd>
        <dt>Is my personal information protected safely?</dt>
        <dd>
          Sure, all your personal information are securely protected with strict
          confidentiality which in accordance with our Privacy Policy.
        </dd>
      </dl>
    </div>
    <div class="content-wrapper" v-if="langId == '2'">
      <dl class="faq">
        <dt>ฉันจะวางเดิมพันได้อย่างไร ?</dt>
        <dd>
          คุณสามารถวางเดิมพันกับเราผ่านทางเวบไวค์โดยระบบอินเตอร์เนตอย่างเดียวเท่านั้นตามที่ระบุไว้ในกฎกติกา
        </dd>

        <dt>ฉันสามารถยกเลิกการเดิมพันได้หรือไม่ ?</dt>
        <dd>
          เมื่อเราได้ยอมรับรายการเดิมพันของคุณแล้ว คุณไม่สามารถยกเลิกได้
          กติกานี้ได้ถูกโพสต์ไว้ในกฎและกติกาของเวบไซค์เรียบร้อยแล้ว
          เป็นหน้าที่ของลูกค้าที่จะต้องทำความคุ้นเคยกับกติกาเหล่านี้
        </dd>

        <dt>ข้อมูลส่วนตัวของฉันปลอดภัยหรือเปล่าเมื่อเล่นกับเวบYBET.com ?</dt>
        <dd>
          ใช่คะ
          ข้อมูลส่วนตัวของคุณที่ให้ไว้กับทางเวบไซค์จะถูกเก็บไว้อย่างเป็นความลับคะ
        </dd>

        <dt>ฉันจะทำอย่างไรถ้าฉันลืมยูสเซอร์เนม หรือ พาสเวริ์ด ?</dt>
        <dd>
          กรุณาติดต่อฝ่ายบริการลูกค้าของเราโดยด่วนทาง อีเมลล์ โทรศัพท์ หรือ
          แฟกซ์
        </dd>

        <dt>ถ้าฉันต้องการเปลี่ยนแปลงข้อมูลส่วนตัวของฉัน ?</dt>
        <dd>
          กรุณาติดต่อฝ่ายบริการลูกค้าเพื่ออัพเดทข้อมูลปัจจุบันที่ต้องการเปลี่ยนแปลงโดยด่วน
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
//Vue export
export default {
  data() {},
  methods: {},
  computed: {
    langId() {
      return this.$store.getters.langId.toString();
    },
  },
};
</script>
<style scoped>
.body-wrap {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 100%;
  font-family: 'Montserrat';
  /* height: 823px; */
  margin-bottom: 5em;
}
* {
  padding: 0px;
  margin: 0px;
}

h1 {
  font-weight: bold;
  color: #ff9611;
  margin-top: 0px;
  font-size: 20px;
  text-align: center;
  font-family: SegoeUI;
  margin-bottom: 1em;
}


h3 {
  font-size: 1.5em;
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}

.main-img {
  max-width: 1200px;
  height: 200px;
  background-image: url('../../../../assets/images/faq.jpg');
  background-size: cover;
  background-position: center;
}

.container1 {
  text-align: center;
  max-width: 1200px;
  height: 200px;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  background-color: #1c3452;
  padding: 0px;
}

.centered {
  font-size: 2em;
  text-shadow: 2px 2px #000;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrapper {
  font-size: 15px;
  margin: 0 auto;
  text-align: justify;
  padding: 0 20px;
}

.faq {
  counter-reset: my-badass-counter;
}

.faq dt {
  color: #1c327e;
  position: relative;
  text-align: left;
}
.faq dd {
  color: #1c327e;
  position: relative;
  font-size: small;
  text-align: left;
}

.faq dt:before {
  color: #1c327e;
  content: counter(my-badass-counter);
  counter-increment: my-badass-counter;
  position: absolute;
  left: 0;
  top: 2px;
  font: bold medium Sans-Serif;
}

.faq dd {
  margin: 0 0 30px 0;
}

.faq dt,
.faq dd {
  padding-left: 20px;
}
</style>
