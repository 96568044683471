<template>
  <loading-spinner v-if="isLoading"></loading-spinner>
  <transition name="fadeInTop">
    <div v-if="showSuccessAlert">
      <success-alert-mobile :message="successMessage"></success-alert-mobile>
    </div>
  </transition>
  <transition name="fadeInTop">
    <div class="mobile" v-if="showErrorAlert">
      <danger-alert-mobile :message="errorMessage"></danger-alert-mobile>
    </div>
  </transition>
  <div id="main" class="content-section2">
    <ul
      class="list-group"
      style="width: 92%; margin: auto; padding-top: 10px"
    >
      <!---- user nickname -->
      <div class="my-account-heading">{{$t('Setting')}}</div>
<!--       <li class="list-group-item">
        <span class="m_left"> {{ $t("NickNameLabel") }}</span>
        <span class="m_right">
          <span class="padding-right-20" v-if="inputNickname != ''">
            {{ inputNickname }}
          </span>
          <a
            v-if="inputNickname == ''"
            class="padding-right-10"
            @click="clearNicknameErr()"
            href="#GetNickName"
            role="button"
            data-toggle="modal"
            style="color: inherit"
          >
            <i class="fa fa-edit" style="width: 35px; cursor: pointer"></i>
          </a>
        </span>
      </li> -->
      <!---- user password -->
      <li class="list-group-item col-spaced">
        <span class="m_left">
          {{$t('setting_wallet')}}
        </span>
        <div class="wallet " @click="toggleWallet()" :class="(autoTransferWallet) ? 'wallet-on' : 'wallet-off'">
          <template v-if="autoTransferWallet">{{$t('setting_on')}}</template>
          <template v-else>{{$t('setting_off')}}</template>
          <span class="wallet-circle"></span>
        </div>
      </li>
      <li class="list-group-item">
        <span class="m_left"> {{ $t("Password2") }}</span>
        <span class="m_right padding-right-10">
          <a
            href="#GetPassword"
            role="button"
            data-toggle="modal"
            style="color: inherit"
            @click="resetPasswordError"
          >
          <img src="./../../../assets/icons/icn-change.svg"/>
          </a>
        </span>
      </li>
      <!---- user language -->
      <li class="list-group-item">
        <span class="m_left">{{ $t("LanguageLabel") }}</span>
        <span class="m_right" data-toggle="dropdown">
          <a
            class="dropdown-toggle"
            href="#"
            id="ddlDates"
            style="
              color: inherit;
              width: 100%;
              padding-right: 10px;
              text-decoration: none;
            "
          >
            {{ selectedLang.remark }}
          </a>
          <img class="pointer padding-right-10" src="./../../../assets/icons/icn-change.svg"/>
          <ul
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="ddlDates"
            style="width: 150px; text-align: center"
          >
            <li
              v-for="(lang, index) in languageList"
              :key="lang.code"
              class="cursor-pointer"
              @click="onClickLanguage(index)"
            >
              <a class="dropdown-item">
                {{ lang.remark }}
              </a>
            </li>
          </ul>
        </span>
      </li>
      <!---- user match display time -->
      <li class="list-group-item">
        <span class="m_left" style="width: 30%">
          {{ $t("MatchDisplayTime") }}
        </span>
        <span class="m_right col-center" style="width: 70%" >
          <a
            class="dropdown-toggle"
            href="#"
            role="button"
            id="ddlTimeZone"
            data-toggle="dropdown"
            style="
              color: inherit;
              width: 100%;
              padding-right: 10px;
              text-decoration: none;
              display: block;
              white-space: normal;
              text-align: right;
            "
          >
            {{ selectedTimezone.desc }}
          </a>
          <img class="pointer padding-right-10" src="./../../../assets/icons/icn-change.svg" data-toggle="dropdown"/>
          <ul
            id="ddlTZ"
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="ddlTimeZonesList"
            style="transform: none"
          >
            <li
              v-for="(tz, index) in timeZoneList"
              :key="tz.value"
              @click="onClickTimezone(index)"
            >
              <a
                class="dropdown-item"
                href="#"
                style="white-space: normal; padding: 0.25em"
                >{{ tz.desc }}</a
              >
            </li>
          </ul>

          <div
            id="GetNickName"
            class="modal fade modal-get-offer hide"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
            data-backdrop="false"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h3>{{ $t("NickNameLabel") }}</h3>
                  <a
                    href="#"
                    ref="closeNicknameBtn"
                    class="close"
                    style="line-height: 38px"
                    data-dismiss="modal"
                    @click="resetInputNickname"
                    >×</a
                  >
                </div>
                <div class="modal-body">
                  <div class="form">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control ddlbtm"
                        name="nickName"
                        id="txtNickName"
                        v-model="inputNickname"
                        :placeholder="$t('NickNameLabel')"
                        v-on:input="onChangeNickname()"
                        maxlength="50"
                      />
                      <div
                        id="divErrMsg"
                        class="Error"
                        style="margin-top: 10px"
                        v-html="$t(errNickname)"
                      ></div>
                    </div>
                    <div>
                      <input
                        type="button"
                        class="btn btnsubmit"
                        id="btnNickName"
                        @click="onClickSubmitNickName()"
                        :value="$t('Submit')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </li>
    </ul>

    <div
      id="GetPassword"
      data-backdrop="false"
      class="modal fade modal-get-offer hide"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header col-center" style="justify-content:flex-start;">
            <img class="pointer padding-right-10" src="./../../../assets/icons/icn-back.svg" data-dismiss="modal" ref="closePasswordBtn"/>
            <div class="my-account-heading my-0">{{ $t("ChangePassword") }}</div>
          </div>
          <div class="modal-body">
            <div class="form">
              <div class="form-group">
                <input
                  type="password"
                  class="form-control ddlbtm"
                  name="password"
                  id="txtOpw2"
                  v-model.trim="inputCurrent"
                  :placeholder="$t('CurrentPassword')"
                  v-on:input="onChangePassword"
                />
                <label id="lblErrOpw" class="Error"> </label>
                <label
                  id="lblErrNpw"
                  class="Error"
                  v-if="errInputCurrent && passwordFormSubmit"
                >
                  {{ $t("Passwordmustnotbeblank") }}
                </label>
              </div>
              <div class="form-group">
                <input
                  type="password"
                  class="form-control ddlbtm"
                  name="password"
                  id="txtNpw2"
                  v-model.trim="inputNew"
                  :placeholder="$t('NewPassword')"
                  v-on:input="onChangePassword"
                />
                <label
                  id="lblErrNpw"
                  class="Error"
                  v-if="errInputNew && passwordFormSubmit"
                >
                  {{ $t("Passwordmustnotbeblank") }}
                </label>
              </div>
              <div class="form-group">
                <input
                  type="password"
                  class="form-control ddlbtm"
                  name="password"
                  id="txtCpw2"
                  :placeholder="$t('ConfirmPassword')"
                  v-model.trim="inputConfirm"
                  v-on:input="onChangePassword"
                />
                <label
                  id="lblErrCpw"
                  class="Error"
                  v-if="errInputConfirm && passwordFormSubmit"
                >
                  {{ $t("Passwordmustnotbeblank") }}
                </label>
              </div>
              <div class="text-center mt-2">
                <input
                  type="button"
                  class="btn btnsubmit"
                  id="btnPassword"
                  :value="$t('Submit2')"
                  @click="onClickSavePassword"
                  :disabled="
                    !validateFlag1 ||
                    !validateFlag2 ||
                    !validateFlag3 ||
                    !validateFlag4 ||
                    !validateFlag5
                  "
                />
              </div>
              <div>
                <label v-if="errPasswordMessage != ''" id="lblErrStatus" class="Error">
                  {{ errPasswordMessage }}
                </label>
              </div>
              <div class="Error mt-3">
               <div
                 class="col-center"
                 :class="[validateFlag1 ? 'text-success' : 'text-muted']"
               >
                 <i class="fas fa-check-circle"></i>
                 <span class="password-info pl-2">
                   {{ $t('Password_RemarkLength') }}
                 </span>
               </div>
               <div
                 class="col-center"
                 :class="[validateFlag2 ? 'text-success' : 'text-muted']"
               >
                 <i class="fas fa-check-circle"></i>
                 <span class="password-info pl-2">
                   {{ $t('Password_RemarkInclude') }}
                 </span>
               </div>
               <div
                 class="col-center"
                 :class="[validateFlag3 ? 'text-success' : 'text-muted']"
               >
                 <i class="fas fa-check-circle"></i>
                 <span class="password-info pl-2">
                   {{ $t('Password_RemarkExclude') }}
                 </span>
               </div>
              <div
                class="col-center"
                :class="[validateFlag4 ? 'text-success' : 'text-muted']"
              >
                <i class="fas fa-check-circle"></i>
                <span class="password-info pl-2">
                  {{ $t('ConfirmPasswordMustSame') }}
                </span>
              </div>
              <div
                class="col-center"
                :class="[validateFlag5 ? 'text-success' : 'text-muted']"
              >
                <i class="fas fa-check-circle"></i>
                <span class="password-info pl-2">
                  {{ $t('NewPasswordCannotBeSameAsOldPassword') }}
                </span>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Vue export
// import _ from 'lodash';
import i18n from "../../../i18n";
const { t } = i18n.global;
import CustomHttp from "../../../CustomHttp";
import PreferenceImport from "../../../assets/script/preference";
import CustomEncrypt from "../../../CustomEncrypt";
// const capitalize = ([firstLetter, ...restOfWord]) =>
//   firstLetter.toUpperCase() + restOfWord.join('');
export default {
  emits: ["toggle-login", "set-component"],
  mounted() {
    this.inputNickname = this.$store.getters.nickname;
    this.username = this.$store.getters.nickname;
    // this.inputNickname = "";
    this.getUserPreference();
  },
  data() {
    return {
      isLoading: false,
      showSuccessAlert: false,
      preferenceSnap: null,
      password: "",
      confirmPassword: "",
      inputNickname: "",
      errNickname: "",
      // successNickname: '',
      timeZoneList: PreferenceImport.TimeZone,
      languageList: PreferenceImport.Language,
      //user setting
      selectedTimezone: "",
      selectedLang: "",
      //input parameter
      inputCurrent: "",
      inputNew: "",
      inputConfirm: "",
      //err handling
      passwordFormSubmit: false,
      errInputConfirm: false,
      errInputNew: false,
      errInputCurrent: false,
      errPasswordMessage: "",
      showErrorAlert: false,
      errorMessage: "",
      autoTransferWallet: false,
      validateFlag1: false,
      validateFlag2: false,
      validateFlag3: false,
      validateFlag4: false,
      validateFlag5: false,
      username : '',
    };
  },
  methods: {
    restrictInputPassword(){
      this.password = this.password.replace(/(\r\n|\n|\r)/gm,"");
    },
    async toggleWallet(){
      if(this.autoTransferWallet == false){
        this.autoTransferWallet = true;

        let json = { username: this.$store.getters.username, autoTransfer: true };
        let response = await CustomHttp.post("api/v1/member/update-autotransfer", json);

        if(response.data.success == true){
          this.toggleSuccessAlert("Main Wallet Auto Transfer Successfully Updated");
          this.$store.dispatch("setAutoTransferWallet", true);
        }

      }else{
        this.autoTransferWallet = false;

        let json = { username: this.$store.getters.username, autoTransfer: false };
        let response = await CustomHttp.post("api/v1/member/update-autotransfer", json);

        if(response.data.success == true){
          this.toggleSuccessAlert("Main Wallet Auto Transfer Successfully Updated");
          this.$store.dispatch("setAutoTransferWallet", false);
        }

      }
    },
    resetPasswordError() {
      this.passwordFormSubmit = false;
      this.errInputConfirm = false;
      this.errInputNew = false;
      this.errInputCurrent = false;
      this.errPasswordMessage = "";
      this.inputCurrent = "";
      this.inputNew = "";
      this.inputConfirm = "";
    },
    resetInputNickname() {
      this.errNickname = "";
      this.inputNickname = "";
    },
    onChangeNickname() {
      this.errNickname = "";
      // eslint-disable-next-line
      const english = /^([a-zA-Z0-9!#$%^&*@_.()+\-=\[\]{};':"\\|,<>\/?]+)$/;
      //const english = /^[A-Za-z0-9]*$/;
      // eslint-disable-next-line
      const format = /[!#$%^&*()+\-=\[\]{};':"\\|,<>\/?]+/;
      if (this.inputNickname != "" && !english.test(this.inputNickname)) {
        this.errNickname = "Only alphanumeric are allowed";
      } else if (format.test(this.inputNickname)) {
        this.errNickname = "Special characters other";
      } else if (this.inputNickname.length > 50) {
        this.errNickname = "Nick name must be less than 50 characters";
      } else {
        this.errNickname = "";
      }
    },
    async onSaveLanguage() {
      let json = {
        username: this.$store.getters.username,
        langId: this.preferenceSnap.langId.toString(),
      };
      this.isLoading = true;
      const response = await CustomHttp.post("/api/v1/member/update-language", json);
      // this.isLoading = false;
      const responseData = response.data;
      if (responseData.success) {
        this.$store.dispatch("setLangId", this.preferenceSnap.langId.toString());
        this.isLoading = false;
        //this.$emit("set-component", "screen-landing");
        
      } else {
        this.toggleDangerAlert(responseData.error.message);
        this.isLoading = false;
      }
    },
    async onSavePreference() {
      if (this.selectedTimezone.value == null) {
        this.toggleDangerAlert(t("MatchDisplayTimeCannotEmpty"));
        return;
      }

      let json = {
        username: this.$store.getters.username,
        langId: this.preferenceSnap.langId.toString(),
        oddsType: Number(this.preferenceSnap.oddsType),
        oddsView: Number(this.preferenceSnap.oddsView),
        defaultStake: Number(this.preferenceSnap.defaultStake),
        acceptBetterOdds: Number(this.preferenceSnap.acceptBetterOdds),
        sortBy: Number(this.preferenceSnap.sortBy),
        quickBetAmt: Number(this.preferenceSnap.quickBetAmt),
        timezone: Number(this.selectedTimezone.value),
      };
      this.isLoading = true;
      const response = await CustomHttp.post("/api/v1/member/update-preference", json);
      // this.isLoading = false;
      const responseData = response.data;
      if (responseData.success) {
        //update match display time
        await this.getUserPreference();
        this.isLoading = false;
      } else {
        this.toggleDangerAlert(responseData.error.message);
        this.isLoading = false;
      }
      // this.getUserPreference();
    },
    async onClickTimezone(i) {
      if (
        this.selectedTimezone.value.toString() == this.timeZoneList[i].value.toString()
      ) {
        return;
      } else {
        this.selectedTimezone = this.timeZoneList[i];
        this.onSavePreference();
      }
    },
    async onClickLanguage(i) {
      if (this.selectedLang.value.toString() == this.languageList[i].value.toString()) {
        return;
      } else {
        this.preferenceSnap.langId = this.languageList[i].value.toString();
        this.onSaveLanguage();
      }
    },
    async onClickSavePassword() {
      this.errPasswordMessage = "";
      this.passwordFormSubmit = true;
      this.onChangeNewPassword();
      if (this.errInputNew || this.errInputCurrent || this.errInputConfirm) {
        return;
      }

      if (!(this.inputNew.length >= 8 && this.inputNew.length <= 15)) {
        this.errPasswordMessage = t("Password_RemarkLength");
      }
      // eslint-disable-next-line
      // const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      //format.test(this.inputNew) &&
      if (!/\d/.test(this.inputNew)) {
        this.errPasswordMessage = t("InvalidPassword");
      }
      const username = this.$store.getters.nickname;
      // const nickname = this.$store.getters.nickname;
      if (
        this.inputNew.toLowerCase().includes(username.toLowerCase())
      ) {
        this.errPasswordMessage = t("InvalidPassword");
      }
      if (!(this.inputNew == this.inputConfirm)) {
        this.errPasswordMessage = t("ConfirmPasswordMustSame");
      }

      if (!(this.inputCurrent != this.inputNew)) {
        this.errPasswordMessage = t("NewPasswordCannotBeSameAsOldPassword");
      }

      if (this.errPasswordMessage != "") {
        return;
      }

      const oldPassword = CustomEncrypt.encrypt(this.inputCurrent).toString();
      const newPassword = CustomEncrypt.encrypt(this.inputNew).toString();
      let json = {
        username: this.$store.getters.username,
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      this.isLoading = true;
      let response;
      try {
        response = await CustomHttp.post("/api/v1/member/update-pw", json);
      } catch (error) {
        this.isLoading = false;
        let tempError = error.message;
        tempError = tempError.replace("Error:", "").trim();
        tempError = t(tempError);
        this.errPasswordMessage = tempError;
        // this.toggleDangerAlert(tempError.toString());
        return;
      }

      this.passwordFormSubmit = false;
      this.isLoading = false;
      const responseData = response.data;
      if (responseData.error == null) {
        this.$refs.closePasswordBtn.click();
        this.errInputCurrent = false;
        this.errInputNew = false;
        this.errInputConfirm = false;
        this.inputCurrent = "";
        this.inputConfirm = "";
        this.inputNew = "";
        this.toggleSuccessAlert(t("Passwordupdatedsuccessfully"));
      } else {
        this.errPasswordMessage = responseData.error.message;

        //this.toggleDangerAlert(responseData.error.message);
      }
    },
    onChangeNewPassword() {
      if (
        this.inputCurrent == ""
          ? (this.errInputCurrent = true)
          : (this.errInputCurrent = false)
      );
      if (this.inputNew == "" ? (this.errInputNew = true) : (this.errInputNew = false));
      if (
        this.inputConfirm == ""
          ? (this.errInputConfirm = true)
          : (this.errInputConfirm = false)
      );
    },
    onChangePassword() {
      this.restrictInputPassword();

      this.validateFlag1 = false;
      this.validateFlag2 = false;
      this.validateFlag3 = false;
      this.validateFlag4 = false;
      this.validateFlag5 = false;
      if (this.inputNew.length >= 8 && this.inputNew.length <= 15) {
        this.validateFlag1 = true;
      }
      // eslint-disable-next-line
      // const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      //format.test(this.inputNew) &&
      // if (/\d/.test(this.inputNew)) {
      //   this.validateFlag2 = true;
      // }
      // eslint-disable-next-line
      if (/^(?=.*[A-Za-z])(?=.*\d)/.test(this.inputNew)) {
        this.validateFlag2 = true;
      }
      // const nickname = this.$store.getters.nickname;
      if (
        !this.inputNew.toLowerCase().includes(this.username.toLowerCase())
      ) {
        this.validateFlag3 = true;
      }
      if (this.inputNew == this.inputConfirm) {
        this.validateFlag4 = true;
      }
      if (this.inputCurrent != this.inputNew) {
        this.validateFlag5 = true;
      }
    },
    clearNicknameErr() {
      this.errNickname = "";
    },
    async onClickSubmitNickName() {
      if (this.errNickname != "") {
        return;
      }

      if (this.inputNickname.length < 5) {
        this.errNickname = t("NicknameMustBeAtLeast5Characters");
        return;
      }
      if (this.inputNickname == "") {
        this.errNickname = "Nickname is required";
        return;
      }
      //prevent input value lost
      const tempNickName = this.inputNickname;
      const json = {
        username: this.$store.getters.username,
        nickname: this.inputNickname,
      };
      /// promt confirm
      this.isLoading = true;

      try {
        await CustomHttp.post("api/v1/member/update-nickname", json);
        this.isLoading = false;
        this.$refs.closeNicknameBtn.click();
        this.inputNickname = tempNickName;
        this.$store.dispatch("setMemberNickname", this.inputNickname);
        this.toggleSuccessAlert("Nick name is updated");
      } catch (err) {
        this.isLoading = false;
        let tempError = err.message;
        tempError = tempError.replace("Error:", "").trim();
        tempError = t(tempError);
        //err
        this.errNickname = tempError;
        //this.errNickname = capitalize(responseData.message);
      }
    },
    toggleSuccessAlert(message) {
      this.successMessage = message;
      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 1900);
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1900);
    },
    async getUserPreference() {
      this.isLoading = true;
      const json = { username: this.$store.getters.username };
      const response = await CustomHttp.post("api/v1/member/get-preference", json);
      const responseData = response.data.data;
      this.preferenceSnap = responseData;
      this.isLoading = false;
      this.selectedLang = this.languageList.find(
        (item) => item.value == responseData.langId
      );
      this.autoTransferWallet = responseData.autoTransfer;
      //handle dont have 0 in list
      if (responseData.timezone.toString() == "0") {
        this.selectedTimezone = { value: null, desc: null };
      } else {
        this.selectedTimezone = this.timeZoneList.find(
          (item) => item.value.toString() == responseData.timezone.toString()
        );
      }
      //end new section
    },
  },
};
</script>
<style scoped>
.dropdown-toggle::after{
  display: none;
}
.m_right{
  color: #2f4798;
}
.m_left{
  color: #5c5f70;
}
.my-account-heading{
  color: #4856a7;
  margin: 1em 0;
}
.content-section2 {
  margin-top: 1px !important;
}

.form-control,
.form-control:hover,
.form-control:active,
.form-control:link,
.form-control:valid {
  border: 0;
  border: 1px solid #caccd4;
  display: inline-block;
  color: inherit;
}

.dropdown-menu.show {
  display: block;
  z-index: 1050;
}

.Error {
  font-size: 13px !important;
}
.dropdown-menu {
  font-size: inherit;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-right-10 {
  padding-right: 10px;
}
.m_right {
  max-width: 70%;
  word-wrap: break-word;
}

#GetPassword{
  margin-top: 3em;
}
#GetPassword .modal-dialog{
  margin: 0;
}
#GetNickName{
  margin-top: 4.5em;
}
.wallet{
  color: white;
  font-size: small;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 20px;
  min-width: 60px;
  font-size: small;
  font-weight: 500;
  margin-left: 1em;
  cursor: pointer;
}
.wallet-on{
  background: #5aaf4b;
  padding: 2px 4px 4px 10px;
}
.wallet-off{
  background: #bbc4d5;
  padding: 2px 4px 4px 4px;
}
.wallet-circle{
  float: right;
  display: inline-block;
  content: " ";
  width: 20px;
  background: white;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.48);
}
.wallet-off .wallet-circle{
   float: left;
   margin-right: 0.5em;
}
#ddlTZ {
  top: 24em !important;
  bottom: -12em !important;
  overflow-y: auto;
  position: fixed !important;
  left: 1em !important;
}
.btnsubmit{
  box-shadow: none;
}
.btnsubmit:disabled{
  background: #a1adc2;
}
</style>
