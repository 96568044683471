<template>
  <div id="home-body">
      <desktop-header></desktop-header>
      <!--- page content --->
      <div class="account-background">
        <div class="container container-min-height pt-5">
          <div class="info-header">{{$t('MyAccount')}}</div>
          <div class="row px-0">
            <!--- page header --->
            <!--- end page header --->
            <!----card section --->
            <div class="col-lg-3 py-3 px-lg-3">
              <div class="left-card">
                <div class="full-center">
                  <img
                    src="../../../../assets/icons/avatar.png"
                    class="img-avatar mt-4"
                  />
                </div>

                <template v-if="!showProgress">
                  <div
                    class="pt-2 pb-0 px-0 full-center"
                  >
                    <span class="username-text"> {{ nickname }}</span>
                  </div>
                  <div
                    class="pt-1 pb-0 px-0 full-center"
                  >
                      <img class="w-100" src="../../../../assets/icons/separator.png"/>
                  </div>

                  <div
                    class="text-center mb-4"
                  >
                    <div class="currency-text">{{ currency }}</div>
                    <span class="credit-text">
                      <span v-if="!isLoadingBalance">
                         {{ $filters.currency(totalRemainingCreditLimit) }}
                      </span>
                      <!-- <span v-if="isLoadingBalance">
                        <i class="fas fa-spinner fa-pulse"></i>
                      </span> -->
                    </span>
                    <span v-if="!isLoadingBalance">
                      <img
                        src="../../../../assets/icons/icn-refresh.png"
                        class="img-responsive ml-2 cursor-pointer"
                        alt="Responsive image"
                        style="margin-top:-7px"
                        width="22"
                        height="22"
                        @click="onClickRefreshBalance"
                      />
                    </span>
                    <span v-if="isLoadingBalance">
                      <i
                        class="fas fa-spinner fa-pulse fa-lg text-dark my-2 font-weight-bold"
                      ></i>
                    </span>

                    </div>
                  
                </template>
                <template v-if="showProgress">
                  <div class="full-center">
                    <progress-spinner></progress-spinner>
                  </div>
                </template>

                <template v-for="(tab, index) in tabList" :key="tab.name">
                  <div
                    class="cursor-pointer"
                    @click="onClickTab(index)"
                    :class="[ selectedTabIndex == index ? 'account-tab-active' : 'account-tab']"
                  >
                    <div :class="[selectedTabIndex == index ? 'tab-label-active' : 'tab-label']">
                      <img
                      :src="selectedTabIndex == index ? tab.iconUrlActive : tab.iconUrl "
                      class="img-tab mx-1" />
                      {{ $t(tab.name) }}
                    </div>
                  </div>
                </template>
              </div>
            </div>



              <template v-if="selectedTabIndex == 0 && !showProgress">
                <user-info></user-info>
              </template>
              <template v-if="selectedTabIndex == 1 && !showProgress">
                <user-preference></user-preference>
              </template>
              <template v-if="selectedTabIndex == 2 && !showProgress">
                <change-password></change-password>
              </template>
            <template v-if="showProgress">
              <div class="col-lg-8 p-3">
                <div class="card left-card py-5">
                  <progress-spinner></progress-spinner>
                </div>
              </div>
            </template>

            <!---end card section -->
          </div>
        </div>
        </div>
      <!----end page comtent --->
      <!-- Footer-->
      <desktop-footer></desktop-footer>

    <!--globa  element component  --->
    <loading-spinner v-if="isLoading"></loading-spinner>
    <!-- <transition name="fadeIn">
      <div v-if="showSuccessAlert">
        <success-alert :message="successMessage"></success-alert>
      </div>
    </transition>
    <transition name="fadeIn">
      <div v-if="showErrorAlert">
        <danger-alert :message="errorMessage"></danger-alert>
      </div>
    </transition> -->
  </div>
</template>

<script>
import accIcn from '../../../../assets/icons/icn-account.png';
import accIcnChecked from '../../../../assets/icons/icn-account.png';

import icnPw from '../../../../assets/icons/icn-changepw.png';
import icnPwChecked from '../../../../assets/icons/icn-changepw.png';

import icnPreference from '../../../../assets/icons/icn-preference.png';
import icnPreferenceChecked from '../../../../assets/icons/icn-preference.png';
// import CustomHttp from '../../../CustomHttp';
import UserInfo from './Info';
import ChangePassword from './ChangePassword';
import UserPreference from './Preference';
//Vue export
export default {
  components: {
    UserInfo,
    UserPreference,
    ChangePassword,
  },
  mounted() {
    this.isLoading = false;
    this.selectedTabIndex = 0;
    this.getMemberAllBalance();
  },
  data() {
    return {
      currentDate: '',
      currentTime: '',
      showProgress: false,
      isLoading: false,
      isLoadingBalance: false,
      showErrorAlert: false,
      showSuccessAlert: false,
      successMessage: false,
      errorMessage: false,
      selectedTabIndex: 0,
      tabList: [
        {
          name: 'AccountInfo2',
          iconUrl: accIcn,
          iconUrlActive: accIcnChecked,
        },
        {
          name: 'Preferences',
          iconUrl: icnPreference,
          iconUrlActive: icnPreferenceChecked,
        },
        {
          name: 'ChangePassword',
          iconUrl: icnPw,
          iconUrlActive: icnPwChecked,
        },
      ],
    };
  },
  methods: {
    onClickTab(idx) {
      this.selectedTabIndex = idx;
    },
    toggleSuccessAlert(message) {
      this.successMessage = message;
      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 1500);
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1500);
    },
    async getMemberAllBalance() {
      // this.isLoading = true;
      this.showProgress = true;
      try {
        await this.$store.dispatch('getMemberAllBalance');
        //   this.isLoading = false;
        this.showProgress = false;
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        // this.isLoading = false;
        this.showProgress = false;
        this.toggleDangerAlert(error);
      }
    },
    async onClickRefreshBalance() {
      this.isLoadingBalance = true;
      try {
        await this.$store.dispatch('getMemberBalance');
        this.isLoadingBalance = false;
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        this.isLoadingBalance = false;
        this.toggleDangerAlert(error);
      }
    }
  },
  computed: {
    nickname() {
      return this.$store.getters.nickname;
    },
    username() {
      return this.$store.getters.username;
    },
    totalRemainingCreditLimit() {
      return this.$store.getters.totalRemainingCreditLimit;
    },
    currency() {
      return this.$store.getters.currency;
    },
  },
};
</script>

<style scoped>
.account-background{
  background: url(../../../../assets/images/main-bg.jpg) #dae2f5 no-repeat 0 0 / 100% 100%;
  min-height: 600px;
}
.bg-light-grey {
  background-color: #f7f7f7;
}

.bg-light-gold-gradient {
  background: linear-gradient(to left, rgba(241, 215, 152, 0), #f5b50a);
  border-radius: 5px;
}

.menu-text {
  margin-top: 4px;
  font-size: 11px;
  white-space: nowrap;
}

.account-text {
  font-weight: 600;
  opacity: 0.9;
  color: #36342f;
  padding-top: 3px;
  padding-bottom: 3px;
  /* font-family: SegoeUI; */
  font-size: 16px;
}

.account-btn {
  text-align: center;
  flex-wrap: wrap;
  padding: 7px 10px;
}

.left-card {
  background:  white;
  color: #2f4798;
    box-shadow: 0 9px 9px 0 rgba(141, 155, 181, 0.2);
}

.img-avatar {
  object-fit: contain;
  width: 60px;
}
.username-text {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.21px;
  text-align: center;
  color: #2f4798;
}

.currency-text {
  font-size: 12px;
  text-align: center;
  color: #a1a1a1;
  margin-top: -1em;
  margin-bottom: 0.5em;
}

.credit-text {
  color:#000000;
  font-size: 20px;
  font-weight: 600;
}

.tab-label-active {
  background: #2f4798;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding: 15px 20px;
  border: 1px solid #2f4798;
}

.tab-label {
  font-size: 14px;
  padding: 15px 20px;
  width: 90%;
  color: #908d84;
}

.img-tab {
  width: 22px;
}

.tab-border {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.account-tab{
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.container-min-height {
  min-height: 65vh;
}
</style>
