<template>
  <section>
    <div v-if="showProgress">
      <div class="col-lg-12 p-1">
        <div class="card d-flex align-items-center">
          <white-progress-spinner></white-progress-spinner>
        </div>
      </div>
    </div>
    <template v-if="!showProgress">
      <template v-for="(bet, index) in betList" :key="bet.refNo">
        <!---- Soccer---->
        <ul
          v-if="bet.transType != 'PAR'"
          class="list-group"
          style="width: 92%; margin: auto; padding-top: 10px"
        >
          <li class="list-group-item">
            <div style="display: inline-block; width: 100%">
              <div class="left" style="width: 70%">
                <span class="Hd2B">
                  {{ bet.moduleTitle }}
                  <br />
                  {{ bet.home }}
                </span>
                -vs-
                <span class="Hd2B">
                  {{ bet.away }}
                  &nbsp;</span
                >
                {{ bet.transDesc2 }}
                <br />
                <span class="Hd2B"> {{ getWorkingDate(bet.workingDate) }}</span>
              </div>
              <div class="right" style="text-align: right">
                {{ bet.gameDesc }}
                <br />
                <span class="Hd2B">
                  {{ bet.odds }}

                  <span v-if="bet.oddsType != ''"> ({{ bet.oddsType }})</span>
                </span>
              </div>
            </div>
            <div style="display: inline-block; width: 100%">
              <div class="left">
                <!-- <span class="GBGive4">Flamengo (n)</span> -->
                <!-- &nbsp;(-1.75) -->

                <span class="GBGive4">
                  {{ bet.transDesc }}
                  <!-- <span v-if="bet.transDesc2"> {{ bet.transDesc2 }}</span> -->
                </span>
              </div>
              <div class="right b" style="text-align: right">
                <span
                  :class="[
                    bet.statusCode == 'A' || bet.statusCode == 'N' ? 'Accepted' : 'Error',
                  ]"
                >
                  {{ bet.statusDesc }}
                </span>
              </div>
            </div>
            <div class="stakeDetail">
              <div class="left align-middle" style="width: 60%">
                {{ $t("Date") }} :
                {{ getTransDate(bet.transDate) }}
                <br />
                {{ $t("Stake") }}:
                <span class="Simple">
                  {{ $filters.currency(bet.stake) }}
                </span>
              </div>
              <div class="right align-middle" style="width: 40%">ID: {{ bet.refNo }}</div>
            </div>
          </li>
        </ul>

        <!---- Parley---->
        <ul
          class="list-group"
          v-if="bet.transType == 'PAR'"
          style="width: 92%; margin: auto; padding-top: 10px"
        >
          <li class="list-group-item">
            <div style="display: inline-block; width: 100%">
              <div style="text-align: right; width: 100%">
                <a
                  class="btnCollapse3 collapsed"
                  data-toggle="collapse"
                  :href="'#collapse_0' + index"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapse_1"
                  ><span class="Hd2B"> {{ bet.odds }}</span
                  >&nbsp;&nbsp;</a
                >
              </div>
              <div
                :id="'collapse_0' + index"
                class="multi-collapse collapse"
                style="width: 100%"
              >
                <hr />

                <template v-for="bet2 in bet.parlayList" :key="bet2.transDate">
                  <div style="display: inline-block; width: 100%">
                    <div class="left" style="width: 70%">
                      <span class="Hd2B">
                        {{ bet2.moduleTitle }}<br />{{ bet2.home }}</span
                      >
                      -vs- <span class="Hd2B">{{ bet2.away }}&nbsp;</span>

                      {{ bet2.transDesc2 }}

                      <br /><span class="Hd2B">{{ bet2.matchDate }}</span>
                    </div>
                    <div class="right" style="text-align: right">
                      <span class="Hd2B"> {{ getBetOdd(bet2.odds) }}</span>
                    </div>
                  </div>
                  <div style="display: inline-block; width: 100%">
                    <div class="left">
                      <span class="GBTake4"> {{ bet2.transDesc }}</span>
                    </div>
                    <div class="right b" style="text-align: right">
                      <span v-if="bet2.statusCode == 'N'" class="Accepted">{{
                        bet.statusDesc
                      }}</span>
                      <span v-if="bet2.statusCode == 'RG'" class="Error">
                        {{ bet.statusDesc }}</span
                      >
                    </div>
                  </div>
                  <hr />
                </template>
              </div>
            </div>
            <div style="display: inline-block; width: 100%">
              <div class="left">
                <span class="HdGive">{{ $t("Mix Parlay") }}</span>
              </div>
              <div class="right b" style="text-align: right">
                <span class="Accepted"> {{ $t("ACCEPTED") }}</span>
              </div>
            </div>
            <div class="stakeDetail">
              <div class="left align-middle" style="width: 60%">
                {{ $t("Date") }} : {{ getTransDate(bet.transDate) }} <br />
                {{ $t("Stake") }}:
                <span class="Simple"> {{ $filters.currency(bet.stake) }}</span>
              </div>
              <div class="right align-middle" style="width: 40%">ID: {{ bet.refNo }}</div>
            </div>
          </li>
        </ul>
      </template>
    </template>
    <div style="height:100px">&nbsp;</div>
  </section>
</template>

<script>
// page component
import CustomHttp from "../../../../CustomHttp";
import moment from "moment";
export default {
  data() {
    return { showProgress: false, betList: [] };
  },

  mounted() {
    this.getBetList();
  },
  methods: {
    getBetOdd(odd) {
      return (odd * 10).toFixed(2);
    },
    getTransDate(date) {
      return moment(date).format("DD/MM hh:mm:ss a").toString();
    },
    getWorkingDate(date) {
      return moment(date).format("DD/MM").toString();
    },
    async getBetList() {
      const json = {
        username: this.$store.getters.username,
        langId: this.$store.getters.langId,
      };
      this.showProgress = true;
      const response = await CustomHttp.post("api/v1/member/bet-list", json);
      if (response.data.data == null) {
        this.betList = [];
      } else {
        this.betList = response.data.data.memberBetList;
      }

      const parlayList = response.data.data.parlayList;

      this.betList.forEach((item, index) => {
        let tempParlayList = parlayList.filter((p) => p.socTransId == item.socTransId);
        if (tempParlayList != undefined) {
          this.betList[index].parlayList = tempParlayList;
        }
      });
      this.showProgress = false;
    },
  }, //end methods
  computed: {
    // isTimeout() {
    //   return this.$store.getters.isTimeout;
    // },
  }, //end computed
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  background-color: inherit !important;
}

a.btnCollapse3.collapsed:before {
  font-weight: 900;
  font-family: "Font Awesome\ 5 Free";
  content: "\f067";
  color: #2f4798;
  padding-left: 7px;
}

a.btnCollapse3:before {
  font-weight: 900;
  font-family: "Font Awesome\ 5 Free";
  content: "\f068";
  color: #2f4798;
  padding-left: 7px;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid #d1c57c;
}

.list-group {
  font-size: 13px !important;
}
.list-group-item{
  border-radius: 5px;
}
</style>
