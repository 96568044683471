<template>
  <div class="card-body">
    <h1 class="frank header">Who is YBET99?</h1>
    <p class="card-text">
      YBET99是世界领先的在线游戏品牌之一。
    </p>
    <p class="card-text">
      YBET99娱乐网是全球最知名的在线博彩品牌之一。
      <br><br>
      YBET99娱乐网体育博彩每周提供超过 500 个体育活动，囊括了所有重大足球联赛和国际体育赛事。YBET娱乐网还提供全天候的足球实时更新、即刻获奖确认、迅捷的支付、方便的网络访问和快速的在线投注。
      <br><br>
      YBET娱乐网赌场是个一站式的娱乐网站，提供广泛的博彩游戏。为了满足玩家需要，YBET娱乐网赌场与澳洲 线上游戏软体商开发了“多人现场荷官” (Multi Player Live Dealer)，并获得很高评价。
      <br><br>
      YBET99娱乐网游戏将让您体验到我们独家游戏的精彩，比如刮奖卡，基诺以及卡牌游戏。所有的游戏都无须下载安装，就能免费无限试玩。
    </p>
    <p class="card-text">
      YBET99娱乐网利用最先进的技术为玩家创造快捷安全的娱乐环境。我们竭诚为用户提供卓越的娱乐体验。从消遣型的投注者到贵宾玩家，再到国际博彩公司，YBET99娱乐网为所有体育投注者量身打造广泛的产品和服务。
      <br />
      不论何时何地，您都可以轻松投注于我们所提供的广泛博彩项目。YBET99娱乐网是一个有着良好信誉的在线体育投注商，通过YBET99娱乐网下注，您可以放心的感受到我们在博彩业几十年的丰富经验带给您的信心。
    </p>

    <p class="card-text">
      无论何时，我们亲切的客户服务部门会竭力为您提供各种服务，包括帐户查询、存取资金、以及常见的投注帮助。 
    </p>
    <p class="card-text">
      敬请加入YBET99娱乐网，您值得信赖的体育博彩。
    </p>
    
  </div>
</template>

<style scoped>
 .header{
  color: #ff9611;
}
.card-text {
  font-size: 16px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  letter-spacing: 0.2px;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
}
</style>
