<template>
  <div
    :class="{
      'padding-logged': isLoggedIn && enablePaymentGateway,
      'padding-unlogged': !isLoggedIn,
      'padding-logged-60': isLoggedIn && !enablePaymentGateway,
    }"
  >
    <div class="top-section mobile-body-font">
      <nav id="mobile-nav" class="navbar fixed-top">
          <div
            class="row d-flex"
            :class="isLoggedIn ? '' : 'align-items-center'"
          >
            
              <div
                @click="toggleCollapse()"
                ref="myToggleBar"
                class="toggle-bar"
                data-toggle="collapse"
                data-target="#myNavbar"
              >
                <img src="../../../assets/icons/icn-main.png" width="32" />
              </div>
            
            <div class="px-0 col text-white">
              <img
                @click="setActiveComponent('screen-landing')"
                src="../../../assets/images/logo.png"
                alt="User Avatar"
                class="brand-image"
              />
            </div>
            <div class="pr-2 pt-1 col-flex">
              <div class="text-right font-helvetica" v-if="isLoggedIn">
                <div class="b smaller text-uppercase">{{ nickname }}</div>
                <div style="font-size:8px">
                  {{ currentDate }} {{ currentTime }}
                </div>
                <!-- 
                <div class="user-cash-container pointer" @click="onClickRefreshBalance">
                  <img src="../../../assets/icons/user-cash.png" width="12" height="12" style="position: absolute; left:0.5em"/>
                  <div class="w-100 text-right" v-if="isLoadingBalance"><i class="fas fa-spinner fa-pulse currency-spinner ml-5 mr-2"></i></div>
                  <div class="w-100 text-right" v-else>{{ $filters.currencyDecimal(totalRemainingCreditLimit) }}</div>
                  
                </div> -->
              </div>
              <router-link to="/register"
                class="btn-register cursor-pointer float-right ml-2"
                v-if="!isLoggedIn"
              >
                {{ $t('Register') }}
              </router-link>
              <div
                class="btn-login cursor-pointer float-right"
                v-if="!isLoggedIn"
                @click="toggleLogin()"
              >
                {{ $t('Login') }}
              </div>

              <!-- <router-link to="/public/login">
              <div class="btn-login cursor-pointer float-right px-3 " @click="toggleLogin()">
                Login
              </div>
            </router-link> -->
            </div>
          </div>
      </nav>
      <div class="navbar navbar-inverse">
        <div class="navbar-collapse collapse px-1 py-2" id="myNavbar" style="">
          <ul class="nav navbar-nav">
            <li>
              <a href="#" @click="setActiveComponent('screen-about-us')">
                {{ $t('AboutUs2') }}
              </a>
            </li>
            <li>
              <a href="#" @click="setActiveComponent('screen-betting-rules')">
                {{ $t('BettingRules2') }}
              </a>
            </li>
            <li>
              <a href="#" @click="setActiveComponent('screen-faq')">
                {{ $t('FAQ2') }}
              </a>
            </li>
            <li>
              <a href="#" @click="setActiveComponent('screen-why-us')">
                {{ $t('WhyUs2') }}
              </a>
            </li>
            <li>
              <a href="#" @click="setActiveComponent('screen-contact-us')">
                {{ $t('ContactUs2') }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--- credit bar--->
      <div
        id="credit"
        class="col-center spaced smaller credit-container d-none"
        v-if="isLoggedIn && enablePaymentGateway"
      >
        <div class="credit-left">
          <img src="../../../assets/icons/icn-balance-checked.png" width="18" />
          <span class="credit-balance">
            <span v-if="isLoadingBalance">
              <i class="fas fa-spinner fa-pulse currency-spinner ml-5 mr-2"></i>
            </span>
            <span
              class="Positive cursor-pointer"
              v-if="!isLoadingBalance"
              @click="onClickRefreshBalance"
            >
              {{ $filters.currencyDecimal(totalRemainingCreditLimit) }}
            </span>
          </span>
        </div>
        <div class="col-center credit-right">
          <div
            v-show="!suspend"
            id="deposit"
            class="credit-item cursor-pointer"
            @click="onClickDeposit"
          >
            <div class="text-center">
              <img
                src="../../../assets/icons/icn-deposit-cd.png?v2"
                width="40"
              />
              <br />
              <div class="mt-1 lh">{{ $t('Btn_Deposit') }}</div>
            </div>
          </div>
          <div
            v-show="!suspend"
            id="withdraw"
            class="credit-item cursor-pointer"
            @click="onClickWithdrawal"
          >
            <div class="text-center">
              <img
                src="../../../assets/icons/icn-Withdrawal-cd.png?v2"
                width="40"
              />
              <br />
              <div class="mt-1 lh">{{ $t('Btn_Withdraw') }}</div>
            </div>
          </div>
          <div class="credit-item">
            <router-link to="/history">
              <div class="text-center">
                <img
                  src="../../../assets/icons/icn-history-cd.png?v2"
                  width="40"
                />
                <br />
                <div class="mt-1 lh">{{ $t('History') }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!---backdrop---->
      <transition name="fadeIn">
        <div
          class="dark-backdrop"
          @click="closeCollapse()"
          v-if="showCollapse"
        ></div>
      </transition>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import moment from 'moment';
import CustomEncrypt from '../../../CustomEncrypt';
const registerUrl = process.env.VUE_APP_USER_REGISTER_URL;
export default {
  props: ['showCollapse'],
  emits: ['toggle-login', 'toggle-collapse', 'set-component'],
  mounted() {
    this.updateCurrentTime();
    setInterval(() => {
      this.updateCurrentTime();
    }, 1000);

    const nav = $('#mobile-nav');

    function checkPositionForNav() {
      if (window.scrollY > 30) {
        nav.addClass('nav-back');
      } else {
        nav.removeClass('nav-back');
      }
    }
    window.addEventListener('scroll', checkPositionForNav);
  },
  data() {
    return {
      currentDate: '',
      currentTime: '',
      isLoadingBalance: false,
      showSidebar: false,
    };
  },
  methods: {
    updateCurrentTime() {
      const today = new Date();
      const date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate();
      const time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const dateTime = date + ' ' + time;
      let tempDate = new Date(dateTime);

      let tempDateGmt = new Date();
      let gmt = Math.abs(tempDateGmt.getTimezoneOffset() / 60);

      tempDate = moment().format('DD-MM-yyyy');
      let tempDate2 =
        moment().format('hh:mm A') + ' (GMT+' + gmt.toString() + ')';
      this.currentDate = tempDate;
      this.currentTime = tempDate2;
    },
    onClickRegister() {
      window.open(registerUrl, '_blank');
    },
    onClickDeposit() {
      this.goToPayment('D');
    },
    onClickWithdrawal() {
      this.goToPayment('W');
    },
    goToPayment(method) {
      let today = new Date();
      today.setHours(today.getHours() + 8);
      let currentUnixTime = moment(today).unix();
      let token = CustomEncrypt.SignAndHash(currentUnixTime);
      let routeData = this.$router.resolve({
        path: 'ValidationLogin',
        query: {
          username: this.$store.getters.username,
          timestamp: currentUnixTime,
          token: token,
          method: method,
          lang: CustomEncrypt.getPaymentLanguage(),
          landingPage: window.location.origin,
        },
      });
      window.open(CustomEncrypt.getPaymentUrl() + routeData.href, '_blank');
    },
    toggleLogin() {
      this.$emit('toggle-login');
    },
    toggleCollapse() {
      const isMenuOpen = !$('#myNavbar').is(':visible');
      this.$emit('toggle-collapse', isMenuOpen);
    },
    setActiveComponent(componentName) {
      this.$emit('set-component', componentName);
    },
    closeCollapse() {
      this.$refs.myToggleBar.click();
    },
    async onClickRefreshBalance() {
      this.isLoadingBalance = true;
      try {
        await this.$store.dispatch('getMemberBalance');
        this.isLoadingBalance = false;
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        this.isLoadingBalance = false;
        this.toggleDangerAlert(error);
      }
    },
  }, //end methods
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    suspend() {
      return this.$store.getters.suspend;
    },
    username() {
      return this.$store.getters.username;
    },
    nickname() {
      return this.$store.getters.nickname;
    },
    currency() {
      return this.$store.getters.currency;
    },
    totalRemainingCreditLimit() {
      // const current = new Date();
      return this.$store.getters.totalRemainingCreditLimit;
    },
    enablePaymentGateway() {
      return this.$store.getters.enablePaymentGateway;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.brand-image {
  width: 100px;
  position: relative;
  object-fit: fill;
}



.divider {
  border: opx;
  padding: 11px 0px;
}

.fixed-top {
  position: fixed !important;
}

.toggle-bar {
  padding: 0px;
  position: relative;
}

.navbar-inverse {
  z-index: 1000;
  margin-top: 50px !important;
  padding: 0px;
}

.dark-backdrop {
  z-index: 10;
}

.padding-logged {
  /* padding-bottom: 131px; */
  padding-bottom: 45px;
}

.padding-logged-60 {
  padding-bottom: 45px;
}

@media (max-width: 370px) {
  .btn-register {
    top: 0.4rem;
    font-size: 0.65rem;
  }
}

.padding-unlogged {
  padding-bottom: 49px;
}

.padding-bottom-60 {
  padding-bottom: 49px;
}
.credit-left {
  padding-left: 17px !important;
}

.credit-balance {
  padding-left: 5px !important;
}

.credit-container {
  padding-top: 5px;
  background: #2f4798;
}

#timecontainer {
  white-space: nowrap;
}

.credit-item {
  padding: 0.75em 0.4em 0.75em 0.2em !important;
}

.credit-right {
  padding-right: 1rem !important;
}

.font-helvetica {
  font-family: 'Helvetica';
}

.navbar-padding {
  padding: 0.2rem 1rem 0.5rem 1rem;
}
.user-cash-container {
  position: relative;
  border: 2px solid #0059aa;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: small;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
}
#myNavbar {
  background: #1c2e6b;
}
.btn-login{
  font-size: small;
  color: white;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  background: linear-gradient(to bottom, #f79500, #eb8100);
  text-shadow: 0 1px 1px #b56a00;
  padding: 5px 1em;
  font-weight: 600;
  border-radius: 3px;
}
.btn-register {
  font-size: small;
  color: white;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  background: linear-gradient(to bottom, #f76300, #d51d00);
  text-shadow: 0 1px 1px #b56a00;
  padding: 5px 1em;
  font-weight: 600;
  border-radius: 3px;
}
</style>

