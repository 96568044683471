<template>
  <div id="home-body">
    <div class="row mx-0">
      <desktop-header ref="desktopHeader"></desktop-header>
      <!--- page content --->
      <div class="col-12 px-0 pt-0 pb-0 container-min-height">
        <!----promo banner---->
        <div class="container px-0">
          <img class="w-100 banner" :src="bannerUrl" />
        </div>

        <div class="container clear-both px-0 pt-3 pb-4 mb-5">
          <div class="row">
            <!--- game card content--->
                <div
                  class="col-lg-3 col-md-6 mt-3 game-card-wrap pl-3 pr-3 menu-hover"
                  v-for="(game, index) in gameList"
                  :key="game.name"
                  @click="onClickGameIcon(index)"
                >
                  <div class="game-card mt-1">
                    <img
                      class="card-img-sm"
                      :src="getLangImg(game)"
                      alt="game img"
                    />
                    <!--
                    <div class="game-footer col-12">
                      <span class="float-left text-dark pt-1">
                        <strong>{{ game.name }}</strong>
                      </span>
                      <span class="float-right">
                        <img
                          class="icon-play-outline cursor-pointer"
                          src="../../../../assets/icons/icn-gold-play.png"
                          alt="play icon"
                        />
                      </span>
                    </div>
                    -->
                  </div>
                </div>
            <!---end card content -->
          </div>
        </div>
      </div>

      <!----end page comtent --->
      <!-- Footer-->
      <desktop-footer></desktop-footer>
    </div>
    <!--- login modal--->
    <login-modal
      ref="loginModal"
      :errMessage="loginPanelErr"
      @toggle-danger="toggleDangerAlert"
      @set-err-messsage="setErrMessage"
    ></login-modal>
    <!---game  modal --->
    <game-modal ref="gameModal"></game-modal>
    <!--globa  element component  --->
    <loading-spinner v-if="isLoading"></loading-spinner>
  </div>
</template>

<script>
import GameBanner from '../../../../assets/images/game-banner/itp.png';
// import desktopImport from '../../../../assets/script/desktop.js';
import gameItp from '../../../../assets/script/catalog/itp';

// import CustomHttp from '../../../CustomHttp';
export default {
  components: {},
  created() {},
  data() {
    return {
      bannerUrl: GameBanner,
      isLoading: false,
      gameList: gameItp.items,
      loginPanelErr: '',
    };
  },
  methods: {
    setErrMessage(errMessage) {
      this.loginPanelErr = errMessage.toString();
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1500);
    },
    onClickGameIcon(i) {
      if (!this.$store.getters.isLoggedIn) {
        //not logged in
        this.$refs.desktopHeader.onClickGameIcon(i);
      } else {
        const selectedGame = this.gameList[i];
        this.$store.dispatch('setSelectedGame', selectedGame);

        this.$refs.desktopHeader.toOpenGameModal();

        // this.$refs.gameModal.showModal();
        // this.$refs.gameModal.getGameDetails();
      }
    },
    getLangImg(obj) {
      let imgSrc = obj.img[0].src;
      let tempSrc = obj.img.find((i) => i.langId == this.$store.getters.langId);
      if (tempSrc) {
        imgSrc = tempSrc.src;
      }
      return imgSrc;
    },
  },
  computed: {},
};
</script>

<style scoped>
.bg-light-grey {
  background-color: #f7f7f7;
}

.container-min-height {
  min-height: 85vh;
}

.banner {
  width: 100%;
  object-fit: contain;
}
@media screen and (max-width : 992px) {
  .banner {
    top: 2em;
  }
}
.game-footer {
  padding: 0.7rem 2.2rem 0.7rem 0.7rem !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45) !important;
  font-size: 0.7rem !important;
  border-radius: 0rem 0rem 8px 8px !important;
  /* white-space: nowrap; */
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  background-color: white;
  margin-top: -25px;
  flex: 0 0 0%;
  border: 0.5px solid rgba(0, 0, 0, 0.125);
  font-size: 0.75rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.game-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  border-radius: 0.75rem;
  padding-bottom: 10px;
}

.game-card-wrap {
  padding-left: 18px;
  padding-right: 18px;
}

.menu-hover:hover {
  position: relative;
  top: -5px;
  opacity: 0.97;
  cursor: pointer;
}

.menu-hover {
  position: relative;
  top: 0;
  transition: top ease 0.15s;
}

.card-img-sm {
  /* height: 165px; */
  width: 100%;
}

.icon-play-outline {
  width: 31px;
  height: 31px;
  position: absolute;
  padding-top: 1px;
  top: 11px;
}

.text-dark {
  /* font-family: Nunito; */
  /* font-family: SegoeUI; */
  font-weight: 900;
  font-size: 14px;
  text-align: left;
  color: #36342f;
}
</style>
