import sb from './../../images/game2/sportsbook/sb.png';
import sbTh from './../../images/game2/sportsbook/sb.png';

import step from './../../images/game2/sportsbook/img-stepparley.png';
import stepTh from './../../images/game2/sportsbook/img-stepparley.png';

import basketball from './../../images/game2/sportsbook/img-basketball.png';
import basketballTh from './../../images/game2/sportsbook/img-basketball.png';

import tennis from './../../images/game2/sportsbook/img-tennis.png';
import tennisTh from './../../images/game2/sportsbook/img-tennis.png';

import badminton from './../../images/game2/sportsbook/img-badminton.png';
import badmintonTh from './../../images/game2/sportsbook/img-badminton.png';

import baseball from './../../images/game2/sportsbook/img-baseball.png';
import baseballTh from './../../images/game2/sportsbook/img-baseball.png';

import sbmb from './../../images/game2/sportsbook/sb-mobile.png';

// import evo from './../../images/game2/sportsbook/evo-racing-long-min.png';

// import gameImport from './../game';
var gameMenu = {
  name: 'SPORTSBOOK',
  items: [
    {
      name: 'Sportsbook',
      target :'sportsbook',
      isFullWidth: false,
      provider: '',
      icon: sb,
      code: '3',
      img: [
        { langId: 0, src: sb },
        { langId: 2, src: sbTh },
      ],
      img2: [
        { langId: 0, src: sb },
        { langId: 2, src: sbTh },
      ],
      isNew: false,
      labelName: '',
      parentName: '',
    },  
    {
      name: 'Sportsbook',
      target :'sportsbook_mobile',
      isFullWidth: false,
      provider: '',
      icon: sbmb,
      code: '4',
      img: [
        { langId: 0, src: sbmb },
        { langId: 2, src: sbmb },
      ],
      img2: [
        { langId: 0, src: sbmb },
        { langId: 2, src: sbmb },
      ],
      isNew: false,
      labelName: '',
      parentName: '',
    },  
    {
      name: 'Step',
      target :'sportsbook',
      isFullWidth: false,
      provider: '',
      icon: sb,
      code: '5',
      img: [
        { langId: 0, src: step },
        { langId: 2, src: stepTh },
      ],
      img2: [
        { langId: 0, src: step },
        { langId: 2, src: stepTh },
      ],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Basketball',
      target :'sportsbook',
      isFullWidth: false,
      provider: '',
      icon: step,
      code: '6',
      img: [
        { langId: 0, src: basketball },
        { langId: 2, src: basketballTh },
      ],
      img2: [
        { langId: 0, src: basketball },
        { langId: 2, src: basketballTh },
      ],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Tennis',
      target :'sportsbook',
      isFullWidth: false,
      icon: '',
      code: '7',
      img: [
        { langId: 0, src: tennis },
        { langId: 2, src: tennisTh },
      ],
      img2: [
        { langId: 0, src: tennis },
        { langId: 2, src: tennisTh },
      ],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Badminton',
      target :'sportsbook',
      isFullWidth: false,
      provider: '',
      icon: '',
      code: '8',
      img: [
        { langId: 0, src: badminton },
        { langId: 2, src: badmintonTh },
      ],
      img2: [
        { langId: 0, src: badminton },
        { langId: 2, src: badmintonTh },
      ],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Baseball',
      target :'sportsbook',
      isFullWidth: false,
      provider: '',
      icon: '',
      code: '9',
      img: [
        { langId: 0, src: baseball },
        { langId: 2, src: baseballTh },
      ],
      img2: [
        { langId: 0, src: baseball },
        { langId: 2, src: baseballTh },
      ],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    // {
    //   name: 'Evo Racing',
    //   isFullWidth: false,
    //   provider: 'Evo Racing',
    //   icon: gameImport.find((x) => x.code.toString() === '98').icon,
    //   code: '98',
    //   img: [
    //     { langId: 0, src: evo },
    //     { langId: 2, src: evo },
    //   ],
    //   img2: [
    //     { langId: 0, src: evo },
    //     { langId: 2, src: evo },
    //   ],
    //   isNew: false,
    //   labelName: '',
    //   parentName: '',
    // },
  ],
};

export default gameMenu;
