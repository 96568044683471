<template>
  <div class="body-wrap">
    <loading-spinner v-if="isLoading"></loading-spinner>
    <transition name="fadeInTop">
      <div v-if="showSuccessAlert">
        <success-alert-mobile :message="successMessage"></success-alert-mobile>
      </div>
    </transition>
    <transition name="fadeInTop">
      <div v-if="showErrorAlert">
        <danger-alert-mobile :message="errorMessage"></danger-alert-mobile>
      </div>
    </transition>

    <div class="loading" v-if="showProgress">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
      <div class="bar4"></div>
      <div class="bar5"></div>
      <div class="bar6"></div>
      <div class="bar7"></div>
      <div class="bar8"></div>
      <div class="bar9"></div>
      <div class="bar10"></div>
      <div class="bar11"></div>
      <div class="bar12"></div>
    </div>
    <div class="col-spaced mt-3 mb-2 px-3">
      <div class="cashout-heading">{{$t('CashOut')}}</div>
      <button v-if="!showProgress" class="CashOut btn-restore btn "
            :disabled="showProgress || isSomeBalanceLaoding || isAllBalanceZero"
            @click="onClickRestore()">
            <img src="../../../assets/icons/icn-cashout-resotre.png" width="14" />
            <span class="ml-2">{{ $t('BtnRestore') }}</span>
      </button>
    </div>

    <div class="col-spaced cashout-headings">
      <div style="flex:0.75">{{$t('cashout_name')}}</div>
      <div>{{$t('cashout_bal')}}</div>
      <div>{{$t('cashout_tout')}}</div>
    </div>
    <div v-if="!showProgress">

        <div class="col-spaced cashout-row" v-for="(game, index) in gameImport" :key="game.code">
          <template v-if="game.enabled">
            <div class="gamename">{{ game.name }}</div>
            <div class="gamebalance" :colspan="game.Reason == '' ? 0 : 2">
              <span class="text-danger" v-if="suspend">
                {{ $t('AccountSuspended') }}
              </span>
              <span class="balance" v-else-if="game.isProgress || showProgress">
                <i class="fas fa-spinner fa-spin"></i>
              </span>
              <span
                @click="onClickRefreshBalance(index)"
                class="text-danger mr-2"
                v-else-if="game.Reason != ''"
              >
                {{ game.Reason }}</span
              >
              <span v-if="isError">-</span>
              <img class="icn-cashout" v-if="game.Reason != ''" 
              src="../../../assets/icons/icn-transfer-inactive.png" width="36" />

              <span
                class="balance cursor-pointer"
                @click="onClickRefreshBalance(index)"
                v-else-if="!game.isProgress && !showProgress"
              >
                <span>{{ $filters.currencyDecimal(game.GameBalance) }}</span>
              </span>
            </div>
            <div v-if="game.Reason == ''">
                <img
                  class="icn-cashout"
                  src="../../../assets/icons/icn-mb-cashout-inactive.png"
                  width="36"
                  v-if="game.GameBalance <= 0 || game.Reason != '' || game.isProgress"
                />
                <img
                  class="icn-cashout"
                  src="../../../assets/icons/icn-mb-cashout.png"
                  @click="onClickCashOut(index)"
                  width="40"
                  v-else
                />
            </div>
          </template>
        </div>
    </div>


<!-- table design
    <table id="tblWithdraw" class="table" v-if="!showProgress">
      <thead>
        <tr class="border-top-bottom-none">
          <th class="border-top-bottom-none" style="width: 30%">
            {{ $t('GameType') }}
          </th>
          <th style="width: 40%" class="text-right border-top-bottom-none">
            {{ $t('GameBalance') }}
          </th>
          <th class="border-top-bottom-none" style="width: 30%">

          </th>
        </tr>
      </thead>

      <tbody>
        <tr class="dim" v-for="(game, index) in gameImport" :key="game.code">
          <template v-if="game.enabled">
            <td class="gamename">{{ game.name }}</td>
            <td class="gamebalance" :colspan="game.Reason == '' ? 0 : 2">
              <span class="text-danger" v-if="suspend">
                {{ $t('AccountSuspended') }}
              </span>
              <span class="balance" v-else-if="game.isProgress || showProgress">
                <i class="fas fa-spinner fa-spin"></i>
              </span>
              <span
                @click="onClickRefreshBalance(index)"
                class="text-danger"
                v-else-if="game.Reason != ''"
              >
                {{ game.Reason }}</span
              >
              <span v-if="isError">-</span>
              <span
                class="balance cursor-pointer"
                @click="onClickRefreshBalance(index)"
                v-else-if="!game.isProgress && !showProgress"
              >
                {{ $filters.currencyDecimal(game.GameBalance) }}
              </span>
            </td>
            <td v-if="game.Reason == ''">
              <button
                class="CashOut TransferButton btn-cashout btn btn-warning"
                id="btn-wd-31"
                :disabled="
                  game.GameBalance <= 0 || game.Reason != '' || game.isProgress
                "
                @click="onClickCashOut(index)"
              >
                <img
                  class="icn-cashout"
                  src="../../../assets/icons/icn-cashout.png"
                  style=""
                />
                <span class="Txt3 text-cashout">{{ $t('BtnCashOut') }}</span>
              </button>
            </td>
          </template>
        </tr>
      </tbody>
    </table> -->
  </div>
</template>

<script>
import CustomHttp from '../../../CustomHttp';
import CustomStorage from '../../../CustomStorage';
// import CustomEncrypt from '../../../CustomEncrypt';
import GameImport from '../../../assets/script/game';
const gameUrl = process.env.VUE_APP_GAME_URL;
//Vue export
export default {
  mounted() {
    this.gameImport = this.gameImport.filter((x) => x.enabled == true);
    this.gameImport.forEach((item, index) => {
      //  always use gameURl 1 for cashout withdrawal
      this.gameImport[index].gameUrl = gameUrl;
      this.gameImport[index].GameBalance = 0;
      this.gameImport[index].Reason = '';
    });
    this.getUserCashOutDetails();
  },
  data() {
    return {
      isLoading: false,
      showProgress: false,
      isError: false,
      gameImport: JSON.parse(JSON.stringify(GameImport)),
      showSuccessAlert: false,
      showErrorAlert: false,
      successMessage: '',
      errorMessage: '',
    };
  },
  methods: {
    async onClickCashOut(i) {
      // this.isLoading = true;
      this.gameImport[i].isProgress = true;
      const selectedRecord = this.gameImport[i];
      const response = await CustomHttp.withDrawFromGame(
        selectedRecord.code,
        selectedRecord.GameBalance,
        10,
        selectedRecord.gameUrl
      );
      this.isLoading = false;
      if (response.errtext[0] == '') {
        this.onClickRefreshBalance(i);
        this.toggleSuccessAlert('Success Withdraw');
      } else {
        this.toggleDangerAlert(response.errtext[0]);
      }
    },
    async onClickRestore() {
      this.isLoading = true;
      try {
        for (let i = 0; i < this.gameImport.length; i++) {
          if (this.gameImport[i].GameBalance != 0) {
            this.gameImport[i].isProgress = true;
          }
        } //end for loop

        for (let i = 0; i < this.gameImport.length; i++) {
          if (this.gameImport[i].GameBalance != 0) {
            const response = await CustomHttp.withDrawFromGame(
              this.gameImport[i].code,
              this.gameImport[i].GameBalance,
              10,
              this.gameImport[i].gameUrl
            );
            if (response.errtext[0] == '') {
              this.onClickRefreshBalance2(i);
            }
          }
        } //end for loop

        this.toggleSuccessAlert('Success Withdraw');
        this.isLoading = false;
      } catch (error) {
        this.toggleDangerAlert('Failed restore credit');
        this.isLoading = false;
      }
    },
    async onClickRefreshBalance2(i) {
      this.gameImport[i].isProgress = true;
      const selectedRecord = this.gameImport[i];
      try {
        const response = await CustomHttp.getOneGame2(
          selectedRecord.code,
          selectedRecord.gameUrl
        );
        const responseBalance = response.credit[0];
        this.gameImport[i].GameBalance = responseBalance;
        this.gameImport[i].isProgress = false;
        this.gameImport[i].Reason = '';
      } catch (error) {
        let tempMessage = error.message;
        tempMessage = tempMessage.replace('Error: ', '');
        this.gameImport[i].GameBalance = 0;
        this.gameImport[i].Reason = tempMessage;
        this.gameImport[i].isProgress = false;
      }
    },
    async onClickRefreshBalance(i) {
      this.gameImport[i].isProgress = true;
      const selectedRecord = this.gameImport[i];
      try {
        const response = await CustomHttp.getOneGame(
          selectedRecord.code,
          10,
          selectedRecord.gameUrl
        );
        const responseBalance = response.credit[0];
        this.gameImport[i].GameBalance = responseBalance;
        this.gameImport[i].isProgress = false;
        this.gameImport[i].Reason = '';
      } catch (err) {
        let tempMessage = err.message;
        tempMessage = tempMessage.replace('Error: ', '');
        this.gameImport[i].GameBalance = 0;
        this.gameImport[i].Reason = tempMessage;
        this.gameImport[i].isProgress = false;
      }
    },
    async getUserCashOutDetails() {
      if (this.$store.getters.suspend) {
        return;
      }
      this.showProgress = true;
      //  await this.$store.dispatch('getSession');

      let idListString = '';
      // url 1
      const gameImportUrl1 = this.gameImport.filter(
        (x) => x.gameUrl == gameUrl
      );
      gameImportUrl1.forEach((item, index) => {
        if (this.gameImport.length == index + 1) {
          idListString += item.code;
        } else {
          idListString += item.code + ',';
        }
      });

      let response = await CustomHttp.getAllGame(idListString, gameUrl);
      if (!response.data) {
        this.showProgress = false;
        this.showErrorAlert = true;
        this.isError = true;
        this.errorMessage =
          'Error connecting to server. Please try refreshing the page!';

        return false;
      }
      if (response.data.ErrText != '') {
        this.$swal.fire(response.data.ErrText);
        return;
      }
      let responseData = [];
      // eslint-disable-next-line
      gameImportUrl1.forEach((item, index) => {
        const tempRecord = response.data.game_list.find(
          (l) => l.GameId == item.code
        );
        responseData.push(tempRecord);
      });

      // url 2
      //   let gameImportUrl2 = this.gameImport.filter((x) => x.gameUrl == gameUrl2);
      //   idListString = '';
      //   gameImportUrl2.forEach((item, index) => {
      //     if (this.gameImport.length == index + 1) {
      //       idListString += item.code;
      //     } else {
      //       idListString += item.code + ',';
      //     }
      //   });
      //   let response2 = await CustomHttp.getAllGame(idListString, gameUrl2);
      //   if (response2.data.ErrText != '') {
      //     this.$swal.fire(response2.data.ErrText);
      //     return;
      //   }
      //   gameImportUrl2.forEach((item) => {
      //     const tempRecord = response2.data.game_list.find(
      //       (l) => l.GameId == item.code
      //     );
      //     responseData.push(tempRecord);
      //   });
      this.gameImport.forEach((item, index) => {
        const record = responseData.find((r) => r.GameId == item.code);
        this.gameImport[index] = {
          ...this.gameImport[index],
          ...record,
          isProgress: false,
        };
      }); //end for each

      //end get game from details from API
      this.showProgress = false;
      //   const response = await CustomHttp.post(
      //     'api/v1/member/get-preference',
      //     json
      //   );
      //   const responseData = response.data.data;
    }, //end getcashout details
    toggleSuccessAlert(message) {
      this.successMessage = message;
      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 1500);
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1500);
    },
  },
  computed: {
    suspend() {
      return this.$store.getters.suspend;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isAllBalanceZero() {
      const flag = this.gameImport.find((g) => g.GameBalance != 0);
      if (flag == undefined) {
        return true;
      } else {
        return false;
      }
    },
    isSomeBalanceLaoding() {
      const flag = this.gameImport.find((g) => g.isProgress == true);
      if (flag == undefined) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    // eslint-disable-next-line
    isLoggedIn(newValue, oldValue) {
      if (newValue == false) {
        this.isLoading = true;
        CustomStorage.setItem('isTimeout', 'true');
        window.location.reload();
      }
    },
  },
};
</script>
<style scoped>
.right-icon{
  min-width: 35px;
  margin-left: 1em;
}
.body-wrap {
  overflow: hidden;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 100%;
  margin-bottom: 100px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  /* height: 966px; */
}

.cashout-row{
  background: white;
  color: black;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px 0 rgba(72, 86, 167, 0.41);
  border-left: 5px solid #ff9611;
  padding: 10px 15px;
  font-size: small;
}

.bg-gold {
  background: linear-gradient(rgb(231, 211, 157), rgb(177, 149, 96)) !important;
}

.gamename {
  /*text-align:left;*/
  font-size: small;
  flex: 0.55;
}

.gamebalance {
  text-align: right;
}


.gamebalance {
  font-weight: bold;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: show;
  margin: auto;
  top: 28rem;
  left: 0;
  bottom: 0;
  right: 0;
  /* z-index: 1; */
}

div.loading div {
  width: 6%;
  height: 16%;
  background: #fff;
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  -webkit-border-radius: 50px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -webkit-animation: fade 1s linear infinite;
}

.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

.loading div.bar1 {
  -webkit-transform: rotate(0deg) translate(0, -130%);
  -webkit-animation-delay: 0s;
}

.loading div.bar2 {
  -webkit-transform: rotate(30deg) translate(0, -130%);
  -webkit-animation-delay: -0.9167s;
}

.loading div.bar3 {
  -webkit-transform: rotate(60deg) translate(0, -130%);
  -webkit-animation-delay: -0.833s;
}

.loading div.bar4 {
  -webkit-transform: rotate(90deg) translate(0, -130%);
  -webkit-animation-delay: -0.7497s;
}

.loading div.bar5 {
  -webkit-transform: rotate(120deg) translate(0, -130%);
  -webkit-animation-delay: -0.667s;
}

.loading div.bar6 {
  -webkit-transform: rotate(150deg) translate(0, -130%);
  -webkit-animation-delay: -0.5837s;
}

.loading div.bar7 {
  -webkit-transform: rotate(180deg) translate(0, -130%);
  -webkit-animation-delay: -0.5s;
}

.loading div.bar8 {
  -webkit-transform: rotate(210deg) translate(0, -130%);
  -webkit-animation-delay: -0.4167s;
}

.loading div.bar9 {
  -webkit-transform: rotate(240deg) translate(0, -130%);
  -webkit-animation-delay: -0.333s;
}

.loading div.bar10 {
  -webkit-transform: rotate(270deg) translate(0, -130%);
  -webkit-animation-delay: -0.2497s;
}

.loading div.bar11 {
  -webkit-transform: rotate(300deg) translate(0, -130%);
  -webkit-animation-delay: -0.167s;
}

.loading div.bar12 {
  -webkit-transform: rotate(330deg) translate(0, -130%);
  -webkit-animation-delay: -0.0833s;
}

.btn-danger {
  background-color: #3ab54a !important;
  color: white;
  border: 1px solid white !important;
  border-radius: 20px;
  width: 140px;
}

.spinner-color {
  background: gray;
}

body {
  background-color: #2a2a2a;
}
.btn-cashout {
  background: linear-gradient(to bottom, #00edff, #22a4dd);
  /* min-width: 110px; */
  padding: 5px;
  min-width: 6.5rem;
}
.btn-cashout:disabled{
  opacity: 0.5;
}

.text-cashout {
  line-height: 1.19;
  /* font-family: Helvetica; */
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #2b2b32;
  position: relative;
  top: 0.05rem;
}

.text-restore {
  position: relative;
  top: -1.5px;
  line-height: 1.19;
  /* font-family: Helvetica; */
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  position: relative;
  top: 0.05rem;
}

.btn-warning {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5) !important;
  text-shadow: 0 1px 1px rgba(254, 235, 203, 0.5);
  line-height: 1.25;
  border: 0px !important;

}

.border-top-bottom-none {
  border-top: 0px !important;
  border-bottom: 0px !important;
}
.cashout-heading{
  color: #4856a7;
  font-size: large;
}
.btn-restore {
  background: linear-gradient(to bottom, #ff9611, #c60);
  min-width: 90px;
  padding: 4px 10px;
  font-size: 12px;
  font-family: 'Helvetica';
  color: #fff;
  border: none;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.icn-cashout{
}
.cashout-headings{
  color: #888b9a;
  font-size: x-small;
  padding: 10px 15px 10px 20px;
}
</style>
