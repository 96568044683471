<template>
  <div class="col-lg-9">
    <div class="right-card">
      <div class="info-text">
        {{ $t('AccountInfo') }}
      </div>
      <div class="py-1 mt-1">
        <div class="row">
          <div class="col-4 profile-label">{{ $t('UserNameLabel') }}:</div>
          <div class="col-8 profile-info">
            {{ nickname }}
          </div>
        </div>
      </div>
      <div class="py-1">
        <div class="row">
          <div class="col-4 profile-label">{{$t('acc_userid')}}:</div>
          <div class="col-8 profile-info">
            {{ username }}
          </div>
        </div>
      </div>
      <div class="py-1">
        <div class="row">
          <div class="col-4 profile-label">{{ $t('CurrencyLabel') }}:</div>
          <div class="col-8 profile-info">
            {{ currency }}
          </div>
        </div>
      </div>
      <div class="py-1">
        <div class="row">
          <div class="col-4 profile-label">{{ $t('CreditLabel') }}:</div>
          <div class="col-8 profile-info">
            {{ $filters.currency(creditLimit) }}
          </div>
        </div>
      </div>
      <div class="py-1">
        <div class="row">
          <div class="col-4 profile-label">{{ $t('TotalBalanceLabel') }}:</div>
          <div
            class="col-8 profile-info"
            :class="[totalBalance >= 0 ? 'text-success' : 'text-danger']"
          >
            {{ $filters.currency(totalBalance) }}
          </div>
        </div>
      </div>
      <div class="py-1">
        <div class="row">
          <div class="col-4 profile-label">
            {{ $t('RemainSportsbookLabel') }}:
          </div>
          <div class="col-8 profile-info">
            {{ $filters.currency(totalRemainingCreditLimit) }}
          </div>
        </div>
      </div>
      <div class="py-1">
        <div class="row">
          <div class="col-4 profile-label">{{ $t('MinMaxBetLabel') }}:</div>
          <div class="col-8 profile-info">
            {{ $filters.currencyDecimal(minBet) }} /
            {{ $filters.currencyDecimal(maxBet) }}
          </div>
        </div>
      </div>
      <div class="py-1 mb-5">
        <div class="row pb-2">
          <div class="col-4 profile-label">
            {{ $t('TotalOutstandingLabel') }}:
          </div>
          <div class="col-8 profile-info">
            {{ totalOutstanding }} ({{ totalOutstandingCount }}
            bets)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Vue export
export default {
  computed: {
    username() {
      return this.$store.getters.username;
    },
    nickname() {
      return this.$store.getters.nickname;
    },
    creditLimit() {
      return this.$store.getters.creditLimit;
    },
    minBet() {
      return this.$store.getters.minBet;
    },
    maxBet() {
      return this.$store.getters.maxBet;
    },
    totalOutstanding() {
      return this.$store.getters.totalOutstanding;
    },
    totalOutstandingCount() {
      return this.$store.getters.totalOutstandingCount;
    },
    totalRemainingCreditLimit() {
      return this.$store.getters.totalRemainingCreditLimit;
    },
    totalBalance() {
      return this.$store.getters.totalBalance;
    },
    currency() {
      return this.$store.getters.currency;
    },
  },
};
</script>

<style scoped>
  .profile-spacer{
    margin-right: 4em;
    color: white;
  }
.info-text {
  font-size: small;
  color: #2f4798;
  font-weight: bold;
}

.profile-label {
  font-size: small;
  text-align: right;
}

.profile-info {
  text-align: left;
  font-size: small;
  font-weight: 900;
}

.right-card {
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important;
  padding: 20px 25px;
  overflow-y: auto;
  color: #36342f;
  background: white;
  font-size: small;
  margin-top: 1em;
  margin-bottom: 3em;
}

.py-3 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 19px;
  margin: 11px 34px 11px 0px;
}
</style>
