<template>
  <section>
    <div
      class="mobile-body-font"
    >
      <router-view />
      <div id="wrapper">
        <!-- Navigation -->
        <!--top menu--->
        <section>
          <widget-header
            :showCollapse="showCollapse"
            @toggle-collapse="toggleCollapse"
            @toggle-login="toggleLogin()"
            @set-component="setActiveComponent"
            ref="theHeader"
          ></widget-header>
        </section>

        <!---dynamic component -->
        <div class="wrapper">
          <!-- <screen-landing></screen-landing> -->
          <!-- <transition name="componentSlideUp">
          <component :is="activeComponent"></component>
        </transition> -->
          <component
            @toggle-login="toggleLogin()"
            @set-component="setActiveComponent"
            :is="activeComponent"
          ></component>
        </div>
        <!---login page section--->
        <transition name="slideDown">
          <div class="" v-show="showLogin">
            <screen-login
              @toggle-close="toggleLogin()"
              :backgroundImage="preloadImage"
            ></screen-login>
          </div>
        </transition>

        <!-------footer menu bar--->
        <widget-footer
        :footerMenuActive = "currentFooterMenu"
          @toggle-sidebar="toggleSidebar"
          @set-component="setActiveComponent"
        ></widget-footer>
      </div>

      <!-- side bar-->
      <widget-sidebar
        :sideOpen="sideOpen"
        @toggle-sidebar="toggleSidebar()"
        @close-collapse="closeCollapse()"
        @set-component="setActiveComponent"
      ></widget-sidebar>
      <!-- end side bar-->
    </div>
  </section>
</template>

<script>
// page component
import ScreenLogin from "./pages/Login";
import ScreenLanding from "./pages/Landing";
import ScreenAccount from "./pages/MyAccount";
import ScreenStatement from "./pages/Statement";
import ScreenResult from "./pages/Result";
import ScreenSetting from "./pages/Setting";
import ScreenCashOut from "./pages/CashOut";
import ScreenWithdraw from "./pages/Withdrawm";
import ScreenDeposit from "./pages/Depositm";
//header menu page
import ScreenAboutUs from "./pages/HeaderPage/AboutUs";
import ScreenFaq from "./pages/HeaderPage/Faq";
import ScreenBettingRules from "./pages/HeaderPage/BettingRules";
import ScreenContactUs from "./pages/HeaderPage/ContactUs";
import ScreenWhyUs from "./pages/HeaderPage/WhyUs";
// UI widget
import WidgetFooter from "./ui-elements/Footer";
import WidgetSidebar from "./ui-elements/Sidebar";
import WidgetHeader from "./ui-elements/Header";

import preloadImg from "./../../assets/images/login-backdrop-old.png";
export default {
  name: "Home",
  currentFooterMenu : '',
  beforeMount() {
    let image = new Image();
    image.src = preloadImg;
    this.preloadImage = image.src;
    // console.log(this.preloadImage);
    // console.log(this.preloadImage);
  },
  components: {
    WidgetFooter,
    WidgetSidebar,
    WidgetHeader,
    ScreenLogin,
    ScreenLanding,
    ScreenAccount,
    ScreenStatement,
    ScreenResult,
    ScreenSetting,
    ScreenCashOut,
    ScreenAboutUs,
    ScreenFaq,
    ScreenBettingRules,
    ScreenContactUs,
    ScreenWhyUs,
    ScreenWithdraw,
    ScreenDeposit,
    preloadImage: "",
  },
  data() {
    return {
      sideOpen: false,
      showLogin: false,
      showCollapse: false,
      activeComponent: "screen-landing",
    };
  },
  methods: {
    toggleSidebar() {
      this.sideOpen = !this.sideOpen;
      //console.log(this.sideOpen)
      if (this.showCollapse) {
        this.closeCollapse();
      }
    },
    toggleCollapse(isOpen) {
      this.showCollapse = isOpen;
      if (this.showCollapse) {
        this.sideOpen = false;
      }
    },
    closeCollapse() {
      //this.$refs.myToggleBar.click()
      this.$refs.theHeader.closeCollapse();
      this.showCollapse = false;
    },
    toggleLogin() {
      this.showLogin = !this.showLogin;
    },
    setActiveComponent(componentName) {
      this.currentFooterMenu = componentName;
      if (this.showCollapse) {
        this.$refs.theHeader.closeCollapse();
      }
      this.sideOpen = this.showCollapse = false;
      //solve carousel banner sturck
      if (componentName == "screen-landing") {
        this.$router.go(0);
      } else {
        this.activeComponent = componentName;
      }
    },
  }, //end methods
  computed: {
    // isTimeout() {
    //   return this.$store.getters.isTimeout;
    // },
  }, //end computed
  watch: {
    /* eslint-disable */
    // isTimeout(newValue, oldValue) {
    //   if (newValue) {
    //     this.toggleDangerAlert(t("Session timed out. Please login again."));
    //     window.location.reload();
    //     this.logUserOut();
    //   }
    // },
    /* eslint-enable */
  }, //end watch
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.transfer-wrap {
  z-index: 1060;
}

.wrapper {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.wrapper2 {
  -webkit-backface-visibility: none;
  backface-visibility: none;
}
</style>
