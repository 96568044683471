<template>
  <div class="body-wrap">
    <div class="container1">
     <!-- <div class="main-img"></div> -->
     <!-- <div class="centered">{{ $t('AboutUs') }}</div> -->
    </div>

    <!-- Small Thumbnail -->
    <div class="content-wrapper" v-if="langId == '0'">
      <h1>Who Is YBET?</h1>
      <hr style="color:#c2c9e6"><br />
      YBET is one of the world’s leading online gaming brands.
      <br />
      <br />

      YBET Sportsbook offers over 500 sporting events every week, with
      extensive coverage of all major soccer leagues and international sporting
      events. YBET also provides live soccer updates, immediate winnings
      confirmation, rapid payouts, easy access and fast online betting available
      around the clock. YBET Casino is a one-stop entertainment site offering
      an extensive range of casino games and the highly-rated Multi Player Live
      Dealer developed in conjunction with the o&shy;nline Gaming Systems of
      Australia to satisfy every player’s needs.
      <br />
      <br />

      In YBET Games, you can choose to play exclusive games such as
      scratchcards, keno, poker and roulette, all with unlimited plays and no
      download or installation required. YBET uses the latest technologies to
      deliver a fast and secure player environment. At YBET, we are committed
      to providing our customers with an outstanding gaming experience. With our
      wide selections of products and services, YBET caters for all sports
      bettors ranging from the casual punter through to VIP players and
      International Bookmakers.
      <br />
      <br />

      All of this on the widest range of sports, events and markets, in the
      comfort of your own home or anywhere in the world. When betting with
      YBET, you can feel confident knowing that you are dealing with a
      reputable o&shy;nline sportsbook whose management has many decades of
      experience in the gaming industry. Our friendly YBET Support Team is
      always available to assist in any way possible, including account
      enquiries, depositing or withdrawing of funds, or general betting
      assistance.
      <br />
      <br />

      Bet with YBET, the Sportsbook you can trust.
    </div>
    <div class="content-wrapper" v-if="langId == '1'">
      <h1>体育运动是谁?</h1>
      <hr style="color:#c2c9e6"><br />
      <p>YBET是世界领先的在线游戏品牌之一。</p><br />
      <p>
        YBET娱乐网是全球最知名的在线博彩品牌之一。
      </p><br />
      <p>
        YBET娱乐网体育博彩每周提供超过 500 个体育活动，囊括了所有重大足球联赛和国际体育赛事。YBET娱乐网还提供全天候的足球实时更新、即刻获奖确认、迅捷的支付、方便的网络访问和快速的在线投注。
      </p><br />
      <p>
        YBET娱乐网赌场是个一站式的娱乐网站，提供广泛的博彩游戏。为了满足玩家需要，YBET娱乐网赌场与澳洲 线上游戏软体商开发了“多人现场荷官” (Multi Player Live Dealer)，并获得很高评价。
      </p><br />
      <p>
        YBET娱乐网游戏将让您体验到我们独家游戏的精彩，比如刮奖卡，基诺以及卡牌游戏。所有的游戏都无须下载安装，就能免费无限试玩。
      </p><br />
      <p>
        YBET娱乐网利用最先进的技术为玩家创造快捷安全的娱乐环境。我们竭诚为用户提供卓越的娱乐体验。从消遣型的投注者到贵宾玩家，再到国际博彩公司，YBET娱乐网为所有体育投注者量身打造广泛的产品和服务。
      </p><br />
      <p>
        不论何时何地，您都可以轻松投注于我们所提供的广泛博彩项目。YBET娱乐网是一个有着良好信誉的在线体育投注商，通过YBET娱乐网下注，您可以放心的感受到我们在博彩业几十年的丰富经验带给您的信心。
      </p><br />
      <p>无论何时，我们亲切的客户服务部门会竭力为您提供各种服务，包括帐户查询、存取资金、以及常见的投注帮助。 </p><br />
      <p>
        敬请加入YBET娱乐网，您值得信赖的体育博彩。
      </p>
    </div>
    <div class="content-wrapper" v-if="langId == '2'">
      <h1>YBET คือใคร?</h1>
      <br>
      <p>YBET เป็นหนึ่งในผู้รับวางพนันออนไลน์ชั้นนำของโลก</p>
      <p>
        สปอร์ตบุ๊ค YBET มีเกมส์การแข่งขันกีฬาต่างๆ ให้เลือกมากกว่า 500
        เกมส์ต่อสัปดาห์ ครอบคลุมรายการแข่งฟุตบอลใหญ่ๆ
        ทุกนัดและรายการกีฬานานาชาติต่างๆ ทั้งยังนำเสนอผลฟุตบอลสด,
        ยืนยันผลชนะทันที, จ่ายเงินรวดเร็ว และเข้าระบบได้สะดวกรวดเร็วตลอดเวลา
      </p>
      <p>
        YBET คาสิโน ให้บริการครบถ้วนเบ็ดเสร็จในที่เดียว
        ซึ่งเรานำเสนอเกมส์คาสิโนต่างๆหลากหลายและท่านสามารถร่วมเล่นกับผู้เล่นท่านอื่นๆ
        โดยมีผู้แจกไพ่สดแบบในคาสิโนจริงซึ่งเป็นระบบที่พัฒนาขึ้นร่วมกับระบบการเล่นเกมส์ออนไลน์ของประเทศออสเตรเลียซึ่งตอบโจ
        ทย์ทุกความต้องการของผู้เล่น
      </p>
      <p>
        ท่านสามารถเลือกเล่นเกมส์ต่างๆใน YBET เกมส์ อาทิเช่น บัตรขูด, keno,
        และเกมส์ไพ่
        ท่านสามารถเล่นได้ไม่จำกัดและไม่จำเป็นต้องดาวน์โหลดหรือติดตั้งโปรแกรม
      </p>
      <p>
        YBET
        ใช้เทคโนโลยีล่าสุดเพื่อให้บริการที่เร็วกว่าและปลอดภัยขึ้นสำหรับผู้เล่น
        YBET
        ให้คำมั่นสัญญากับลูกค้าว่าเราจะมอบประสบการณ์การเล่นเกมส์พนันที่เป็นเลิศ
        ด้วยผลิตภัณฑ์และบริการที่หลากหลายเหมาะกับผู้เล่นทุกความต้องการ
        ตั้งแต่ผู้เล่นทั่วไปถึงผู้เล่นวีไอพีและผู้วางพนันระดับนานาชาติ
      </p>
      <p>
        ท่านสามารถเพลิดเพลินกับเกมส์การแข่งขันกีฬารายการต่างๆทั่วทุกภูมิภาคของโลกได้อย่างสบายง่ายๆ
        ในบ้านของท่านหรือที่ใดก็ตามได้ทั่วโลก เมื่อท่านวางพนันกับ YBET
        ท่านสามารถมั่นใจได้ว่าท่านได้วางพนันกับสปอร์ตบุ๊คออนไลน์ที่มีชื่อเสียงไว้ใจได้
        และมีผู้บริหารงานที่มีประสบการณ์และความชำนาญนับหลายสิบปีในธุรกิจ
      </p>
      <p>
        เจ้าหน้าที่บริการลูกค้า YBET พร้อมให้ความช่วยเหลือท่าน
        รวมถึงพร้อมตอบทุกคำถามเกี่ยวกับบัญชี, การฝากหรือถอนเงิน
        และเรื่องการวางเดิมพันอื่นๆ ทั่วไป
      </p>
      <p>วางเดิมพันกับ YBET สปอร์ตบุ๊คที่ท่านเชื่อใจได้</p>
    </div>
    <div class="content-wrapper" v-if="langId == '3'">
      <h1>Ai là YBET?</h1>
      <p>
        YBET là một trong những thương hiệu cá cược qua mạng hàng đầu trên thế
        giới.
      </p>
      <p>
        YBET Sportsbook cung cấp hơn 500 sự kiện thể thao mỗi tuần, bao gồm
        tất cả các giải bóng đá chính và các sự kiện thể thao quốc tế. YBET
        cũng cung cấp thông tin cập nhật bóng đá trực tiếp, xác nhận thắng tức
        thì, thanh toán nhanh chóng, truy cập dễ dàng và cá cược qua mạng nhanh
        chóng.
      </p>
      <p>
        Sòng Bài YBET là một dịch vụ giải trí tổng hợp cung cấp hàng loại các
        lọai chơi bài và Người Chia Bài Trực Tiếp cho Nhiều Người Chơi hợp tác
        với Hệ Thống Chơi Bài qua mạng của Úc đáp ứng từng yêu cầu của người
        chơi.
      </p>
      <p>
        Trong Trò Chơi YBET, bạn có thể chọn chơi các trò chơi độc quyền như
        thẻ cào, keno, xì phé và Rulét, cho phép bạn chơi không giới hạn và
        không cần phải tải về hay lắp đặt chương trình nào.
      </p>
      <p>
        YBET sử dụng công nghệ tiên tiến nhất để mang lại một môi trường cá
        cược bảo mật và hiệu quả nhất. Tại YBET, chúng tôi cam kết cung cấp
        cho khách hàng một dịch vụ cá cược hoàn hảo. Với đa dạng sản phẩm và
        dịch vụ, YBET đáp ứng yêu cầu của tất cả khách hàng chơi cá cược thể
        thao từ người chơi không thường xuyên cho đến khách hàng VIP và Nhiều
        Nhà Cái Quốc Tế.
      </p>
      <p>
        Tất cả những điều này trên phạm vi rộng nhất của thể thao, sự kiện và thị trường, trong sự thoải mái tại nhà riêng của bạn hoặc bất cứ nơi nào trên thế giới. Khi đặt cược với YBET, bạn có thể cảm thấy tự tin khi biết rằng bạn đang giao dịch với một nhà cái thể thao trực tuyến uy tín có ban quản lý có nhiều thập kỷ kinh nghiệm trong ngành công nghiệp trò chơi.
      </p>
      <p>
        Bộ Phận Hỗ Trợ YBET của chúng tôi luôn luôn sẵn sàng trợ giúp trong
        bất cứ tình huống nào, bao gồm thắc mắc về tài khoản, gởi và rút tiền,
        hoặc trợ giúp chung về cá cược.
      </p>
      <p>Hãy cá cược với YBET, nhà cái bạn có thể tin tưởng.</p>
    </div>
  </div>
</template>

<script>
//Vue export
export default {
  data() {
    return {
      selectedMenuIndex: 0,
      selectedGame: null,
    };
  },
  methods: {},
  computed: {
    langId() {
      return this.$store.getters.langId.toString();
    },
  },
};
</script>
<style scoped>
.body-wrap {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 100%;
  margin-bottom: 5em;
}

* {
  padding: 0px;
  margin: 0px;
}

h1 {
  font-weight: bold;
  color: #ff9611;
  margin-top: 0px;
  font-size: 20px;
  text-align: center;
  font-family: SegoeUI;
  margin-bottom: 1em;
}

h3 {
  font-size: 1em;
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}

.main-img {
  max-width: 1200px;
  height: 200px;
  background-image: url('../../../../assets/images/about.jpg');
  background-size: cover;
  background-position: center;
}

.container1 {
  text-align: center;
  max-width: 1200px;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  padding: 0px;
  margin-top: 2em;
}

.centered {
  font-size: 2em;
  text-shadow: 2px 2px #000;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrapper {
  color: #1c327e;
  font-size: 15px;
  margin: 0 auto;
  background: transparent;
  text-align: justify;
  padding: 0 20px;
}
</style>
