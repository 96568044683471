var httpError = [
  { code: '0', message: 'Success' }, // Success
  { code: '-111', message: 'SystemMaintenance' }, // system is under maintenance
  { code: '-999', message: 'UnknownError' }, //   unknown error
  { code: '-1', message: 'InvalidUsernameOrPassword' }, // "invalid password
  { code: '-2', message: 'InvalidUsernameOrPassword' }, // invalid username
  { code: '-3', message: 'InvalidRequest' }, // invalid request
  { code: '-4', message: 'InvalidRequest' }, // invalid secret key
  { code: '-5', message: 'InvalidUsernameOrPassword' }, //       [Description("invalid user role")]
  { code: '-6', message: 'InvalidUsernameOrPassword' }, //  [Description("account suspend")]
  { code: '-7', message: 'InvalidUsernameOrPassword' }, //      [Description("account deleted")]
  { code: '-8', message: 'InactiveAccount' }, // inactive account
  { code: '-9', message: 'InvalidUsernameOrPassword' }, // account locked
  { code: '-10', message: 'InactiveAccount' }, // upline's account locked
  { code: '-11', message: 'InvalidOldPassword' }, // invalid old password
  { code: '-12', message: 'InvalidRequest' }, // password max length is 16
  { code: '-13', message: 'InvalidRequest' }, // nickname is required
  { code: '-14', message: 'NickNamealreadyexists' }, // nickname is exists
  { code: '-15', message: 'InvalidRequest' }, // nickname is not exists"
  { code: '-16', message: 'InvalidRequest' }, // invalid odds type"
  { code: '-17', message: 'InvalidRequest' }, // invalid odds vie
  { code: '-18', message: 'InvalidRequest' }, // "invalid default stake"
  { code: '-19', message: 'InvalidRequest' }, // "invalid accept better odds
  { code: '-20', message: 'InvalidRequest' }, // invalid sort by"
  { code: '-21', message: 'InvalidRequest' }, // "invalid language"
  { code: '-22', message: 'InvalidRequest' }, // "quickBetAmt cannot less than 0

  { code: '-23', message: 'FailGetRecord' }, // failed to retrieve record"
  { code: '-24', message: 'NicknameMustBeAtLeast5Characters' }, // nickname length must more than 5"
  { code: '-25', message: 'NoRecord' }, // ("no record"

  { code: '-26', message: ' ' }, // only can retrieve last #MAXDATE# days
  { code: '-27', message: 'InvalidRequest' }, // invalid date format"
  { code: '-28', message: 'InvalidRequest' }, // invalid date range

  { code: '-29', message: 'InvalidRequest' }, // invalid session id"
  { code: '-30', message: 'InvalidRequest' }, // "ip address is required"
  { code: '-31', message: 'InvalidRequest' }, // "invalid maintenance key"
];

export default httpError;
