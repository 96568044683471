<template>
  <div class="col-12" style="background:black; min-height: 100vh;">
    <transition name="fadeIn">
      <template v-if="isWalletTransfer && errMsg == ''">
        <div class="dark-backdrop2 d-none d-block">
          <div class="darkbg">
            <div class="wrapper2">
              <div class="circle circle-1">
                <img
                  src="./../../../assets/images/chips/chip2.png"
                  width="50"
                  height="50"
                />
              </div>
              <div class="circle circle-2">
                <img
                  src="./../../../assets/images/chips/chip3.png"
                  width="50"
                  height="50"
                />
              </div>
              <div class="circle circle-3">
                <img
                  src="./../../../assets/images/chips/chip4.png"
                  width="50"
                  height="50"
                />
              </div>
            </div>
            <h1>{{ $t('Logging into lobby') }}</h1>
            <h2 v-if="transferGameProvider != ''">
              {{ $t('Transferring from') }} {{ transferGameProvider }}
            </h2>

            <!---->
          </div>
        </div>
      </template>
    </transition>
    <div
      class="col-12 py-3 d-flex justify-content-center"
      v-if="!isError && !isWalletTransfer && errMsg == ''"
    >
      <white-progress-spinner></white-progress-spinner>
    </div>
    <div
      class="col-12 p-5 d-flex justify-content-center text-danger"
      v-if="isError && errMsg == ''"
    >
      <p>{{ $t('Session Time Out') }}</p>
    </div>
    <div
      class="col-12 p-5 d-flex justify-content-center text-white"
      v-if="errMsg != ''"
    >
      <p>{{ errMsg }}</p>
    </div>
  </div>
</template>

<script>
import CustomHttp from '../../../CustomHttp';
import CustomStorage from '../../../CustomStorage';
import i18n from '../../../i18n';
import GameImport from '../../../assets/script/game';

const { t } = i18n.global;
const sportsbookMobileUrl = process.env.VUE_APP_SPORTSBOOK_MOBILE_URL;
const gameUrl = process.env.VUE_APP_GAME_URL;
const gameUrl2 = process.env.VUE_APP_GAME_URL2;
// import ThFlag from './../../';
//Vue export
export default {
  data() {
    return {
      isLoading: false,
      showProgress: false,
      isError: false,
      isWalletTransfer: false,
      transferGameProvider: '',
      setTransferError: '',
      errMsg: '',
    };
  },
  mounted() {
    if (localStorage.getItem('IOS-transfer')) {
      let gameCode = localStorage.getItem('IOS-transfer');
      console.log(gameCode);
      localStorage.removeItem('IOS-transfer');
      this.isWalletTransfer = true;
      this.processWalletTransfer(gameCode);
    } else if (localStorage.getItem('IOS-transfer-sb')) {
      localStorage.removeItem('IOS-transfer-sb');
      this.goToMobileSportsbook(true);
    } else if (localStorage.getItem('sb-mobile')) {
      this.goToMobileSportsbook();
    } else if (localStorage.getItem('sb-memberUrl')) {
      this.goToSportsbook2();
    } else {
      //this.addListener();
      this.lineGame();
    }
  },
  created() {
    //listen to initial game tab message
    window.addEventListener(
      'storage',
      (event) => {
        if (event.key == 'event-closeTransferModal') {
          localStorage.removeItem('event-closeTransferModal');
          this.$store.dispatch('setIsWalletTransfer', false);
          window.opener = self;
          window.close();
        }
      },
      false
    );
  },
  methods: {
    async processWalletTransfer(gameCode) {
      let remainingTime = await this.withdrawAllFromGame();
      // console.log('remainingTime');
      //console.log(remainingTime);
      //check for game status
      try {
        if (remainingTime < 0) {
          throw new Error('Please try again.');
        }
        console.log(remainingTime);
        console.log(gameCode);
        gameCode = gameCode.replace(/^"(.+)"$/, '$1');
        await CustomHttp.getOneGame(gameCode, remainingTime);
      } catch (err) {
        console.log(err.message);
        let tempMessage = err.message;
        tempMessage = tempMessage.replace('Error:', '');
        //this.toggleDangerAlert(t(tempMessage));
        this.setTransferError = tempMessage;
        this.isWalletTransfer = false;
        // window.opener = self;
        // window.close();
      }
      // get latest balance
      if (!this.isWalletTransfer) {
        return;
      }
      let creditResult = await CustomHttp.post('/api/v1/member/get', {
        username: this.$store.getters.username,
        withTotalRemainingCreditLimit: true,
      });
      let totalRemainingCreditLimit =
        creditResult.data.data.totalRemainingCreditLimit;
      //deposit latest balance to elected game

      // start game
      this.transferGameProvider = '';
      CustomStorage.setItem('gameCode', gameCode);
      CustomStorage.setItem('tempAmount', totalRemainingCreditLimit);

      console.log(gameCode);
      console.log(totalRemainingCreditLimit);
      this.isWalletTransfer = false;
      this.lineGame();
    },
    async withdrawAllFromGame() {
      this.transferGameProvider = '';
      //  this.isWalletTransfer = true;

      // url 1
      let gameImport = JSON.parse(JSON.stringify(GameImport));
      gameImport = gameImport.filter((x) => x.gameUrl == gameUrl);
      // gameImport = gameImport.filter((x) => x.code != providerId);
      let idListString = '';
      gameImport.forEach((item, index) => {
        if (gameImport.length == index + 1) {
          idListString += item.code;
        } else {
          idListString += item.code + ',';
        }
      });
      //get balance from all game provider
      let response = [];
      if (!this.isWalletTransfer) {
        return;
      }
      let startTime = new Date();
      try {
        response = await CustomHttp.getAllGame(idListString, gameUrl, 5);
      } catch (err) {
        this.setTransferError = 'Please try again';
        this.isWalletTransfer = false;
        console.log(err);
        // window.opener = self;
        // window.close();
        return;
      }
      //url 2
      gameImport = JSON.parse(JSON.stringify(GameImport));
      gameImport = gameImport.filter((x) => x.gameUrl == gameUrl2);
      // gameImport = gameImport.filter((x) => x.code != providerId);
      idListString = '';
      gameImport.forEach((item, index) => {
        if (gameImport.length == index + 1) {
          idListString += item.code;
        } else {
          idListString += item.code + ',';
        }
      });
      //get balance from all game provider
      let response2 = [];
      if (!this.isWalletTransfer) {
        return;
      }
      let endDate = new Date();
      let seconds = (endDate.getTime() - startTime.getTime()) / 1000;
      seconds = 5 - seconds;
      startTime = new Date();
      console.log('seconds');
      console.log(seconds);
      try {
        if (seconds < 0) {
          throw new Error('Please try again.');
        }
        response2 = await CustomHttp.getAllGame(
          idListString,
          gameUrl2,
          seconds
        );
      } catch (err) {
        this.setTransferError = 'Please try again';
        this.isWalletTransfer = false;
        console.log(err);
        // window.opener = self;
        // window.close();
        return;
      }
      //handle login in other site message
      if (response.data.ErrText != '' || response2.data.ErrText != '') {
        this.setTransferError = response.data.ErrText;
        this.isWalletTransfer = false;

        console.log('setTransferError');
        // window.opener = self;
        // window.close();
        return;
      }
      let responseData = [];
      console.log(gameUrl);
      let gameImportUrl = GameImport.filter((x) => x.gameUrl == gameUrl);
      let gameImportUrl2 = GameImport.filter((x) => x.gameUrl == gameUrl2);
      console.log(gameImportUrl2);
      //handle api return null
      let gameList = response.data.game_list;
      gameList = gameList.filter((x) => x != null);
      gameImportUrl.forEach((item) => {
        const tempRecord = gameList.find((l) => l.GameId == item.code);
        responseData.push(tempRecord);
      });
      //handle api return null
      let gameList2 = response2.data.game_list;
      gameList2 = gameList2.filter((x) => x != null);
      gameImportUrl2.forEach((item) => {
        const tempRecord = gameList2.find((l) => l.GameId == item.code);
        responseData.push(tempRecord);
      });
      let gameProviderWithBalance = [];
      console.log(responseData);
      responseData.forEach((item) => {
        if (item.GameBalance != 0) {
          gameProviderWithBalance.push({
            GameId: item.GameId,
            GameBalance: item.GameBalance,
          });
        }
      });
      //start withdraw
      endDate = new Date();
      seconds = (endDate.getTime() - startTime.getTime()) / 1000;
      seconds = 5 - seconds;
      startTime = new Date();
      console.log('seconds');
      console.log(seconds);
      if (gameProviderWithBalance.length >= 1) {
        console.log('enter withdraw');
        for (let i = 0; i < gameProviderWithBalance.length; i++) {
          let gameProviderName = GameImport.find(
            (g) => g.code == gameProviderWithBalance[i].GameId
          ).name;
          this.transferGameProvider = gameProviderName;

          if (!this.isWalletTransfer) {
            return;
          }
          //console.log('withdraw from');
          // console.log(gameProviderWithBalance[i].GameId);

          try {
            if (seconds < 0) {
              throw new Error('Please try again.');
            }
            await CustomHttp.withDrawFromGame(
              gameProviderWithBalance[i].GameId,
              gameProviderWithBalance[i].GameBalance,
              seconds
            );
            endDate = new Date();
            seconds = (endDate.getTime() - startTime.getTime()) / 1000;
            seconds = 5 - seconds;
            startTime = new Date();
            console.log('seconds after withdraw');
            console.log(seconds);
            return seconds;
          } catch (err) {
            this.setTransferError = 'Please try again';
            this.isWalletTransfer = false;
            console.log('setTransferError');
            // window.opener = self;
            // window.close();
            return;
          }
          //handle possible error
          //   if (response.errtext[0] == '') {
          //   }
        }
      } else {
        return seconds;
      }
    },
    async goToMobileSportsbook(isIOS = false) {
      if (isIOS) {
        let remainingTime = await this.withdrawAllFromGame();
        // console.log('remainingTime');
        //console.log(remainingTime);
        //check for game status
        try {
          if (remainingTime < 0) {
            throw new Error('Please try again.');
          }
        } catch (err) {
          console.log(err.message);
          let tempMessage = err.message;
          tempMessage = tempMessage.replace('Error:', '');
          this.setTransferError = tempMessage;
          this.isWalletTransfer = false;
        }
      }
      console.log('sb here');
      //check
      const isMaintenance = await this.isSportsbookMaintenance();
      if (isMaintenance) {
        localStorage.setItem(
          'event-sb-maintenance',
          t('Sportsbook is under maintenance')
        );
        window.opener = self;
        window.close();
        return;
      }
      await this.$store.dispatch('getSession');
      let baseURl = sportsbookMobileUrl;
      let accId = this.$store.getters.accId;
      let session = this.$store.getters.session;

      let wc = false;
      wc = localStorage.getItem('sb-mobile-wc');
      
      let loginUrl = baseURl + '?accid=' + accId + '&sid=' + session + '&site=ybet';
      if(wc == 'true'){
        loginUrl = loginUrl + '&wc=1';
      }
      
      localStorage.removeItem('sb-mobile');
      localStorage.removeItem('sb-mobile-wc');

      window.location.assign(loginUrl);
    },
    async isSportsbookMaintenance() {
      let res = await CustomHttp.post(
        'api/v1/system/get-sb-maintenance-status'
      );
      return res.data.data.isMaintenance;
    },
    addListener() {
      window.addEventListener(
        'storage',
        (event) => {
          //console.log('event');
          //console.log(event);
          // this.toggleDangerAlert(
          //   'Logged out in another tab. Please login again.'
          // );

          if (event.key == 'event-game') {
            //  console.log('logUserOut');
            const gameUrl = localStorage.getItem('event-game');
            window.location.assign(gameUrl);
          }
        },
        false
      );
    },
    redirect(gameUrl) {
      // window.location.href = gameUrl;
      window.location.assign(gameUrl);
    },
    async goToSportsbook2() {
      let json = localStorage.getItem('sb-memberUrl');
      let wc = 0;
      wc = localStorage.getItem('sb-wc');
      localStorage.removeItem('sb-memberUrl');
      localStorage.removeItem('sb-wc');
      this.isLoading = true;
      const isMaintenance = await this.isSportsbookMaintenance();
      if (isMaintenance) {
        localStorage.setItem(
          'event-sb-maintenance',
          t('Sportsbook is under maintenance')
        );
        window.opener = self;
        window.close();
        return;
      }
      let response;
      // var windowReference = window.open();

      try {
        response = await CustomHttp.post('api/v1/member/login-old-site', json);
      } catch (err) {
        console.log(err);
      }
      let memberUrl =
        process.env.VUE_APP_MEMBER_SITE + response.data.data.url;

      if(wc == 1){
        memberUrl += '&wc=1';
      }
      window.location.assign(memberUrl);
    },
    async lineGame() {
      this.isError = false;
      //for Line App
      const tempAmount = CustomStorage.getItem('tempAmount');
      const gameCode = CustomStorage.getItem('gameCode');
      if (tempAmount == undefined) {
        window.location.assign('/');
      }
      try {
        CustomStorage.removeItem('tempAmount');
        CustomStorage.removeItem('gameCode');
        await CustomHttp.depositToGame(gameCode, Math.trunc(tempAmount));
        const result = await CustomHttp.loginGame(gameCode, 'loginMobile');
        const resultUrl = result.result[0];
        if (result.errcode[0] != '0') {
          this.errMsg = result.errtext[0];
          document.title = this.errMsg;
          return;
        }
        await this.$store.dispatch('getMemberBalance');
        window.location.assign(resultUrl);
      } catch (err) {
        // console.log(err);
        // this.isError = true;
        // localStorage.setItem('event-game-error', 'logout');
        window.opener = self;
        window.close();
        //in unable to close tab in IOS mobile chrome
        window.location.assign('/');
      }
    },
    // async startGame() {
    //   const gameCode = CustomStorage.getItem('gameCode');
    //   const tempAmount = CustomStorage.getItem('tempAmount');
    //   try {
    //     await CustomHttp.depositToGame(gameCode, tempAmount);
    //     const result = await CustomHttp.loginGame(gameCode, 'loginMobile');
    //     const resultUrl = result.result[0];
    //     await this.$store.dispatch('getMemberBalance');
    //     window.location.href(resultUrl);
    //     this.isLoading = false;
    //   } catch (err) {
    //     this.$refs.closeBtn.click();
    //     this.isLoading = false;
    //   }
    // },
  },
  watch: {
    isWalletTransfer() {
      console.log(this.isWalletTransfer);
      localStorage.setItem('event-isWalletTransfer', this.isWalletTransfer);
    },
    transferGameProvider() {
      localStorage.setItem(
        'event-transferGameProvider',
        this.transferGameProvider
      );
    },
    setTransferError() {
      localStorage.removeItem('event-setTransferError');
      localStorage.setItem('event-setTransferError', this.setTransferError);
      window.opener = self;
      window.close();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* chips  css */
@import url(https://fonts.googleapis.com/css?family=Oswald);
* {
  box-sizing: border-box;
}
.darkbg {
  width: 100%;
  margin-top: calc(50vh - 120px);
  margin-left: auto;
  margin-right: auto;
  height: 150px;
  border-radius: 15px;
}

.wrapper2 {
  width: 190px;
  height: 50px;
  padding-top: 30px;
  margin: 0 auto;
}
.circle {
  float: left;
  height: 50px;
  margin: 0 5px;
  width: 50px;
}
.circle-1 {
  animation: move1 4s ease-in-out infinite;
}
.circle-2 {
  animation: move2 4s ease-in-out infinite;
}
.circle-3 {
  animation: move3 4s ease-in-out infinite;
}

h1 {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 50px auto 5px auto;
  text-align: center;
}

h2 {
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 5px auto;
  text-align: center;
}

.close-right-img2 {
  padding: 10px 20px;
  float: right;
}

@keyframes move1 {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(60px);
  }

  40% {
    transform: translateX(120px);
    opacity: 1;
  }

  60% {
    transform: translateX(120px);
    opacity: 0;
  }

  80% {
    transform: translateX(0);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes move2 {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(60px);
    opacity: 1;
  }

  40% {
    transform: translateX(60px);
    opacity: 0;
  }

  60% {
    transform: translateX(-60px);
    opacity: 0;
  }

  80% {
    transform: translateX(-60px);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes move3 {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(0);
    opacity: 0;
  }

  40% {
    transform: translateX(-120px);
    opacity: 0;
  }

  60% {
    transform: translateX(-120px);
    opacity: 1;
  }

  80% {
    transform: translateX(-60px);
  }

  100% {
    transform: translateX(0);
  }
}

.dark-backdrop2 {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 200;
}

/* desktop */
@media (min-width: 769px) {
  .darkbg {
    width: 100%;
    margin-top: calc(50vh - 170px);
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    border-radius: 15px;
  }
}

/* mobile */
@media (max-width: 769px) {
  .darkbg {
    width: 100%;
    margin-top: calc(50vh - 170px);
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    border-radius: 15px;
  }
}
</style>
