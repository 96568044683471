import gameImport from './../game';

import sv from './../../images/game/cockft/sv388.png';

var gameMenu = {
  name: 'ESPORT',
  items: [
    {
      name: 'SV 388',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-103',
      img: [
        { langId: 0, src: sv },
        { langId: 2, src: sv },
      ],
      img2: [
        { langId: 0, src: sv },
        { langId: 2, src: sv },
      ],
    },
  ],
};

export default gameMenu;
