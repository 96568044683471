<template>
  <div class="container withdraw-container mb-5 pb-5">
  <transition name="fadeIn">
    <div v-if="showSuccessAlert">
      <success-alert :message="successMessage"></success-alert>
    </div>
  </transition>
      <nav class="full-center mt-4">
          <div class="tab-menu left-radius" @click="setActiveComponent('screen-deposit')">{{$t('Btn_Deposit')}}</div>
          <div class="tab-menu active right-radius">{{$t('dep_withdraw')}}</div>
      </nav>
      <br>
      <h5 class="heading">{{$t('Btn_Withdraw')}}</h5>
      <loading-spinner v-if="loadingPage"></loading-spinner>
      <section v-if="user_contact.isContactVerify == true && user_bankAccount == ''">
        <div class="input-heading-info">{{$t('with_bankinfo')}}</div>

        <div class="col-center">
          <div class="acc-pill">
            <div class="pill-left">{{$t('with_accname')}}</div>
            <div class="pill-right">{{user_contact.fullName}}</div>
          </div>
        </div>  

          <div class="sm-heading mb-2">
            {{$t('with_bankname')}} 
          </div>
        <div class="dropdown form-control withdrawal-form">
          <div id="dropdownMenuButton" class="bank-dropdown" data-toggle="dropdown" aria-expanded="false">
            {{selected_bank.bankName}}
            <i class="ml-2 fa fa-chevron-down"></i>
          </div>
          <ul class="dropdown-menu dropdown-menu-end">
            <li v-for="b in banks" :key="b.bankCode" @click="selected_bank = b"><a class="dropdown-item">{{b.bankName}}</a></li>
          </ul>
        </div>

          <div class="sm-heading mb-2">
            {{$t('with_accnum')}}
          </div>
          <input class="form-control withdrawal-form" type="text" v-model="newAccountInput" @input="restrictInputAccount"/>
        <div class="input-error col-center" v-if="error_create_account">
          <i class="fa fa-exclamation-circle mr-2"></i>
            {{error_create_account}}
        </div>

        <br>
        <button class="btn-submit" v-if="!showPaymentProgress" @click="addNewBank()">
          {{$t('Submit')}}
        </button>
        <button class="btn-submit" v-else>
          <i class="fas fa-spinner fa-spin"></i>
        </button>

      </section>
      <section v-if="user_contact.isContactVerify == true && user_bankAccount != ''">
        <p class="sm-heading">{{$t('with_verify')}}</p>
        <div>
          <div class="acc-pill-flat">
            <div class="pill-left">{{$t('with_accname')}}</div>
            <div class="pill-right">{{user_contact.fullName}}</div>
          </div>
          <div class="acc-pill-flat">
            <div class="pill-left">{{$t('with_bankname')}}</div>
            <div class="pill-right">{{user_bankAccount.bankName}}</div>
          </div>
          <div class="acc-pill-flat">
            <div class="pill-left">{{$t('with_accnum')}}</div>
            <div class="pill-right">{{user_bankAccount.bankAccountNo}}</div>
          </div>
        </div>  
        <div class="input-info col-center">
          <i class="fa fa-exclamation-circle mr-2"></i>
          <div>{{$t('with_diffinfo')}}</div>
        </div>
        <div class="sm-heading">{{$t('with_amt')}}</div>
          <div class="amount-form mt-2 w-100" :class="(error_withdrawal) ? 'red-border' : ''">
            <div class="right-separator">{{currency}}</div>
            <input type='text' placeholder="50" v-model="user_bankAmount" @input="restrictInput"/>
          </div>
          <div class="input-error mobile col-center" v-if="error_withdrawal">
              {{error_withdrawal}}
          </div>
        <div class="smaller mt-1" style="color:#a1a1a1">{{$t('with_minwith')}}: <b>{{currency}} 50</b></div>


        <button class="btn-submit mt-3" v-if="!showPaymentProgress" @click="withdraw">
          {{$t('dep_withdraw')}}
        </button>
        <button class="btn-submit mt-3" v-else>
          <i class="fas fa-spinner fa-spin"></i>
        </button>
      </section>
      <section v-if="user_contact.isContactVerify == false">
        <div class="input-info">
          <div class="b">{{$t('with_verifyotp')}}</div>
          <div>{{$t('with_onetime')}}</div>
        </div>

        <div class="otp-selection">
          <div>
            <div class="otp-radio" v-if="user_contact.contact">
              <input type="radio" id="mobile1" name="mobile" value="mobile1" class="mr-2" checked>
                <label for="mobile1">{{$t('with_mobile')}}: *******{{user_contact.contact.substring(7)}}</label><br>
            </div>
            <a class="otp-number" href="#" onclick="window.fcWidget.open();window.fcWidget.show();">{{$t('with_notmobile')}}</a>
          </div>
        </div>
          <div>
            <button class="btn-submit" v-if="!showPaymentProgress && !sendingOTP" @click="sendOTP">
              {{$t('with_getotp')}}
            </button><br>
          </div>


        <template v-if="sendingOTP">
          <div class="sm-heading mt-3">OTP</div>
          <input type="text" class="form-control" maxlength="6" v-model="otp_input"/>
          <div class="text-grey smaller mt-1">{{$t('with_otpexpire')}}</div>
        </template>

        <button class="btn-submit my-3" :disabled="!canSendOTP" v-if="sendingOTP" @click="sendOTP">
          {{$t('with_resendotp')}}<template v-if="otp_timer > 0">({{otp_timer}}s)</template>
         </button>
        <button class="btn-submit" v-if="showPaymentProgress">
          <i class="fas fa-spinner fa-spin"></i>
        </button>
        <button class="btn-submit" v-if="!showPaymentProgress && sendingOTP" @click="verifyOTP">
          {{$t('Submit')}}
        </button>
      </section>
  </div>
</template>

<script>
import CustomHttp from '../../../CustomHttp';

//Vue export
export default {
  mounted() {
    this.getUserContact(true);
  },
  data() {
    return {
      user_contact : '',
      error_withdrawal : '',
      user_bankAmount : '',
      user_bankAccount : '',
      showPaymentProgress: false,
      error_create_account : '',
      selected_bank : '',
      newAccountInput : '',
      banks : [],
      current_page : 'select_bank',
      loadingPage: true,
      selectedTabIndex: 0,
      isLoadingBalance: false,
      sendingOTP : false,
      canSendOTP : false,
      otp_input : '',
      otp_timer : 60,
      otp_interval : {},
      showSuccessAlert : false,
      successMessage : '',
    };
  },
  methods: {
    setActiveComponent(componentName) {
      this.$emit('set-component', componentName);
    },
    restrictInput(){
      this.user_bankAmount = this.user_bankAmount.replace(/[^0-9]/g, '');
    },
    restrictInputAccount(){
      this.newAccountInput = this.newAccountInput.replace(/[^0-9]/g, '');
    },
  toggleSuccessAlert(message) {
    this.successMessage = message;
    this.showSuccessAlert = true;
    setTimeout(() => {
      this.showSuccessAlert = false;
    }, 4000);
  },
  async verifyOTP(){ 
    this.showPaymentProgress = true;
    try {
      let json = { 
        Username: this.$store.getters.username,
        Contact: this.user_contact.contact,
        OTPCode : this.otp_input,
      };
      let input = new URLSearchParams(Object.entries(json)).toString();
      let response = await CustomHttp.postPayment('api/v1/user/verify-otp',input);

      console.log(response);

      if(response.data.success == true){
        this.sendingOTP = true;   

        this.loadingPage = true;
        this.user_bankAccount = '';
        this.user_contact = '';
        //restart the whole page
        this.getUserContact(true);
      }
      this.showPaymentProgress = false;

    } catch (error) {
      alert(this.$t('reg_otp_err') + ' (7)');
        
      this.showPaymentProgress = false;
      return;
    }
  },
  async sendOTP(){ 
    this.showPaymentProgress = true;
    this.canSendOTP = false;
    try {
      let json = { 
        Username: this.$store.getters.username,
        Contact: this.user_contact.contact,
      };
      let input = new URLSearchParams(Object.entries(json)).toString();
      let response = await CustomHttp.postPayment('api/v1/user/resend-otp',input);

      console.log(response);
      if(response.data.error == 'Application error'){
        alert(this.$t('with_phone_err'));
        this.showPaymentProgress = false;
        return false
      }

      if(response.data.success == true){
        this.sendingOTP = true;
      }
      this.showPaymentProgress = false;
      setTimeout(()=>{
        this.canSendOTP = true;
      },60000)
      this.otp_timer = 60;
      clearInterval(this.otp_interval);
      this.otp_interval = setInterval(()=>{
        this.otp_timer -= 1;
      },1000)
    } catch (error) {
      console.log(error)
        alert(this.$t('reg_server_err') + ' (4)');
        this.showPaymentProgress = false;
      return;
    }
  },
  async withdraw(){ 
    this.showPaymentProgress = true;
    this.error_withdrawal = '';

    if(this.user_bankAmount == '' || isNaN(this.user_bankAmount)){
      this.error_withdrawal = this.$t('with_amount_err');

      this.showPaymentProgress = false;
      return false;
    }
    if(parseInt(this.user_bankAmount) < 50){
      this.error_withdrawal = this.$t('with_min_err') + this.currency + ' 50';

      this.showPaymentProgress = false;
      return false;
    }
    
    try {
      let json = { 
        username: this.$store.getters.username,
        amount: '-' + this.user_bankAmount,
        userBankId: this.user_bankAccount.userBankId
      };
      let response = await CustomHttp.post('api/v1/member/withdrawal',json);

      if(response.data.success == true){
        this.toggleSuccessAlert(this.$t('with_success'));
        this.onClickRefreshBalance();
        this.user_bankAmount = '';
      }else{
        if(response.data.error.code == -39){
          alert(this.$t('with_insufficient') + ' (8)');
        }else{
          alert(this.$t('reg_server_err') + ' (5)');
        }
        this.isLoadingBalance = false;
        this.showPaymentProgress = false;
      }
      this.showPaymentProgress = false;

    } catch (error) {
        alert(this.$t('reg_server_err') + ' (1)');
        this.isLoadingBalance = false;
        this.showPaymentProgress = false;
      return;
    }
  },
    async onClickRefreshBalance() {
      this.isLoadingBalance = true;
      try {
        await this.$store.dispatch('getMemberBalance');
        this.isLoadingBalance = false;
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        this.isLoadingBalance = false;
        this.toggleDangerAlert(error);
      }
    },
  async getUserContact(landing = false){ 
      try {
        let json = { username: this.$store.getters.username};
        let input = new URLSearchParams(Object.entries(json)).toString();
        let response = await CustomHttp.postPayment('api/v1/user/get-by-username',input);
        console.log(response.data);
        if(response.data.success == true){
          this.user_contact = response.data.data;

          if(this.user_contact.isContactVerify == true && landing == true){
            this.getUserBanks(true);
          }else{
            this.loadingPage = false;
          }
        }
        
      } catch (error) {
          alert(this.$t('reg_server_err') + ' (2)');
          this.loadingPage = false;
        return;
      }
  },
  async getUserBanks(landing = false){ 
      try {
        let json = { username: this.$store.getters.username};
        let input = new URLSearchParams(Object.entries(json)).toString();
        let response = await CustomHttp.postPayment('api/v1/user-bank/get',input);

        if(response.data.success == true && response.data.data != null){
          this.user_bankAccount = response.data.data[0];
        }

        if(landing == true && response.data.data.length == 0){
          this.user_bankAccount = '';
          this.getBanks();
        }else{
          this.loadingPage = false;
        }
        

      } catch (error) {
          alert(this.$t('reg_server_err') + ' (3)');
          this.loadingPage = false;
        return;
      }

  },
  async getBanks(){ 
      try {
        let json = { username: this.$store.getters.username, currency : 'MYR', active : true};
        let input = new URLSearchParams(Object.entries(json)).toString();
        let response = await CustomHttp.postPayment('api/v1/bank/get',input);

        this.banks = response.data.data;
        this.loadingPage = false;
      } catch (error) {
          alert(this.$t('reg_server_err') + ' (6)');
          this.isLoadingBalance = false;
        return;
      }
  },
  async addNewBank(){ 
      this.showPaymentProgress = true;
      this.error_create_account = '';
      if(this.selected_bank == ''){
        this.error_create_account = this.$t('with_bank_err');
        this.showPaymentProgress = false;
        return false;
      }
      if(this.newAccountInput.length < 5){
        this.error_create_account = this.$t('with_number_err');
        this.showPaymentProgress = false;
        return false;
      }
      try {
        let json = { 
          username: this.$store.getters.username, 
          BankCode : this.selected_bank.bankCode, 
          BankAccountNo : this.newAccountInput
        };
        let input = new URLSearchParams(Object.entries(json)).toString();
        let response = await CustomHttp.postPayment('api/v1/user-bank/add',input);

        if(response.data.success == true){
          //change page
          this.getUserBanks();
        }else{
          this.error_create_account = this.$t('reg_server_err');
        }
      } catch (error) {
        console.log(error);
          alert('error');
        return;
      }
      this.showPaymentProgress = false;
  }
  },
  computed: {
    nickname() {
      return this.$store.getters.nickname;
    },
    username() {
      return this.$store.getters.username;
    },
    currency() {
      return this.$store.getters.currency;
    },
    totalRemainingCreditLimit() {
      return this.$store.getters.totalRemainingCreditLimit;
    },
    computedLangId() {
      return this.$store.getters.langId;
    },
  },
};
</script>
<style scoped>
.tab-menu{
  padding: 0.5em 1em;
  background: #bccbe6;
  width: 100%;
}
.tab-menu.active{
  background: #2f4798;
}
.left-radius{
  border-radius: 8px 0 0 8px;
}
.right-radius{
  border-radius: 0 8px 8px 0;
}
.heading{
  font-weight: bold;
  color: #2f4798;
}
.input-info{
  background: #f5f8ff;
  color: #8d98ab;
  padding: 8px 10px;
  margin: 10px 0;
  border-radius: 5px;
  max-width: 410px;
  font-size: smaller;
  align-items: flex-start;
}
.input-error{
  background: #fee6e6;
  color: #ef0b0b;
  padding: 8px 10px;
  margin: 1em 0 10px;
  border-radius: 5px;
  min-width: 300px;
  font-size: small;
}
.input-error.mobile{
  background: transparent;
  padding: 0;
}
.bank-dropdown{
  width: 100%;
  height: 2em;
}

.bank-dropdown svg{
  float: right;
  margin-top: 5px;
}
.withdrawal-form{
  width: 100%;
  border: 1px solid #7786c3;
  position: relative;
}
.text-grey{
  color: #a1a1a1;
}
.sm-heading{
  font-weight: bold;
  color: #2f4798;
  font-size: smaller;
}
.info-block{
  display: inline-block;
  margin: 10px 0;
  font-size: small;
  padding: 10px;
  padding-right: 30px;
  border-radius: 5px;
  color: #7786c3;
  background: #e8f0ff;
}
.btn-submit{
  background: #ff9611;
  color: white;
  border-radius: 6px;
  padding: 10px 30px;
    border: none;
  display: inline-block;
  font-size: small;
  font-weight: bold;
  width: 100%;
}
.btn-submit:disabled{
  background: #bbc4d5;
}


.acc-pill{
  display: flex;
  border-radius: 7px;
  font-size: small;
  margin: 2em 0;
  color: black;
  width: 100%;
}
.acc-pill-flat{
  display: flex;
  font-size: small;
  max-width: 100%;
  color: black;
}
.acc-pill-flat:first-child .pill-left{
  border-radius: 7px 0 0 0;
}
.acc-pill-flat:first-child .pill-right{
  border-radius: 0 7px 0 0;
}
.acc-pill-flat:last-child .pill-left{
  border-radius: 0 0 0 7px;
}
.acc-pill-flat:last-child .pill-right{
  border-radius: 0 0 7px 0;
}

.pill-left{
  background: #d7e1f2;
  padding: 0.5em 1em;
  min-width: 170px;
  font-weight: bold;
  border: 1px solid #a1adc2;
}
.pill-right{
  background: white;
  border: 1px solid #a1adc2;
  padding: 0.5em 1em;
  padding-right: 4em;
  flex: 1;
  border-left: none;
}
.menu-text {
  margin-top: 4px;
  font-size: 12px;
  white-space: nowrap;
}

.account-text {
  font-weight: 600;
  opacity: 0.9;
  color: #36342f;
  padding-top: 3px;
  padding-bottom: 3px;
  /* font-family: SegoeUI; */
  font-size: 16px;
}

.account-btn {
  text-align: center;
  flex-wrap: wrap;
  padding: 7px 10px;
}

.left-card {
  background:  white;
  color: #2f4798;
    box-shadow: 0 9px 9px 0 rgba(141, 155, 181, 0.2);
}

.img-avatar {
  object-fit: contain;
  width: 60px;
}

.img-separator {
  object-fit: contain;
  width: 100%;
}
.username-text {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.21px;
  text-align: center;
  color: #f5b50a;
}

.currency-text {
  font-size: 12px;
  text-align: center;
  color: #a1a1a1;
}

.credit-text {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.account-tab-active {
  background-image: linear-gradient(to bottom, #36342f, #5c4812);
}

.account-tab {
  background-color: #fff;
}

/* .account-tab-active:hover,
.account-tab:hover {
  opacity: 1;
} */

.tab-label-active {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.tab-label {
  color: #908d84;
  font-size: 14px;
  font-weight: 600;
}

.img-tab {
  width: 24px;
  margin-right: 5px !important;
}

.tab-border {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.btn-inactive-tab {
  width: 100%;
  display: block;
  background-color: #fff;
  font-size: 12px;
  text-align: left;
  color: #908d84;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
}


.btn-active {
  /* font-family: SegoeUI; */
  font-size: 12px;
  font-weight: 600;
  color: black;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background: linear-gradient(to bottom, #00edff, #22a4dd);
}
.account-background{
  background: url(../../../assets/images/main-bg.jpg) #dae2f5 no-repeat 0 0 / 100% 100%;
  min-height: 600px;
}
.otp-selection{
  width: min(330px,100%);
  font-size:small;
  margin-bottom: 5px;
}
.otp-number{
font-size: smaller;
color: #2f4798;
text-decoration: underline;
}
.otp-radio{
color: #2f4798;
font-weight: bold;
}
.amount-form{
  border: 1px solid #7786c3;
  border-radius: 5px;
  display: flex;
  padding: 5px 1em;
  color: #2f4798;
  background: white;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.amount-form input{
  border: none;
  flex: auto;
  text-align: right;
  max-width: 150px;
}
.amount-form input:focus{
  outline: none;
}
.right-separator{
  border-right: 1px solid #ddd;
  padding-right: 1em;
}
.input-heading-info{
  color: #8d98ab;
  background: #ffffff80;
  padding: 10px;
  font-weight: 600;
  font-size: small;
  border-radius: 5px;
}
.withdraw-container{
  margin-top: -20px;
  padding-top: 20px;
  min-height: 90vh;
  background: url(../../../assets/images/login-backdrop-old.png) #dae2f5 no-repeat 0 0 / 100% 100%;
}
.red-border{
  border: 1px solid #ef0b0b;
}
</style>
