import CustomStorage from '../../../CustomStorage';
import CustomHttp from '../../../CustomHttp';
// import CustomEncrypt from '../../../CustomEncrypt';
export default {
  async getSession(context) {
    let json = {
      username: context.rootGetters.username,
    };
    const response = await CustomHttp.post('/api/v1/member/get-session', json);
    if (response.data.data != null) {
      let session = response.data.data.sessionId;
      CustomStorage.setItem('session', session);
      context.commit('setSession', session);
    } else {
      CustomStorage.setItem('session', '');
      context.commit('setSession', '');
    }
  },
  setSelectedHeaderIndex(context, payload) {
    context.commit('setSelectedHeaderIndex', payload);
  },
  setSelectedGame(context, payload) {
    context.commit('setSelectedGame', payload);
  },
  setMiniGameUrl(context, payload) {
    context.commit('setMiniGameUrl', payload);
  },
  setTransferGameProvider(context, payload) {
    context.commit('setTransferGameProvider', payload);
  },
  setIsWalletTransfer(context, payload) {
    context.commit('setIsWalletTransfer', payload);
  },
  setTransferError(context, payload) {
    context.commit('setTransferError', payload);
  },
  setAutoTransferWallet(context, payload) {
     context.commit('setAutoTransferWallet', payload);
  },
};
