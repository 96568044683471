<template>
  <div class="content-wrapper"  @scroll="onScroll">
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
      <img
        class="returnTop"
        src="./../../../../../assets/icons/top.png"
        @click="scrollTop()"
          v-show="showTop"
      />

      <tbody>
        <tr id="containerDiv">
          <td width="10">&nbsp;</td>
          <td>&nbsp;</td>
          <td width="10">&nbsp;</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>
            <span style="color: Red; font-size: 13px;"
              >Attn: We are reserves the right to refuse/reject and suspend
              without prior notification any Customer who is suspected of
              cheating, hacking, attacking, manipulating or damaging the
              Operator's normal betting operations (including the Internet
              Betting Service). Any of the "abnormal bets" will be VOID without
              prior notification. The use of artificial intelligence or "bots"
              on the Internet Betting Service is strictly forbidden. We will
              take measures to prevent and detect the use of programs that are
              designed to enable artificial intelligence (non-humans) to utilize
              the Website. Any attempted or actual use of artificial
              intelligence by the Customer will lead to termination of their
              Account or Voiding the bets, at the discretion of us. Our company
              has the right to refrain from any relevant claims liability.</span
            >
            <div class="information_text termsList">
              <p>
                Some Events, and Markets have different rules and these are
                listed below in the Specific Event/Market Betting Rules for each
                specific Event or Market/bet type in this Website. The following
                are the general rules of betting applicable to all Events and
                Markets/bet types, full compliance is mandatory. Where
                applicable, provisions and definitions set out in the Terms and
                Conditions published in the Website shall apply to these Betting
                Rules and Regulations.
              </p>
              <br />
              <h1><a name="R1" id="R1"></a>1. General Betting Rules</h1>

              <!-- 1.1 -->
              <h2>
                <a name="R11" id="R11"></a>1.1. Application of General Betting
                Rules - Members are advised to read the following general rules
                of betting which shall generally apply to all Events, Markets
                and Products, unless otherwise a specific set of rules apply.
                The Company reserves the right to amend, update or change the
                rules herein, and such revised rules shall be effective and
                binding immediately upon posting in the website.
              </h2>
              <h3>
                <strong>1.1.1.</strong
                ><span>
                  To serve the Members, the Company shall exert effort to
                  provide useful betting information and shall ensure Markets
                  are administered with integrity and transparency. However, the
                  Company does not warrant timeliness, accuracy and completeness
                  of betting information such as dates, venues, odds, results,
                  score, match status and other betting information. It is the
                  Members responsibility to verify such information before
                  placing a bet. The Company shall not be liable for any errors
                  or omissions of such betting information.</span
                >
              </h3>
              <h3>
                <strong>1.1.2.</strong
                ><span>
                  The English names used for the events or teams on the Website
                  may vary with the Non- English names. In the event of any
                  inconsistency between the names, the English version shall
                  prevail.</span
                >
              </h3>
              <h3>
                <strong>1.1.3.</strong
                ><span>
                  Certain betting information in the Website such as current
                  score, time elapsed and other data feed provided by third
                  party supplier may be inaccurate due to time delays and other
                  reasons. The Member acknowledges that the Company does not
                  warrant the timeliness, accuracy and completeness of the data,
                  and in this relation the Member accepts all the risks in
                  relying on the data and placing a bet.</span
                >
              </h3>
              <h3>
                <strong>1.1.4.</strong
                ><span>
                  Once a bet is placed by the Member and confirmed by the
                  Company, the bet can no longer be withdrawn, changed, or
                  cancelled by the Member.</span
                >
              </h3>
              <h3>
                <strong>1.1.5.</strong
                ><span>
                  Under the following circumstances below and without the need
                  for prior notice, the Company shall exercise its sole and
                  absolute discretion to cancel the bet, void any placed bet,
                  and/or put the Member’s account under suspension: The Member
                  has placed a bet in a manner contrary to the rules; The Member
                  has placed a bet more than the maximum allowed; The Member has
                  placed an erroneous and incomplete bet; The Member has placed
                  a bet where the gaming data or odds displayed were erroneous
                  because of human errors or transmission errors; When there
                  appears to have an excessively high number of losses or
                  winnings; When there appears to be an abnormal or irregular
                  use of the gaming platform; When the Company has reason to
                  believe that the bet is involved in illegal betting
                  activities;
                </span>
              </h3>
              <h3>
                <strong>1.1.6.</strong
                ><span>
                  The Company shall have absolute discretion to set maximum
                  amount of pay-outs for its games or products or change the
                  existing amount set, which shall be binding to the Members
                  without the need for prior notice.</span
                >
              </h3>
              <h3>
                <strong>1.1.7.</strong
                ><span>
                  Any bets placed on live betting during a live match will stand
                  and be settled when the event has been completed and when the
                  result is known, even if the stream is disconnected for any
                  cause or reason. However, should this case be covered by a
                  specific rule on disconnection for a specific game or product,
                  that specific rule shall govern.</span
                >
              </h3>
              <h3>
                <strong>1.1.8.</strong
                ><span>
                  Should there be any errors or attacks on the integrity of a
                  game or specific product, the Company shall have the right, in
                  its absolute and full discretion, to suspend or close the game
                  or specific product to maintain its integrity. Should the
                  Member suspect any wrong or error in the system, the Member
                  must discontinue playing and communicate the issue with the
                  Company’s member support department, who shall have absolute
                  discretion whether or not to resolve the issue. Else, the
                  Member who continues to play shall be deemed to have accepted
                  all risks in relation to the outcome of his bets.</span
                >
              </h3>
              <h3>
                <strong>1.1.9.</strong
                ><span>
                  In the ordinary course of business, if the Company rationally
                  believes that a race, event, or match has been fixed or
                  rigged, or that the pool has been manipulated, the Company
                  reserves the right to defer the settlement or withhold any
                  payments. In this connection, the Company may base its belief
                  on the rational analysis of the size, volume or pattern of
                  bets placed across any or all of its betting channels. Any
                  dispute over the interpretation of these rules shall not
                  prevail over the Company’s interpretation.</span
                >
              </h3>
              <h3>
                <strong>1.1.10.</strong
                ><span>
                  Without the need to give prior notice nor further explanation,
                  the Company shall reasonably exercise its absolute discretion
                  to restrict Member from joining the games, refuse Member’s
                  request to join the games and/or expel the Member from the
                  games.</span
                >
              </h3>

              <!-- 1.2 -->
              <h2><a name="R12" id="R12"></a>1.2. Software and Games</h2>
              <h3>
                <strong>1.2.1.</strong
                ><span>
                  The Company does not warrant the quality or security of the
                  Software, nor represents that the Software or the servers are
                  free from errors, defects or virus. The Company shall not
                  admit any liability arising from the use of the Software or
                  from communication or system error and the Company reserves
                  the right to remove all games from the Software for the
                  purpose of debugging, correcting errors or for any other
                  purpose.</span
                >
              </h3>
              <h3>
                <strong>1.2.2.</strong
                ><span>
                  The Company shall grant the Member a personal,
                  non-transferable rights to use the Software in order to play
                  games and place bets, but without giving or transferring
                  ownership or intellectual property rights over the Software.
                  The Member shall be solely responsible and shall assume full
                  risk in placing a bet using the Software and the Company shall
                  not be liable for any loss or damage in this connection.</span
                >
              </h3>
              <h3>
                <strong>1.2.3.</strong
                ><span>
                  In using the Software, the Member is strictly prohibited:
                  Replicate or make copies of the Software; assign, sub-license,
                  lease or transfer the rights to use the Software; Install the
                  software in another device or make the software available for
                  access by another person; Cover, blur or remove any notices
                  such as copyright, trademark, proprietary marks or similar
                  notices from the Software; Disclose any confidential
                  information which may be displayed on the Software about the
                  Company or about the software provider; Decode, disassemble,
                  decompile, reverse engineer, modify or create derivative works
                  based on the Software, any part of it or any copy, adaptation,
                  transcription or merged portion of the Software, or convert
                  the Software or any part of it or make attempt to discover the
                  source code of the Software; Attempt to bypass, access or
                  enter the security system of the Website or the Company or
                  interfere in any way with the Software, games or Website.
                </span>
              </h3>

              <!-- 1.3 -->
              <h2><a name="R13" id="R13"></a>1.3. Change in Scheduled Time</h2>
              <h3>
                <strong>1.3.1.</strong
                ><span>
                  If a Match or Event begins before the scheduled time then only
                  bets placed before the start of the Event (excluding specified
                  live betting) will be considered valid. If a Market is not
                  closed or suspended at the correct time then the Company
                  reserves the right to void all bets placed after the actual
                  start time (excluding specified live betting).</span
                >
              </h3>
              <h3>
                <strong>1.3.2.</strong
                ><span>
                  If a Match or Event is postponed on a date different from the
                  scheduled start date and is not completed within the
                  originally scheduled completion date, then all bets will be
                  void, except for those Markets which have been unconditionally
                  determined.</span
                >
              </h3>
              <h3>
                <strong>1.3.3.</strong
                ><span>
                  If a Match or Event starts but is later abandoned and is not
                  completed within the originally scheduled completion date,
                  then all bets will be void, except for those Markets which
                  have been unconditionally determined.</span
                >
              </h3>
              <h3>
                <strong>1.3.4.</strong
                ><span>
                  If a Match or Event is not completed within the originally
                  scheduled completion time and the result is declared by the
                  particular Event’s relevant governing body, the Company
                  reserves the right to decide whether the match is valid or
                  not. The Company’s decision in this regard is final and
                  binding.</span
                >
              </h3>

              <!-- 1.4 -->
              <h2><a name="R14" id="R14"></a>1.4. Change of Venue</h2>
              <h3>
                <strong>1.4.1.</strong
                ><span>
                  If a Match is scheduled to be played on the ground of the home
                  team but was played on the ground of the away team, then all
                  bets will be considered void. Bets will also be considered
                  void if the home and away team names are wrongly stated in
                  reverse. If a Match is scheduled to be played on neutral
                  ground but is played on non-neutral ground or vice versa, all
                  bets will still be considered valid, unless otherwise
                  stated.</span
                >
              </h3>
              <h3>
                <strong>1.4.2.</strong
                ><span>
                  If there is a change of venue after the Market has been
                  opened, all bets will still be considered valid. This is
                  applicable for all non-team Events.</span
                >
              </h3>

              <!-- 1.5 -->
              <h2><a name="R15" id="R15"></a>1.5. Duration of Time</h2>
              <h3>
                <strong>1.5.1.</strong
                ><span>
                  If there is a change in scheduled duration of the Match or
                  Event, all bets will still be considered valid.</span
                >
              </h3>
              <h3>
                <strong>1.5.2.</strong
                ><span>
                  Any incident during injury or stoppage time that is played, is
                  deemed to have occurred at the end of regular time (example: a
                  goal scored in first half injury time of a soccer match is
                  deemed to have been scored on 45 minutes).</span
                >
              </h3>

              <!-- 1.6 -->
              <h2><a name="R16" id="R16"></a>1.6. Results</h2>
              <h3>
                <strong>1.6.1.</strong
                ><span>
                  For the purpose of Market settlement, the podium positions
                  will count as the official result regardless of any
                  disqualification or amendments subsequent to the announcement.
                  If there is no podium ceremony, results will be determined in
                  accordance with the official result of the relevant governing
                  body at the time of the Market settlement, regardless of any
                  subsequent disqualification or amendment to the results.
                  Otherwise, if no official result is available then the result
                  will be determined with reference to the available evidence
                  known at the time of Market settlement.</span
                >
              </h3>
              <h3>
                <strong>1.6.2.</strong
                ><span>
                  Generally, Markets are settled shortly after the conclusion of
                  an Event. In the event that the Market is erroneously settled,
                  the Company reserves the right to reverse settlement.</span
                >
              </h3>
              <h3>
                <strong>1.6.3.</strong
                ><span>
                  If the result of the Match or Event is uncertain, the Company
                  reserves the right to suspend the settlement of the Market
                  until the result is finally determined.</span
                >
              </h3>
              <h3>
                <strong>1.6.4.</strong
                ><span>
                  The Company will not void or refund settled bets due to any
                  amendments or changes to the results, team names, or any other
                  Match details made seventy-two (72) hours after the Event
                  start time or for bets that have already been settled, except
                  for non-existent Matches.</span
                >
              </h3>
              <h3>
                <strong>1.6.5.</strong
                ><span>
                  In cases where there is a conflict between the official
                  results and the results posted on the Company’s Website,
                  reference shall be made to the Company's video recording of
                  the particular Event to determine the correct result. If there
                  is no video recording available, the official result published
                  in the official website of the relevant governing body for the
                  particular Event shall be considered in determining the
                  correct result. If the official website does not publish the
                  result or the result is patently and obviously wrong, the
                  Company reserves the right to make decisions/ amendments to
                  determine the final result. In this regard, the Company’s
                  decision shall be final and binding.</span
                >
              </h3>
              <h3>
                <strong>1.6.6.</strong
                ><span>
                  For purposes of determining the winning bets, the winner of an
                  Event is determined at the conclusion of the Event regardless
                  of the subsequent reversal of decisions or the result of any
                  protest or appeal.</span
                >
              </h3>

              <!-- 1.7 -->
              <h2>
                <a name="R17" id="R17"></a>1.7. Countdown Timer Acceptance
              </h2>
              <h3>
                <strong>1.7.1.</strong
                ><span>
                  This feature shall be available only on certain Events. If
                  available, Members may place a bet by selecting the “Timer
                  Accept” button on the menu. Each bet placed using timer
                  acceptance will have its own countdown timer for a certain
                  duration. Provided no interruptions, the placed bet will be
                  accepted at the end of the timer. If any interruptions happen
                  before the countdown timer ends, the bets placed under this
                  section shall be considered void.</span
                >
              </h3>
              <h3>
                <strong>1.7.2.</strong
                ><span>
                  The following shall be considered interruptions: If there
                  appears to be a possible red card or a red card is actually
                  given; If there is a possible penalty or a penalty is given;
                  If there appears to be a possible goal or a goal is scored by
                  any team; If there is a fortuitous event including, but not
                  limited to, failure in any equipment or telecommunication that
                  prevents the correct placing, accepting, recording or
                  notification of bets, delays or interruptions in operation or
                  transmission, communication lines failure.
                </span>
              </h3>
              <h3>
                <strong>1.7.3.</strong
                ><span>
                  The Member acknowledges that the current score, time elapsed
                  and other data provided on the Website, while coming from a
                  "live" feed provided by a third party is subject to a time
                  delay and/or may be inaccurate, and that any bet placed based
                  on this data is entirely at the Member’s own risk. The Company
                  provides this data as-is with no warranty as to the accuracy,
                  completeness or timeliness of such data and accepts no
                  responsibility for any loss (direct or indirect) suffered by
                  the Member as a result of his reliance on it.</span
                >
              </h3>

              <!-- 1.8 -->
              <h2><a name="R18" id="R18"></a>1.8. Mix Parlay Maximum Payout</h2>
              <h3>
                <strong>1.8.1.</strong
                ><span>
                  If a Mix Parlay bet has been placed which involves
                  sports with different maximum payout limits, then the lowest
                  maximum limit will be applied.</span
                >
              </h3>
              <h3>
                <strong>1.8.2.</strong
                ><span>
                  All maximum payout limits also apply to any group of Members
                  acting together, in concert, or as an organized
                  group/syndicate and who have bet on the same combination of
                  selections, including where placed in a series of bets, at a
                  range of prices, over a number of days using different
                  accounts.</span
                >
              </h3>
              <h3>
                <strong>1.8.3.</strong
                ><span>
                  If the Company finds any reason to believe that a number of
                  bets have been placed in the manner indicated in the
                  immediately preceding paragraph, the total payout of all those
                  bets combined will be limited to one single maximum payout.
                  This single maximum payout will be credited to the account of
                  the Member who placed the first bet in the series of
                  bets.</span
                >
              </h3>
              <h3>
                <strong>1.8.4.</strong
                ><span>
                  The maximum payout is shown in MYR Ringgit. If bets are placed
                  in another currency, the payout will be converted using system
                  default currency conversion rate on the day of settlement of
                  the bets.</span
                >
              </h3>

              <h1>
                <a name="R2" id="R2"></a>2. Market (Bet Type) Rules General
                Rules
              </h1>
              <!-- 2.1 -->
              <h2><a name="R21" id="R21"></a>2.1. General</h2>
              <h3><strong>2.1.1.</strong><span> Outright </span></h3>
              <h4>
                <strong>2.1.1.1.</strong
                ><span>
                  Outright means betting on the winner of an Event, race or
                  tournament.
                </span>
              </h4>
              <h4>
                <strong>2.1.1.2.</strong
                ><span>
                  Outright place means betting on competitors that fill the
                  designated places in an Event, race or tournament. The number
                  of places that are paid as winners will be indicated in the
                  Market title.
                </span>
              </h4>
              <h4>
                <strong>2.1.1.3.</strong
                ><span>
                  Nếu một đấu thủ/cầu thủ không bắt đầu một Sự kiện, cuộc đua
                  hoặc trận đấu thì tất cả các khoản cược Thắng cho đấu thủ/cầu
                  thủ đó sẽ vô hiệu trừ phi có quy định khác trong quy tắc cá
                  cược thể thao cụ thể.
                </span>
              </h4>
              <h4>
                <strong>2.1.1.4.</strong
                ><span>
                  If there are two or more winners, or a “Dead Heat” is declared
                  in any Outright Market then the payout odds (less the stake)
                  are divided by the number of winners and settled accordingly
                  with the stake returned.
                </span>
              </h4>
              <h4>
                <strong>This is an example</strong
                ><span>
                  For customer inquiry on what if this
                  <strong>ENGLISH PREMIER LEAGUE</strong> - Top Goalscorer have
                  2 top goalscorer, how company calculate on the payout.
                </span>
              </h4>
              <h4 class="style1">
                The payout will be: stake/winner x(odds-1) = Payout (If top
                goalscorer have more than 2 person = stake/ (number of
                person)<br />
              </h4>
              <h5>
                <strong>For example:</strong><br />
                <span>
                  <strong>ENGLISH PREMIER LEAGUE - Top Goalscorer</strong><br />
                  Dimitar Berbatov <strong>1.40</strong><br />
                  Carlos Tevez <strong>3.50</strong><br />
                  IF i placed bet 100 at
                  <strong>Dimitar Berbatovwith</strong> odds 1.40.<br />
                  If top goalscorer have 2 person.<br />
                  my payout will be: = Stake 100/2 x (Odds - 1) = 20<br />
                  IF i placed bet 100 at <strong>Carlos Tevez</strong> with odds
                  3.50<br />
                  If top goalscorer have 2 person.<br />
                  my payout will be:= Stake 100/2 x (Odds - 1) = 125<br />
                </span>
              </h5>
              <h4>
                <strong>2.1.1.5.</strong
                ><span>
                  The term “Any Other Player” (Any Other Team etc) refers to all
                  competitors not named in the market.
                </span>
              </h4>
              <h3><strong>2.1.2.</strong><span> Moneyline </span></h3>
              <h4>
                <strong>2.1.2.1</strong
                ><span>
                  Moneyline means betting on one competitor or team to defeat
                  another in an Event or to be placed higher in an Event
                  match-up. Remaining Moneyline rules are set out in the
                  Specific Event Betting Rules.
                </span>
              </h4>
              <h4>
                <strong>2.1.2.2</strong
                ><span>
                  The term “The Field” refers to all competitors other than the
                  competitor named in the Moneyline match up.
                </span>
              </h4>
              <h3>
                <strong>2.1.3.</strong
                ><span> Cược chấp (HDP) &amp; Cược chấp hiệp 1 </span>
              </h3>
              <h4>
                <strong>2.1.3.1.</strong
                ><span>
                  Handicap means betting when one competitor or team receives a
                  virtual head start (effectively leading by that head start
                  before the Event begins). The winner is the competitor or team
                  with the better score after adding the given handicap to the
                  result. Remaining Handicap rules are set out in the Specific
                  Event Betting Rules.
                </span>
              </h4>
              <h4>
                <strong>2.1.3.2.</strong
                ><span>
                  Cược chấp hiệp 1 có nghĩa là cá cược đội nào ghi bàn nhiều hơn
                  sau khi đã tính thêm số bàn chấp vào kết quả hiệp 1 của trận
                  đấu.
                </span>
              </h4>
              <h3>
                <strong>2.1.4.</strong
                ><span> Tài/Xỉu (OU) &amp; Tài/Xỉu hiệp 1 </span>
              </h3>
              <h4>
                <strong>2.1.4.1.</strong
                ><span>
                  Over/Under means betting that is determined by the total
                  number of points (goals, games etc) in the final result of an
                  Event. If the total is more than the Over/Under pre-designated
                  line then the winning result is Over; if the total is less
                  than the Over/Under pre-designated line then the winning
                  result is Under.
                </span>
              </h4>
              <h4>
                <strong>2.1.4.2.</strong
                ><span>
                  Tài/Xỉu hiệp 1 là loại cược được xác định dựa trên tổng số
                  điểm trong kết quả hiệp 1 của trận đấu. Nếu tổng số điểm lớn
                  hơn đường Tài/Xỉu đã định sẵn thì kết quả thắng sẽ là Tài; nếu
                  tổng số điểm nhỏ hơn đường Tài/Xỉu định sẵn thì kết quả thắng
                  sẽ là Xỉu.
                </span>
              </h4>
              <h3>
                <strong>2.1.5.</strong
                ><span> Lẻ/Chẵn (OE) &amp; Lẻ/Chẵn hiệp 1 </span>
              </h3>
              <h4>
                <strong>2.1.5.1.</strong
                ><span>
                  Odd/Even means betting that is determined by whether the total
                  number of points (goals, games etc) in the final result of an
                  Event is Odd or Even.
                </span>
              </h4>
              <h4>
                <strong>2.1.5.2.</strong
                ><span>
                  1 Lẻ/Chẵn hiệp 1 là loại cược được xác định dựa trên việc tổng
                  số điểm vào cuối hiệp 1 của trận đấu là Lẻ hay Chẵn..
                </span>
              </h4>
              <h4>
                <strong>2.1.5.3.</strong
                ><span>
                  Khoản cược cho hiệp 1 sẽ không có giá trị nếu trận đấu bị bỏ
                  dở trước khi kết thúc hiệp 1. Tuy nhiên, nếu trận đấu bị bỏ dở
                  sau khi hiệp 1 kết thúc thì tất cả các khoản cược cho hiệp 1
                  sẽ vẫn có giá trị.
                </span>
              </h4>
              <h3>
                <strong>2.1.6.</strong
                ><span> Tỷ Số Chẵn / Tỷ Số Lẻ của mỗi đội </span>
              </h3>
              <h4>
                <strong>2.1.6.1.</strong
                ><span>
                  Tỷ Số Chẵn / Tỷ Số Lẻ của mỗi đội chẵn / lẻ có nghĩa là cá
                  cược để dự đoán xem số điểm toàn thời gian của một đội bóng
                  trong một trận đấu cụ thể sẽ là lẻ hoặc chẵn.
                </span>
              </h4>
              <h4>
                <strong>2.1.6.2.</strong
                ><span>
                  Bất kỳ thêm thời gian trong trận đấu sẽ không được tính cho
                  các.
                </span>
              </h4>
              <h3>
                <strong>2.1.7.</strong
                ><span> Chẳn / Lẻ của Nửa trận/Toàn trận </span>
              </h3>
              <h4>
                <strong>2.1.7.1.</strong
                ><span>
                  Chẳn / Lẻ của Nửa trận/Toàn trận nghĩa là đặt cược để dự đoán
                  kết quả Nửa trận và Toàn trận trong trận đấu là lẻ và lẻ, lẻ
                  và chẵn ,chẵn và lẻ , hoặc chẵn và chẵn.
                </span>
              </h4>
              <h4>
                <strong>2.1.7.2.</strong
                ><span> Có 4 lựa chọn để đặt cược: </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>Lẻ/Lẻ</li>
                  <li>Lẻ/Chẳn</li>
                  <li>Chẳn/Lẻ</li>
                  <li>Chẳn/Chẳn</li>
                </ol>
              </h5>
              <h4>
                <strong>2.1.7.3.</strong
                ><span>
                  Đối với loại hình đặt cược này , bất kỳ thêm thời gian sẽ
                  không được tính cho các mục đích xác định kết quả toàn thời
                  gian của trận đấu.
                </span>
              </h4>
              <h3><strong>2.1.8.</strong><span> Mix Parlays </span></h3>
              <h4>
                <strong>2.1.8.1.</strong
                ><span>
                  Cược Tổng Hợp có nghĩa là cược nhóm, phải có ít nhất 2 lựa
                  chọn (hoặc hơn) cho một lần cược, Nếu tất cả các lựa chọn đều
                  thắng thì Cược Tổng hợp thắng và sẽ được thanh toán ở tỷ lệ
                  cược kết hợp của hai lựa chọn đó. Nếu một (hoặc nhiều) lựa
                  chọn bị thua thì Cược tổng hợp thua. Nếu một (hoặc nhiều) lựa
                  chọn bị trì hoãn thì tỷ lệ cược cho lựa chọn đó sẽ được chuyển
                  thành tỷ lệ 1,00.
                </span>
              </h4>
              <h4>
                <strong>2.1.8.2.</strong
                ><span>
                  During LIVE Mix Parlay, if any bet in the selection is
                  REJECTED, then the Parlay odds shall be evaluated at [1.00].
                </span>
              </h4>
              <h4>
                <strong>2.1.8.3.</strong
                ><span>
                  Please refer to “Help” on the Mix Parlay betting page for
                  further details.
                </span>
              </h4>
              <h4>
                <strong>2.1.8.4.</strong
                ><span>
                  A trixie consists of 4 bets involving 3 selections in
                  different events, 3 doubles and 1 treble. Any two winning
                  selections guarantee a return. If any 2 of your selections
                  win, 1 of the double bets gives a return. If all 3 of your
                  selections win, all 3 of the doubles, and the treble give a
                  return. If one (or more) selections should be postponed then
                  the odds for that selection will revert to 1.00 odds.
                </span>
              </h4>
              <h4>
                <strong>2.1.8.5.</strong
                ><span>
                  A yankee consists of 11 bets involving 4 selections in
                  different events, 6 doubles, 4 trebles and 1 four-fold. Any
                  two winning selections guarantee a return. If any 2 of your
                  selections win, 1 of the doubles gives a return. If any 3
                  selections win, 3 of the double bets and 1 of the trebles give
                  a return. If all 4 selections win, then all 6 doubles, 4
                  trebles and the four-fold give a return. If one (or more)
                  selections should be postponed then the odds for that
                  selection will revert to 1.00 odds.
                </span>
              </h4>
              <h4>
                <strong>2.1.8.6.</strong
                ><span>
                  A Canadian consists of 26 bets involving 5 selections in
                  different events, 10 doubles, 10 trebles, 5 four-folds and 1
                  five-fold. Any two winning selections guarantee a return. If
                  any 2 of your selections win, 1 of the doubles gives a return.
                  If any 4 selections win, 6 of the double bets, 4 trebles and
                  the four-fold give a return. If all 5 selections win, then all
                  10 doubles, 10 trebles, 5 four-fold and the five-fold give a
                  return. If one (or more) selections should be postponed then
                  the odds for that selection will revert to 1.00 odds.
                </span>
              </h4>
              <h4>
                <strong>2.1.8.7.</strong
                ><span>
                  A heinz consists of 57 bets involving 6 selections in
                  different events, 15 doubles, 20 trebles, 15 four-folds, 6
                  five-folds and 1 six-fold. Any two winning selections
                  guarantee a return. If any 2 of your selections win, 1 of the
                  double bets gives a return. If any 5 selections win, then all
                  10 doubles, 10 trebles, 5 four-fold and the five-fold give a
                  return. If all 6 selections win, then all 15 doubles, 20
                  trebles, 15 four-fold, 6 five-fold and the six-fold give a
                  return. If one (or more) selections should be postponed then
                  the odds for that selection will revert to 1.00 odds.
                </span>
              </h4>
              <h4>
                <strong>2.1.8.8.</strong
                ><span>
                  A super heinz consists of 120 bets involving 7 selections in
                  different events, 21 doubles, 35 trebles, 35 four-folds, 21
                  five-folds, 7 six-folds and 1 seven-fold. Any two winning
                  selections guarantee a return. If any 2 of your selections
                  win, 1 of the double bets gives a return. If any 6 selections
                  win, then all 15 doubles, 20 trebles, 15 four-fold, 6
                  five-fold and the six-fold give a return. If all 7 selections
                  win, then all 21 doubles, 35 trebles, 35 four-fold, 21
                  five-fold, 7 six-fold and the seven-fold give a return. If one
                  (or more) selections should be postponed then the odds for
                  that selection will revert to 1.00 odds.
                </span>
              </h4>
              <h4>
                <strong>2.1.8.9.</strong
                ><span>
                  A goliath consists of 247 bets involving 8 selections in
                  different events, 28 doubles, 56 trebles, 70 four-folds, 56
                  five-folds, 28 six-folds, 8 seven-folds and 1 eight-fold. Any
                  two winning selections guarantee a return. If any 2 of your
                  selections win, 1 of the double bets gives a return. If any 7
                  selections win, then all 21 doubles, 35 trebles, 35 four-fold,
                  21 five-fold, 7 six-fold and the seven-fold give a return. If
                  all 8 selections win, then all 28 doubles, 56 trebles, 70
                  four-fold, 56 five-fold, 28 six-fold, 8 seven-fold and the
                  eight-fold give a return.If one (or more) selections should be
                  postponed then the odds for that selection will revert to 1.00
                  odds.
                </span>
              </h4>
              <h4>
                <strong>2.1.8.10.</strong
                ><span>
                  LIVE events are not offered for selection in Trixie, Yankee,
                  Canadian, Heinz, Super Heinz and Goliath.
                </span>
              </h4>
              <h4>
                <strong>2.1.8.11.</strong
                ><span>
                  Please refer to the “ ” icon on the bet menu in the Mix Parlay
                  betting page for further details.
                </span>
              </h4>

              <h2><a name="R22" id="R22"></a>2.2. Soccer Bet Types</h2>
              <h3>
                <strong>2.2.1.</strong
                ><span>
                  Unless otherwise stated the result to soccer bet types refer
                  to the score at the end of regular time (including any
                  stoppage time added by the referee). Extra-time does not
                  count.
                </span>
              </h3>
              <h3><strong>2.2.2.</strong><span> 1X2 </span></h3>
              <h4>
                <strong>2.2.2.1.</strong
                ><span>
                  1X2 means betting in any one of three possible winning
                  outcomes for an Event. 1 refers to the team that is named
                  first (usually the home team); X refers to the game resulting
                  in a draw or tie; 2 refers to the team that is named second
                  (usually the away team).
                </span>
              </h4>

              <h3>
                <strong>2.2.3.</strong
                ><span> Correct Score &amp; Correct Score Running </span>
              </h3>
              <h4>
                <strong>2.2.3.1.</strong
                ><span>
                  Correct Score means betting to predict the final score at the
                  end of full-time.
                </span>
              </h4>

              <h4>
                <strong>2.2.3.2.</strong
                ><span>
                  Correct Score to win "AOS" means the selected team must win to
                  a score not listed in the selection.
                </span>
              </h4>

              <h4>
                <strong>2.2.3.3.</strong
                ><span>
                  Correct Score Running to win Full Time "AOS" means the
                  selected team must win to a score not listed in the selection.
                  (Where final score with either Home or Away with additional
                  "5" goals or above excluding current score)
                </span>
              </h4>

              <h4>
                <strong>2.2.3.4.</strong
                ><span>
                  Correct Score Running to win Half Time "AOS" means the
                  selected team must win to a score not listed in the selection.
                  (Where final score with either Home or Away with additional
                  "4" goals or above excluding current score)
                </span>
              </h4>

              <h5>
                <strong>Example Full Time AOS:</strong><br />
                <span>
                  Result for Full Time AOS: Current Score 1-2 (Excluding: 2-2,
                  3-2, 3-3, 4-2, 4-3, 4-4, 5-2, 5-3, 5-4, 5-5, 1-2, 2-3, 3-4,
                  4-5, 5-6, 1-3, 1-4, 2-4, 1-5, 2-5, 3-5, 1-6, 2-6, 3-6, 4-6)<br />
                  Result for Full Time AOS: Current Score 3-2 (Excluding: 4-2,
                  5-2, 5-3, 6-2, 6-3, 6-4, 7-2, 7-3, 7-4, 7-5, 3-2, 4-3, 5-4,
                  6-5, 7-6, 3-3, 3-4, 4-4, 3-5, 4-5, 5-5, 3-6, 4-6, 5-6, 6-6)
                </span>
              </h5>

              <h5>
                <strong>Example Half Time AOS:</strong><br />
                <span>
                  Result for Half Time AOS: Current Score 1-2 (Excluding: 2-2,
                  3-2, 3-3, 4-2, 4-3, 4-4, 1-2, 2-3, 3-4, 4-5, 1-3, 1-4, 2-4,
                  1-5, 2-5, 3-5)<br />
                  Result for Half Time AOS: Current Score 3-2 (Excluding: 4-2,
                  5-2, 5-3, 6-2, 6-3, 6-4, 3-2, 4-3, 5-4, 6-5, 3-3, 3-4, 4-4,
                  3-5, 4-5, 5-5)
                </span>
              </h5>

              <h3>
                <strong>2.2.4.</strong
                ><span>
                  Tổng số bàn thắng &amp; Tổng số bàn thắng trong hiệp 1
                </span>
              </h3>
              <h4>
                <strong>2.2.4.1.</strong
                ><span>
                  Total Goal means betting determined by the total sum of the
                  number of goals scored in an Event.
                </span>
              </h4>
              <h4>
                <strong>2.2.4.2.</strong
                ><span>
                  Tổng số bàn thắng trong hiệp 1 nghĩa là cá cược được xác định
                  dựa trên tổng số bàn thắng được ghi trong hiệp 1 của trận đấu.
                </span>
              </h4>
              <h4>
                <strong>2.2.4.3.</strong
                ><span>
                  Khoản cược sẽ không có giá trị nếu trận đấu bị huỷ bỏ ngoại
                  trừ trường hợp khoản cược đã được định đoạt trước.
                </span>
              </h4>
              <h3>
                <strong>2.2.5.</strong
                ><span> Half-time/Full-time (HT.FT) </span>
              </h3>
              <h4>
                <strong>2.2.5.1.</strong
                ><span>
                  Half-time/Full-time means betting to predict both the
                  Half-time result and Full-time result of an Event (extra-time
                  does not count). The following with respect to this Market
                  shall mean: H refers to the first named team (usually the home
                  team); D refers to a draw; A refers to the second named team
                  (usually the away team).
                </span>
              </h4>
              <h4>
                <strong>2.2.5.2.</strong
                ><span>
                  Example – HA means that the first named team (Home) will be
                  leading at half-time, and the second named team (Away) will be
                  leading at full-time.
                </span>
              </h4>
              <h3>
                <strong>2.2.6.</strong
                ><span>
                  Bàn thắng đầu tiên/ Bàn thắng cuối cùng &amp; Bàn thắng đầu
                  tiên/Bàn thắng cuối cùng trong hiệp 1
                </span>
              </h3>
              <h4>
                <strong>2.2.6.1.</strong
                ><span>
                  First Goal/Last Goal means betting on which team will score
                  the first or last goal in a match. With respect to this
                  Market, the following shall mean: HF refers to the first named
                  team (usually the home team) scoring the first goal. HL refers
                  to the first named team scoring the last goal. AF refers to
                  the second named team (usually the away team) scoring the
                  first goal. AL refers to the second named team scoring the
                  last goal. NG refers to no goals being scored during the
                  Event.
                </span>
              </h4>
              <h4>
                <strong>2.2.6.2.</strong
                ><span>
                  Bàn thắng đầu tiên/Bàn thắng cuối cùng trong hiệp 1 nghĩa là
                  cá cược đội nào sẽ ghi bàn thắng đầu tiên hoặc bàn thắng cuối
                  cùng trong hiệp 1 của trận đấu. Trong hình thức chơi này,
                  những từ sau có ý nghĩa như sau: HF là đội được nhắc đến tên
                  trước (thông thường là đội nhà) đã ghi bàn thắng đầu tiên. HL
                  chỉ đội được nhắc đến tên đầu tiên đã ghi bàn thắng cuối cùng.
                  AF chỉ đội được nhắc đến tên thứ hai (thông thường là đội
                  khách) đã ghi bàn thắng đầu tiên. AL chỉ đội được nhắc đến tên
                  thứ hai đã ghi bàn thắng cuối cùng. NG nghĩa là không có bàn
                  thắng nào được ghi trong suốt thời gian diễn ra sự kiện.
                </span>
              </h4>
              <h4>
                <strong>2.2.6.3.</strong
                ><span>
                  Own goals count to the team credited with the goal.
                </span>
              </h4>
              <h4>
                <strong>2.2.6.4.</strong
                ><span>
                  If an Event is abandoned after a goal is scored then all bets
                  on First Goal (and No Goal) will stand while bets on Last Goal
                  will be void. If an Event is abandoned without any goal being
                  scored then all wagers on First Goal, Last Goal and No Goal
                  will be void.
                </span>
              </h4>
              <h3>
                <strong>2.2.7.</strong><span> Which Team to Kick-Off </span>
              </h3>
              <h4>
                <strong>2.2.7.1.</strong
                ><span>
                  Which Team to Kick-Off means betting on which team will
                  kick-off the Event.
                </span>
              </h4>
              <h4>
                <strong>2.2.7.2.</strong
                ><span>
                  If an Event is abandoned after the Event has kicked-off then
                  all bets are still valid.
                </span>
              </h4>
              <h3>
                <strong>2.2.8.</strong
                ><span> Tổng số của đội nhà so với Tổng số của đội khách </span>
              </h3>
              <h4>
                <strong>2.2.8.1.</strong
                ><span>
                  Tổng số bàn thắng của đội nhà so với tổng số bàn thắng của đội
                  khách
                </span>
              </h4>
              <h5>
                <strong>2.2.8.1.1.</strong
                ><span>
                  Tổng số bàn thắng của đội nhà so với tổng số bàn thắng của đội
                  khách có nghĩa là cược để dự đoán tổng số bàn thắng mà đội nhà
                  đã ghi được so với tổng số bàn thắng mà đội khách đã ghi được
                  đối với các trận đấu trong một liên đoàn cụ thể chơi vào một
                  ngày nhất định.
                </span>
              </h5>
              <h4>
                <strong>2.2.8.2.</strong
                ><span>
                  Tổng số quả phạt góc của đội nhà so với tổng số quả phạt góc
                  của đội khách
                </span>
              </h4>
              <h5>
                <strong>2.2.8.2.1.</strong
                ><span>
                  Tổng số quả phạt góc của đội nhà so với tổng số quả phạt góc
                  của đội khách nghĩa là cược để dự đoán tổng số quả phạt góc mà
                  đội nhà được hưởng so với tổng số quả phạt góc mà đội khách đã
                  được hưởng đối với các trận đấu trong một liên đoàn cụ thể
                  chơi vào một ngày nhất định.
                </span>
              </h5>
              <h4>
                <strong>2.2.8.3.</strong
                ><span>
                  Đội nhà chỉ đội được nêu tên đầu tiên và đội khách chỉ đội
                  được nêu tên thứ hai.
                </span>
              </h4>
              <h4>
                <strong>2.2.8.4.</strong
                ><span>
                  Nếu một (hoặc nhiều) trận đấu bị hoãn lại hoặc hủy bỏ thì
                  khoản cược sẽ vô hiệu.
                </span>
              </h4>
              <h3>
                <strong>2.2.9.</strong
                ><span>
                  Cược Trên/Dưới cho một đội (Single Team Over/Under) &amp; Cược
                  Trên/Dưới trong hiệp 1 cho một đội (Single Team 1st Half
                  Over/Under)
                </span>
              </h3>
              <h4>
                <strong>2.2.9.1.</strong
                ><span>
                  Single Team Over/Under means goal scored by a specified team
                  in a match.
                </span>
              </h4>
              <h4>
                <strong>2.2.9.2.</strong
                ><span>
                  Cược Trên/Dưới trong hiệp 1 cho một đội (Single Team 1st Half
                  Over/Under) là loại cược dự đoán bàn thắng được ghi bởi một
                  đội cụ thể trong hiệp 1 của trận đấu.
                </span>
              </h4>
              <h4>
                <strong>2.2.9.3.</strong
                ><span>
                  If the total is more than the OU line then the winning result
                  is Over; if the total is less than the OU line then the
                  winning result is Under.
                </span>
              </h4>
              <h3><strong>2.2.10.</strong><span> Corners </span></h3>
              <h4>
                <strong>2.2.10.1.</strong
                ><span> An awarded corner but untaken do not count. </span>
              </h4>
              <h4>
                <strong>2.2.10.2.</strong><span> Number of Corners </span>
              </h4>
              <h5>
                <strong>2.2.10.2.1.</strong
                ><span> Cược chấp &amp; Cược chấp hiệp 1 </span>
                <p class="h7">
                  <strong class="style3">2.2.10.2.1.1.</strong
                  ><span>
                    Handicap means betting on which team will take the most
                    corners during a match inclusive of any handicaps.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.10.2.1.2.</strong
                  ><span>
                    Cược chấp hiệp 1 nghĩa là đặt cược vào đội nào có nhiều quả
                    phạt góc nhất trong hiệp 1 của trận đấu bao gồm bất kỳ quả
                    phạt góc được chấp nào.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.10.2.1.3.</strong
                  ><span>
                    Khoản cược của cược chấp hiệp 1 sẽ không có giá trị nếu trận
                    đấu bị bỏ dở trong suốt thời gian diễn ra hiệp 1 của trận
                    đấu. Khoản cược của cược chấp hiệp 1 sẽ vẫn có giá trị nếu
                    trận đấu bị bỏ dở trong suốt thời gian diễn ra hiệp 2 của
                    trận đấu.
                  </span>
                </p>
              </h5>
              <h5>
                <strong>2.2.10.2.2.</strong
                ><span> Trên/Dưới &amp; Trên/Dưới hiệp 1 </span>
                <p class="h7">
                  <strong class="style3">2.2.10.2.2.1.</strong
                  ><span>
                    Over/Under means betting on the total number of corners
                    taken by both teams during a match.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.10.2.2.2.</strong
                  ><span>
                    Trên/Dưới hiệp 1 nghĩa là đặt cược dựa trên tổng số quả phạt
                    góc do cả hai đội ghi trong suốt thời gian diễn ra hiệp 1
                    của trận đấu.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.10.2.2.3.</strong
                  ><span>
                    If the total is more than the OU line then the winning
                    result is Over; if the total is less than the OU line then
                    the winning result is Under.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.10.2.2.4.</strong
                  ><span>
                    Khoản cược sẽ không có giá trị nếu trận đấu bị bỏ dở trừ phi
                    việc dàn xếp khoản cược đã được quyết định.
                  </span>
                </p>
              </h5>
              <h4>
                <strong>2.2.10.3.</strong
                ><span> First Corner &amp; 2nd Half First Corner </span>
              </h4>
              <h5>
                <strong>2.2.10.3.1.</strong
                ><span>
                  Quả phạt góc đầu tiên nghĩa là đặt cược đội nào sẽ có quả phạt
                  góc đầu tiên trong trận đấu.
                </span>
              </h5>
              <h5>
                <strong>2.2.10.3.2.</strong
                ><span>
                  2nd Half First Corner means betting on which team will take
                  the first corner in the 2nd half of a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.10.3.3.</strong
                ><span>
                  If a match is abandoned after the first corner has been taken
                  then all bets are valid. If a match is abandoned before the
                  first corner has been taken then all bets are void.
                </span>
              </h5>
              <h4><strong>2.2.10.4.</strong><span> Last Corner </span></h4>
              <h5>
                <strong>2.2.10.4.1.</strong
                ><span>
                  Last Corner means betting on which team will take the last
                  corner in a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.10.4.2.</strong
                ><span> If a match is abandoned then all bets are void. </span>
              </h5>
              <h4><strong>2.2.10.5.</strong><span> Next Corner </span></h4>
              <h5>
                <strong>2.2.10.5.1.</strong
                ><span>
                  Next Corner means betting on which team will take the next
                  corner in a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.10.5.2.</strong
                ><span>
                  All bets are consider Valid if the designated corner had been
                  taken.
                </span>
              </h5>
              <h3><strong>2.2.11.</strong><span> Total Bookings </span></h3>
              <h4>
                <strong>2.2.11.1.</strong
                ><span>
                  A yellow card counts as one point and a red card counts as two
                  points. The maximum number of points that a player can receive
                  during a match is three (one for a yellow and two for a red,
                  the second yellow card does not count).
                </span>
              </h4>
              <h4>
                <strong>2.2.11.2.</strong
                ><span>
                  Cards shown to non-players (managers, coaches, substitutes
                  etc) do not count.
                </span>
              </h4>
              <h4>
                <strong>2.2.11.3.</strong
                ><span> Number of Total Bookings </span>
              </h4>
              <h5>
                <strong>2.2.11.3.1.</strong
                ><span> Cược chấp &amp; Cược chấp hiệp 1 </span>
                <p class="h7">
                  <strong class="style3">2.2.11.3.1.1.</strong
                  ><span>
                    Handicap means betting on which team will receive the most
                    cards during a match inclusive of any handicaps.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.11.3.1.2.</strong
                  ><span>
                    Cược chấp hiệp 1 nghĩa là đặt cược đội nào sẽ nhận nhiều thẻ
                    phạt nhất trong suốt hiệp 1 của trận đấu bao gồm của bất kỳ
                    thẻ phạt được chấp nào.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.11.3.1.3.</strong
                  ><span>
                    Khoản cược của Cược chấp hiệp 1 sẽ không có giá trị nếu trận
                    đấu bị bỏ dở trong suốt thời gian diễn ra hiệp 1 của trận
                    đấu. Tuy nhiên, khoản cược sẽ vẫn có giá trị nếu trận đấu bị
                    bỏ dở trong suốt thời gian diễn ra hiệp 2 của trận đấu.
                  </span>
                </p>
              </h5>
              <h5>
                <strong>2.2.11.3.2.</strong
                ><span> Trên/Dưới &amp; Trên/Dưới hiệp 1 </span>
                <p class="h7">
                  <strong class="style3">2.2.11.3.2.1.</strong
                  ><span>
                    Over/Under means betting on the total number of cards
                    received by both teams during a match.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.11.3.2.2.</strong
                  ><span>
                    Trên/Dưới hiệp 1 nghĩa là cược dựa trên tổng số thẻ phạt mà
                    cả hai đội phải nhận trong suốt hiệp 1 của trận đấu.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.11.3.2.3.</strong
                  ><span>
                    If the total is more than the OU line then the winning
                    result is Over; if the total is less than the OU line then
                    the winning result is Under.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.11.3.2.4.</strong
                  ><span>
                    Khoản cược sẽ không có giá trị nếu trận đấu bị bỏ dở trừ phi
                    việc dàn xếp khoản cược đã được quyết định.
                  </span>
                </p>
              </h5>
              <h4>
                <strong>2.2.11.4.</strong
                ><span> First Booking &amp; 2nd Half First Booking </span>
              </h4>
              <h5>
                <strong>2.2.11.4.1.</strong
                ><span>
                  First Booking means betting on which team will receive first
                  booking (Yellow or Red) in a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.11.4.2.</strong
                ><span>
                  2nd Half First Booking means betting on which team will
                  receive first booking (Yellow or Red) in the 2nd half of a
                  match.
                </span>
              </h5>
              <h5>
                <strong>2.2.11.4.3.</strong
                ><span>
                  If two or more players receive a booking for the same incident
                  then the player who is shown the first card by the referee
                  will be deemed the winner.
                </span>
              </h5>
              <h5>
                <strong>2.2.11.4.4.</strong
                ><span>
                  If a match is abandoned after the first card has been issued
                  then all bets are valid. If a match is abandoned before the
                  first card has been issued then all bets are void.
                </span>
              </h5>
              <h4><strong>2.2.11.5.</strong><span> Last Booking </span></h4>
              <h5>
                <strong>2.2.11.5.1.</strong
                ><span>
                  Last Booking means betting on which team will receive last
                  booking (Yellow or Red) in a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.11.5.2.</strong
                ><span>
                  If two or more players receive a booking for the same incident
                  then the player who is shown the last card by the referee will
                  be deemed the winner.
                </span>
              </h5>
              <h5>
                <strong>2.2.11.5.3.</strong
                ><span> If a match is abandoned then all bets are void. </span>
              </h5>
              <h4>
                <strong>2.2.11.6.</strong
                ><span> Thẻ phạt tiếp theo (Next Booking) </span>
              </h4>
              <h5>
                <strong>2.2.11.6.1.</strong
                ><span>
                  Thẻ phạt tiếp theo (Next Booking) là loại cược dự đoán đội nào
                  sẽ nhận thẻ phạt tiếp theo, có thể là thẻ đỏ và/hoặc thẻ vàng.
                </span>
              </h5>
              <h5>
                <strong>2.2.11.7.2.</strong
                ><span>
                  Tất cả các khoản cược sẽ được xem là có giá trị nếu số thẻ
                  phạt mà hai đội phải nhận bằng số thẻ phạt đã đặt cược.
                </span>
              </h5>
              <h3><strong>2.2.12.</strong><span> Offsides </span></h3>
              <h4>
                <strong>2.2.12.1.</strong><span> Number of Offside </span>
              </h4>
              <h5>
                <strong>2.2.12.1.1.</strong
                ><span> Cược chấp &amp; Cược chấp hiệp 1 </span>
                <p class="h7">
                  <strong class="style3">2.2.12.1.1.1.</strong
                  ><span>
                    Handicap means betting on which team will be caught offside
                    the most number of times during a match, inclusive of any
                    handicaps.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.12.1.1.2.</strong
                  ><span>
                    Cược chấp hiệp 1 nghĩa là đặt cược đội nào sẽ bị bắt việt vị
                    nhiều lần nhất trong hiệp 1 của trận đấu, bao gồm bất kỳ lần
                    việt vị được chấp nào.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.12.1.1.3.</strong
                  ><span>
                    Khoản cược của Cược chấp hiệp 1 sẽ không có giá trị nếu trận
                    đấu bị bỏ dở trong suốt thời gian diễn ra hiệp 1 của trận
                    đấu. Tuy nhiên, khoản cược sẽ vẫn có giá trị nếu trận đấu bị
                    bỏ dở trong suốt thời gian diễn ra hiệp 2 của trận đấu.
                  </span>
                </p>
              </h5>
              <h5>
                <strong>2.2.12.1.2.</strong
                ><span> Trên/Dưới &amp; Trên/Dưới hiệp 1 </span>
                <p class="h7">
                  <strong class="style3">2.2.12.1.2.1.</strong
                  ><span>
                    Over/Under means betting on the total number of offside
                    decisions during a match.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.12.1.2.2.</strong
                  ><span>
                    Trên/Dưới hiệp 1 là loại cược dựa trên tổng số lần quyết
                    định việt vị trong hiệp 1 của trận đấu.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.12.1.2.3.</strong
                  ><span>
                    If the total is more than the OU line then the winning
                    result is Over; if the total is less than the OU line then
                    the winning result is Under.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.12.1.2.4.</strong
                  ><span>
                    Khoản cược sẽ không có giá trị nếu trận đấu bị bỏ dở trừ phi
                    việc dàn xếp khoản cược đã được quyết định.
                  </span>
                </p>
              </h5>
              <h4>
                <strong>2.2.12.2.</strong
                ><span> First Offside &amp; 2nd Half First Offside </span>
              </h4>
              <h5>
                <strong>2.2.12.2.1.</strong
                ><span>
                  First Offside means betting on which team will be caught
                  offside first in a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.12.2.2.</strong
                ><span>
                  2nd Half First Offside means betting on which team will be
                  caught offside first in the 2nd half of a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.12.2.3.</strong
                ><span>
                  If a match is abandoned after the first offside decision then
                  all bets are valid. If a match is abandoned before the first
                  offside decision then all bets are void.
                </span>
              </h5>
              <h4><strong>2.2.12.3.</strong><span> Last Offside </span></h4>
              <h5>
                <strong>2.2.12.3.1.</strong
                ><span>
                  Last Offside means betting on which team will be caught
                  offside last in a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.12.3.2.</strong
                ><span> If a match is abandoned then all bets are void. </span>
              </h5>
              <h4>
                <strong>2.2.12.4.</strong><span> Lỗi việt vị tiếp theo </span>
              </h4>
              <h5>
                <strong>2.2.12.4.1.</strong
                ><span>
                  Lỗi việt vị tiếp theo là loại cược dự đoán đội nào sẽ bị bắt
                  việt vị tiếp theo.
                </span>
              </h5>
              <h5>
                <strong>2.2.12.4.2.</strong
                ><span>
                  Tất cả các khoản cược sẽ được xem là có giá trị nếu đội đặt
                  cược bị bắt lỗi việt vị tiếp theo đúng như dự đoán.
                </span>
              </h5>
              <h3><strong>2.2.13.</strong><span> Substitutions </span></h3>
              <h4>
                <strong>2.2.13.1.</strong><span> Number of Substitution </span>
              </h4>
              <h5>
                <strong>2.2.13.1.1.</strong><span> Handicap </span>
                <p class="h7">
                  <strong class="style3">2.2.13.1.1.1.</strong
                  ><span>
                    Handicap means betting on which team will make the most
                    subsubstitutions during a match, inclusive of any handicaps.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.13.1.1.2.</strong
                  ><span>
                    If a match is abandoned then all bets are void.
                  </span>
                </p>
              </h5>
              <h5>
                <strong>2.2.13.1.2.</strong><span> Over/Under </span>
                <p class="h7">
                  <strong class="style3">2.2.13.1.2.1.</strong
                  ><span>
                    Over/Under means betting on the total number of
                    substitutions during a match.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.13.1.2.2.</strong
                  ><span>
                    If the total is more than the OU line then the winning
                    result is Over; if the total is less than the OU line then
                    the winning result is Under.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.13.1.2.3.</strong
                  ><span>
                    Khoản cược sẽ không có giá trị nếu trận đấu bị bỏ dở trừ phi
                    việc dàn xếp khoản cược đã được quyết định.
                  </span>
                </p>
              </h5>
              <h4>
                <strong>2.2.13.2.</strong><span> First Substitution </span>
              </h4>
              <h5>
                <strong>2.2.13.2.1.</strong
                ><span>
                  First Substitution means betting on which team will make the
                  first substitution during a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.13.2.2.</strong
                ><span>
                  If two or more players are substituted at the same time then
                  the player whose number is shown first by the fourth official
                  is deemed the winner.
                </span>
              </h5>
              <h5>
                <strong>2.2.13.2.3.</strong
                ><span>
                  If a match is abandoned after the first substitution has been
                  made then all bets are valid. If a match is abandoned before
                  the first substitution has been made then all bets are void.
                </span>
              </h5>
              <h4>
                <strong>2.2.13.3.</strong><span> Last Substitution </span>
              </h4>
              <h5>
                <strong>2.2.13.3.1.</strong
                ><span>
                  Last Substitution means betting on which team will make the
                  last substitution during a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.13.3.2.</strong
                ><span>
                  If two or more players are substituted at the same time then
                  the player whose number is shown last by the fourth official
                  is deemed the winner.
                </span>
              </h5>
              <h5>
                <strong>2.2.13.3.3.</strong
                ><span> If a match is abandoned then all bets are void </span>
              </h5>
              <h3><strong>2.2.14.</strong><span> Clean Sheets </span></h3>
              <h4>
                <strong>2.2.14.1.</strong
                ><span>
                  Clean Sheets means betting "Yes" on a team to keep a clean
                  sheet (not to concede a goal) or "No" on a team not to keep a
                  clean sheet (to concede a goal).
                </span>
              </h4>
              <h4>
                <strong>2.2.14.2.</strong
                ><span>
                  Nếu bạn từ bỏ không tham gia cá cược đối với một sự kiện sau
                  khi mới chỉ có đội nhà ghi bàn thì khoản cược “Đội khách Có
                  &amp; Không” sẽ vẫn có hiệu lực trong khi khoản cược “Đội nhà
                  Có &amp; Không” sẽ không có giá trị. Nếu mới chỉ có đội khách
                  ghi bàn thì khoản cược “Đội nhà Có &amp; Không” sẽ vẫn có hiệu
                  lực trong khi khoản cược “Đội khách Có &amp; Không” sẽ không
                  có giá trị. Nếu cả hai đội khách và đội nhà đã ghi bàn thắng
                  thì tất cả mọi khoản cược sẽ đều có hiệu lực. Nếu bạn từ bỏ
                  không tham gia cá cược đối với một sự kiện khi chưa có bàn
                  thắng nào được ghi thì tất cả mọi khoản cược sẽ không có giá
                  trị.
                </span>
              </h4>
              <h5>
                <strong>Luật 1：</strong
                ><span>
                  <br />
                  Số bàn đội nhà ghi ≥ 1, Số bàn đội khách ghi = 0 (1, 2..:0)<br />
                  Đội nhà Có – Hoàn tiền Đội khách Có – Thua<br />
                  Đội nhà Không – Hoàn tiền Đội khách Không – Thắng<br />
                </span>
              </h5>
              <h5>
                <strong>Luật 2：</strong
                ><span>
                  <br />
                  Số bàn đội nhà ghi ≥ 1, Số bàn đội khách ghi ≥ 1 (1, 2…: 1,
                  2…)<br />
                  Đội nhà Có – Thua Đội khách Có – Thua<br />
                  Đội nhà Không – Thắng Đội khách Không - Thắng<br />
                </span>
              </h5>
              <h5>
                <strong>Luật 3：</strong
                ><span>
                  <br />
                  Số bàn đội nhà ghi = 0, Số bàn đội khách ghi ≥ 1 (0:1, 2…)
                  <br />
                  Đội nhà Có – Thua Đội khách Có – Hoàn tiền<br />
                  Đội nhà Không – Thắng Đội khách Không – Hoàn tiền<br />
                </span>
              </h5>
              <h3><strong>2.2.15.</strong><span> Penalties </span></h3>
              <h4>
                <strong>2.2.15.1.</strong
                ><span>
                  Penalties means betting on a penalty being awarded and taken
                  during a match.
                </span>
              </h4>
              <h4>
                <strong>2.2.15.2.</strong
                ><span>
                  If a match is abandoned after a penalty has been awarded and
                  taken then all bets are still valid.
                </span>
              </h4>
              <h4>
                <strong>2.2.15.3.</strong
                ><span>
                  If a match is abandoned before a penalty has been awarded and
                  taken then all bets are void.
                </span>
              </h4>
              <h3><strong>2.2.16.</strong><span> Penalty Shoot-outs </span></h3>
              <h4>
                <strong>2.2.16.1.</strong
                ><span>
                  Penalty Shoot-outs means betting on which team will win the
                  penalty shootout.
                </span>
              </h4>
              <h4>
                <strong>2.2.16.2.</strong
                ><span>
                  In handicap betting the result includes all penalties taken in
                  the shoot-out, including sudden death. In over/under betting
                  the result only includes the regulation ten penalties in the
                  shoot-out and does not include sudden death.
                </span>
              </h4>
              <h3><strong>2.2.17.</strong><span> Fantasy League </span></h3>
              <h4>
                <strong>2.2.17.1.</strong
                ><span>
                  Fantasy matches are pairings of teams from different matches.
                </span>
              </h4>
              <h4>
                <strong>2.2.17.2.</strong
                ><span>
                  All venues are strictly for reference purposes only.
                </span>
              </h4>
              <h3>
                <strong>2.2.18.</strong
                ><span> Specific 15-Minute Over/Under (OU) </span>
              </h3>
              <h4>
                <strong>2.2.18.1.</strong
                ><span>
                  The Specific 15-Minute (OU) means betting that is determined
                  by the total number of points (goals, corners, games, cards
                  etc.) at the end of every 15th minute [INTERVAL OF] time of a
                  match.
                </span>
              </h4>
              <h4>
                <strong>2.2.18.2.</strong
                ><span>
                  If the total is more than the Over/Under pre-designated line
                  then the winning result is Over; if the total is less than the
                  Over/Under pre-designated line then the winning result is
                  Under.
                </span>
              </h4>
              <h4><strong>2.2.18.3.</strong><span> For example: </span></h4>
              <h5 class="style1">
                <strong>15<sup>th</sup> Minute OU</strong><br />
                00:00 – 15:00 OU: Total number of points to be scored from 00:00
                till 15:00.<br />
                All bets must be placed on or before the end of the 15th minute.
              </h5>
              <h5 class="style1">
                <strong>30<sup>th</sup> Minute OU</strong><br />
                15:01 – 30:00 OU: Total number of points to be scored from 15:01
                till 30:00.<br />
                All bets must be placed on or before the end of the 30th minute.
              </h5>
              <h5 class="style1">
                <strong>45<sup>th</sup> Minute OU</strong><br />
                30:01- 45:00 OU: Total number of points to be scored from 30:01
                - 45:00.<br />
                All bets must be placed on or before the end of 45th minute.
              </h5>
              <h5 class="style1">
                <strong>60<sup>th</sup> Minute OU</strong><br />
                45:01 – 60:00 OU: Total number of points to be scored from 45:01
                till 60:00.<br />
                All bets must be placed on or before the end of the 60th minute.
              </h5>
              <h5 class="style1">
                <strong>75<sup>th</sup> Minute OU</strong><br />
                60:01 – 75:00 OU: Total number of points to be scored from 60:01
                till 75:00.<br />
                All bets must be placed on or before the end of the 75th minute.
              </h5>
              <h5 class="style1">
                <strong>90<sup>th</sup> Minute OU</strong><br />
                75:01- 90:00 OU: Total number of points to be scored from 75:01
                till 90:00.<br />
                All bets must be placed on or before the end of the 90th minute.
              </h5>
              <h4>
                <strong>2.2.18.4.</strong
                ><span>
                  For the Specific 15-Minute OU, bets are settled on the exact
                  time the goal is scored (ball crossing the goal line), number
                  of corners (corners taken) and Total Bookings (cards given by
                  the official referee) as shown by the clock as published in
                  the live broadcast.
                </span>
              </h4>
              <h4>
                <strong>2.2.18.5.</strong
                ><span>
                  If a match is suspended or abandoned, then bets placed on
                  unfinished Specific 15-Minute OU will be considered void. If
                  the designated Specific 15-Minute OU are completed then bets
                  will be valid.
                </span>
              </h4>
              <h4>
                <strong>2.2.18.6.</strong
                ><span>
                  Đối với hai (2) phút cuối cùng của bất kỳ việc đặt cược trực
                  tiếp thông qua Cược tài/xỉu đặc biệt 15 phút (Specific
                  15-Minutes O/U), bất kỳ hành động nào khác với những hành động
                  được đề cập ở đây sẽ được xem là Chơi an toàn (Safe Play) và
                  vì vậy, tất cả các khoản cược treo đã đặt có thể sẽ được cân
                  nhắc chấp nhận: bàn thắng, quả đá phạt đền và thẻ đỏ.
                </span>
              </h4>
              <h4>
                <strong>2.2.18.7.</strong
                ><span>
                  Đối với hai (2) phút cuối của đặt cược trực tiếp số lần phạt
                  góc 15 phút cụ thể bất kỳ nào, mọi hành động khác với các hành
                  động được đề cập dưới đây, sẽ được xem là Chơi an toàn và do
                  đó tất cả các khoản cược chưa xử lý đã đặt có thể được xem xét
                  chấp nhận: đá phạt trực tiếp nguy hiểm tại sân đối phương, cầu
                  thủ tấn công đang giữ bóng tại sân đối phương và phạt penalty.
                </span>
              </h4>
              <h4>
                <strong>2.2.18.8.</strong
                ><span>
                  Đối với hai (2) phút cuối của đặt cược Tổng số thẻ phạt của 15
                  phút cụ thể bất kỳ nào, mọi hành động ngoài những hành động
                  được đề cập dưới đây sẽ được xem là Chơi an toàn và do đó tất
                  cả các khoản cược chưa xử lý đã đặt có thể được xem xét chấp
                  nhận: đá phạt trực tiếp nguy hiểm tại sân đối phương, phạt
                  penalty, ghi bàn, phạt góc, cầu thủ ngã bị thương không giải
                  thích, cầu thủ cãi nhau và cầu thủ đánh nhau.
                </span>
              </h4>
              <h4>
                <strong>2.2.18.9.</strong
                ><span>
                  For 30:01-45:00 &amp; 75:01 - 90:00, bets are settled on the
                  exact time the goal is scored ( ball crossing the goal line) ,
                  number of corners (corners taken) and Total bookings (cards
                  given by the official referee) as shown by the clock as
                  published in the live broadcast excluding any additional time
                  or injury time.
                </span>
              </h4>
              <h3><strong>2.2.19.</strong><span> Free kick </span></h3>
              <h4>
                <strong>2.2.19.1.</strong
                ><span> An awarded free kick but untaken do not count. </span>
              </h4>
              <h4>
                <strong>2.2.19.2.</strong
                ><span>
                  Free kick refers to direct free kick and indirect free kick.
                  (Except Penalty kick &amp; Goal kick).
                </span>
              </h4>
              <h4>
                <strong>2.2.19.3.</strong><span> Number of Free kicks </span>
              </h4>
              <h5>
                <strong>2.2.19.3.1.</strong
                ><span> Cược chấp &amp; Cược chấp hiệp 1 </span>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.19.3.1.1.</strong
                  ><span>
                    Handicap means betting on which team will take the most free
                    kicks during a match inclusive of any handicaps.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.19.3.1.2.</strong
                  ><span>
                    Cược chấp hiệp 1 nghĩa là đặt cược đội nào sẽ có nhiều cú
                    sút phạt nhất trong hiệp 1 của trận đấu bao gồm bất kỳ cú
                    sút phạt được chấp nào.
                  </span>
                </p>
                <p class="h7">
                  <strong class="style3">2.2.19.3.1.3.</strong
                  ><span>
                    Khoản cược của Cược chấp hiệp 1 sẽ không có giá trị nếu trận
                    đấu bị bỏ dở trong suốt thời gian diễn ra hiệp 1 của trận
                    đấu. Tuy nhiên, khoản cược sẽ vẫn có giá trị nếu trận đấu bị
                    bỏ dở trong suốt thời gian diễn ra hiệp 2 của trận đấu.
                  </span>
                </p>
              </h5>
              <h5>
                <strong>2.2.19.3.2.</strong
                ><span> Trên/Dưới &amp; Trên/Dưới hiệp 1 </span>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.19.3.2.1.</strong
                  ><span>
                    Over/Under means betting on the total number of free kicks
                    taken by both teams during a match.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.19.3.2.2.</strong
                  ><span>
                    Trên/Dưới hiệp 1 là loại cược dựa trên tổng số lần sút phạt
                    do hai đội thực hiện trong suốt hiệp 1 của trận đấu.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.19.3.2.3.</strong
                  ><span>
                    If the total is more than the OU line then the winning
                    result is Over; if the total is less than the OU line then
                    the winning result is Under.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.19.3.2.4.</strong
                  ><span>
                    Khoản cược sẽ không có giá trị nếu trận đấu bị bỏ dở trừ phi
                    việc dàn xếp khoản cược đã được quyết định.
                  </span>
                </p>
              </h5>
              <h4>
                <strong>2.2.19.4.</strong
                ><span> First Free kick &amp; 2nd Half First Free kick </span>
              </h4>
              <h5>
                <strong>2.2.19.4.1.</strong
                ><span>
                  First Free kick means betting on which team will take the
                  first free kick in a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.19.4.2.</strong
                ><span>
                  2nd Half First Free kick means betting on which team will take
                  the first free kick in the 2nd half of a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.19.4.3.</strong
                ><span>
                  If a match is abandoned after the first free kick has been
                  taken then all bets are valid. If a match is abandoned before
                  the first free kick has been taken then all bets are void.
                </span>
              </h5>
              <h4><strong>2.2.19.5.</strong><span> Last Free Kick </span></h4>
              <h5>
                <strong>2.2.19.5.1.</strong
                ><span>
                  Last Free kick means betting on which team will take the last
                  free kick in a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.19.5.2.</strong
                ><span> If a match is abandoned then all bets are void. </span>
              </h5>
              <h4>
                <strong>2.2.19.6.</strong><span> Cú sút phạt tiếp theo </span>
              </h4>
              <h5>
                <strong>2.2.19.6.1.</strong
                ><span>
                  Cú sút phạt tiếp theo là loại cược dự đoán đội nào sẽ được
                  hưởng cú sút phạt tiếp theo.
                </span>
              </h5>
              <h5>
                <strong>2.2.19.6.2.</strong
                ><span>
                  Tất cả các khoản cược sẽ được xem là có giá trị nếu đội dự
                  đoán được hưởng cú sút phạt tiếp theo đúng như dự đoán.
                </span>
              </h5>
              <h3><strong>2.2.20.</strong><span> Goal kick </span></h3>
              <h4>
                <strong>2.2.20.1.</strong
                ><span>
                  A goal kick is awarded to the defending team if the ball
                  completely crosses the end line, as a result of contact with
                  an opposing player.
                </span>
              </h4>
              <h4>
                <strong>2.2.20.2.</strong
                ><span>
                  Kick by a goalkeeper after making a save does not count.
                </span>
              </h4>
              <h4>
                <strong>2.2.20.3.</strong><span> Number of Goal kicks </span>
              </h4>
              <h5>
                <strong>2.2.20.3.1.</strong
                ><span> Cược chấp &amp; Cược chấp hiệp 1 </span>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.20.3.1.1.</strong
                  ><span>
                    Handicap means betting on which team will take the most goal
                    kicks during a match inclusive of any handicaps.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.20.3.1.2.</strong
                  ><span>
                    Hiệp 1 nghĩa là cược đội nào sẽ có nhiều cú phát bóng từ cầu
                    môn nhất trong hiệp 1 của trận đấu bao gồm bất kỳ cú phát
                    bóng từ cầu môn được chấp nào.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.20.3.1.3.</strong
                  ><span>
                    Khoản cược của Cược chấp hiệp 1 sẽ không có giá trị nếu trận
                    đấu bị bỏ dở trong thời gian diễn ra hiệp 1 của trận đấu.
                    Tuy nhiên, khoản cược sẽ vẫn có giá trị nếu trận đấu bị bỏ
                    dở trong suốt thời gian diễn ra hiệp 2 của trận đấu.
                  </span>
                </p>
              </h5>
              <h5>
                <strong>2.2.20.3.2.</strong
                ><span> Trên/Dưới &amp; Trên/Dưới hiệp 1 </span>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.20.3.2.1.</strong
                  ><span>
                    Over/Under means betting on the total number of goal kicks
                    taken by both teams during a match.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.20.3.2.2.</strong
                  ><span>
                    Trên/Dưới hiệp 1 là loại cược dựa trên tổng số cú phát bóng
                    từ cầu môn do cả hai đội thực hiện trong suốt hiệp 1 của
                    trận đấu.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.20.3.2.3.</strong
                  ><span>
                    If the total is more than the OU line then the winning
                    result is Over; if the total is less than the OU line then
                    the winning result is Under.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.20.3.2.4.</strong
                  ><span>
                    Khoản cược sẽ không có giá trị nếu trận đấu bị bỏ dở trừ phi
                    việc dàn xếp khoản cược đã được quyết định.
                  </span>
                </p>
              </h5>
              <h4>
                <strong>2.2.20.4.</strong
                ><span> First Goal kick &amp; 2nd Half First Goal kick </span>
              </h4>
              <h5>
                <strong>2.2.20.4.1.</strong
                ><span>
                  First Goal kick means betting on which team will take the
                  first goal kick in a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.20.4.2.</strong
                ><span>
                  2nd Half First Goal kick means betting on which team will take
                  the first goal kick in the 2nd half of a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.20.4.3.</strong
                ><span>
                  If a match is abandoned after the first goal kick has been
                  taken then all bets are valid. If a match is abandoned before
                  the first goal kick has been taken then all bets are void.
                </span>
              </h5>
              <h4><strong>2.2.20.5.</strong><span> Last Goal Kick </span></h4>
              <h5>
                <strong>2.2.20.5.1.</strong
                ><span>
                  Last Goal kick means betting on which team will take the last
                  goal kick in a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.20.5.2.</strong
                ><span> If a match is abandoned then all bets are void. </span>
              </h5>
              <h4>
                <strong>2.2.20.6.</strong
                ><span> Cú phát bóng từ cầu môn tiếp theo </span>
              </h4>
              <h5>
                <strong>2.2.20.6.1.</strong
                ><span>
                  Cú phát bóng từ cầu môn tiếp theo nghĩa là đặt cược đội nào sẽ
                  có cú phát bóng từ cầu môn tiếp theo trong trận đấu.
                </span>
              </h5>
              <h5>
                <strong>2.2.20.6.2.</strong
                ><span>
                  Tất cả các khoản cược đều được xem là có giá trị nếu đội được
                  đặt cược có cú phát bóng từ cầu môn tiếp theo đúng như dự
                  đoán.
                </span>
              </h5>
              <h3><strong>2.2.21.</strong><span> Throw-in </span></h3>
              <h4>
                <strong>2.2.21.1.</strong
                ><span>
                  A throw-in is awarded to a team if the ball completely crosses
                  the side touch line, as a result of contact with an opposing
                  player.
                </span>
              </h4>
              <h4>
                <strong>2.2.21.2.</strong><span> Number of Throw-in </span>
              </h4>
              <h5>
                <strong>2.2.21.2.1.</strong
                ><span> Cược chấp &amp; Cược chấp hiệp 1 </span>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.21.2.1.1.</strong
                  ><span>
                    Handicap means betting on which team will take the most
                    throw-in during a match inclusive of any handicaps.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.21.2.1.2.</strong
                  ><span>
                    Cược chấp hiệp 1 nghĩa là đặt cược đội nào sẽ có nhiều cú
                    ném biên nhất trong hiệp 1 của trận đấu bao gồm bất kỳ cú
                    ném biên được chấp nào.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.21.2.1.3.</strong
                  ><span>
                    Khoản cược của Cược chấp hiệp 1 sẽ không có hiệu lực nếu
                    trận đấu bị bỏ dở trong suốt thời gian diễn ra hiệp 1 của
                    trận đấu. Tuy nhiên, khoản cược sẽ vẫn có giá trị nếu trận
                    đấu bị bỏ dở trong suốt thời gian diễn ra hiệp 2 của trận
                    đấu.
                  </span>
                </p>
              </h5>
              <h5>
                <strong>2.2.21.2.2.</strong
                ><span> Trên/Dưới &amp; Trên/Dưới hiệp 1 </span>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.21.2.2.1.</strong
                  ><span>
                    Over/Under means betting on the total number of throw-in
                    taken by both teams during a match.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.21.2.2.2.</strong
                  ><span>
                    Trên/Dưới hiệp 1 nghĩa là đặt cược dựa trên tổng số cú ném
                    biên do cả hai đội thực hiện trong suốt hiệp 1 của trận đấu.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.21.2.2.3.</strong
                  ><span>
                    If the total is more than the OU line then the winning
                    result is Over; if the total is less than the OU line then
                    the winning result is Under.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">2.2.21.2.2.4.</strong
                  ><span>
                    Khoản cược sẽ không có giá trị nếu trận đấu bị bỏ dở trừ phi
                    việc dàn xếp khoản cược đã được quyết định.
                  </span>
                </p>
              </h5>
              <h4>
                <strong>2.2.21.3.</strong
                ><span> First Throw-in &amp; 2nd Half First Throw-in </span>
              </h4>
              <h5>
                <strong>2.2.21.3.1.</strong
                ><span>
                  First throw-in means betting on which team will take the first
                  throw-in in a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.21.3.2.</strong
                ><span>
                  2nd Half First throw-in means betting on which team will take
                  the first throw-in in the 2nd half of a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.21.3.3.</strong
                ><span>
                  If a match is abandoned after the first throw-in has been
                  taken then all bets are valid. If a match is abandoned before
                  the first throw-in has been taken then all bets are void.
                </span>
              </h5>
              <h4><strong>2.2.21.4.</strong><span> Last Throw-in </span></h4>
              <h5>
                <strong>2.2.21.4.1.</strong
                ><span>
                  Last throw-in means betting on which team will take the last
                  throw-in in a match.
                </span>
              </h5>
              <h5>
                <strong>2.2.21.4.2.</strong
                ><span> If a match is abandoned then all bets are void. </span>
              </h5>
              <h4>
                <strong>2.2.21.5.</strong><span> Cú ném biên tiếp theo </span>
              </h4>
              <h5>
                <strong>2.2.21.5.1.</strong
                ><span>
                  Cú ném biên tiếp theo nghĩa là đặt cược đội nào sẽ có cú ném
                  biên tiếp theo trong trận đấu.
                </span>
              </h5>
              <h5>
                <strong>2.2.21.5.2.</strong
                ><span>
                  Tất cả các khoản cược sẽ được xem là có giá trị nếu đội được
                  đặt cược có cú ném biên tiếp theo đúng như dự đoán.
                </span>
              </h5>
              <h3>
                <strong>2.2.22.</strong
                ><span> 10 Minutes Over/Under (O/U) </span>
              </h3>
              <h4>
                <strong>2.2.22.1.</strong
                ><span>
                  The Specific 10-Minute (OU) means betting that is determined
                  by the total number of points (goals, corners, games, cards
                  etc.) at the end of every 10th minute [INTERVAL OF] time of a
                  match.
                </span>
              </h4>
              <h4>
                <strong>2.2.22.2.</strong
                ><span>
                  If the total is more than the Over/Under pre-designated line
                  then the winning result is Over; if the total is less than the
                  Over/Under pre-designated line then the winning result is
                  Under.
                </span>
              </h4>
              <h4><strong>2.2.22.3.</strong><span> For example: </span></h4>
              <h5 class="style1">
                <strong>10<sup>th</sup> Minute OU</strong><br />
                00:00 – 10:00 O/U: Total number of points to be scored from
                00:00 till 10:00.<br />
                All bets must be placed on or before the end of the 10th minute.
              </h5>
              <h5 class="style1">
                <strong>20<sup>th</sup> Minute OU</strong><br />
                10:01 – 20:00 O/U: Total number of points to be scored from
                10:01 till 20:00.<br />
                All bets must be placed on or before the end of the 20th minute.
              </h5>
              <h5 class="style1">
                <strong>30<sup>th</sup> Minute OU</strong><br />
                20:01 – 30:00 O/U: Total number of points to be scored from
                20:01 till 30:00.<br />
                All bets must be placed on or before the end of the 30th minute.
              </h5>
              <h5 class="style1">
                <strong>40<sup>th</sup> Minute OU</strong><br />
                30:01 – 40:00 O/U: Total number of points to be scored from
                30:01 till 40:00.<br />
                All bets must be placed on or before the end of the 40th minute.
              </h5>
              <h5 class="style1">
                <strong>60<sup>th</sup> Minute OU</strong><br />
                50:01 – 60:00 O/U: Total number of points to be scored from
                50:01 till 60:00.<br />
                All bets must be placed on or before the end of the 60th minute.
              </h5>
              <h5 class="style1">
                <strong>70<sup>th</sup> Minute OU</strong><br />
                60:01 – 70:00 O/U: Total number of points to be scored from
                60:01 till 70:00.<br />
                All bets must be placed on or before the end of the 70th minute.
              </h5>
              <h5 class="style1">
                <strong>80<sup>th</sup> Minute OU</strong><br />
                70:01 – 80:00 O/U: Total number of points to be scored from
                70:01 till 80:00.<br />
                All bets must be placed on or before the end of the 80th minute.
              </h5>
              <h5 class="style1">
                <strong>90<sup>th</sup> Minute OU</strong><br />
                80:01 - 90:00 O/U: Total number of points to be scored from
                80:01 till 90:00.<br />
                All bets must be placed on or before the end of the 90th minute.
              </h5>
              <h4>
                <strong>2.2.22.4.</strong
                ><span>
                  For the Specific 10-Minutes O/U, bets are settled on the exact
                  time the goal is scored (ball crossing the goal line) , number
                  of corners (corners taken) and Total Bookings (cards given by
                  the official referee) as shown by the clock as published in
                  the live broadcast.
                </span>
              </h4>
              <h4>
                <strong>2.2.22.5.</strong
                ><span>
                  If a match is suspended or abandoned, then bets placed on
                  unfinished Specific 10-Minute OU will be considered void. If
                  the designated Specific 10-Minute O/U are completed then bets
                  will be valid.
                </span>
              </h4>
              <h4>
                <strong>2.2.22.6.</strong
                ><span>
                  Đối với hai (2) phút cuối cùng của bất kỳ việc đặt cược trực
                  tiếp thông qua Cược tài/xỉu đặc biệt 10 phút (Specific
                  10-Minutes O/U), bất kỳ hành động nào khác với những hành động
                  được đề cập ở đây sẽ được xem là Chơi an toàn (Safe Play) và
                  vì vậy, tất cả các khoản cược treo đã đặt có thể sẽ được cân
                  nhắc chấp nhận: bàn thắng, quả đá phạt đền và thẻ đỏ.
                </span>
              </h4>
              <h4>
                <strong>2.2.22.7.</strong
                ><span>
                  For 80:01-90:00, bets are settled on the exact time the goal
                  is scored ( ball crossing the goal line) , number of corners
                  (corners taken) and Total bookings (cards given by the
                  official referee) as shown by the clock as published in the
                  live broadcast excluding any additional time or injury time.
                </span>
              </h4>
              <h3><strong>2.2.23.</strong><span> Futsal Rules </span></h3>
              <h4>
                <strong>2.2.23.1.</strong
                ><span>
                  All bets are based on the result at the end of the scheduled
                  <strong>40 minutes (2 x 20 minutes)</strong> regulation time.
                  Extra-time or penalties <strong>DOES NOT</strong> count.
                </span>
              </h4>
              <h4>
                <strong>2.2.23.2.</strong
                ><span>
                  If a match is postponed, or is suspended and is not resumed on
                  the same day, then all bets will be considered void.
                </span>
              </h4>
              <h4>
                <strong>2.2.23.3.</strong
                ><span>
                  If a match begins before the scheduled time then only bets
                  made before the actual kick-off will be considered valid. All
                  bets placed after the starting time will be considered void,
                  except for Live Betting.
                </span>
              </h4>
              <h3><strong>2.2.24.</strong><span> Top Goal Scorer </span></h3>
              <h4>
                <strong>2.2.24.1.</strong
                ><span> Cầu thủ ghi bàn nhiều nhất liên đoàn </span>
              </h4>
              <h5>
                <strong>2.2.24.1.1.</strong
                ><span>
                  Cầu thủ ghi bàn nhiều nhất liên đoàn nghĩa là cá cược để đoán
                  cầu thủ nào sẽ ghi nhiều bàn thắng nhất trong một mùa giải
                  định kỳ của một liên đoàn.
                </span>
              </h5>
              <h5>
                <strong>2.2.24.1.2.</strong
                ><span>
                  Trong trường hợp một cầu thủ gia nhập một câu lạc bộ khác
                  trong cùng liên đoàn trong thời gian giữa mùa giải, tất cả các
                  bàn thắng ghi được trong khi cầu thủ đó chơi ở các câu lạc bộ
                  khác sẽ được tính trong tổng số bàn thắng được ghi. Nhưng
                  những bàn thắng do một cầu thủ ghi trong một trận đấu bên
                  ngoài liên đoàn sẽ không được tính.
                </span>
              </h5>
              <h5>
                <strong>2.2.24.1.3.</strong
                ><span>
                  Các bàn thắng được ghi trong các hiệp phụ để phân định thắng
                  thua và đá phạt đền sẽ không được tính.
                </span>
              </h5>
              <h5>
                <strong>2.2.24.1.4.</strong
                ><span>
                  Bàn ghi vào lưới nhà sẽ bị loại khi tính tổng bàn thắng do một
                  cầu thủ ghi được.
                </span>
              </h5>
              <h5>
                <strong>2.2.24.1.5.</strong
                ><span>
                  Cược vào các cầu thủ không thi đấu sẽ vẫn có hiệu lực. Một cầu
                  thủ không thi đấu là bất kỳ cầu thủ nào không có tên trong đội
                  hình cuối cùng cho mùa giải hiện tại của một liên đoàn.
                </span>
              </h5>
              <h5>
                <strong>2.2.24.1.6.</strong
                ><span>
                  Nếu một cầu thủ rút tên hoặc được chuyển nhượng sang một câu
                  lạc bộ thuộc một liên đoàn khác trước khi bắt đầu mùa giải,
                  cược vào cầu thủ rút tên hoặc được chuyển nhượng sang câu lạc
                  bộ khác sẽ được hoàn trả.
                </span>
              </h5>
              <h5>
                <strong>2.2.24.1.7.</strong
                ><span>
                  Trong trường hợp có nhiều cầu thủ đồng hạng về số bàn thắng
                  ghi được, tỷ lệ thanh toán (trừ đi tiền đặt cược) được chia
                  cho số người thắng cược theo tỷ lệ phù hợp với cược thắng.
                </span>
              </h5>
              <h4>
                <strong>2.2.24.2.</strong
                ><span> Cầu thủ ghi bàn nhiều nhất trận đấu hoặc sự kiện </span>
              </h4>
              <h5>
                <strong>2.2.24.2.1.</strong
                ><span>
                  Cầu thủ ghi bàn nhiều nhất trận đấu hoặc sự kiện nghĩa là cá
                  cược để đoán cầu thủ nào sẽ ghi nhiều bàn thắng nhất trong một
                  trận đấu hoặc một sự kiện.
                </span>
              </h5>
              <h5>
                <strong>2.2.24.2.2.</strong
                ><span>
                  Bàn ghi vào lưới nhà và bàn đá phạt đền sẽ bị loại khi tính
                  tổng bàn thắng do một cầu thủ ghi được. Tuy nhiên, bàn thắng
                  ghi được trong lúc cộng thêm giờ sẽ được tính.
                </span>
              </h5>
              <h5>
                <strong>2.2.24.2.3.</strong
                ><span>
                  Cược vào các cầu thủ không thi đấu sẽ vẫn có hiệu lực. Cầu thủ
                  không thi đấu là bất kỳ cầu thủ nào không có tên trong đội
                  hình cuối cùng trong một trận đấu hoặc sự kiện.
                </span>
              </h5>
              <h5>
                <strong>2.2.24.2.4.</strong
                ><span>
                  Nếu một cầu thủ rút khỏi hoặc được chuyển nhượng sang một câu
                  lạc bộ khác trong một trận đấu hoặc sự kiện khác trước khi bắt
                  đầu trận đấu hoặc sự kiện này, tiền cược vào cầu thủ rút tên
                  hoặc được chuyển nhượng sẽ được hoàn trả.
                </span>
              </h5>
              <h5>
                <strong>2.2.24.2.5.</strong
                ><span>
                  Trong trường hợp có nhiều cầu thủ đồng hạng về số bàn thắng
                  ghi được, tỷ lệ thanh toán (trừ đi tiền đặt cược) được chia
                  cho số người thắng cược theo tỷ lệ phù hợp với cược thắng.
                </span>
              </h5>
              <h4>
                <strong>2.2.24.3.</strong
                ><span> Players Head to Head Match Top Goal Scorer </span>
              </h4>
              <h5>
                <strong>2.2.24.3.1.</strong
                ><span>
                  Own goals and goals scored in penalty-shootouts do not count.
                  Extra-time are excluded and do not count for betting purposes.
                </span>
              </h5>
              <h5>
                <strong>2.2.24.3.2.</strong
                ><span>
                  If a player does not start/play in the match, all bets will be
                  considered Void.
                </span>
              </h5>
              <h5>
                <strong>2.2.24.3.3.</strong
                ><span>
                  All results are taken when the official result is declared at
                  the end of the match by the governing body.
                </span>
              </h5>
              <h3><strong>2.2.25.</strong><span> Injury Time </span></h3>
              <h4>
                <strong>2.2.25.1.</strong
                ><span>
                  Injury time means the extra playing time added on to
                  compensate for time spent attending to injured players during
                  the match. The injury time may be awarded during the end of
                  the 1st half or the end of the 2nd half, and the duration may
                  be as follows:
                </span>
              </h4>
              <h5 class="style1"><strong>None</strong><br /></h5>
              <h5 class="style1"><strong>1 minute</strong><br /></h5>
              <h5 class="style1"><strong>2 minutes</strong><br /></h5>
              <h5 class="style1"><strong>3 minutes</strong><br /></h5>
              <h5 class="style1"><strong>4+ minutes</strong><br /></h5>
              <h4>
                <strong>2.2.25.2.</strong
                ><span> Injury Time Awarded at the End of 1st Half </span>
              </h4>
              <h5>
                <strong>2.2.25.2.1.</strong
                ><span>
                  All bets placed are based on the full 45-minute play excluding
                  extra time. Bets are settled on the Injury time awarded by the
                  match fourth official referee after the full 45 minutes of
                  play or at end of 1st half.
                </span>
              </h5>
              <h5>
                <strong>2.2.25.2.2.</strong
                ><span>
                  If a match is abandoned at any time during the 1st Half, all
                  bets on Injury Time Awarded at the end of 1st Half will be
                  considered void and bets will be refunded to the member’s
                  accounts.
                </span>
              </h5>
              <h4>
                <strong>2.2.25.3.</strong
                ><span> Injury Time awarded at the End of the 2nd Half </span>
              </h4>
              <h5>
                <strong>2.2.25.3.1.</strong
                ><span>
                  All bets placed are based on the full 90-minute play excluding
                  extra time. Bets are settled on the Injury time awarded by the
                  match fourth official referee after the full 90 minutes of
                  play or at end of 2nd half.
                </span>
              </h5>
              <h5>
                <strong>2.2.25.3.2.</strong
                ><span>
                  If a match is abandoned at any time, all bets on Injury Time
                  Awarded at the end of 2nd Half will be considered void and
                  bets will be refunded to the member’s accounts.
                </span>
              </h5>
              <h4>
                <strong>2.2.25.4.</strong
                ><span>
                  The Company will settle bets according to the official results
                  made available by the soccer authority responsible for
                  organizing the match.
                </span>
              </h4>
              <h3>
                <strong>2.2.26.</strong
                ><span> Cơ hội kép &amp; Cơ hội kép hiệp 1 </span>
              </h3>
              <h4>
                <strong>2.2.26.1.</strong
                ><span> The following options are available: </span>
              </h4>
              <h4>
                <strong>*</strong
                ><span>
                  1 or X - If the result is either a home or draw then bets on
                  this option are winners.
                </span>
              </h4>
              <h4>
                <strong>*</strong
                ><span>
                  X or 2 - If the result is either a draw or away then bets on
                  this option are winners.
                </span>
              </h4>
              <h4>
                <strong>*</strong
                ><span>
                  1 or 2 - If the result is either a home or away then bets on
                  this option are winners.
                </span>
              </h4>
              <h4>
                <strong>*</strong
                ><span>
                  If a match is played at a neutral venue the team listed first
                  is deemed the home team for betting purposes.
                </span>
              </h4>
              <h3><strong>2.2.27.</strong><span> Draw no bet </span></h3>
              <h4>
                <strong>2.2.27.1.</strong
                ><span>
                  Dự đoán Thắng ngược dòng hoặc Thắng từ đầu trong trận đấu. Nếu
                  kết quả chung cuộc sau thời gian chơi thông thường hoặc khi
                  hết thời gian đã lên lịch là Hòa, toàn bộ tiền cược sẽ được
                  hoàn lại.
                </span>
              </h4>
              <h3>
                <strong>2.2.28.</strong
                ><span> Both/One /Neither to score </span>
              </h3>
              <h4>
                <strong>*</strong><span> Both= Both teams to score. </span>
              </h4>
              <h4>
                <strong>*</strong><span> One= Either team to score. </span>
              </h4>
              <h4>
                <strong>*</strong
                ><span> Neither= Both team not to score. </span>
              </h4>
              <h4>
                <strong>2.2.28.1.</strong
                ><span>
                  Nếu một Trận đấu bị bỏ dỡ sau khi chỉ Đội nhà ghi bàn, thì
                  cược ‘Không có đội nào’ sẽ vẫn có hiệu lực trong khi cược ‘Cả
                  hai’ và ‘Một’ sẽ vô hiệu. Nếu một Trận đấu bị bỏ dỡ sau khi
                  chỉ Đội khách ghi bàn, cược ‘Không có đội nào’ sẽ vẫn có hiệu
                  lực trong khi cược ‘Cả hai’ và ‘Một’ sẽ vô hiệu. Nếu một Trận
                  đấu bị bỏ dỡ sau khi cả Đội nhà và Đội khách ghi bàn, tất cả
                  các cược đều có hiệu lực. Nếu một Trận đấu bị bỏ dỡ mà không
                  có bàn thắng nào được ghi, tất cả các khoản cược đều vô hiệu.
                </span>
              </h4>
              <h5>
                <strong>Luật 1：</strong
                ><span>
                  <br />
                  Số bàn đội nhà ghi ≥ 1, Số bàn đội khách ghi = 0 (1, 2..:0)
                  <br />
                  Cả hai – Hoàn tiền<br />
                  Một – Hoàn tiền<br />
                  Cả hai đều không - Thua <br />
                </span>
              </h5>
              <h5>
                <strong>Luật 2：</strong
                ><span>
                  <br />
                  Số bàn đội nhà ghi ≥ 1, Số bàn đội khách ghi ≥ 1 (1, 2: 1,
                  2…)<br />
                  Cả hai – Thắng<br />
                  Một – Thua<br />
                  Cả hai đều không - Thua <br />
                </span>
              </h5>
              <h5>
                <strong>Luật 3: </strong
                ><span>
                  <br />
                  Số bàn đội nhà ghi = 0, Số bàn đội khách ghi ≥ 1 (0:1, 2…)<br />
                  Cả hai – Hoàn tiền<br />
                  Một – Hoàn tiền<br />
                  Cả hai đều không – Thua<br />
                </span>
              </h5>
              <h3><strong>2.2.29.</strong><span> TO WIN TO NIL </span></h3>
              <h4>
                <strong>2.2.29.1.</strong
                ><span>
                  Predict whether your selection can win the match without
                  conceding a goal after regular time of play or at the end of
                  that scheduled, excluding extra time or penalty shoot-outs.
                </span>
              </h4>
              <h4>
                <strong>2.2.29.2.</strong
                ><span>
                  Nếu bạn từ bỏ không tham gia cá cược đối với một sự kiện sau
                  khi mới chỉ có đội nhà ghi bàn thì khoản cược “Đội khách” sẽ
                  vẫn có hiệu lực trong khi khoản cược “Đội nhà” sẽ không có giá
                  trị. Nếu mới chỉ có đội khách ghi bàn thì khoản cược “Đội nhà”
                  sẽ vẫn có hiệu lực trong khi khoản cược “Đội khách” sẽ không
                  có giá trị. Nếu cả hai đội khách và đội nhà đã ghi bàn thắng
                  thì tất cả mọi khoản cược sẽ đều có hiệu lực. Nếu bạn từ bỏ
                  không tham gia cá cược đối với một sự kiện khi chưa có bàn
                  thắng nào được ghi thì tất cả mọi khoản cược sẽ không có giá
                  trị.
                </span>
              </h4>
              <h5>
                <strong>Luật 1：</strong
                ><span>
                  <br />
                  Số bàn đội nhà ghi ≥ 1, Số bàn đội khách ghi = 0 (1, 2..:0)<br />
                  Đội nhà – Hoàn tiền Đội khách – Thua <br />
                </span>
              </h5>
              <h5>
                <strong>Luật 2：</strong
                ><span>
                  <br />
                  Số bàn đội nhà ghi ≥ 1, Số bàn đội khách ghi ≥ 1 (1, 2…: 1,
                  2…)<br />
                  Đội nhà – Thua Đội khách – Thua <br />
                </span>
              </h5>
              <h5>
                <strong>Luật 3：</strong
                ><span>
                  <br />
                  Số bàn đội nhà ghi = 0, Số bàn đội khách ghi ≥ 1 (0:1, 2…)<br />
                  Đội nhà – Thua Đội khách – Hoàn tiền<br />
                </span>
              </h5>
              <h3><strong>2.2.30.</strong><span> 3 way handicap </span></h3>
              <h4>
                <strong>2.2.30.1.</strong
                ><span>
                  Settlement will be at the odds displayed using the actual
                  score in the match adjusted for the handicap.
                </span>
              </h4>
              <h3>
                <strong>2.2.31.</strong
                ><span> Đội thi đấu chỉ thắng một trong hai hiệp </span>
              </h3>
              <h4>
                <strong>2.2.31.1.</strong
                ><span>
                  Predict whether your selection can score more goals than their
                  opponent in one of two halves.
                </span>
              </h4>
              <h4>
                <strong>2.2.31.2.</strong
                ><span> Đội nhà thắng một trong hai hiệp</span>
              </h4>
              <h4>
                <strong>2.2.31.2.1.</strong
                ><span>
                  Đội nhà thắng một trong hai hiệp nghĩa là đặt cược dự đoán
                  liệu đội nhà có thể ghi nhiều bàn thắng hơn đội khách vào một
                  trong hai hiệp của trận đấu hay không.
                </span>
              </h4>
              <h4>
                <strong>2.2.31.2.2.</strong
                ><span>
                  Khoản cược sẽ không có giá trị nếu trận đấu bị huỷ bỏ ngoại
                  trừ trường hợp khoản cược đã được định đoạt trước.
                </span>
              </h4>
              <h4>
                <strong>2.2.31.3.</strong
                ><span> Đội khách thắng một trong hai hiệp</span>
              </h4>
              <h4>
                <strong>2.2.31.3.1.</strong
                ><span>
                  Đội khách thắng một trong hai hiệp nghĩa là đặt cược dự đoán
                  liệu đội khách có thể ghi nhiều bàn thắng hơn đội nhà vào một
                  trong hai hiệp của trận đấu hay không.
                </span>
              </h4>
              <h4>
                <strong>2.2.31.3.2.</strong
                ><span>
                  Khoản cược sẽ không có giá trị nếu trận đấu bị huỷ bỏ ngoại
                  trừ trường hợp khoản cược đã được định đoạt trước.
                </span>
              </h4>
              <h3>
                <strong>2.2.32.</strong
                ><span> Đội thi đấu thắng trong cả hai hiệp </span>
              </h3>
              <h4>
                <strong>2.2.32.1.</strong
                ><span>
                  Predict whether your selection can score more goals than their
                  opponent in each half.
                </span>
              </h4>
              <h5>
                <strong>For example :</strong
                ><span>
                  If your selection scores in the first half of a match and the
                  match finishes 1-0, although the first half was won 1-0 , the
                  score in the second 45 minutes was effectively 0-0 and
                  therefore a draw. If this occurs, only the first half is
                  considered to have been 'won' and therefore bets would be
                  losers.
                </span>
              </h5>
              <h4>
                <strong>2.2.32.2.</strong
                ><span> Đội nhà thắng trong cả hai hiệp </span>
              </h4>
              <h4>
                <strong>2.2.32.2.1.</strong
                ><span>
                  Đội nhà thắng trong cả hai hiệp nghĩa là đặt cược dự đoán liệu
                  đội nhà có thể ghi nhiều bàn thắng hơn đội khách trong mỗi
                  hiệp hay không.
                </span>
              </h4>
              <h4>
                <strong>2.2.32.2.2.</strong
                ><span>
                  Khoản cược sẽ không có giá trị nếu trận đấu bị huỷ bỏ ngoại
                  trừ trường hợp khoản cược đã được định đoạt trước.
                </span>
              </h4>
              <h4>
                <strong>2.2.32.3.</strong
                ><span> Đội khách thắng trong cả hai hiệp</span>
              </h4>
              <h4>
                <strong>2.2.32.3.1.</strong
                ><span>
                  Đội khách thắng trong cả hai hiệp nghĩa là đặt cược dự đoán
                  liệu đội khách có thể ghi nhiều bàn thắng hơn đội nhà trong
                  mỗi hiệp hay không.</span
                >
              </h4>
              <h4>
                <strong>2.2.32.3.2.</strong
                ><span>
                  Khoản cược sẽ không có giá trị nếu trận đấu bị huỷ bỏ ngoại
                  trừ trường hợp khoản cược đã được định đoạt trước.
                </span>
              </h4>
              <h3>
                <strong>2.2.33.</strong
                ><span> Đội ghi nhiều bàn thắng nhất </span>
              </h3>
              <h4>
                <strong>2.2.33.1.</strong
                ><span>Đội có số bàn thắng nhiều nhất là đội thắng</span>
              </h4>
              <h4>
                <strong>2.2.33.2.</strong
                ><span>Bàn thắng cách biệt sẽ không được tính.</span>
              </h4>
              <h4>
                <strong>2.2.33.3.</strong
                ><span
                  >Nếu các đội có số bàn thắng bằng nhau thì luật đồng hạng sẽ
                  được áp dụng.</span
                >
              </h4>
              <h5>
                <strong>Ví dụ:</strong><br />
                <span>Man city 4 Swansea 4</span><br />
                <span>Liverpool 4 Sunderland 1</span><br />
                <span
                  >Vậy thì Liverpool, Man city và Swansea là những đội
                  thắng.</span
                >
              </h5>
              <h4>
                <strong>2.2.33.4.</strong><span>Hủy bỏ và hoãn thi đấu</span>
              </h4>
              <h5>
                <strong>2.2.33.4.1.</strong
                ><span
                  >Khoản cược đặt cho đội có trận thi đấu bị hủy bỏ hoặc bị hoãn
                  (nhưng không được xếp lịch thi đấu trở lại trong khoảng thời
                  gian do công ty đã tuyên bố) sẽ bị xem là không có giá trị.
                </span>
              </h5>
              <h4>
                <strong>2.2.33.5.</strong
                ><span
                  >Bàn thắng được ghi trong loạt đá phạt luân lưu sẽ không được
                  tính.
                </span>
              </h4>
              <h4>
                <strong>2.2.33.6.</strong
                ><span
                  >Đối với thị trường liên quan đến giải đấu, bàn thắng được ghi
                  trong hiệp phụ sẽ được tính.
                </span>
              </h4>
              <h4>
                <strong>2.2.33.7.</strong
                ><span
                  >Đối với thị trường liên quan đến loạt trận đấu diễn ra vào
                  ngày đã định cụ thể, bàn thắng được ghi trong hiệp phụ sẽ
                  không được tính.
                </span>
              </h4>
              <h3>
                <strong>2.2.34.</strong><span> Exact First Half Goals </span>
              </h3>
              <h4>
                <strong>2.2.34.1.</strong
                ><span>
                  Exact First Half Goal means betting to predict the total
                  number of goals scored by both teams by the end of the first
                  half.
                </span>
              </h4>
              <h4>
                <strong>2.2.34.2.</strong
                ><span>
                  If an event is abandoned before the end of the first half, all
                  bets are considered void.
                </span>
              </h4>
              <h3>
                <strong>2.2.35.</strong><span> Exact Group Points: </span>
              </h3>
              <h4>
                <strong>2.2.35.1.</strong
                ><span>
                  Exact Group Points means betting on a team to get a specific
                  number of total points at the end of the Group round.
                </span>
              </h4>
              <h3>
                <strong>2.2.36.</strong><span> Highest Scoring Group </span>
              </h3>
              <h4>
                <strong>2.2.36.1.</strong
                ><span>
                  Means betting to predict the total number of goals scored by
                  the group in the date specified.
                </span>
              </h4>
              <h4>
                <strong>2.2.36.2.</strong
                ><span>
                  If groups are level on scores, then Dead Heat rules apply.
                </span>
              </h4>
              <h5>
                <strong>Example :</strong><br />
                Group A<br />
                <span>Poland -vs- Greece</span><span>2 - 1 = 3 Goals</span
                ><br />
                <span>Russia -vs- Czech</span><span>3 - 2 = 5 Goals</span><br />
                Group A Total: 8Goals<br />
                Group B Total: 7Goals<br />
                Group C Total: 7Goals<br />
                Group D Total: 6Goals<br />
                <strong>Group A </strong> is the winner.<br />
              </h5>
              <h3>
                <strong>2.2.37.</strong><span> Điểm của đội trong bảng </span>
              </h3>
              <h4>
                <strong>2.2.37.1.</strong
                ><span>
                  Nghĩa là cá cược về điểm của một đội khi kết thúc vòng Bảng mà
                  điểm đó khớp chính xác vào loại đã đặt, "Dưới", "Trong" &amp;
                  "Trên".
                </span>
              </h4>
              <h5>
                <strong>Ví dụ:</strong><br />
                Tổng số điểm của Đội X là 5 điểm<br />
                Nếu cá cược là:<br />
                <span>Dưới 3 điểm – thua</span><br />
                <span>Từ 3-4 điểm – thua</span><br />
                <span>Trên 4 điểm – thắng</span><br />
              </h5>
              <h3><strong>2.2.38.</strong><span> Dự báo thắng bảng </span></h3>
              <h4>
                <strong>2.2.38.1.</strong
                ><span>
                  Nghĩa là cá cược để dự đoán các đội sẽ đứng thứ nhất và thứ
                  nhì, theo thứ tự cụ thể, trong bảng xếp hạng các đội của Bảng
                  tương ứng khi kết thúc các trận đấu vòng Bảng.
                </span>
              </h4>
              <h3>
                <strong>2.2.39.</strong
                ><span> Injury time awarded Over/Under </span>
              </h3>
              <h4>
                <strong>2.2.39.1.</strong
                ><span>
                  Injury time awarded at end of 1st half Over/Under:
                </span>
              </h4>
              <h5>
                <strong>2.2.39.1.1.</strong
                ><span>
                  1st Half Injury Time Over/Under means betting Over/Under on
                  the injury time awarded at the end of 1st half.
                </span>
              </h5>
              <h5>
                <strong>2.2.39.1.2.</strong
                ><span>
                  If the total is more than the OU line then the winning result
                  is Over; if the total is less than the OU line then the
                  winning result is Under.
                </span>
              </h5>
              <h5>
                <strong>2.2.39.1.3.</strong
                ><span>
                  Bets are settled on the injury time awarded by the match
                  fourth official referee after the full 45 minutes of play or
                  at end of 1st half.
                </span>
              </h5>
              <h4>
                <strong>2.2.39.2.</strong
                ><span>
                  Injury time awarded at end of 2nd half Over/Under
                </span>
              </h4>
              <h5>
                <strong>2.2.39.2.1.</strong
                ><span>
                  2nd Half Injury Time Over/Under means betting Over/Under on
                  the injury time awarded at the end of 2nd half.
                </span>
              </h5>
              <h5>
                <strong>2.2.39.2.2.</strong
                ><span>
                  If the total is more than the OU line then the winning result
                  is Over; if the total is less than the OU line then the
                  winning result is Under.
                </span>
              </h5>
              <h5>
                <strong>2.2.39.2.3.</strong
                ><span>
                  Bets are settled on the injury time awarded by the match
                  fourth official referee after the full 90 minutes of play or
                  at end of 2nd half.
                </span>
              </h5>
              <h3><strong>2.2.40.</strong><span> First Goal Method </span></h3>
              <h4>
                <strong>2.2.40.1.</strong
                ><span>
                  Means predicting the way the first goal of a match is scored
                  by either team.
                </span>
              </h4>
              <h5>
                <strong>2.2.40.1.1.</strong
                ><span>
                  Free-kick - Goal must be scored directly from the free kick.
                  Deflected shots count provided the free-kick taker is awarded
                  the goal. Also includes goals scored directly from a corner
                  kick.
                </span>
              </h5>
              <h5>
                <strong>2.2.40.1.2.</strong
                ><span>
                  Penalty - Goal must be scored directly from the penalty, with
                  penalty taker as named scorer.
                </span>
              </h5>
              <h5>
                <strong>2.2.40.1.3.</strong
                ><span> Own Goal - If goal is declared as an own goal. </span>
              </h5>
              <h5>
                <strong>2.2.40.1.4.</strong
                ><span>
                  Header - Last touch of the scorer must be with the head.
                </span>
              </h5>
              <h5>
                <strong>2.2.40.1.5.</strong
                ><span> Shot - All other goal-types not included above. </span>
              </h5>
              <h5><strong>2.2.40.1.6.</strong><span> No Goal </span></h5>
              <h4>
                <strong>2.2.40.2.</strong
                ><span>
                  The Company will settle bets according to the official results
                  made available by the soccer authority responsible for
                  organizing the match.
                </span>
              </h4>
              <h3>
                <strong>2.2.41.</strong
                ><span> Penalty Shootout – Will the Penalty be Scored? </span>
              </h3>
              <h4>
                <strong>2.2.41.1.</strong
                ><span>
                  Means betting to predict if a designated kick-taker will score
                  or miss on penalty kick during penalty shooutout.
                </span>
              </h4>
              <h4>
                <strong>2.2.41.2.</strong
                ><span>
                  If penalty kick is not taken, then all bets will be considered
                  void.
                </span>
              </h4>
              <h3><strong>2.2.42.</strong><span> Dual Forecast </span></h3>
              <h4>
                <strong>2.2.42.1.</strong
                ><span>
                  Dual Forecast means betting to predict the two (2) teams which
                  must be in top 2 positions, in any order, at the end of the
                  tournament.
                </span>
              </h4>
              <h3><strong>2.2.43.</strong><span> Straight Forecast </span></h3>
              <h4>
                <strong>2.2.43.1.</strong
                ><span>
                  Straight Forecast means betting to predict the two (2) teams
                  which must be in top 2 positions, in exact order, at the end
                  of the tournament.
                </span>
              </h4>
              <h3><strong>2.2.44.</strong><span> Đội mới đứng đầu </span></h3>
              <h4>
                <strong>2.2.44.1.</strong
                ><span>
                  Đội mới đứng đầu nghĩa là dự đoán đội nào sẽ thắng đầu một Sự
                  kiện hay mùa giải.
                </span>
              </h4>
              <h4>
                <strong>2.2.44.2.</strong
                ><span>
                  “Đội mới” là một đội mới được đề bạt tham gia vào một Sự kiện
                  hoặc mùa giải.
                </span>
              </h4>
              <h3>
                <strong>2.2.45.</strong><span> Người thắng trong khu vực </span>
              </h3>
              <h4>
                <strong>2.2.45.1.</strong
                ><span>
                  Người thắng trong khu vực nghĩa là dự đoán người chiến thắng
                  sự kiện hoặc mùa giải từ khu vực đó.
                </span>
              </h4>
              <h4>
                <strong>2.2.45.2.</strong
                ><span>
                  Tất cả kết quả được ghi nhận khi kết quả chính thức được cơ
                  quan quản lý công bố vào cuối mùa giải.
                </span>
              </h4>
              <h3>
                <strong>2.2.46.</strong
                ><span> Thắng ngược dòng, cược không có hiệu lực </span>
              </h3>
              <h4>
                <strong>2.2.46.1.</strong
                ><span>
                  Dự đoán Hòa hoặc Thắng từ đầu trong trận đấu. Nếu kết quả
                  chung cuộc sau thời gian chơi thông thường hoặc khi hết thời
                  gian đã lên lịch là Thắng ngược dòng, toàn bộ tiền cược sẽ
                  được hoàn lại.
                </span>
              </h4>
              <h3>
                <strong>2.2.47.</strong
                ><span> Thắng từ đầu, cược không có hiệu lực </span>
              </h3>
              <h4>
                <strong>2.2.47.1.</strong
                ><span>
                  Dự đoán Hòa hoặc Thắng ngược dòng trong trận đấu. Nếu kết quả
                  chung cuộc sau thời gian chơi thông thường hoặc khi hết thời
                  gian đã lên lịch là Thắng từ đầu, toàn bộ tiền cược sẽ được
                  hoàn lại.
                </span>
              </h4>
              <h3><strong>2.2.48.</strong><span> Hòa / Không hòa </span></h3>
              <h4>
                <strong>2.2.48.1.</strong
                ><span>
                  Dự đoán Hòa và Không hòa trong kết quả chung cuộc của một trận
                  đấu sau thời gian chơi thông thường hoặc khi hết thời gian đã
                  lên lịch.
                </span>
              </h4>
              <h3>
                <strong>2.2.49.</strong
                ><span> Tỷ số chính xác đến hết Hiệp 1 </span>
              </h3>
              <h4>
                <strong>2.2.49.1.</strong
                ><span>
                  Tỷ số chính xác đến hết Hiệp 1 có nghĩa là cược để dự đoán tỷ
                  số cuối cùng khi kết thúc Hiệp 1.
                </span>
              </h4>
              <h4>
                <strong>2.2.49.2.</strong
                ><span>
                  Khoản cược sẽ không có giá trị nếu trận đấu bị huỷ bỏ ngoại
                  trừ trường hợp khoản cược đã được định đoạt trước.
                </span>
              </h4>
              <h3><strong>2.2.50.</strong><span> Result/Total Goal </span></h3>
              <h4>
                <strong>2.2.50.1.</strong
                ><span> Result/Total Goal means betting to both predict: </span>
              </h4>
              <h5>
                <strong>(A)</strong
                ><span>
                  whether a match will result in Home win or Away win or Draw;
                  and
                </span>
              </h5>
              <h5>
                <strong>(B)</strong
                ><span>
                  whether the total number of goals in the final result of an
                  event will be Over or Under.
                </span>
              </h5>
              <h4>
                <strong>2.2.50.2.</strong
                ><span> The following betting options are available: </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>
                    Home &amp; Over – bet wins if home team wins and the total
                    goals are above pre-designated line.
                  </li>
                  <li>
                    Home &amp; Under – bet wins if home team wins and the total
                    goals are below pre-designated line.
                  </li>
                  <li>
                    Away &amp; Over – bet wins if away team wins and the total
                    goals are above pre-designated line.
                  </li>
                  <li>
                    Away &amp; Under - bet wins if away team wins and the total
                    goals are below pre-designated line.
                  </li>
                  <li>
                    Draw &amp; Over – bet wins if the match results in draw and
                    the total goals are above pre-designated line.
                  </li>
                  <li>
                    Draw &amp; Under – bet wins if the match results in draw and
                    the total goals are below pre-designated line.
                  </li>
                </ol>
              </h5>
              <h3>
                <strong>2.2.51.</strong><span> Team to Win From Behind </span>
              </h3>
              <h4>
                <strong>2.2.51.1.</strong
                ><span>
                  Team to Win From Behind means betting to predict the team that
                  will be losing at any time in the match but eventually comes
                  from behind and win at the end of 90 minutes.
                </span>
              </h4>
              <h3>
                <strong>2.2.52.</strong><span> Người ghi bàn đầu tiên </span>
              </h3>
              <h4>
                <strong>2.2.52.1.</strong
                ><span>
                  Người ghi bàn đầu tiên có nghĩa là cá cược để dự đoán cầu thủ
                  nào sẽ là người ghi bàn đầu tiên trong trận đấu.
                </span>
              </h4>
              <h4>
                <strong>2.2.52.2.</strong
                ><span>
                  Các khoản cược vào bất kỳ cầu thủ nào không tham gia trận đấu
                  sẽ không có giá trị và sẽ được hoàn lại tiền.
                </span>
              </h4>
              <h4>
                <strong>2.2.52.3.</strong
                ><span>
                  Các khoản cược vào bất kỳ cầu thủ nào ra sân thay thế sau khi
                  bàn thắng đầu tiên được ghi sẽ không có giá trị và sẽ được
                  hoàn lại tiền.
                </span>
              </h4>
              <h4>
                <strong>2.2.52.4.</strong
                ><span>
                  Các bàn đá phản lưới nhà không được tính là bàn thắng đầu
                  tiên. Trong trường hợp đó, cầu thủ tiếp theo ghi bàn sẽ được
                  xem là người ghi bàn đầu tiên.
                </span>
              </h4>
              <h4>
                <strong>2.2.52.5.</strong
                ><span>
                  Nếu cầu thủ được chọn sau khi bàn thắng đầu tiên được ghi thì
                  khoản cược sẽ bị xem là không có giá trị trừ phi bàn đầu tiên
                  là bàn đá phản lưới nhà, trong trường hợp này, khoản cược sẽ
                  vẫn có giá trị.
                </span>
              </h4>
              <h4>
                <strong>2.2.52.6.</strong
                ><span>
                  Các khoản cược vào cầu thủ bị thay thế hoặc bị đuổi khỏi sân
                  trước khi bàn thắng đầu tiên được ghi sẽ mất.
                </span>
              </h4>
              <h4>
                <strong>2.2.52.7.</strong
                ><span>
                  Các khoản cược vào “cầu thủ không ghi bàn” sẽ thắng nếu không
                  có cầu thủ nào ghi bàn trong trận đấu. Nếu bàn đá phản lưới
                  nhà là bàn được ghi duy nhất trong trận đấu thì các khoản cược
                  vào “cầu thủ không ghi bàn” sẽ thắng.
                </span>
              </h4>
              <h3>
                <strong>2.2.53.</strong
                ><span> First Team to Score 2 Goals </span>
              </h3>
              <h4>
                <strong>2.2.53.1.</strong
                ><span>
                  Means betting to predict which team will be the first to score
                  two (2) goals in a match.
                </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>Home</li>
                  <li>Away</li>
                  <li>Neither</li>
                </ol>
              </h5>
              <h4>
                <strong>2.2.53.2.</strong
                ><span>
                  If a match is abandoned after a team has scored two (2) goals,
                  all bets will stand.
                </span>
              </h4>
              <h4>
                <strong>2.2.53.3.</strong
                ><span>
                  If a match is abandoned before any team has scored two (2)
                  goals, all bets will be void.
                </span>
              </h4>
              <h3>
                <strong>2.2.54.</strong
                ><span> First Team to Score 3 Goals </span>
              </h3>
              <h4>
                <strong>2.2.54.1.</strong
                ><span>
                  Means betting to predict which team will be the first to score
                  three (3) goals in a match.
                </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>Home</li>
                  <li>Away</li>
                  <li>Neither</li>
                </ol>
              </h5>
              <h4>
                <strong>2.2.54.2.</strong
                ><span>
                  If a match is abandoned after a team has scored three (3)
                  goals, all bets will stand.
                </span>
              </h4>
              <h4>
                <strong>2.2.54.3.</strong
                ><span>
                  If a match is abandoned before any team has scored three (3)
                  goals, all bets will be void.
                </span>
              </h4>
              <h3><strong>2.2.55.</strong><span> Time of First Goal </span></h3>
              <h4>
                <strong>2.2.55.1.</strong
                ><span>
                  Means betting to predict in which time bracket the first goal
                  will be scored.
                </span>
              </h4>
              <h4>
                <strong>2.2.55.2.</strong
                ><span>
                  The following time brackets/betting options are available:
                </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>00:00 - 10:00</li>
                  <li>10:01 - 20:00</li>
                  <li>20:01 - 30:00</li>
                  <li>30:01 - 40:00</li>
                  <li>40:01 - 50:00</li>
                  <li>50:01 - 60:00</li>
                  <li>60:01 - 70:00</li>
                  <li>70:01 - 80:00</li>
                  <li>80:01 – Full Time</li>
                  <li>No goal</li>
                </ol>
              </h5>
              <h4>
                <strong>2.2.55.3.</strong
                ><span>
                  If a match is abandoned after the first goal has been scored,
                  all bets will stand.
                </span>
              </h4>
              <h4>
                <strong>2.2.55.4.</strong
                ><span>
                  If a match is abandoned before the first goal has been scored,
                  all bets will be void.
                </span>
              </h4>
              <h3>
                <strong>2.2.56.</strong
                ><span> Which Half Will Produce The First Goal </span>
              </h3>
              <h4>
                <strong>2.2.56.1.</strong
                ><span>
                  Means betting to predict on which half of the match the first
                  goal will be scored.
                </span>
              </h4>
              <h4>
                <strong>2.2.56.2.</strong
                ><span> The following betting options are available: </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>First half</li>
                  <li>Second half</li>
                  <li>No goal</li>
                </ol>
              </h5>
              <h4>
                <strong>2.2.56.3.</strong
                ><span>
                  If a match is subsequently abandoned after the first goal has
                  been scored during the first half, all bets will stand.
                </span>
              </h4>
              <h4>
                <strong>2.2.56.4.</strong
                ><span>
                  If a match is abandoned at any time before the first goal has
                  been scored, all bets will be void. "
                </span>
              </h4>
              <h3>
                <strong>2.2.57.</strong
                ><span>
                  Cả hai đội ghi bàn/Kết quả và Cả hai đội ghi bàn/Kết quả Hiệp
                  một
                </span>
              </h3>
              <h4>
                <strong>2.2.57.1.</strong
                ><span>
                  Both teams to score / result means betting to both predict:
                </span>
              </h4>
              <h5>
                <strong>(A)</strong
                ><span>
                  whether a match will result in both teams scoring and;
                </span>
              </h5>
              <h5>
                <strong>(B)</strong
                ><span>
                  whether a match will result in Home Win or Away win or Draw.
                </span>
              </h5>
              <h4>
                <strong>2.2.57.2.</strong
                ><span> The following betting options are available: </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>
                    Yes &amp; Home - bet wins if both teams score and Home team
                    wins.
                  </li>
                  <li>
                    Yes &amp; Away - bet wins if both teams score and Away team
                    wins.
                  </li>
                  <li>
                    Yes &amp; Draw - bet wins if both teams score and the match
                    results in Draw.
                  </li>
                </ol>
              </h5>
              <h4>
                <strong>2.2.57.3.</strong
                ><span>
                  Cược cả hai đội ghi bàn/Kết quả hiệp một nghĩa là cược để dự
                  đoán kết quả hiệp một của trận đấu và liệu cả hai đội có ghi
                  bàn trong hiệp một hay không.
                </span>
              </h4>
              <h4>
                <strong>2.2.57.4.</strong
                ><span>
                  Các khoản cược sẽ vô hiệu nếu trận đấu bị hủy bỏ trừ khi việc
                  thanh toán tiền cược đã được xác định.
                </span>
              </h4>
              <h3>
                <strong>2.2.58.</strong
                ><span> Chẵn/lẻ cho nửa trận/cả trận </span>
              </h3>
              <h4>
                <strong>2.2.58.1.</strong
                ><span>
                  Chẵn/lẻ cho nửa trận/cả trận nghĩa là cá cược dự đoán kết quả
                  của nửa trận và kết quả của cả trận đấu là lẻ/lẻ, lẻ/chẵn,
                  chẵn/lẻ hay chẵn/chẵn.
                </span>
              </h4>
              <h4>
                <strong>2.2.58.2.</strong
                ><span> Có bốn (4) phương án cược: </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>Lẻ/Lẻ</li>
                  <li>Lẻ/Chẵn</li>
                  <li>Chẵn/Lẻ</li>
                  <li>Chẵn/Chẵn</li>
                </ol>
              </h5>
              <h4>
                <strong>2.2.58.3.</strong
                ><span>
                  Đối với kiểu cá cược này, tất cả mọi thời gian bù giờ sẽ không
                  được tính để xác định kết quả của cả trận đấu.
                </span>
              </h4>
              <h4>
                <strong>2.2.58.4.</strong
                ><span>
                  Khoản cược sẽ không có giá trị nếu trận đấu bị huỷ bỏ, trừ
                  trường hợp đã xác định từ trước việc thanh toán cá cược.
                </span>
              </h4>
              <h3>
                <strong>2.2.59.</strong
                ><span> Half with Most Away Team Goal: </span>
              </h3>
              <h4>
                <strong>2.2.59.1.</strong
                ><span>
                  Means betting to predict in which half of the match the Away
                  Team will score more goals.
                </span>
              </h4>
              <h4>
                <strong>2.2.59.2.</strong
                ><span> The following options are available: </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>1st Half</li>
                  <li>2nd Half</li>
                  <li>Tie</li>
                </ol>
              </h5>
              <h3>
                <strong>2.2.60.</strong
                ><span> Half with Home Team Score the First Goal </span>
              </h3>
              <h4>
                <strong>2.2.60.1.</strong
                ><span>
                  Means betting to predict in which half of the match the Home
                  Team will score the First Goal.
                </span>
              </h4>
              <h4>
                <strong>2.2.60.2.</strong
                ><span> The following options are available: </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>1st Half</li>
                  <li>2nd Half</li>
                  <li>No Goal</li>
                </ol>
              </h5>
              <h3>
                <strong>2.2.61.</strong
                ><span> Half with Away Team Score the First Goal </span>
              </h3>
              <h4>
                <strong>2.2.61.1.</strong
                ><span>
                  Means betting to predict which half of the match the Away Team
                  will score the First Goal.
                </span>
              </h4>
              <h4>
                <strong>2.2.61.2.</strong
                ><span> The following options are available: </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>1st Half</li>
                  <li>2nd Half</li>
                  <li>No Goal</li>
                </ol>
              </h5>
              <h3>
                <strong>2.2.62.</strong
                ><span> Specific 15-Minute Handicap (HDP) </span>
              </h3>
              <h4>
                <strong>2.2.62.1.</strong
                ><span>
                  The Specific 15-minute Handicap means betting when one
                  competitor or team receives a virtual head start .The winner
                  is the competitor or team with the better score after adding
                  the given handicap to the result at the end of every 15th
                  minute (INTERVAL OF) time of a match.
                </span>
              </h4>
              <h4><strong>2.2.62.2.</strong><span> For example: </span></h4>
              <h5 class="style1">
                <strong>15th Minute HDP</strong><br />
                00:00 – 15:00 HDP: The winner is the competitor or team with the
                better score from 00:00 till 15:00.<br />
                All bets must be placed on or before the end of the 15th minute.
              </h5>
              <h5 class="style1">
                <strong>30th Minute HDP</strong><br />
                15:01 – 30:00 HDP: The winner is the competitor or team with the
                better score from 15:01 till 30:00.<br />
                All bets must be placed on or before the end of the 30th minute.
              </h5>
              <h5 class="style1">
                <strong>45th Minute HDP</strong><br />
                30:01- 45:00 HDP: The winner is the competitor or team with the
                better score from 30:01 - 45:00.<br />
                All bets must be placed on or before the end of 45th minute.
              </h5>
              <h5 class="style1">
                <strong>60th Minute HDP</strong><br />
                45:01 – 60:00 HDP: The winner is the competitor or team with the
                better score from 45:01 till 60:00.<br />
                All bets must be placed on or before the end of the 60th minute.
              </h5>
              <h5 class="style1">
                <strong>75th Minute HDP</strong><br />
                60:01 – 75:00 HDP: The winner is the competitor or team with the
                better score from 60:01 till 75:00.<br />
                All bets must be placed on or before the end of the 75th minute.
              </h5>
              <h5 class="style1">
                <strong>90th Minute HDP</strong><br />
                75:01- 90:00 HDP: The winner is the competitor or team with the
                better score from 75:01 till 90:00.<br />
                All bets must be placed on or before the end of the 90th minute.
              </h5>
              <h4>
                <strong>2.2.62.3.</strong
                ><span>
                  For the Specific 15-Minute HDP, bets are settled on the exact
                  time the goal is scored (ball crossing the goal line), number
                  of corners (corners taken) and Total Bookings (cards given by
                  the official referee) as shown by the clock as published in
                  the live broadcast.
                </span>
              </h4>
              <h4>
                <strong>2.2.62.4.</strong
                ><span>
                  If a match is suspended or abandoned, then bets placed on
                  unfinished Specific 15-Minute HDP will be considered void. If
                  the designated Specific 15-Minute HDP are completed then bets
                  will be valid.
                </span>
              </h4>
              <h4>
                <strong>2.2.62.5.</strong
                ><span>
                  Đối với hai (2) phút cuối cùng của bất kỳ việc đặt cược trực
                  tiếp thông qua Cược chấp đặc biệt 15 phút (Specific 15-Minutes
                  HDP), bất kỳ hành động nào khác với những hành động được đề
                  cập ở đây sẽ được xem là Chơi an toàn (Safe Play) và vì vậy,
                  tất cả các khoản cược treo đã đặt có thể sẽ được cân nhắc chấp
                  nhận: bàn thắng, quả đá phạt đền và thẻ đỏ.
                </span>
              </h4>
              <h4>
                <strong>2.2.62.6.</strong
                ><span>
                  For 30:01-45:00 &amp; 75:01 - 90:00, bets are settled on the
                  exact time the goal is scored ( ball crossing the goal line) ,
                  number of corners (corners taken) and Total bookings (cards
                  given by the official referee) as shown by the clock as
                  published in the live broadcast excluding any additional time
                  or injury time.
                </span>
              </h4>
              <h3>
                <strong>2.2.63.</strong
                ><span>
                  Trận chọn đội đá cặp (Fantasy match): Đội ghi bàn đầu tiên
                </span>
              </h3>
              <h4>
                <strong>2.2.63.1.</strong
                ><span>
                  Trận chọn đội đá cặp (Fantasy match): Đội ghi bàn đầu tiên có
                  nghĩa là đặt cược để dự đoán đội nào sẽ ghi bàn thắng đầu tiên
                  trong một trận chọn đội đá cặp.
                </span>
              </h4>
              <h4><strong>Ví dụ:</strong></h4>
              <h5 class="style1">
                Các trận: Man City đấu với Liverpool; Chelsea đấu với Man
                United<br />
                Trận chọn đội đá cặp (Fantasy match): Man City đấu với
                Chelsea<br />
                Man City ghi bàn thắng đầu tiên ở phút thứ 25:10<br />
                Chelsea ghi bàn thắng đầu tiên ở phút thứ 25:48<br />
                Cược thắng: Man City.<br />
              </h5>
              <h4>
                <strong>2.2.63.2.</strong
                ><span>
                  Nếu cả hai trận đấu đều có bàn thắng ghi trùng thời gian (phút
                  lẫn giây) hoặc cả hai trận đều không có bàn thắng nào được ghi
                  thì trận chọn đội đá cặp sẽ được xem như hòa.
                </span>
              </h4>
              <h4>
                <strong>2.2.63.3.</strong
                ><span>
                  Nếu một trận bị hoãn hoặc bị hủy sau khi trận kia có bàn thắng
                  được ghi thì tất cả các khoản cược sẽ vẫn có giá trị. Nếu một
                  trận bị hoãn hoặc bị hủy trước khi trận kia không có bàn thắng
                  nào được ghi thì tất cả các khoản cược sẽ không có giá trị.
                </span>
              </h4>
              <h4><strong>Ví dụ:</strong></h4>
              <h5 class="style1">
                Các trận: Man City đấu với Liverpool; Chelsea đấu với Man
                United<br />
                Trận chọn đội đá cặp (Fantasy match): Man City đấu với
                Chelsea<br />
                Man City ghi bàn thắng đầu tiên ở phút thứ 25:10<br />
                Trận đấu của Chelsea bị hoãn hoặc bỏ trước phút thứ 25:10<br />
                Kết quả: tất cả các khoản cược đều sẽ không có giá trị. <br />
              </h5>
              <h4>
                <strong>2.2.63.4.</strong
                ><span>
                  Nếu cả hai trận đều bị hủy mà không có bàn thắng nào được ghi
                  thì tất cả các khoản cược đều không có giá trị.
                </span>
              </h4>
              <h4>
                <strong>2.2.63.5.</strong
                ><span>
                  Tất cả mọi bàn thắng ghi trong thời gian đá bù giờ đều không
                  được tính.
                </span>
              </h4>
              <h3>
                <strong>2.2.64.</strong><span> Đội châu Âu đứng đầu </span>
              </h3>
              <h4>
                <strong>2.2.64.1.</strong
                ><span>
                  Cược đội châu Âu đứng đầu nghĩa là cược đội châu Âu nào sẽ
                  tiến xa nhất trong giải đấu FIFA World Cup.
                </span>
              </h4>
              <h4>
                <strong>2.2.64.2.</strong
                ><span>
                  Nếu hai đội châu Âu thi đấu trong trận chung kết hoặc tranh
                  hạng ba, đội chiến thắng trận đấu sẽ được xem là Đội châu Âu
                  đứng đầu.
                </span>
              </h4>
              <h4>
                <strong>2.2.64.3.</strong
                ><span>
                  Trong các trường hợp khác khi có nhiều đội châu Âu rời giải
                  đấu trong cùng một vòng xa nhất, quy tắc “Đồng hạng” (Dead
                  Heat) sẽ được áp dụng và tỷ lệ trả (trừ tiền đặt cược) được
                  chia cho số người chiến thắng và được thanh toán tương ứng với
                  tiền đặt cược được trả lại.
                </span>
              </h4>
              <h4>
                <strong>2.2.64.4.</strong
                ><span>
                  Tất cả các khoản cược cho Đội châu Âu đứng đầu sẽ được thanh
                  toán khi FIFA công bố đội chiến thắng chính thức trận chung
                  kết hoặc tranh hạng ba hoặc khi đội châu Âu cuối cùng rời khỏi
                  giải đấu.
                </span>
              </h4>
              <h3>
                <strong>2.2.65.</strong><span> Đội Nam Mỹ đứng đầu </span>
              </h3>
              <h4>
                <strong>2.2.65.1.</strong
                ><span>
                  Cược Đội Nam Mỹ đứng đầu nghĩa là cược đội Nam Mỹ nào sẽ tiến
                  xa nhất trong giải đấu FIFA World Cup.
                </span>
              </h4>
              <h4>
                <strong>2.2.65.2.</strong
                ><span>
                  Nếu hai đội Nam Mỹ thi đấu trong trận chung kết hoặc tranh
                  hạng ba, đội chiến thắng trận đấu sẽ được xem là Đội Nam Mỹ
                  đứng đầu.
                </span>
              </h4>
              <h4>
                <strong>2.2.65.3.</strong
                ><span>
                  Trong các trường hợp khác khi có nhiều đội Nam Mỹ rời giải đấu
                  trong cùng một vòng xa nhất, quy tắc “Đồng hạng” (Dead Heat)
                  sẽ được áp dụng và tỷ lệ trả (trừ tiền đặt cược) được chia cho
                  số người chiến thắng và được thanh toán tương ứng với tiền đặt
                  cược được trả lại.
                </span>
              </h4>
              <h4>
                <strong>2.2.65.4.</strong
                ><span>
                  Tất cả các khoản cược cho Đội Nam Mỹ đứng đầu sẽ được thanh
                  toán khi FIFA công bố đội chiến thắng chính thức trận chung
                  kết hoặc tranh hạng ba hoặc khi đội Nam Mỹ cuối cùng rời khỏi
                  giải đấu.
                </span>
              </h4>
              <h3>
                <strong>2.2.66.</strong><span> Đội Châu Phi đứng đầu </span>
              </h3>
              <h4>
                <strong>2.2.66.1.</strong
                ><span>
                  Cược Đội Châu Phi đứng đầu nghĩa là cược đội Châu Phi nào sẽ
                  tiến xa nhất trong giải đấu FIFA World Cup.
                </span>
              </h4>
              <h4>
                <strong>2.2.66.2.</strong
                ><span>
                  Nếu hai đội Châu Phi thi đấu trong trận chung kết hoặc tranh
                  hạng ba, đội chiến thắng trận đấu sẽ được xem là Đội Châu Phi
                  đứng đầu.
                </span>
              </h4>
              <h4>
                <strong>2.2.66.3.</strong
                ><span>
                  Trong các trường hợp khác khi có nhiều đội Châu Phi rời giải
                  đấu trong cùng một vòng xa nhất, quy tắc “Đồng hạng” (Dead
                  Heat) sẽ được áp dụng và tỷ lệ trả (trừ tiền đặt cược) được
                  chia cho số người chiến thắng và được thanh toán tương ứng với
                  tiền đặt cược được trả lại.
                </span>
              </h4>
              <h4>
                <strong>2.2.66.4.</strong
                ><span>
                  Tất cả các khoản cược cho Đội Châu Phi đứng đầu sẽ được thanh
                  toán khi FIFA công bố đội chiến thắng chính thức trận chung
                  kết hoặc tranh hạng ba hoặc khi đội Châu Phi cuối cùng rời
                  khỏi giải đấu.
                </span>
              </h4>
              <h3><strong>2.2.67.</strong><span> Cả hai đội ghi bàn </span></h3>
              <h4>
                <strong>2.2.67.1.</strong
                ><span>
                  Cược cả hai đội ghi bàn nghĩa là cược để dự đoán liệu một trận
                  đấu sẽ có kết quả là cả hai đội ghi bàn hay không.
                </span>
              </h4>
              <h4>
                <strong>2.2.67.2.</strong
                ><span>
                  Khoản cược sẽ không có giá trị nếu trận đấu bị huỷ bỏ ngoại
                  trừ trường hợp khoản cược đã được định đoạt trước.
                </span>
              </h4>
              <h3>
                <strong>2.2.68.</strong
                ><span> Cả hai đội ghi bàn trong hiệp một </span>
              </h3>
              <h4>
                <strong>2.2.68.1.</strong
                ><span>
                  Cược cả hai đội ghi bàn trong hiệp một nghĩa là cược để dự
                  đoán liệu cả hai đội sẽ ghi bàn trong hiệp một của trận đấu
                  hay không.
                </span>
              </h4>
              <h4>
                <strong>2.2.68.2.</strong
                ><span>
                  Nếu một trận đấu bị hủy bỏ sau khi cả hai đội đã ghi bàn trong
                  hiệp một, thì cược ‘Có’ sẽ được xác định là thắng cuộc và cược
                  ‘Không’ là thua cuộc. Nếu trận đấu bị hoãn hoặc hủy bỏ trước
                  khi kết thúc Hiệp Một mà cả hai đội không ghi bàn, tất cả các
                  khoản cược sẽ vô hiệu.
                </span>
              </h4>
              <h3>
                <strong>2.2.69.</strong
                ><span> Cả hai đội ghi bàn trong Hiệp Hai </span>
              </h3>
              <h4>
                <strong>2.2.69.1.</strong
                ><span>
                  Cược cả hai đội sẽ ghi bàn trong Hiệp Hai nghĩa là cược để dự
                  đoán liệu cả hai đội có ghi bàn trong hiệp hai của một trận
                  đấu hay không.
                </span>
              </h4>
              <h4>
                <strong>2.2.69.2.</strong
                ><span>
                  Nếu một trận đấu bị hủy bỏ sau khi cả hai đội đã ghi bàn trong
                  hiệp hai, thì các khoản cược ‘Có’ sẽ được xác định là thắng
                  cuộc và cược ‘Không’ là thua cuộc. Nếu trận đấu bị hoãn lại
                  hoặc hủy bỏ mà cả hai đội đều không ghi bàn thì tất cả các
                  khoản cược sẽ vô hiệu.
                </span>
              </h4>
              <h3>
                <strong>2.2.70.</strong
                ><span>
                  Cả hai đội sẽ ghi bàn trong hiệp một và/hoặc hiệp hai
                </span>
              </h3>
              <h4>
                <strong>2.2.70.1.</strong
                ><span>
                  Cược cả hai đội sẽ ghi bàn trong Hiệp Một và/hoặc Hiệp Hai
                  nghĩa là cược để dự đoán liệu cả hai đội có ghi bàn trong hiệp
                  một và liệu cả hai đội có ghi bàn trong hiệp hai của trận đấu
                  hay không.
                </span>
              </h4>
              <h4>
                <strong>2.2.70.2.</strong
                ><span>
                  Các khoản cược sẽ vô hiệu nếu trận đấu bị hủy bỏ trừ khi cả
                  hai đội đã ghi bàn trong hiệp một và hiệp hai của trận đấu.
                </span>
              </h4>
              <h3>
                <strong>2.2.71.</strong
                ><span> Cả hai đội sẽ ghi bàn/Tổng số bàn thắng </span>
              </h3>
              <h4>
                <strong>2.2.71.1.</strong
                ><span>
                  Cược cả hai đội sẽ ghi bàn/Tổng số bàn thắng nghĩa là cược để
                  dự đoán tổng số bàn thắng trong trận đấu và liệu cả hai đội có
                  ghi bàn trong trận đấu hay không.
                </span>
              </h4>
              <h4>
                <strong>2.2.71.2.</strong
                ><span>
                  Các khoản cược sẽ vô hiệu nếu trận đấu bị hủy bỏ trừ khi việc
                  thanh toán tiền cược đã được xác định.
                </span>
              </h4>
              <h3>
                <strong>2.2.72.</strong
                ><span> Kết quả hiệp một/Tổng số bàn thắng </span>
              </h3>
              <h4>
                <strong>2.2.72.1.</strong
                ><span>
                  Cược kết quả hiệp một/Tổng số bàn thắng nghĩa là cược để dự
                  đoán kết quả của hiệp một của trận đấu và tổng số bàn thắng
                  của hiệp một.
                </span>
              </h4>
              <h4>
                <strong>2.2.72.2.</strong
                ><span>
                  Các khoản cược sẽ vô hiệu nếu trận đấu bị hủy bỏ trong hiệp
                  một của trận đấu. Các khoản cược sẽ giữ nguyên giá trị nếu
                  trận đấu bị hủy bỏ trong hiệp hai của trận đấu.
                </span>
              </h4>
              <h3>
                <strong>2.2.73.</strong
                ><span>
                  Đội nhà ghi bàn trong Hiệp Một/ghi bàn trong Hiệp Hai
                </span>
              </h3>
              <h4>
                <strong>2.2.73.1.</strong
                ><span>
                  Cược Đội nhà sẽ ghi bàn trong Hiệp Một/ghi bàn trong Hiệp Hai
                  nghĩa là cược để dự đoán liệu Đội nhà có ghi bàn trong hiệp
                  một và hiệp hai của trận đấu hay không.
                </span>
              </h4>
              <h4>
                <strong>2.2.73.2.</strong
                ><span>
                  Các khoản cược sẽ vô hiệu nếu trận đấu bị hủy bỏ trừ khi việc
                  thanh toán tiền cược đã được xác định.
                </span>
              </h4>
              <h3>
                <strong>2.2.74.</strong
                ><span>
                  Đội khách ghi bàn trong Hiệp Một/ghi bàn trong Hiệp Hai
                </span>
              </h3>
              <h4>
                <strong>2.2.74.1.</strong
                ><span>
                  Cược Đội khách sẽ ghi bàn trong Hiệp Một/ghi bàn trong Hiệp
                  Hai nghĩa là cược để dự đoán liệu Đội khách có ghi bàn trong
                  hiệp một và hiệp hai của trận đấu hay không.
                </span>
              </h4>
              <h4>
                <strong>2.2.74.2.</strong
                ><span>
                  Các khoản cược sẽ vô hiệu nếu trận đấu bị hủy bỏ trừ khi việc
                  thanh toán tiền cược đã được xác định.
                </span>
              </h4>
              <h3>
                <strong>2.2.75.</strong><span> Specific 15 Minutes 1X2 </span>
              </h3>
              <h4>
                <strong>2.2.75.1.</strong
                ><span>
                  Specific 15 Minutes 1X2 means betting to predict any one of
                  three possible winning outcomes at the end of every 15th
                  minute (INTERVAL OF) time of a match. 1 refers to the team
                  that is named first (usually the home team); X refer to the
                  game resulting in a draw or tie; 2 refers to the team that is
                  named second (usually away team).
                </span>
              </h4>
              <h4><strong>2.2.75.2.</strong><span> For example: </span></h4>
              <h5 class="style1">
                <strong>15th Minute 1X2</strong><br />
                Predict any one of three possible winning outcomes which is 1X2
                from 00:00 – 15:00. All bets must be placed on or before the end
                of the 15th minute.
              </h5>
              <h5 class="style1">
                <strong>30th Minute 1X2</strong><br />
                Predict any one of three possible winning outcomes which is 1X2
                from 15:01 – 30:00. All bets must be placed on or before the end
                of the 30th minute.
              </h5>
              <h5 class="style1">
                <strong>45th Minute 1X2</strong><br />
                Predict any one of three possible winning outcomes which is 1X2
                from 30:01 – 45:00. All bets must be placed on or before the end
                of the 45th minute.
              </h5>
              <h5 class="style1">
                <strong>60th Minute 1X2</strong><br />
                Predict any one of three possible winning outcomes which is 1X2
                from 45:01 – 60:00. All bets must be placed on or before the end
                of the 60th minute.
              </h5>
              <h5 class="style1">
                <strong>75th Minute 1X2</strong><br />
                Predict any one of three possible winning outcomes which is 1X2
                from 60:01 – 75:00. All bets must be placed on or before the end
                of the 75th minute.
              </h5>
              <h5 class="style1">
                <strong>90th Minute 1X2</strong><br />
                Predict any one of three possible winning outcomes which is 1X2
                from 75:01 – 90:00. All bets must be placed on or before the end
                of the 90th minute.
              </h5>
              <h4>
                <strong>2.2.75.3.</strong
                ><span>
                  For the Specific 15 Minutes 1X2, bets are settled on the exact
                  time the goal is scored (ball crossing the goal line), number
                  of corners (corners taken) and total bookings (cards given by
                  the official referee) as shown by the clock as published in
                  the live broadcast.
                </span>
              </h4>
              <h4>
                <strong>2.2.75.4.</strong
                ><span>
                  If a match is suspended or abandoned, then bets placed on
                  unfinished Specific 15 Minutes 1X2 will be considered void. If
                  the designated Specific 15 Minutes 1X2 are completed then bets
                  will be valid.
                </span>
              </h4>
              <h4>
                <strong>2.2.75.5.</strong
                ><span>
                  For last two (2) minutes of any Specific 15 Minutes 1X2 live
                  betting, any actions other than the one mentioned herein
                  below, will be considered Safe Play and thus all pending bets
                  placed may be considered for acceptance: a goal, a penalty and
                  red card.
                </span>
              </h4>
              <h4>
                <strong>2.2.75.6.</strong
                ><span>
                  For 30:01 – 45:00 &amp; 75:01 – 90:00, bets are settled on the
                  exact time the goal is scored (ball crossing the goal line),
                  number of corners (corners taken) and total bookings (cards
                  given by the official referee) as shown by the clock as
                  published in the live broadcast excluding any additional time
                  or injury time.
                </span>
              </h4>
              <h3>
                <strong> 2.2.76. </strong
                ><span> Đội nào sẽ lọt vào vòng tiếp theo </span>
              </h3>
              <h4>
                <strong> 2.2.76.1. </strong
                ><span>
                  Đội nào sẽ lọt vào vòng tiếp theo là loại cược dự đoán đội nào
                  sẽ lọt vào vòng tiếp theo của giải đấu.
                </span>
              </h4>
              <h3>
                <strong> 2.2.77. </strong
                ><span>
                  Cược chấp 10 phút cụ thể (Specific 10-Minute Handicap)
                </span>
              </h3>
              <h4>
                <strong>2.2.77.1. </strong
                ><span>
                  Cược chấp 10 phút cụ thể (Specific 10-Minute Handicap) nghĩa
                  là đặt cược khi nào một đối thủ hay một đội được nhận khởi đầu
                  thuận lợi ảo. Người thắng là đối thủ hay đội có bàn thắng
                  nhiều hơn sau khi đã cộng thêm bàn chấp đã cược vào kết quả
                  vào cuối mỗi khoảng thời gian 10 phút của trận đấu.
                </span>
              </h4>
              <h4><strong>2.2.77.2. </strong><span> Ví dụ: </span></h4>
              <h5 class="style1">
                <strong>Cược chấp (HDP) phút thứ 10</strong><br />
                00:00 – 10:00 HDP: Người thắng là đối thủ hay đội có tỉ số bàn
                thắng nhiều hơn trong khoảng thời gian từ phút 00:00 đến phút
                thứ 10:00. <br />
                Tất cả mọi khoản cược bắt buộc phải được đặt vào lúc hay trước
                khi kết thúc phút thứ 10.
              </h5>
              <h5 class="style1">
                <strong>Cược chấp (HDP) phút thứ 20</strong><br />
                10:01 – 20:00 HDP: Người thắng là đối thủ hay đội có tỉ số bàn
                thắng nhiều hơn trong khoảng thời gian từ phút thứ 10:01 đến
                phút thứ 20:00. <br />
                Tất cả mọi khoản cược bắt buộc phải được đặt vào lúc hay trước
                khi kết thúc phút thứ 20.
              </h5>
              <h5 class="style1">
                <strong>Cược chấp (HDP) phút thứ 30</strong><br />
                20:01- 30:00 HDP: Người thắng là đối thủ hay đội có tỉ số bàn
                thắng nhiều hơn trong khoảng thời gian từ phút thứ 20:01 đến
                phút thứ 30:00.<br />
                Tất cả mọi khoản cược bắt buộc phải được đặt vào lúc hay trước
                khi kết thúc phút thứ 30.
              </h5>
              <h5 class="style1">
                <strong>Cược chấp (HDP) phút thứ 40</strong><br />
                30:01 – 40:00 HDP: Người thắng là đối thủ hay đội có tỉ số bàn
                thắng nhiều hơn trong khoảng thời gian từ phút thứ 30:01 đến
                phút thứ 40:00.<br />
                Tất cả mọi khoản cược bắt buộc phải được đặt vào lúc hay trước
                khi kết thúc phút thứ 40.
              </h5>
              <h5 class="style1">
                <strong>Cược chấp (HDP) phút thứ 60</strong><br />
                50:01- 60:00 HDP: Người thắng là đối thủ hay đội có tỉ số bàn
                thắng nhiều hơn trong khoảng thời gian từ phút thứ 50:01 đến
                phút thứ 60:00.<br />
                Tất cả mọi khoản cược bắt buộc phải được đặt vào lúc hay trước
                khi kết thúc phút thứ 60.
              </h5>
              <h5 class="style1">
                <strong>Cược chấp (HDP) phút thứ 70</strong><br />
                60:01- 70:00 HDP: Người thắng là đối thủ hay đội có tỉ số bàn
                thắng nhiều hơn trong khoảng thời gian từ phút thứ 60:01 đến
                phút thứ 70:00. <br />
                Tất cả mọi khoản cược bắt buộc phải được đặt vào lúc hay trước
                khi kết thúc phút thứ 70.
              </h5>
              <h5 class="style1">
                <strong>Cược chấp (HDP) phút thứ 80</strong><br />
                70:01- 80:00 HDP: Người thắng là đối thủ hay đội có tỉ số bàn
                thắng nhiều hơn trong khoảng thời gian từ phút thứ 70:01 đến
                phút thứ 80:00.<br />
                Tất cả mọi khoản cược bắt buộc phải được đặt vào lúc hay trước
                khi kết thúc phút thứ 80.
              </h5>
              <h5 class="style1">
                <strong>Cược chấp (HDP) phút thứ 90</strong><br />
                70:01- 80:00 HDP: Người thắng là đối thủ hay đội có tỉ số bàn
                thắng nhiều hơn trong khoảng thời gian từ phút thứ 70:01 đến
                phút thứ 80:00. <br />
                Tất cả mọi khoản cược bắt buộc phải được đặt vào lúc hay trước
                khi kết thúc phút thứ 90.
              </h5>
              <h4>
                <strong>2.2.77.3. </strong
                ><span>
                  Đối với Cược chấp 10 phút cụ thể (Specific 10-Minute HDP),
                  khoản cược được dàn xếp dựa trên thời gian chính xác khi bàn
                  thắng được ghi (bóng vượt qua vạch cầu môn), số quả phạt góc
                  (quả phạt góc đã thực hiện) và tổng số thẻ phạt (số thẻ phạt
                  do trọng tài chính thức đã rút) như thông tin hiển thị trên
                  đồng hồ khi được công bố qua chương trình phát sóng trực tiếp.
                </span>
              </h4>
              <h4>
                <strong>2.2.77.4. </strong
                ><span>
                  Nếu trận đấu bị hoãn hoặc bị bỏ dở, các khoản cược đặt vào
                  Cược chấp 10 phút cụ thể dở dang sẽ được xem là không có giá
                  trị. Nếu Cược chấp 10 phút cụ thể đã định hoàn thành trọn vẹn
                  thì khoản cược sẽ được xem là có giá trị.
                </span>
              </h4>
              <h4>
                <strong>2.2.77.5. </strong
                ><span>
                  Trong hai (2) phút cuối cùng của bất kỳ việc đặt cược trực
                  tiếp theo Cược chấp 10 phút cụ thể nào, bất kỳ hành động nào
                  ngoài những hành động được đề cập dưới đây sẽ được xem là Tình
                  huống an toàn (Safe Play) và vì vậy, tất cả mọi khoản cược
                  đang chờ xử lý có thể được cân nhắc chấp nhận: một bàn thắng,
                  một quả phạt đền và thẻ đỏ.
                </span>
              </h4>
              <h4>
                <strong>2.2.77.6. </strong
                ><span>
                  Đối với cược chấp từ phút 80:01 – 90:00, khoản cược sẽ được
                  dàn xếp dựa trên thời gian chính xác khi bàn thắng được ghi
                  (bóng vượt qua vạch cầu môn), số quả phạt góc (số quả phạt góc
                  đã thực hiện) và tổng số thẻ phạt (số thẻ phạt do trọng tài
                  chính thức đã rút) như thông tin hiển thị trên đồng hồ khi
                  được công bố qua chương trình phát sóng trực tiếp, không tính
                  thời gian bù giờ hay thời gian cộng thêm vào cuối trận đấu do
                  bóng chết vì có cầu thủ bị thương.
                </span>
              </h4>
              <h3>
                <strong> 2.2.78. </strong
                ><span>
                  Cú phạt đền đầu tiên thành bàn thắng hay không thành bàn thắng
                </span>
              </h3>
              <h4>
                <strong>2.2.78.1. </strong
                ><span>
                  Cú phạt đền đầu tiên thành bàn thắng hay không thành bàn thắng
                  nghĩa là loại cược dự đoán quả đá phạt đền đầu tiên của đội có
                  trở thành bàn thắng hay không.
                </span>
              </h4>
              <h3>
                <strong> 2.2.79. </strong
                ><span> Nhà tài trợ Jersey hàng đầu (Top Jersey Sponsor) </span>
              </h3>
              <h4>
                <strong>2.2.79.1. </strong
                ><span>
                  Nhà tài trợ Jersey hàng đầu (Top Jersey Sponsor) là loại cược
                  dự đoán nhà tài trợ jersey nào sẽ có đội mà mình tài trợ giành
                  được danh hiệu vô địch.
                </span>
              </h4>
              <h3>
                <strong> 2.2.80. </strong
                ><span>
                  Châu lục của huấn luyện viên trưởng đội chiến thắng (Continent
                  of Head Coach Winner)
                </span>
              </h3>
              <h4>
                <strong>2.2.80.1. </strong
                ><span>
                  Châu lục của huấn luyện viên trưởng đội chiến thắng (Continent
                  of Head Coach Winner) là loại cược dự đoán châu lục xuất xứ
                  của huấn luyện viên trưởng của đội bóng sẽ giành danh hiệu vô
                  địch.
                </span>
              </h4>
              <h3>
                <strong> 2.2.81. </strong
                ><span> Tỉ số thắng thua (Winning Margin) </span>
              </h3>
              <h4>
                <strong>2.2.81.1. </strong
                ><span>
                  Tỉ số thắng thua (Winning Margin) là loại cược dự đoán đội
                  thắng trong trận đấu và tỉ số bàn thắng được ghi giữa Đội nhà
                  và Đội khách.
                </span>
              </h4>
              <h4>
                <strong>2.2.81.2. </strong
                ><span>
                  Các tùy chọn sẵn có cho loại cược này là những tùy chọn được
                  hiển thị trên trang web. Ví dụ:
                </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>Đội nhà thắng cách biệt 1 bàn</li>
                  <li>Đội nhà thắng cách biệt 2 bàn</li>
                  <li>Đội nhà thắng cách biệt từ 3 bàn trở lên</li>
                  <li>Bất kỳ bàn thắng Hòa nào</li>
                  <li>Đội khách thắng cách biệt 1 bàn</li>
                  <li>Đội khách thắng cách biệt 2 bàn</li>
                  <li>Đội khách thắng cách biệt từ 3 bàn trở lên</li>
                </ol>
              </h5>
              <h4>
                <strong>2.2.81.3. </strong
                ><span>
                  Đối với loại cược này, thời gian bù giờ sẽ không được tính để
                  xác định tỉ số thắng thua.
                </span>
              </h4>
              <h3>
                <strong> 2.2.82. </strong><span> Bàn thắng tiếp theo </span>
              </h3>
              <h4>
                <strong>2.2.82.1. </strong
                ><span>
                  Bàn thắng tiếp theo là loại cược dự đoán đội nào sẽ ghi bàn
                  thắng tiếp theo trong trận đấu.
                </span>
              </h4>
              <h3><strong> 2.2.83. </strong><span> Phạt đền </span></h3>
              <h4>
                <strong>2.2.83.1. </strong
                ><span>
                  Phạt đền nghĩa là cá cược để dự đoán liệu trận đấu có xảy ra
                  phạt đền hay không.
                </span>
              </h4>
              <h4>
                <strong>2.2.83.2. </strong
                ><span> Có các tuỳ chọn sau đây </span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>Có</li>
                  <li>Không</li>
                </ol>
              </h5>
              <h3>
                <strong>2.2.84.</strong
                ><span> Hiệp đấu có nhiều bàn thắng nhất</span>
              </h3>
              <h4>
                <strong>2.2.84.1.</strong
                ><span>
                  Hiệp đấu có nhiều bàn thắng nhất nghĩa là đặt cược dự đoán
                  hiệp nào của trận đấu sẽ có tổng số bàn thắng nhiều hơn hiệp
                  còn lại.
                </span>
              </h4>
              <h4>
                <strong>2.2.84.2.</strong><span> Có các tuỳ chọn sau:</span>
              </h4>
              <h5 class="style1">
                <ol>
                  <li>Hiệp 1</li>
                  <li>Hiệp 2</li>
                  <li>Hòa</li>
                </ol>
              </h5>
              <h4>
                <strong>2.2.84.3.</strong
                ><span>
                  Khoản cược sẽ không có giá trị nếu trận đấu bị huỷ bỏ ngoại
                  trừ trường hợp khoản cược đã được định đoạt trước.
                </span>
              </h4>
              <h4>
                <strong>2.2.84.4.</strong
                ><span> Đội nhà ghi được nhiều bàn thắng nhất ở hiệp nào</span>
              </h4>
              <h4>
                <strong>2.2.84.4.1.</strong
                ><span>
                  Đội nhà ghi được nhiều bàn thắng nhất ở hiệp nào nghĩa là đặt
                  cược dự đoán đội nhà sẽ có tổng số bàn thắng ở hiệp nào nhiều
                  hơn.</span
                >
              </h4>
              <h4>
                <strong>2.2.84.4.2.</strong
                ><span>
                  Khoản cược sẽ không có giá trị nếu trận đấu bị huỷ bỏ ngoại
                  trừ trường hợp khoản cược đã được định đoạt trước.
                </span>
              </h4>
              <h4>
                <strong>2.2.84.5.</strong
                ><span>
                  Đội khách ghi được nhiều bàn thắng nhất ở hiệp nào</span
                >
              </h4>
              <h4>
                <strong>2.2.84.5.1.</strong
                ><span>
                  Đội khách ghi được nhiều bàn thắng nhất ở hiệp nào nghĩa là
                  đặt cược dự đoán đội khách sẽ có tổng số bàn thắng ở hiệp nào
                  nhiều hơn.</span
                >
              </h4>
              <h4>
                <strong>2.2.84.5.2.</strong
                ><span>
                  Khoản cược sẽ không có giá trị nếu trận đấu bị huỷ bỏ ngoại
                  trừ trường hợp khoản cược đã được định đoạt trước.
                </span>
              </h4>
              <h3>
                <strong>2.2.85.</strong
                ><span> Tỉ số chính xác của hiệp 1/cả trận</span>
              </h3>
              <h4>
                <strong
                  >2.2.85.1. Tỉ số chính xác của hiệp 1/ cả trận nghĩa là đặt
                  cược dự đoán tỉ số chính xác sau khi kết thúc hiệp 1 </strong
                ><span
                  >và tỉ số cuối cùng chính xác sau khi kết thúc trận đấu.
                </span>
              </h4>
              <h4>
                <strong>2.2.85.2.</strong
                ><span
                  >Đối với kiểu cược này, “4+” nghĩa là tuỳ chọn cược mà theo
                  đó, tổng số bàn thắng được ghi sau thời gian thi đấu chính
                  thức bắt buộc phải là ít nhất bốn (4) bàn thì mới được xem là
                  thắng cược.</span
                >
              </h4>
              <h4>
                <strong>2.2.85.3.</strong
                ><span
                  >Khoản cược sẽ không có giá trị nếu trận đấu bị huỷ, trừ phi
                  việc thanh toán cá cược đã được quyết định từ trước.</span
                >
              </h4>

              <h1><a name="R3" id="R3"></a>3. Specific Event Betting Rules</h1>
              <!-- 3.1 -->
              <h2><a name="R31" id="R31"></a>3.1. Soccer Rules</h2>
              <h3>
                <strong>3.1.1.</strong
                ><span>
                  Where matches are scheduled for play other than under regular
                  time (e.g. Special time periods of play on various tournaments
                  or friendly matches) all bets will be settled at the end of
                  that scheduled time.
                </span>
              </h3>
              <h4>
                <strong>3.1.1.1.</strong
                ><span>
                  Where less than regular time is played, the operator reserves
                  the right to suspend settlement of all bets pending the
                  official result of that match.
                </span>
              </h4>
              <h4>
                <strong>3.1.1.2.</strong
                ><span>
                  Unless non regular time matches are expressly indicated on the
                  website prior to all soccer matches, bets taken on such
                  matches will be considered VOID.
                </span>
              </h4>
              <h3>
                <strong>3.1.2.</strong
                ><span>
                  If a soccer match is postponed, or is abandoned or suspended
                  and not resumed within 12 hoursfrom the scheduled commencement
                  time, then the match is void (irrespective of any official
                  decision to honor the result). The outcome of all bets on
                  abandoned/suspended matches is at the sole discretion of the
                  Company.
                </span>
              </h3>
              <h3>
                <strong>3.1.3.</strong
                ><span>
                  First Half Betting (1H) applies to first half play only. If a
                  match is abandoned during the first half then all bets are
                  considered void. If a match is abandoned during the second
                  half then all first half bets are still valid.
                </span>
              </h3>
              <h3>
                <strong>3.1.4.</strong
                ><span>
                  The Company provides information (e.g. neutral ground, Red
                  card, timer, statistical information, dates, commencement,
                  etc) as a service and accepts no liability whatsoever. It is
                  the customer’s responsibility to be aware of the correct
                  information for any match.
                </span>
              </h3>
              <h3>
                <strong>3.1.5.</strong
                ><span>
                  Unless otherwise stated, If a match is scheduled to be played
                  on neutral ground (but is played on non neutral ground or vice
                  versa) all bets considered valid.In the event of a change of
                  venue (home team plays away or vice versa), all bets on the
                  match will be considered void. Bets will also be considered
                  void if the home and away team names are wrongly stated in
                  reverse.
                </span>
              </h3>
              <h3>
                <strong>3.1.6.</strong
                ><span>
                  The score will be updated for Soccer live betting and Markets
                  shown during live trading refer to the score displayed at the
                  time the bet is placed. The timer and red card notices are
                  given for reference purposes only.
                </span>
              </h3>
              <h3>
                <strong>3.1.7.</strong
                ><span>
                  Đối với việc cá cược trực tiếp, trong suốt trò chơi, liên quan
                  đến hành động mà Công ty cho là nguy hiểm theo toàn quyền
                  quyết định của mình, theo đó điểm số, kết quả và phong độ của
                  một đội hoặc một người chơi có thể bị ảnh hưởng; hoặc bảo đảm
                  thay đổi lợi thế/giá cá cược hoặc Trung tâm cá cược hoặc Thông
                  tin Cá cược (“Lối chơi nguy hiểm”) Công ty có quyền đình chỉ
                  việc chấp nhận cá cược và có thể chấp nhận hoặc từ chối việc
                  đặt cược sau Lối chơi nguy hiểm. Tất cả các hành động khác
                  trong trò chơi được xem như Lối chơi an toàn và việc cá cược
                  sẽ tiếp tục được xem xét chấp nhận.
                </span>
              </h3>
              <h3>
                <strong>3.1.8.</strong
                ><span>
                  For live betting, bet placement is allowed up to the 90th
                  minute in addition to any injury time for full time for most
                  games (at the discretion of the Company). Any actions other
                  than the ones mentioned in this section 3.1.8, will be
                  considered Safe Play and thus all pending bets placed may be
                  considered for acceptance: Play in or around the penalty spot
                  area; a penalty; and free-kicks deemed by the Company as
                  dangerous (possibility of goal).
                </span>
              </h3>
              <h3>
                <strong>3.1.9.</strong
                ><span>
                  For live betting, all pending bets will be automatically
                  rejected as of the moment the referee ends the match in half
                  time and/or full time.
                </span>
              </h3>
              <h3>
                <strong>3.1.10.</strong
                ><span>
                  Đối với cược trực tiếp nhưng không tính 2 phút cuối của Cược
                  tài/xỉu đặc biệt 15 phút (Specific 15-Minutes O/U), Cược
                  tài/xỉu đặc biệt 10 phút (Specific 10-Minutes O/U) và Cược
                  chấp đặc biệt 15 phút (Specific 15-Minutes HDP), các khoản
                  cược treo sẽ bị bác bỏ khi bàn thắng được ghi; tương tự, các
                  khoản cược treo sẽ được chấp nhận tại khu vực an toàn được xem
                  xét khi quả đá phạt đền bị bỏ lỡ.
                </span>
              </h3>
              <h3>
                <strong>3.1.11.</strong
                ><span>
                  For live fantasy match betting, bet placement is allowed up to
                  the 90th minute in addition to any injury time for full time
                  for most games (at the discretion of the Company). From kick
                  off time (00:00) of play onwards until prior to the end of
                  regulation time (90th minute), whichever is applicable in a
                  game, any actions other than the ones mentioned herein below,
                  will be considered Safe Play and thus all pending bets placed
                  may be considered for acceptance: Play in or around the
                  penalty spot area; a penalty; and free-kicks deemed by the
                  Company as dangerous (possibility of goal).
                </span>
              </h3>

              <h2><a name="R32" id="R32"></a>3.2. Basketball</h2>
              <h3>
                <strong>3.2.1.</strong
                ><span>
                  All Full-Time markets, including Live betting, will be settled
                  on the final result including overtime (unless otherwise
                  stated).
                </span>
              </h3>
              <h3>
                <strong>3.2.2.</strong
                ><span>
                  If a match does not start on the scheduled start date then all
                  bets will be void (unless stated otherwise).
                </span>
              </h3>
              <h3>
                <strong>3.2.3.</strong
                ><span>
                  If a match starts but is suspended or abandoned within twelve
                  hours of the scheduled start time then Full-Time bets are
                  still considered valid if at least forty-three (43) minutes of
                  an NBA match, or thirty-five (35) minutes of any other
                  basketball match have been completed. Bets will also be
                  considered valid if an official result is declared by the
                  relevant governing body. Otherwise bets on suspended or
                  abandoned matches will be void, except for those on markets
                  which have been unconditionally determined.
                </span>
              </h3>
              <h3>
                <strong>3.2.4.</strong
                ><span>
                  First-Half results are the sum of the First and Second
                  Quarters. Second-Half results are the sum of the Third and
                  Fourth Quarters, including any Overtime that may be played.
                </span>
              </h3>
              <h3>
                <strong>3.2.5.</strong
                ><span>
                  Fourth Quarter results do not include any Overtime that may be
                  played.
                </span>
              </h3>
              <h3>
                <strong>3.2.6.</strong
                ><span>
                  If a match is suspended or abandoned then bets placed on
                  unfinished Halves or Quarters will be considered void. If the
                  designated Halves or Quarters are completed then bets will be
                  valid.
                </span>
              </h3>
              <h3>
                <strong>3.2.7.</strong
                ><span>
                  The score will not be updated for basketball live betting and
                  the handicap shown during live trading refers to the score at
                  the start of the match i.e. 0-0.The time and scores displayed
                  are for reference purposes only.
                </span>
              </h3>
              <h3>
                <strong>3.2.8.</strong
                ><span>
                  Which Team to Score the First Basket markets are settled on
                  the team scoring the first points. If a match is suspended or
                  abandoned after the first points have been scored then bets
                  are still valid.
                </span>
              </h3>
              <h3>
                <strong>3.2.9.</strong
                ><span>
                  Which Team to Score the Last Basket markets are settled on the
                  team scoring the last points of a match (including overtime)
                  or specified Half/Quarter (not including overtime). If a match
                  is suspended or abandoned then all bets will be void, except
                  for those on markets which have been unconditionally
                  determined.
                </span>
              </h3>
              <h3>
                <strong>3.2.10.</strong
                ><span>
                  Special markets (including the number of Points, Rebounds,
                  Assists, Three-Points, Free-Throws etc) are valid if both
                  players participate in the match. If one or both players do
                  not take part in the match then all bets are void. Results for
                  special markets include Overtime, unless otherwise stated. All
                  results are taken when the official result is declared at the
                  end of the match by the governing body (NBA.com, FIBA.com etc)
                  and any subsequent changes to the statistics are not valid for
                  betting purposes.
                </span>
              </h3>
              <h3>
                <strong>3.2.11.</strong
                ><span>
                  The Home/Away venue for NCAA matches are provided as a
                  reference only.
                </span>
              </h3>
              <h3>
                <strong>3.2.12.</strong
                ><span>
                  Fantasy Basketball markets (including live markets) are
                  pairings or combinations of teams from different matches. The
                  matches involving both teams must tip-off on the same day,
                  otherwise bets are considered void. The venues for Fantasy
                  Basketball markets are strictly for reference purposes only.
                </span>
              </h3>
              <h3>
                <strong>3.2.13.</strong
                ><span>
                  To win most Quarters markets are settled on the team that wins
                  the most number of quarters during a basketball match. If the
                  result of a specific quarter is a tie then neither team wins
                  that quarter. Overtime is NOT included in this market. If a
                  match is suspended or abandoned then all bets will be void.
                </span>
              </h3>

              <h2><a name="R33" id="R33"></a>3.3. American Football</h2>
              <h3>
                <strong>3.3.1. </strong
                ><span>
                  All Full-Time markets, including Live betting, will be settled
                  on the final result including overtime (unless otherwise
                  stated in these rules).
                </span>
              </h3>
              <h3>
                <strong>3.3.2.</strong
                ><span>
                  If a match does not start on the scheduled start date then all
                  bets will be void (unless stated otherwise).
                </span>
              </h3>
              <h3>
                <strong>3.3.3.</strong
                ><span>
                  If a match starts but is suspended or abandoned within twelve
                  hours of the scheduled start time then Full-Time bets are
                  still considered valid if at fifty-five (55) minutes of the
                  match has been completed. Bets will also be considered valid
                  if an official result is declared by the relevant governing
                  body. Otherwise bets on suspended or abandoned matches will be
                  void, except for those on markets which have been
                  unconditionally determined.
                </span>
              </h3>
              <h3>
                <strong>3.3.4.</strong
                ><span>
                  First-Half results are the sum of the First and Second
                  Quarters. Second-Half results are the sum of the Third and
                  Fourth Quarters, including any Overtime that may be played.
                </span>
              </h3>
              <h3>
                <strong>3.3.5.</strong
                ><span>
                  Fourth Quarter results do not include any Overtime that may be
                  played.
                </span>
              </h3>
              <h3>
                <strong>3.3.6.</strong
                ><span>
                  If a match is suspended or abandoned then bets placed on
                  unfinished Halves or Quarters will be considered void. If the
                  designated Halves or Quarters are completed then bets will be
                  valid.
                </span>
              </h3>
              <h3>
                <strong>3.3.7.</strong
                ><span>
                  The score will be updated for American Football live betting
                  and markets shown during live trading refer to the score
                  displayed at the time the bet is placed.
                </span>
              </h3>
              <h3>
                <strong>3.3.8.</strong
                ><span>
                  Which Team to Score the First Points markets are settled on
                  the team scoring the first points. If a match is suspended or
                  abandoned after the first points have been scored then bets
                  are still valid.
                </span>
              </h3>
              <h3>
                <strong>3.3.9.</strong
                ><span>
                  Which Team to Score the Last Points markets are settled on the
                  team scoring the last points of a match (including overtime).
                  If a match is suspended or abandoned then all bets will be
                  void.
                </span>
              </h3>
              <h3>
                <strong>3.3.10.</strong
                ><span>
                  The Home/Away venue for NCAA matches are provided as a
                  reference only.
                </span>
              </h3>
              <h3>
                <strong>3.3.11.</strong
                ><span>
                  Đối với việc cá cược trực tiếp, trong suốt trò chơi, liên quan
                  đến hành động mà Công ty cho là nguy hiểm theo toàn quyền
                  quyết định của mình, theo đó điểm số, kết quả và phong độ của
                  một đội hoặc một người chơi có thể bị ảnh hưởng; hoặc bảo đảm
                  thay đổi lợi thế/giá cá cược hoặc Trung tâm cá cược hoặc Thông
                  tin Cá cược (“Lối chơi nguy hiểm”) Công ty có quyền đình chỉ
                  việc chấp nhận cá cược và có thể chấp nhận hoặc từ chối việc
                  đặt cược sau Lối chơi nguy hiểm. Tất cả các hành động khác
                  trong trò chơi được xem như Lối chơi an toàn và việc cá cược
                  sẽ tiếp tục được xem xét chấp nhận.
                </span>
              </h3>

              <h2><a name="R34" id="R34"></a>3.4. Baseball</h2>
              <h3>
                <strong>3.4.1.</strong
                ><span>
                  Pitcher names are strictly for reference purposes only. All
                  baseball bets will still be considered valid regardless of the
                  starting pitchers.
                </span>
              </h3>
              <h3>
                <strong>3.4.2.</strong
                ><span>
                  All Full-Time markets, including Live betting, will be settled
                  on the final result including extra innings (unless otherwise
                  stated in these rules). In Japanese baseball a tie can be
                  declared and in this case moneyline bets will be refunded.
                </span>
              </h3>
              <h3>
                <strong>3.4.3.</strong
                ><span>
                  If a game does not start on the scheduled start date then all
                  bets will be void (unless stated otherwise).
                </span>
              </h3>
              <h3>
                <strong>3.4.4.</strong
                ><span>
                  For baseball bets to be considered valid then the game must go
                  9 (nine) innings (or 8.5 innings if the home team is leading).
                  If a game is suspended and completed on a subsequent day then
                  all bets (except those which have been unconditionally
                  determined) will be considered void.
                </span>
              </h3>
              <h3>
                <strong>3.4.5.</strong
                ><span>
                  If a game is suspended or called in extra innings then the
                  score will be determined after the last full inning, unless
                  the home team scores to tie or lead in the bottom half of the
                  inning, in which case the score is determined at the time the
                  game is called.
                </span>
              </h3>
              <h3>
                <strong>3.4.6.</strong
                ><span>
                  First 5 Innings bets are settled on the result at the end of
                  five innings. If five innings are not completed, for whatever
                  reason, then all bets will be considered void.
                </span>
              </h3>
              <h3>
                <strong>3.4.7.</strong
                ><span>
                  The score will be updated for Baseball live betting and
                  markets shown during live trading refer to the score displayed
                  at the time the bet is placed.
                </span>
              </h3>
              <h3>
                <strong>3.4.8.</strong
                ><span>
                  World Baseball Classic games can end early if a team is
                  leading by ten or more runs after the opposing team has batted
                  in at least seven innings, or if a team is leading by more
                  than fifteen runs after the opposing team has batted in at
                  least five innings. Should this happen then all bets will be
                  considered valid.
                </span>
              </h3>
              <h3>
                <strong>3.4.9.</strong
                ><span>
                  International Baseball games (e.g. Olympic matches) may be
                  called early and for bets to be valid, 6.5 innings must have
                  been completed.
                </span>
              </h3>
              <h3>
                <strong>3.4.10.</strong
                ><span>
                  Đối với việc cá cược trực tiếp, trong suốt trò chơi, liên quan
                  đến hành động mà Công ty cho là nguy hiểm theo toàn quyền
                  quyết định của mình, theo đó điểm số, kết quả và phong độ của
                  một đội hoặc một người chơi có thể bị ảnh hưởng; hoặc bảo đảm
                  thay đổi lợi thế/giá cá cược hoặc Trung tâm cá cược hoặc Thông
                  tin Cá cược (“Lối chơi nguy hiểm”) Công ty có quyền đình chỉ
                  việc chấp nhận cá cược và có thể chấp nhận hoặc từ chối việc
                  đặt cược sau Lối chơi nguy hiểm. Tất cả các hành động khác
                  trong trò chơi được xem như Lối chơi an toàn và việc cá cược
                  sẽ tiếp tục được xem xét chấp nhận.
                </span>
              </h3>

              <h2><a name="R35" id="R35"></a>3.5. Ice Hockey</h2>
              <h3>
                <strong>3.5.1</strong>.<span
                  >Full-Time markets may be offered as either "Regular Time
                  Only" or "Including Overtime and Penalty Shootouts" or both.
                  Clients should always refer to the market title. For matches
                  that are decided by a penalty shoot-out then the winning team
                  will have one goal added to their score to determine the final
                  result.</span
                >
              </h3>
              <h3>
                <strong>3.5.2.</strong>
                <span
                  >If a match does not start on the scheduled start date then
                  all bets will be void (unless stated otherwise).
                </span>
              </h3>
              <h3>
                <strong>3.5.3.</strong>
                <span
                  >If a match starts but is suspended or abandoned within twelve
                  hours of the scheduled start time then Full-Time bets are
                  still considered valid if at fifty-five (55) minutes of the
                  match has been completed. Bets will also be considered valid
                  if an official result is declared by the relevant governing
                  body. Otherwise bets on suspended or abandoned matches will be
                  void, except for those on markets which have been
                  unconditionally determined.
                </span>
              </h3>
              <h3>
                <strong>3.5.4.</strong>
                <span
                  >For Period betting, a period must be completed for bets to be
                  considered valid.
                </span>
              </h3>
              <h3>
                <strong>3.5.5.</strong>
                <span
                  >Third period results do not include any overtime or
                  shoot-outs that may be played.
                </span>
              </h3>
              <h3>
                <strong>3.5.6.</strong>
                <span
                  >Ice Hockey Live Betting is settled on the result at the end
                  of normal time (three periods). Overtime and shoot-out results
                  do not count.
                </span>
              </h3>
              <h3>
                <strong>3.5.7.</strong>
                <span
                  >The score will be updated for Ice Hockey live betting and
                  markets shown during live trading refer to the score displayed
                  at the time the bet is placed.</span
                >
              </h3>
              <h3>
                <strong>3.5.8.</strong>
                <span
                  >Đối với việc cá cược trực tiếp, trong suốt trò chơi, liên
                  quan đến hành động mà Công ty cho là nguy hiểm theo toàn quyền
                  quyết định của mình, theo đó điểm số, kết quả và phong độ của
                  một đội hoặc một người chơi có thể bị ảnh hưởng; hoặc bảo đảm
                  thay đổi lợi thế/giá cá cược hoặc Trung tâm cá cược hoặc Thông
                  tin Cá cược (“Lối chơi nguy hiểm”) Công ty có quyền đình chỉ
                  việc chấp nhận cá cược và có thể chấp nhận hoặc từ chối việc
                  đặt cược sau Lối chơi nguy hiểm. Tất cả các hành động khác
                  trong trò chơi được xem như Lối chơi an toàn và việc cá cược
                  sẽ tiếp tục được xem xét chấp nhận.</span
                >
              </h3>

              <h2><a name="R36" id="R36"></a>3.6. Tennis</h2>
              <h3>
                <strong>3.6.1.</strong>
                <span
                  >Moneyline markets refer to the winner of the match or
                  specified set. Handicap markets are based either on sets or
                  games (please refer to market title); Over/Under and Odd/Even
                  markets based on games (unless otherwise stated).
                </span>
              </h3>
              <h3>
                <strong>3.6.2.</strong>
                <span
                  >If a player does not start a tournament or match then all
                  bets on that player will be void.</span
                >
              </h3>
              <h3>
                <strong>3.6.3.</strong
                ><span>
                  If a player (or pairing) retires or is disqualified during a
                  match then all bets will be void, except for those on markets
                  which have been unconditionally determined.
                </span>
              </h3>
              <h3>
                <strong>3.6.4.</strong
                ><span>
                  If a match is postponed or suspended then all bets are still
                  considered valid if the match is completed.
                </span>
              </h3>
              <h3>
                <strong>3.6.5.</strong
                ><span>
                  All bets will still be considered valid regardless of any
                  change of venue or court surface (including moving matches
                  from outdoor to indoor courts or vice versa).
                </span>
              </h3>
              <h3>
                <strong>3.6.6.</strong
                ><span>
                  If the scheduled number of sets required to win a match is
                  changed from that originally scheduled then all bets will be
                  void, except for those that have been unconditionally
                  determined.
                </span>
              </h3>
              <h3>
                <strong>3.6.7.</strong
                ><span>
                  First Set Winner (Second, Third Set Winner etc) refers to the
                  result of the specified set. All bets will be considered void
                  if the specified set is not completed.
                </span>
              </h3>
              <h3>
                <strong>3.6.8.</strong
                ><span>
                  Tennis Live Betting is settled on the result of the match (or
                  specified set). The score will not be updated for tennis live
                  betting.
                </span>
              </h3>
              <h3>
                <strong>3.6.9.</strong
                ><span>
                  Most Aces (double Faults etc) markets are settled according to
                  the official match statistics. If a player should retire or is
                  disqualified before the match is completed then all bets will
                  be void.
                </span>
              </h3>
              <h3>
                <strong>3.6.10.</strong
                ><span>
                  First Ace (Double Fault etc) markets are settled according to
                  the official match statistics. If the first ace (double fault
                  etc) market has been decided, then all bets will still be
                  considered valid even if the match is not completed due to
                  retirement or disqualification. If there is no ace (double
                  fault etc) at the time of the retirement/disqualification then
                  all bets will be considered void.
                </span>
              </h3>
              <h3>
                <strong>3.6.11.</strong
                ><span>
                  Game Winner markets refer to the winner of a specific game,
                  e.g. Set 1 Game 1; Set 1 Game 2 etc. Should a set go to a
                  tie-break then the market will be designated as Set 1 TB; Set
                  2 TB etc. Should there be a retirement/disqualification during
                  an incomplete game then all bets will be considered void. If a
                  game is completed by the umpire awarding a “penalty game” then
                  all bets on that game will be considered void (though if the
                  game is completed by a “penalty point” then all bets are still
                  valid). If a game is suspended then all bets are still
                  considered valid if the game is completed.
                </span>
              </h3>
              <h3>
                <strong>3.6.12.</strong
                ><span>
                  Cược điểm số đúng của trận đấu nghĩa là cược số set chính xác
                  mà mỗi người chơi thắng trong một trận đấu. Nếu trận đấu không
                  hoàn thành thì tất cả các khoản cược sẽ vô hiệu. Nếu số set đã
                  lên lịch cần có để chiến thắng trận đấu bị thay đổi thì tất cả
                  các khoản cược sẽ vô hiệu.
                </span>
              </h3>
              <h3>
                <strong>3.6.13.</strong
                ><span>
                  Cược điểm số đúng của set X nghĩa là cược số ván chính xác mà
                  mỗi người chơi thắng trong một trận đấu. Nếu set đấu không
                  hoàn thành thì tất cả các khoản cược sẽ vô hiệu. Nếu số ván đã
                  lên lịch cần có để chiến thắng trận đấu bị thay đổi thì tất cả
                  các khoản cược sẽ vô hiệu.
                </span>
              </h3>

              <h2><a name="R37" id="R37"></a>3.7. Badminton</h2>
              <h3>
                <strong>3.7.1.</strong
                ><span>
                  Moneyline markets refer to the winner of the match or
                  specified set. Handicap markets are based on either sets or
                  points (please refer to market title); Over/Under and Odd/Even
                  markets based on points (unless otherwise stated).
                </span>
              </h3>
              <h3>
                <strong>3.7.2.</strong
                ><span>
                  If a player does not start a tournament or match then all bets
                  on that player will be void.
                </span>
              </h3>
              <h3>
                <strong>3.7.3.</strong
                ><span>
                  If a player (or pairing) retires or is disqualified during a
                  match then all bets will be void, except for those on markets
                  which have been unconditionally determined.
                </span>
              </h3>
              <h3>
                <strong>3.7.4.</strong
                ><span>
                  If a match is postponed or suspended then all bets are still
                  considered valid if the match resumes before the twelve hour
                  expiration.
                </span>
              </h3>
              <h3>
                <strong>3.7.5.</strong
                ><span>
                  First Set Winner (Second, Third Set Winner etc) refers to the
                  result of the specified set. All bets will be considered void
                  if the specified set is not completed.
                </span>
              </h3>
              <h3>
                <strong>3.7.6. </strong
                ><span>
                  Badminton Live Betting is settled on the result of the match
                  (or specified set). The score will not be updated for
                  badminton live betting.
                </span>
              </h3>

              <h2><a name="R38" id="R38"></a>3.8. Table Tennis</h2>
              <h3>
                <strong>3.8.1.</strong
                ><span>
                  Moneyline markets refer to the winner of the match or
                  specified set. Handicap markets are based on either sets or
                  points (please refer to market title); Over/Under and Odd/Even
                  markets based on points (unless otherwise stated).
                </span>
              </h3>
              <h3>
                <strong>3.8.2.</strong
                ><span>
                  If a player does not start a tournament or match then all bets
                  on that player will be void.
                </span>
              </h3>
              <h3>
                <strong>3.8.3.</strong
                ><span>
                  If a player (or pairing) retires or is disqualified during a
                  match then all bets will be void, except for those on markets
                  which have been unconditionally determined.
                </span>
              </h3>
              <h3>
                <strong>3.8.4. </strong
                ><span>
                  If a match is postponed or suspended then all bets are still
                  considered valid if the match resumes before the twelve hour
                  expiration.
                </span>
              </h3>
              <h3>
                <strong>3.8.5.</strong
                ><span>
                  First Set Winner (Second, Third Set Winner etc) refers to the
                  result of the specified set. All bets will be considered void
                  if the specified set is not completed.
                </span>
              </h3>
              <h3>
                <strong>3.8.6.</strong>
                <span
                  >Table Tennis Live Betting is settled on the result of the
                  match (or specified set). The score will not be updated for
                  table tennis live betting.
                </span>
              </h3>

              <h2>
                <a name="R39" id="R39"></a>3.9. Volleyball &amp; Beach
                Volleyball
              </h2>
              <h3>
                <strong>3.9.1.</strong
                ><span>
                  Moneyline markets refer to the winner of the match or
                  specified set. Handicap markets are based on either sets or
                  points (please refer to market title); Over/Under and Odd/Even
                  markets based on points (unless otherwise stated).
                </span>
              </h3>
              <h3>
                <strong>3.9.2.</strong>
                <span
                  >If a team does not start a tournament or match then all bets
                  on that team will be void.</span
                >
              </h3>
              <h3>
                <strong>3.9.3. </strong
                ><span>
                  If a team retires or is disqualified during a match then all
                  bets will be void, except for those on markets which have been
                  unconditionally determined.
                </span>
              </h3>
              <h3>
                <strong>3.9.4.</strong
                ><span>
                  If a match is postponed or suspended then all bets are still
                  considered valid if the match resumes before the twelve hour
                  expiration.
                </span>
              </h3>
              <h3>
                <strong>3.9.5.</strong
                ><span>
                  First Set Winner (Second, Third Set Winner etc) refers to the
                  result of the specified set. All bets will be considered void
                  if the specified set is not completed.
                </span>
              </h3>
              <h3>
                <strong>3.9.6.</strong
                ><span>
                  Volleyball Live Betting is settled on the result of the match
                  (or specified set). The score will not be updated for
                  volleyball live betting.
                </span>
              </h3>

              <h2><a name="R310" id="R310"></a>3.10. Field Hockey</h2>
              <h3>
                <strong>3.10.1.</strong
                ><span>
                  All Full-Time markets, including Live betting, will be settled
                  on the final result at the end of normal time. Extra-time,
                  golden goal and penalty shoot-outs do not count for full-time
                  markets. (Specific markets for extra-time (ET) and penalty
                  shootouts (PEN) may be offered for betting.)
                </span>
              </h3>
              <h3>
                <strong>3.10.2.</strong
                ><span>
                  If a match is postponed, suspended or abandoned and not
                  resumed within twelve hours of the scheduled start time then
                  all bets are considered void, except for those on markets
                  which have been unconditionally determined. Bets will also be
                  considered valid if an official result is declared by the
                  relevant governing body.
                </span>
              </h3>
              <h3>
                <strong>3.10.3. </strong
                ><span>
                  First Half markets refer to the result of the first half. All
                  bets will be considered void if the specified half is not
                  completed.
                </span>
              </h3>
              <h3>
                <strong>3.10.4. </strong
                ><span>
                  Field Hockey Live Betting is settled on the result of the
                  match at the end of normal time.
                </span>
              </h3>
              <h3>
                <strong>3.10.5.</strong
                ><span>
                  The score will be updated for Field Hockey live betting and
                  markets shown during live trading refer to the score displayed
                  at the time the bet is placed.
                </span>
              </h3>
              <h3>
                <strong>3.10.6.</strong
                ><span>
                  Đối với việc cá cược trực tiếp, trong suốt trò chơi, liên quan
                  đến hành động mà Công ty cho là nguy hiểm theo toàn quyền
                  quyết định của mình, theo đó điểm số, kết quả và phong độ của
                  một đội hoặc một người chơi có thể bị ảnh hưởng; hoặc bảo đảm
                  thay đổi lợi thế/giá cá cược hoặc Trung tâm cá cược hoặc Thông
                  tin Cá cược (“Lối chơi nguy hiểm”) Công ty có quyền đình chỉ
                  việc chấp nhận cá cược và có thể chấp nhận hoặc từ chối việc
                  đặt cược sau Lối chơi nguy hiểm. Tất cả các hành động khác
                  trong trò chơi được xem như Lối chơi an toàn và việc cá cược
                  sẽ tiếp tục được xem xét chấp nhận.
                </span>
              </h3>

              <h2><a name="R311" id="R311"></a>3.11. Snooker &amp; Pool</h2>
              <h3>
                <strong>3.11.1.</strong
                ><span>
                  Moneyline and handicap markets refer to the winner of the
                  match. Over/Under and Odd/Even markets are based on the number
                  of frames/racks (unless otherwise stated).
                </span>
              </h3>
              <h3>
                <strong>3.11.2.</strong
                ><span>
                  If a player does not start a tournament or match then all bets
                  on that player will be void.
                </span>
              </h3>
              <h3>
                <strong>3.11.3.</strong
                ><span>
                  If a match starts but is not completed then all bets are
                  considered void, except for those on markets which have been
                  unconditionally determined.
                </span>
              </h3>
              <h3>
                <strong>3.11.4.</strong
                ><span>
                  Snooker &amp; Pool Live Betting is settled on the result of
                  the match (or specified frame/rack). The score will not be
                  updated for snooker and pool live betting.
                </span>
              </h3>
              <h3>
                <strong>3.11.5.</strong
                ><span>
                  For individual frame markets Over/Under and Odd/Even markets
                  refer to the total number of points scored in that frame.
                </span>
              </h3>
              <h3>
                <strong>3.11.6.</strong
                ><span>
                  Cược Người chơi nào đánh quả bóng đỏ đầu tiên vào lỗ? muốn nói
                  đến cược người chơi đánh quả bóng đỏ hợp lệ đầu tiên vào lỗ
                  trong một trận đấu hoặc ván đấu xác định, tức là đánh quả bóng
                  đỏ vào lỗ trong khi không phạm lỗi. (Nếu đặt lại khuôn lại cho
                  ván đấu thì tất cả các cược sẽ vẫn có giá trị trên khuôn gốc).
                </span>
              </h3>
              <h3>
                <strong>3.11.7.</strong
                ><span>
                  Cược Người chơi nào đạt tới 30 điểm? muốn nói đến người chơi
                  đầu tiên ghi được ba mươi điểm trong một ván đấu xác định.
                  (Nếu đặt lại khuôn cho ván đấu thì tất cả các cược sẽ vẫn có
                  giá trị trên khuôn gốc).
                </span>
              </h3>
              <h3>
                <strong>3.11.8.</strong
                ><span>
                  Cược break cao nhất muốn nói đến số điểm ghi được trong một
                  “break” bởi một hoặc nhiều người chơi trong một ván đấu, trận
                  đấu hoặc giải đấu xác định.
                </span>
              </h3>
              <h3>
                <strong>3.11.9.</strong
                ><span>
                  Cược tổng số century muốn nói đến số “break” đạt 100 điểm trở
                  lên ghi được trong trận đấu hoặc giải đấu xác định.
                </span>
              </h3>

              <h2><a name="R312" id="R312"></a>3.12. Golf</h2>
              <h3>
                <strong>3.12.1.</strong
                ><span>
                  All golf bets are settled according to the official tournament
                  results.
                </span>
              </h3>
              <h3>
                <strong>3.12.2.</strong
                ><span>
                  If a player does not start a tournament or specific round then
                  all bets on that player will be void. If a golfer retires or
                  is disqualified during a tournament or specified round then
                  all bets on that golfer will be lost.
                </span>
              </h3>
              <h3>
                <strong>3.12.3.</strong
                ><span>
                  If a tournament or specified round is delayed or suspended
                  then all bets will remain valid for forty-eight hours after
                  the scheduled finish time.
                </span>
              </h3>
              <h3>
                <strong>3.12.4.</strong
                ><span> Outright (Tournament Winner) </span>
              </h3>
              <h4>
                <strong>3.12.4.1.</strong
                ><span>
                  “Any Other Player” or “The Field” refers to all golfers not
                  named in the outright market.
                </span>
              </h4>
              <h4>
                <strong>3.12.4.2.</strong
                ><span>
                  Outright markets are settled on the tournament winner and the
                  result of any play-off is taken into account.
                </span>
              </h4>
              <h4>
                <strong>3.12.4.3.</strong
                ><span>
                  If a tournament is shortened by the officials (i.e. the
                  scheduled number of holes is not completed) then all bets are
                  still valid if an official winner is declared. However, if
                  there is no further play after a bet has been placed then that
                  bet will be void. If no official winner is declared then all
                  bets will be considered void.
                </span>
              </h4>
              <h3>
                <strong>3.12.5.</strong><span> Tournament matchups </span>
              </h3>
              <h4>
                <strong>3.12.5.1.</strong
                ><span>
                  Tournament matchups refer to the golfer with the lowest score
                  during a tournament (usually 72 holes). If the number of holes
                  played is reduced from that scheduled then bets are still
                  valid if an official tournament result is declared.
                </span>
              </h4>
              <h4>
                <strong>3.12.5.2.</strong
                ><span>
                  Both golfers must tee off for bets to be valid. The golfer who
                  completes the most holes (excluding play-offs) will be the
                  winner. If the golfers complete the same number of holes
                  (again excluding play-offs) then the player with the lowest
                  score is the winner.
                </span>
              </h4>
              <h4>
                <strong>3.12.5.3.</strong
                ><span>
                  If a golfer withdraws or is disqualified after teeing off then
                  the other player is deemed the winner. However, if a player
                  withdraws or is disqualified after the other player has
                  already missed the cut, then the golfer playing the most holes
                  is still declared the winner.
                </span>
              </h4>
              <h4>
                <strong>3.12.5.4.</strong
                ><span>
                  If both golfers withdraw or are disqualified during the same
                  round then all bets will be void, irrespective of how many
                  holes each player has completed.
                </span>
              </h4>
              <h3><strong>3.12.6.</strong><span> Round matchups </span></h3>
              <h4>
                <strong>3.12.6.1.</strong
                ><span>
                  Round match ups refer to the golfer with the lowest score over
                  a specified 18 holes. Play-offs are not included.
                </span>
              </h4>
              <h4>
                <strong>3.12.6.2.</strong
                ><span>
                  Both golfers must tee off for bets to be valid. If a golfer
                  withdraws or is disqualified after teeing off then the other
                  player is deemed the winner. However, if a disqualified player
                  has already started the next round then the original scores
                  are still valid.
                </span>
              </h4>
              <h3><strong>3.12.7.</strong><span> Round Over/Under </span></h3>
              <h4>
                <strong>3.12.7.1.</strong
                ><span>
                  Round Over/Under markets refer to a golfer (or golfers) score
                  over a specified 18 holes. Play-offs are not included.
                </span>
              </h4>
              <h4>
                <strong>3.12.7.2.</strong
                ><span>
                  A golfer must tee off for bets to be valid. If a golfer fails
                  to complete the specified 18 holes then all bets are
                  considered void.
                </span>
              </h4>
              <h3>
                <strong>3.12.8.</strong
                ><span> Individual Hole Over/Under </span>
              </h3>
              <h4>
                <strong>3.12.8.1.</strong
                ><span>
                  Individual hole Over/Under markets refer to a golfer (or
                  golfers) score over a specific individual hole of a specified
                  round.
                </span>
              </h4>
              <h4>
                <strong>3.12.8.2.</strong
                ><span>
                  If the hole is not completed by the golfer (or golfers) then
                  all bets will be void.
                </span>
              </h4>

              <h2><a name="R313" id="R313"></a>3.13. Motor Sports</h2>
              <h3>
                <strong>3.13.1.</strong
                ><span>
                  Motor Sports include, without limitation, Formula One, Moto GP
                  and A1GP.
                </span>
              </h3>
              <h3>
                <strong>3.13.2.</strong
                ><span>
                  Podium positions will count as the official result, regardless
                  of any subsequent disqualification or amendment to the race
                  result. If there is no podium ceremony then the result is that
                  declared by the relevant governing body immediately after the
                  end of the race. Qualifying markets are based on the final
                  qualifying positions declared by the governing body
                  immediately after the end of qualifying.
                </span>
              </h3>
              <h3>
                <strong>3.13.3.</strong
                ><span>
                  If the scheduled venue is changed then all bets will be
                  considered void.
                </span>
              </h3>
              <h3>
                <strong>3.13.4.</strong
                ><span>
                  If a race is postponed then all bets are still considered
                  valid if the race is started before the forty-eight hour
                  expiration, otherwise all bets will be considered void. If a
                  race starts but is not completed then all bets will remain
                  valid if an official result is declared. If no official result
                  is declared then all bets will be void.
                </span>
              </h3>
              <h3>
                <strong>3.13.5.</strong
                ><span>
                  A motor race is deemed to have started when the signal is
                  given to start the warm-up lap. If a competitor is not ready
                  to start from the grid (or pit lane) then bets on that racer
                  will be void. If a competitor fails to start the official
                  qualifying session then pole position bets on that racer will
                  be considered void.
                </span>
              </h3>
              <h3>
                <strong>3.13.6. </strong
                ><span>
                  In Head to Head markets both racers must start the race (or
                  official qualifying session) for bets to be valid. The racer
                  with the better finishing position will be declared the
                  winner. If both racers fail to finish then the competitor
                  completing the most laps is deemed the winner. If the same
                  number of laps are recorded for both racers then all bets will
                  be void, unless official finishing positions are declared.
                </span>
              </h3>
              <h3>
                <strong>3.13.7.</strong
                ><span>
                  Fastest Lap markets are decided on the racer or team with the
                  fastest lap time during the race.
                </span>
              </h3>
              <h3>
                <strong>3.13.8.</strong
                ><span>
                  Number of Classified Finishers markets are decided on the
                  official result declared by the governing body.
                </span>
              </h3>
              <h3>
                <strong>3.13.9.</strong
                ><span>
                  Motor Sports Live Betting is settled on the result of the
                  official result of the specified race.
                </span>
              </h3>
              <h3>
                <strong>3.13.10.</strong
                ><span>
                  Odd/Even markets are based on the final finishing positions as
                  declared by the governing body. For example if Driver A
                  finishes in 1st Position then the result is odd; if Driver B
                  finishes in 2nd Position then the result is even, etc. If a
                  driver is not given an official classification then bets will
                  be considered void and will be refunded.
                </span>
              </h3>
              <h3>
                <strong>3.13.11.</strong
                ><span>
                  Winning Margin markets are based on the time difference (in
                  seconds) between the selected drivers as declared by the
                  governing body. Over means that the time difference will be
                  greater than the handicap, while Under means that the time
                  difference will be less than the handicap offered. Should the
                  time difference be exactly the same as the handicap then bets
                  will be considered void and will be refunded.
                </span>
              </h3>

              <h2><a name="R314" id="R314"></a>3.14. Handball</h2>
              <h3>
                <strong>3.14.1.</strong
                ><span>
                  All Full-Time markets, including Live betting, will be settled
                  on the final result at the end of normal time. Extra-time and
                  penalty shoot-outs do not count for full-time markets.
                </span>
              </h3>
              <h3>
                <strong>3.14.2.</strong
                ><span>
                  If a match is postponed, suspended or abandoned and not
                  resumed within twelve hours of the scheduled start time then
                  all bets are considered void, except for those on markets
                  which have been unconditionally determined. Bets will also be
                  considered valid if an official result is declared by the
                  relevant governing body.
                </span>
              </h3>
              <h3>
                <strong>3.14.3.</strong
                ><span>
                  Handball Live Betting is settled on the result of the match at
                  the end of nor </span
                >mal time.
              </h3>
              <h3>
                <strong>3.14.4.</strong
                ><span>
                  The score will not be updated for handball live betting.
                </span>
              </h3>

              <h2><a name="R315" id="R315"></a>3.15. Water Polo</h2>
              <h3>
                <strong>3.15.1.</strong
                ><span>
                  All Full-Time markets, including Live betting, will be settled
                  on the final result at the end of normal time (four quarters).
                  Extra-time and shoot-outs do not count for full-time markets.
                </span>
              </h3>
              <h3>
                <strong>3.15.2.</strong
                ><span>
                  If a match is postponed, suspended or abandoned and not
                  resumed within twelve hours of the scheduled start time then
                  all bets are considered void, except for those on markets
                  which have been unconditionally determined. Bets will also be
                  considered valid if an official result is declared by the
                  relevant governing body.
                </span>
              </h3>
              <h3>
                <strong>3.15.3.</strong
                ><span>
                  First Half markets refer to the result of the first and second
                  quarters. All bets will be considered void if the specified
                  half is not completed.
                </span>
              </h3>
              <h3>
                <strong>3.15.4.</strong
                ><span>
                  Water Polo Live Betting is settled on the result of the match
                  at the end of normal time.
                </span>
              </h3>
              <h3>
                <strong>3.15.5.</strong
                ><span>
                  The score will not be updated for water-polo live betting.
                </span>
              </h3>

              <h2><a name="R316" id="R316"></a>3.16. Boxing</h2>
              <h3>
                <strong>3.16.1.</strong
                ><span>
                  Bets on postponed fights will still be considered valid if the
                  fight takes place within fourteen days of the original start
                  date.
                </span>
              </h3>
              <h3>
                <strong>3.16.2.</strong
                ><span>
                  For moneyline markets (where no draw option is offered for
                  betting) all bets will be void if the fight result is a draw.
                  If odds for a draw are offered as a third betting option, and
                  the fight ends in a draw, then betting on the draw option will
                  be paid as the winner and bets on the two boxers will be lost.
                </span>
              </h3>
              <h3>
                <strong>3.16.3.</strong
                ><span>
                  A fight is deemed to have started when the bell is sounded for
                  the beginning of the first round. If one, or both, boxers
                  should fail to start the fight then all bets will be void. If
                  a boxer fails to answer the bell for the start of a round, or
                  is disqualified between rounds, the opponent is deemed to have
                  won in the previous round.
                </span>
              </h3>
              <h3>
                <strong>3.16.4.</strong
                ><span>
                  All bets will be settled on the official decision given in the
                  ring immediately after the fight. Subsequent changes to the
                  result are not recognized for betting purposes.
                </span>
              </h3>
              <h3>
                <strong>3.16.5.</strong
                ><span>
                  A knock-out (KO) win also includes a technical knock-out (TKO)
                  or win by disqualification (DSQ). A points decision includes a
                  technical decision (TD) and a draw includes a technical draw.
                </span>
              </h3>
              <h3>
                <strong>3.16.6.</strong
                ><span>
                  Over/Under betting refers to the number of rounds that take
                  place during the fight, e.g. Under 9.5 means that the fight
                  ends before one minute and thirty seconds of Round 9; while
                  Over 9.5 means that the fight ends after one minute and thirty
                  seconds of Round 9 (including a points decision).
                </span>
              </h3>
              <h3><strong>3.16.7.</strong><span> UFC </span></h3>
              <h4>
                <strong>3.16.7.1.</strong
                ><span>
                  UFC rules also apply to fights promoted by other mixed martial
                  arts (MMA) organizations.
                </span>
              </h4>
              <h4>
                <strong>3.16.7.2.</strong
                ><span>
                  Bets on postponed fights will still be considered valid if the
                  fight takes place within fourteen days of the original start
                  date.
                </span>
              </h4>
              <h4>
                <strong>3.16.7.3.</strong
                ><span>
                  If a fight ends in a draw then all bets will be void.
                </span>
              </h4>

              <h2><a name="R317" id="R317"></a>3.17. Darts</h2>
              <h3>
                <strong>3.17.1.</strong
                ><span>
                  Moneyline and markets refer to the winner of the match.
                  Handicap and Over/Under markets are based on the number of
                  sets (unless otherwise stated).
                </span>
              </h3>
              <h3>
                <strong>3.17.2.</strong
                ><span>
                  If a match is postponed and not started within twelve hours of
                  the scheduled start time then all bets are considered void.
                </span>
              </h3>
              <h3>
                <strong>3.17.3.</strong
                ><span>
                  If a player does not start a tournament or match then all bets
                  on that player will be void.
                </span>
              </h3>
              <h3>
                <strong>3.17.4.</strong
                ><span>
                  If a match starts but is not completed then all bets will be
                  considered void.
                </span>
              </h3>
              <h3>
                <strong>3.17.5.</strong
                ><span>
                  Darts Live Betting is settled on the result of the match. The
                  score will not be updated for darts live betting.
                </span>
              </h3>
              <h3>
                <strong>3.17.6.</strong
                ><span>
                  “180s” markets refer to the number of maximum “180s” thrown in
                  a match.
                </span>
              </h3>

              <h2><a name="R318" id="R318"></a>3.18. Môn Cricket</h2>
              <h3>
                <strong>3.18.1</strong>.<span
                  >For Limited Over (including ODI and Twenty20) matches all
                  bets will settled on the official result according to the
                  competition rules. However, if the result is determined by a
                  super-overs, bowl out, toss of coin etc then all match bets
                  will be considered void.</span
                >
              </h3>
              <h3>
                <strong>3.18.2.</strong
                ><span>
                  If “No Result” is the official result or the competition rules
                  do not declare a winner then all bets will be void.
                </span>
              </h3>
              <h3>
                <strong>3.18.3.</strong
                ><span>
                  If a match is postponed or suspended then all bets are
                  considered valid if the match is resumed within the 48 hour
                  expiration.
                </span>
              </h3>
              <h3>
                <strong>3.18.4.</strong
                ><span>
                  Test Match markets will be offered in a 1X2 format. 1 refers
                  to the team that is named first (usually the home team); X
                  refers to the game resulting in a draw; 2 refers to the team
                  that is named second (usually the away team). Should a Test
                  Match end in a “Tie” (as distinct from a “Draw”) then all
                  match bets will be considered void. If a match is abandoned
                  due to outside interference then all bets may be declared
                  void.
                </span>
              </h3>
              <h3>
                <strong>3.18.5.</strong
                ><span>
                  Kèo đặt cược nhiều lượt chạy (run) nhất chỉ cầu thủ có số lượt
                  chạy nhiều nhất trong suốt trận đấu hoặc trong lượt chơi cụ
                  thể. Cả hai cầu thủ bắt buộc phải đứng ở vạch phát bóng đối
                  với ít nhất một quả thì khoản cược mới được xem là có giá trị.
                  Nếu kết quả trận đấu chính thức là “Không bàn thắng” thì tất
                  cả các khoản cược của kèo này sẽ bị xem là không có giá trị,
                  ngoại trừ trường hợp kèo được xác định vô điều kiện.
                </span>
              </h3>
              <h3>
                <strong>3.18.6.</strong
                ><span>
                  Kèo đặt cược số cú wicket (phá tam trụ môn) nhiều nhất chỉ cầu
                  thủ có số cú wicket nhiều nhất trong suốt trận đấu hoặc trong
                  lượt chơi cụ thể. Cả hai cầu thủ bắt buộc phải ném ít nhất một
                  quả thì khoản cược mới được xem là có giá trị. Nếu kết quả
                  trận đấu chính thức là “Không bàn thắng” thì tất cả các khoản
                  cược của kèo này sẽ bị xem là không có giá trị, ngoại trừ
                  trường hợp kèo được xác định vô điều kiện.
                </span>
              </h3>
              <h3>
                <strong>3.18.7.</strong
                ><span>
                  Kèo đặt cược vào số cú bóng không chạm đất, bay ngoài vòng
                  biên nhiều nhất chỉ đội ghi được số cú bóng không chạm đất,
                  bay ngoài vòng biên nhiều nhất trong trận đấu hay lượt chơi cụ
                  thể. Khoản cược sẽ được xem là có giá trị nếu kết quả chính
                  thức được công bố. Nếu kết quả trận đấu chính thức là “Không
                  bàn thắng” thì tất cả các khoản cược của kèo này sẽ bị xem là
                  không có giá trị, ngoại trừ trường hợp kèo được xác định vô
                  điều kiện.
                </span>
              </h3>
              <h3>
                <strong>3.18.8.</strong
                ><span>
                  Next Man Out markets refer to which player is dismissed first
                  in a match or specific innings. If one player retires then
                  bets will be considered void. If neither player is dismissed
                  then all bets will be considered void. All bets on decided
                  markets will be considered valid, even if “No Result” is the
                  official match result.
                </span>
              </h3>
              <h3>
                <strong>3.18.9.</strong
                ><span>
                  Kèo tổng lượt chạy chỉ tổng số lượt chạy do một cầu thủ hay
                  một đội ghi được trong suốt trận đấu hay lượt chơi cụ thể. Một
                  cầu thủ bắt buộc phải đứng ở vạch phát bóng đối với ít nhất
                  một quả thì khoản cược mới được xem là có giá trị. Nếu kết quả
                  trận đấu chính thức là “Không bàn thắng” thì tất cả các khoản
                  cược của kèo này sẽ bị xem là không có giá trị, ngoại trừ
                  trường hợp kèo được xác định vô điều kiện.
                </span>
              </h3>

              <h2><a name="R319" id="R319"></a>3.19. Rugby Union</h2>
              <h3>
                <strong>3.19.1.</strong
                ><span>
                  All Full-Time markets, including Live betting, will be settled
                  on the final result at the end of normal time (80 minutes).
                  Extra-time does not count for full-time markets. Thị trường
                  Bóng bầu dục 7 người (Rugby Sevens) sẽ được chốt vào cuối thời
                  gian thi đấu bình thường (thường là 14 hoặc 20 phút). Thời
                  gian cộng thêm không được tính vào thị trường Bóng bầu dục 7
                  người toàn thời gian.
                </span>
              </h3>
              <h3>
                <strong>3.19.2.</strong
                ><span>
                  If a match is postponed, suspended or abandoned and not
                  resumed within twelve hours of the scheduled start time then
                  all bets are considered void, except for those on markets
                  which have been unconditionally determined. Bets will also be
                  considered valid if an official result is declared by the
                  relevant governing body.
                </span>
              </h3>
              <h3>
                <strong>3.19.3.</strong
                ><span>
                  First Half markets refer to the result of the first half only.
                  All bets will be considered void if the specified half is not
                  completed.
                </span>
              </h3>
              <h3>
                <strong>3.19.4.</strong
                ><span>
                  Rugby Union Live Betting is settled on the result of the match
                  at the end of normal time.
                </span>
              </h3>
              <h3>
                <strong>3.19.5.</strong
                ><span>
                  The score will be updated for Rugby Union live betting and
                  markets shown during live trading refer to the score displayed
                  at the time the bet is placed.
                </span>
              </h3>
              <h3>
                <strong>3.19.6.</strong
                ><span>
                  Đối với việc cá cược trực tiếp, trong suốt trò chơi, liên quan
                  đến hành động mà Công ty cho là nguy hiểm theo toàn quyền
                  quyết định của mình, theo đó điểm số, kết quả và phong độ của
                  một đội hoặc một người chơi có thể bị ảnh hưởng; hoặc bảo đảm
                  thay đổi lợi thế/giá cá cược hoặc Trung tâm cá cược hoặc Thông
                  tin Cá cược (“Lối chơi nguy hiểm”) Công ty có quyền đình chỉ
                  việc chấp nhận cá cược và có thể chấp nhận hoặc từ chối việc
                  đặt cược sau Lối chơi nguy hiểm. Tất cả các hành động khác
                  trong trò chơi được xem như Lối chơi an toàn và việc cá cược
                  sẽ tiếp tục được xem xét chấp nhận.
                </span>
              </h3>

              <h2><a name="R320" id="R320"></a>3.20. Rugby League</h2>
              <h3>
                <strong>3.20.1.</strong
                ><span>
                  All Full-Time markets, including Live betting, will be settled
                  on the final result at the end of the match, including any
                  extra-time that may be played.
                </span>
              </h3>
              <h3>
                <strong>3.20.2.</strong
                ><span>
                  If a match is postponed, suspended or abandoned and not
                  resumed within twelve hours of the scheduled start time then
                  all bets are considered void, except for those on markets
                  which have been unconditionally determined. Bets will also be
                  considered valid if an official result is declared by the
                  relevant governing body.
                </span>
              </h3>
              <h3>
                <strong>3.20.3.</strong
                ><span>
                  First Half markets refer to the result of the first half only.
                  All bets will be considered void if the specified half is not
                  completed.
                </span>
              </h3>
              <h3>
                <strong>3.20.4.</strong
                ><span>
                  Rugby League Live Betting is settled on the result of the
                  match, including any extra-time that may be played.
                </span>
              </h3>
              <h3>
                <strong>3.20.5.</strong
                ><span>
                  The score will be updated for Rugby League live betting and
                  markets shown during live trading refer to the score displayed
                  at the time the bet is placed.
                </span>
              </h3>
              <h3>
                <strong>3.20.6.</strong
                ><span>
                  Đối với việc cá cược trực tiếp, trong suốt trò chơi, liên quan
                  đến hành động mà Công ty cho là nguy hiểm theo toàn quyền
                  quyết định của mình, theo đó điểm số, kết quả và phong độ của
                  một đội hoặc một người chơi có thể bị ảnh hưởng; hoặc bảo đảm
                  thay đổi lợi thế/giá cá cược hoặc Trung tâm cá cược hoặc Thông
                  tin Cá cược (“Lối chơi nguy hiểm”) Công ty có quyền đình chỉ
                  việc chấp nhận cá cược và có thể chấp nhận hoặc từ chối việc
                  đặt cược sau Lối chơi nguy hiểm. Tất cả các hành động khác
                  trong trò chơi được xem như Lối chơi an toàn và việc cá cược
                  sẽ tiếp tục được xem xét chấp nhận.
                </span>
              </h3>

              <h2><a name="R321" id="R321"></a>3.21. Athletics</h2>
              <h3>
                <strong>3.21.1.</strong
                ><span>
                  Outright (to win Gold Medal) markets refer to the winner of
                  each athletics event.
                </span>
              </h3>
              <h3>
                <strong>3.21.2.</strong
                ><span>
                  Moneyline markets refer to the competitor with the best
                  placing in an event (or specified heat). If only one athlete
                  reaches the final of an event then bets on that competitor are
                  winning bets. If both athletes fail to reach the final then
                  the competitor with the better classification during
                  qualifying is declared the winner.
                </span>
              </h3>
              <h3>
                <strong>3.21.3.</strong
                ><span>
                  Podium Positions will be the official result for which all
                  bets are settled. Any subsequent disqualifications or
                  amendments to the result will not be considered for betting
                  purposes.
                </span>
              </h3>
              <h3>
                <strong>3.21.4.</strong
                ><span>
                  If an event is postponed, suspended or abandoned and not
                  resumed within twelve hours of the scheduled start time then
                  all bets are considered void.
                </span>
              </h3>
              <h3>
                <strong>3.21.5.</strong
                ><span>
                  If an athlete fails to start an event then all bets will be
                  void. An athlete is deemed to have started an event if they
                  have taken part to the extent necessary to record an official
                  result or classification (including DSQ (disqualified) but
                  excluding DNS (did not start)).
                </span>
              </h3>

              <h2><a name="R322" id="R322"></a>3.22. Swimming</h2>
              <h3>
                <strong>3.22.1.</strong
                ><span>
                  Outright (to win Gold Medal) markets refer to the winner of
                  each swimming event.
                </span>
              </h3>
              <h3>
                <strong>3.22.2.</strong
                ><span>
                  Moneyline markets refer to the competitor with the best
                  placing in an event (or specified heat). If only one swimmer
                  reaches the final of an event then bets on that competitor are
                  winning bets. If both swimmers fail to reach the final then
                  the competitor with the better classification during
                  qualifying is declared the winner.
                </span>
              </h3>
              <h3>
                <strong>3.22.3.</strong
                ><span>
                  Podium Positions will be the official result for which all
                  bets are settled. Any subsequent disqualifications or
                  amendments to the result will not be considered for betting
                  purposes.
                </span>
              </h3>
              <h3>
                <strong>3.22.4.</strong
                ><span>
                  If an event is postponed, suspended or abandoned and not
                  resumed within twelve hours of the scheduled start time then
                  all bets are considered void.
                </span>
              </h3>
              <h3>
                <strong>3.22.5.</strong
                ><span>
                  If a swimmer fails to start an event then all bets will be
                  void.
                </span>
              </h3>

              <h2><a name="R323" id="R323"></a>3.23. Beach Soccer</h2>
              <h3>
                <strong>3.23.1.</strong>
                <span
                  >All bets will be settled after the result of the full three
                  periods of play (twelve minutes each) unless otherwise stated
                  in writing by the Company.</span
                >
              </h3>
              <h3>
                <strong>3.23.2.</strong>
                <span
                  >If a game begins before the scheduled time, any non-live bets
                  placed after the early start but before the scheduled start
                  will be considered VOID. Live bets placed during live betting
                  are considered valid and live betting is deemed to have
                  commenced when the event has started, even if earlier than the
                  scheduled time.</span
                >
              </h3>
              <h3>
                <strong>3.23.3.</strong>
                <span
                  >Extra time and penalty shoot-outs do not count for betting
                  and settlement purposes, unless otherwise stated.</span
                >
              </h3>
              <h3>
                <strong>3.23.4.</strong>
                <span
                  >If a match is suspended, abandoned or terminated, then the
                  entire match will be considered void.Matches will be declared
                  void irrespective of any official decision to honor the
                  result. The settlement of all bets on voided matches is at the
                  sole discretion of the Company.
                </span>
              </h3>
              <h3>
                <strong>3.23.5.</strong>
                <span
                  >The Company provides venue information (e.g. neutral ground)
                  as helpful information only and accepts no liability
                  whatsoever if the stated neutral venues are correct or not. It
                  is the customer’s responsibility to be aware of the correct
                  venue for any match.
                </span>
              </h3>
              <h3>
                <strong>3.23.6.</strong>
                <span
                  >For live betting, during a game, with respect to actions
                  which the Company in its sole and absolute discretion, deems
                  as dangerous where the score, outcome, performance of one team
                  or player may be affected; or warrant changing the odds/prices
                  or Markets or Betting Information (“Danger Play”) the Company
                  reserves the right to suspend acceptance of bets and may
                  accept or reject bets after the Danger Play. All other actions
                  in a game are deemed Safe Play and bets will continue to be
                  considered for acceptance.</span
                >
              </h3>
              <h3>
                <strong>3.23.7.</strong>
                <span
                  >For live betting, all pending bets will be automatically
                  rejected as of the moment the referee ends the match at the
                  end of each period and/or full time.</span
                >
              </h3>
              <h3>
                <strong>3.23.8.</strong> <span>Beach Soccer Bet Types</span>
              </h3>
              <h4>
                <strong>3.23.8.1.</strong
                ><span> The Company offers the following Bet Types: </span>
              </h4>
              <h5>
                <strong>3.23.8.1.1.</strong> <span>Non Live Betting</span>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">3.23.8.1.1.1.</strong
                  ><span>
                    Asian Handicap - means betting on which team will make the
                    most number of goals based on the final score inclusive of
                    any handicaps.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">3.23.8.1.1.2.</strong
                  ><span>
                    Odd/Even- means betting that is determined by whether the
                    total number of goals at the end of the full three periods
                    of play (twelve minutes each) is Odd or Even.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">3.23.8.1.1.3.</strong
                  ><span>
                    Over/Under- means betting on the total number of goals that
                    will be scored in the normal full three periods of play
                    (twelve minutes each) excluding extra time.
                  </span>
                </p>
              </h5>
              <h5>
                <strong>3.23.8.1.2.</strong>
                <span>In Running ("Live") Betting</span>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">3.23.8.1.2.1.</strong
                  ><span>
                    Asian Handicap- means betting on which team will make the
                    most number of goals based on the final score inclusive of
                    any handicaps.
                  </span>
                </p>
              </h5>
              <h5>
                <p class="h7">
                  <strong class="style3">3.23.8.1.2.2.</strong
                  ><span>
                    Over/Under- means betting on the total number of goals that
                    will be scored in the normal full three periods of play
                    (twelve minutes each) excluding extra time.
                  </span>
                </p>
              </h5>
              <h4>
                <strong>3.23.8.2.</strong
                ><span>
                  For a more comprehensive list of Bet types, please refer to
                  Sec. 2.2 set forth above.
                </span>
              </h4>

              <h2><a name="R324" id="R324"></a>3.24. Squash</h2>
              <h3>
                <strong>3.24.1.</strong>
                <span
                  >Moneyline markets refer to the winner of the match or
                  specified game. Handicap markets are based on either games or
                  points (please refer to market title); Over/Under and Odd/Even
                  markets based on points (unless otherwise stated).</span
                >
              </h3>
              <h3>
                <strong>3.24.2.</strong>
                <span
                  >If a player does not start a tournament or match then all
                  bets on that player will be void.</span
                >
              </h3>
              <h3>
                <strong>3.24.3.</strong>
                <span
                  >If a player (or pairing) retires or is disqualified during a
                  match then all bets will be void, except for those on markets
                  which have been unconditionally determined.
                </span>
              </h3>
              <h3>
                <strong>3.24.4.</strong>
                <span
                  >If a match is postponed or suspended then all bets are still
                  considered valid if the match resumes before the twelve hour
                  expiration.</span
                >
              </h3>
              <h3>
                <strong>3.24.5.</strong>
                <span
                  >First Game Winner (Second, Third Game Winner etc) refers to
                  the result of the specified game. All bets will be considered
                  void if the specified game is not completed.</span
                >
              </h3>
              <h3>
                <strong>3.24.6.</strong>
                <span
                  >Squash Live Betting is settled on the result of the match (or
                  specified game). The score will not be updated for squash live
                  betting.</span
                >
              </h3>

              <h2><a name="R325" id="R325"></a>3.25. Horse Racing Specials</h2>
              <h3>
                <strong>3.25.1.</strong
                ><span>
                  Horse Racing specials will be offered on certain big races,
                  e.g. Melbourne Cup.
                </span>
              </h3>
              <h3><strong>3.25.2.</strong><span> Outright </span></h3>
              <h4>
                <strong>3.25.2.1.</strong
                ><span>
                  Outright betting is on the winner of a horse race. The result
                  of a race shall be official when the “all clear”, “weighed in”
                  or “correct weight” is declared and the “official result” is
                  declared by the racetrack. Subsequent disqualifications or
                  amendment to the results will not be considered for betting
                  purposes.
                </span>
              </h4>
              <h4>
                <strong>3.25.2.2.</strong
                ><span>
                  Outright place betting is on horses that fill the designated
                  places in a race. The number of places that are paid as
                  winners will be indicated in the market title.
                </span>
              </h4>
              <h4>
                <strong>3.25.2.3.</strong
                ><span>
                  Scratchings/Non-runners – Whenever a horse is withdrawn from a
                  race before or after coming under starter’s orders (or if one
                  or more of the starting gates do not open, thus preventing a
                  fair start) the horse shall be deemed to be a
                  non-runner/scratched and the money wagered on that horse will
                  be refunded.
                </span>
              </h4>
              <h4>
                <strong>3.25.2.4.</strong
                ><span>
                  If a “Dead Heat” is declared in any outright market then half
                  the stake is applied to the selection at full odds and the
                  other half of the stake is lost. If more than two horses “Dead
                  Heat” then the stakes are proportioned accordingly.
                </span>
              </h4>
              <h3><strong>3.25.3.</strong><span> Head to Head </span></h3>
              <h4>
                <strong>3.25.3.1.</strong
                ><span>
                  Head to Head betting is on one horse to be better placed than
                  another in a race. If one (or both) horse is scratched/a
                  non-runner then all bets will be void and will be refunded. If
                  the two horses should Dead Heat then all bets will be void.
                </span>
              </h4>

              <h2>
                <a name="R326" id="R326"></a>3.26. Winter Olympic Games Rules
                &amp; Regulations
              </h2>
              <h3><strong>3.26.1.</strong><span> General </span></h3>
              <h4>
                <strong>3.26.1.1.</strong
                ><span>
                  All markets will be settled on the official result declared by
                  the IOC.
                </span>
              </h4>
              <h4>
                <strong>3.26.1.2.</strong
                ><span>
                  The original medal ceremony will be the official result for
                  which all bets are settled. Any subsequent disqualifications
                  or amendments to the result will not be considered for betting
                  purposes.
                </span>
              </h4>
              <h4>
                <strong>3.26.1.3.</strong
                ><span>
                  All bets will be considered valid if the match or event is
                  completed within the official period of the Olympic Games,
                  irrespective of the actual start time. Should a match or event
                  not be completed, and no official result is given, then all
                  bets will be considered void and will be refunded.
                </span>
              </h4>
              <h4>
                <strong>3.26.1.4.</strong
                ><span>
                  Any bets that are accepted in error after an event has
                  actually started (except for live betting) will be considered
                  void and will be refunded.
                </span>
              </h4>
              <h4>
                <strong>3.26.1.5.</strong
                ><span>
                  If a team or competitor does not start an event then all bets
                  placed on that selection (including outright (to win Gold
                  Medal) markets) will be considered void and will be refunded.
                  If a named team or competitor in a Money Line (head to head)
                  market does not start an event then all bets on that market
                  will be considered void and will be refunded.
                </span>
              </h4>
              <h4>
                <strong>3.26.1.6.</strong
                ><span>
                  Should a Dead Heat occur in outright (to win Gold Medal)
                  markets then, half the stake is applied to the selection at
                  the full odds, while the other half of the stake is lost.
                  Should a Dead Heat occur in Money Line (head to head) markets
                  then the result will be a tie and bets on both competitors
                  will be refunded.
                </span>
              </h4>
              <h3><strong>3.26.2.</strong><span> Olympic Medals </span></h3>
              <h4>
                <strong>3.26.2.1.</strong
                ><span>
                  Markets will be offered on the number of medals won by
                  individuals or countries at the Olympic Games.
                </span>
              </h4>
              <h4>
                <strong>3.26.2.2.</strong
                ><span>
                  These markets will refer to either Gold Medals only, or to
                  Total Medals (Gold, Silver and Bronze medals combined).
                </span>
              </h4>
              <h4>
                <strong>3.26.2.3.</strong
                ><span>
                  All markets will be settled by the official medal tables
                  released by the IOC immediately after the end of the Olympic
                  Games. Any subsequent changes to the medal table will not be
                  considered for betting purposes.
                </span>
              </h4>
              <h3>
                <strong>3.26.3.</strong><span> Moneyline (Head to Head) </span>
              </h3>
              <h4>
                <strong>3.26.3.1.</strong
                ><span>
                  Which competitor or team will win a match or be placed higher
                  in an event?
                </span>
              </h4>
              <h5>
                <strong>3.26.3.1.1.</strong
                ><span>
                  The final placing is decided by the competitor who progresses
                  furthest in the event. If both competitors are eliminated at
                  the same stage then the competitor with the higher official
                  ranking is declared the winner. Should both competitors be
                  eliminated at the same stage but neither competitor is given
                  an official ranking then bets will be considered void.
                </span>
              </h5>
              <h3><strong>3.26.4.</strong><span> Ice Hockey </span></h3>
              <h4>
                <strong>3.26.4.1.</strong
                ><span>
                  Olympic Ice Hockey will follow the normal Ice Hockey rules
                  (3.5.) above.
                </span>
              </h4>
              <h4>
                <strong>3.26.4.2.</strong
                ><span>
                  Except for rule 3.5.3 which will be replaced by the following
                  - All bets will be considered valid if the match is completed
                  within the official period of the Olympic Games, irrespective
                  of the actual start time. Bets will also be considered valid
                  if an official result is declared by the IOC. Should a match
                  not be completed, and no official result is given, then all
                  bets will be considered void and will be refunded.
                </span>
              </h4>
              <h4>
                <strong>3.26.4.3.</strong
                ><span>
                  Additional markets may be offered (e.g Overtime included or
                  Shootout Winner) and the terms of these markets will be
                  clearly stated in the title.
                </span>
              </h4>
              <p class="h7">
                <strong class="style3">*</strong
                ><span>
                  Note: Overtime will not be played during preliminary round
                  group matches so ties are possible.
                </span>
              </p>

              <!--3.28-->
              <!--
                            <h2><a name="R328" id="R328"></a>3.28. Financial Betting Rules</h2>
                            <h3><strong>3.28.1.</strong><span>
                            Bet Pricing</span></h3>
                            <h4><strong>3.28.1.1.</strong><span>
                            You agree that bet prices are calculated by the Company and are offered at prices posted on www.maxbet.com. In determining bet prices, the Company shall use a method that will include various assumptions with regard to market volatility, asset price, risk free interest rates, costs of carry, strike prices and other parameters.</span></h4>
                            <h4><strong>3.28.1.2.</strong><span>
                            If dividend payment has been announced for a stock, the Company has the right to adjust the strike price on all open bets on that stock by an amount up or down no greater than the amount of the dividend.</span></h4>
                            <h4><strong>3.28.1.3.</strong><span>
                            The Company has no liability in the event of price errors. All prices advertised on the Website are final unless it otherwise indicates. </span></h4>
                            <h3><strong>3.28.2.</strong><span>
                            Bet Expiry Time</span></h3>
                            <h4><strong>3.28.2.1.</strong><span>
                            By using our betting facilities, you accept that schedule according to which bets are expired in the system may change from time to time to our discretion.</span></h4>
                            <h4><strong>3.28.2.2.</strong><span>
                            Intraday bets with an expiry before the end of our market hours will expire in the number of minutes selected on the betting interface. For example, a 15 minute bet will expire in 15 minutes provided there are 15 minutes left in our market day.  This rule also applies to all intraday 60 minute bets.</span></h4>
                            <h4><strong>3.28.2.3.</strong><span>
                            In the event of inadequate time left in our trading day, a bet placed upon a bet type whose expiry time is due after the market closes will be considered void. </span></h4>
                            <h4><strong>3.28.2.4.</strong><span>
                            The expiry time will be counted from the time the bet is accepted or recorded by our servers. The expiry time shown on our Website is for information purpose only. </span></h4>
                            <h4><strong>3.28.2.5.</strong><span>
                            The first and last 5 minutes of any trading session do not count towards any bet. For example, in a market that closes at 16:30, no data after 16:25 shall be counted towards any bet. Schedules of opening and closing of markets are as published on our Website.</span></h4>
                            <h3><strong>3.28.3.</strong><span>
                            Payment and Security</span></h3>
                            <h4><strong>3.28.3.1.</strong><span>
                            Settlement terms and conditions shall be in accordance with the General Terms and Conditions prescribed by the Company.</span></h4>
                            <h4><strong>3.28.3.2 </strong><span>
                            Your winnings or losses from financial betting will be added or subtracted (respectively) to your credit balance once the bets placed have been settled. </span></h4>
                            <h3><strong>3.28.4.</strong><span>
                            Settlement of Bets</span></h3>
                            <h4><strong>3.28.4.1 </strong><span>
                            Bet expiry times 5, 15, 30, and 60 minute bets during the trading day will be settled no later than 60 seconds after the stated expiry time.</span></h4>
                            <h4><strong>3.28.4.2 </strong><span>
                            Settlement of bets on offered indices is based on the official settlement value of the indices, which is computed based on official value for a particular index after the market closes. Official settlement value is typically made public around 5 minutes after the close of trading day. Thus, an official settlement value of a market that closes at 16:30 may differ from the 16:40 value of a certain index.</span></h4>
                            <h4><strong>3.28.4.3 </strong><span>
                            The value of an index displayed on our Website is for information purpose only and the Company shall not be responsible for any delayed information on value and settlement value. Settlement value is defined as the weighted average of prices traded at the close of a trading day which is also called “settlement level”.</span></h4>
                            <h3><strong>3.28.5.</strong><span>
                            Financial Betting Terms and Conditions</span></h3>
                            <h4><strong>3.28.5.1.</strong><span>
                            In case bets are placed/accepted at prices that reflect erroneous inputs into our pricing models as a result of technical error, you acknowledge that transaction will be voided.</span></h4>
                            <h4><strong>3.28.5.2.</strong><span>
                            The Company determines (as published on our Website) the hours of business that it will accept bets on financials; these may exclude a period of time at the open and close of each market, usually of 30 minutes and some markets break for lunch. The first and last 5 minutes of the market's trading hours do not count towards bet placement. </span></h4>
                            <h4><strong>3.28.5.3.</strong><span>
                            A bet placed beyond respective market business hours set by us to accept bets shall be rejected or voided.</span></h4>
                            <h4><strong>3.28.5.4.</strong><span>
                            When the market unexpectedly closes for any reason, all bets shall be voided and stakes will be refunded.</span></h4>
                            <h4><strong>3.28.5.5.</strong><span>
                            A minimum bet is set at 1.00 unit of the currency that the player’s account utilizes.</span></h4>
                            <h4><strong>3.28.5.6.</strong><span>
                            The maximum stake for financial bets will depend on the offered odds bet type and index. The account holder will be informed of the maximum stake on a particular offered odd. </span></h4>
                            <h4><strong>3.28.5.7.</strong><span>
                            You win the bet if you correctly predict that the product’s settlement level is up or down with respect to the market level at the time you placed the bet (Over Under Bet Type); or whether the last digit of the current level index is an odd or even number at the time you placed the bet (Odd Even Bet Type). If the current level index is expressed with a decimal, all the decimals shall be ignored and not rounded off. For bet settlement purposes for over/under, the bet is settled on the first tick (price unit) that comes in on or after the second that it should expire. If a tick comes in that second, it is settled that second, if it takes another n seconds to see the next tick, it settles n seconds later. For bet settlement purposes for odd/even, the last digit before the decimal point will be used to determine the odd or even number. There is a Tie if the product’s settlement level is exactly the same as the level at the time you placed the bet, the stake is returned and no winning or loss is accounted.</span></h4>
                            <h4><strong>3.28.5.8.</strong><span>
                            The Company takes reasonable measures so that the information provided is accurate. We are not responsible for any possible slight delay of the information displayed on our Website. We reserve the right to void or re-settle bets at the correct price determined by us if we receive inconsistent provision of data from a third party provider in case of errors in the terms or market conditions provided to us, whether or not the error was caused by human error or technical problems.</span></h4>
                            <h4><strong>3.28.5.9.</strong><span>
                            We reserve the right to close your account due to suspected manipulation of, or insider trading on, the underlying markets on which bets are placed.</span></h4>
                            <div id="1-2"align="right"><a href="#top" class="button"><span>ĐẦU TRANG</span></a></div>
                            -->
              <!-- BEGIN Betting Rules -->
              <h2><a name="R327" id="R327"></a>3.27. Netball</h2>
              <h3>
                <strong>3.27.1.</strong
                ><span>
                  All Full-Time markets, including Live betting, will be settled
                  on the final result including overtime (unless otherwise
                  stated).
                </span>
              </h3>
              <h3>
                <strong>3.27.2.</strong
                ><span>
                  If a match does not start on the scheduled start date then all
                  bets will be void (unless stated otherwise). If a match is
                  suspended or abandoned then all bets placed will be considered
                  void, except for those on markets which have been
                  unconditionally determined.
                </span>
              </h3>
              <h3>
                <strong>3.27.3.</strong
                ><span>
                  First-Half results are the sum of the First and Second
                  Quarters. Second-Half results are the sum of the Third and
                  Fourth Quarters, including any Overtime that may be
                  played.Fourth Quarter results do not include any Overtime that
                  may be played.
                </span>
              </h3>
              <h3>
                <strong>3.27.4.</strong
                ><span>
                  If a match is suspended or abandoned then bets placed on
                  unfinished Halves or Quarters will be considered void. If the
                  designated Halves or Quarters are completed then bets will be
                  valid.
                </span>
              </h3>
              <h3>
                <strong>3.27.5.</strong
                ><span>
                  The score will not be updated for netball live betting and the
                  handicap shown during live trading refers to the score at the
                  start of the match i.e. 0-0. The times and scores displayed
                  are for reference purposes only.
                </span>
              </h3>

              <h2>
                <a name="R328" id="R328"></a>3.28. Number Game Betting Rules and
                Regulations
              </h2>
              <h3><strong>3.28.1.</strong><span> Basic concept </span></h3>
              <h4>
                <strong>3.28.1.1.</strong
                ><span>
                  This game is played by betting on the numbers of the balls
                  drawn from a bingo machine.
                </span>
              </h4>
              <h4>
                <strong>3.28.1.2.</strong
                ><span>
                  The Company will provide real time odds on the random draw of
                  balls. You may place your bets based on such odds, in a
                  variety of bet types as explained below.
                </span>
              </h4>
              <h3><strong>3.28.2.</strong><span> Bet Types </span></h3>
              <h4>
                <strong>3.28.2.1.</strong
                ><span>
                  Generally, two types of betting will be available for the
                  Number Game.
                </span>
              </h4>
              <h5>
                <strong>(A)</strong
                ><span>
                  Pre-Games - Players may place bets before the game starts, but
                  once the game starts no bets will allowed.
                </span>
              </h5>
              <h5>
                <strong>(B)</strong
                ><span>
                  Running games - Players may place bets after the game starts
                  and different odds will be given for every single ball drawn.
                </span>
              </h5>
              <h3><strong>3.28.3.</strong><span> Bet Types </span></h3>
              <h4>
                <strong>3.28.3.1.</strong
                ><span> The pregame bet types are as follows: </span>
              </h4>
              <h5>
                <strong>(A)</strong
                ><span> Over/Under, First ball drawn or last ball drawn </span>
              </h5>
              <h5>
                <strong>(B)</strong
                ><span> Odd/Even, First ball or last ball drawn </span>
              </h5>
              <h5>
                <strong>(C)</strong
                ><span> Odd/Even (FT) (sum of three balls drawn) </span>
              </h5>
              <h5>
                <strong>(D)</strong
                ><span>
                  Warrior, comparing Second ball drawn or Third ball drawn
                </span>
              </h5>
              <h5>
                <strong></strong
                ><span>
                  The “last ball drawn” is defined as the last of a pre-arranged
                  number of bingo balls drawn. If for any reason the last ball
                  is not drawn, all bets on the last ball will be considered
                  void and all the bets on the last ball will be refunded.
                </span>
              </h5>
              <h5>
                <strong></strong
                ><span>
                  Payouts are made only on complete games. A complete game is a
                  game where the last ball is drawn without encountering any
                  errors up to that point. In case errors are encountered, the
                  Company reserves the right to void the game and refund the
                  bets.
                </span>
              </h5>
              <h4>
                <strong>3.28.3.2.</strong
                ><span> Over/Under, First ball drawn or last ball drawn </span>
              </h4>
              <h4>
                <strong></strong
                ><span>
                  The Company will offer odds on the number of 1st ball or last
                  ball drawn being “over” or “under.”
                </span>
              </h4>
              <h4>
                <strong></strong
                ><span>
                  Over 1st/last– Ball number 38 to 75 is considered an Over
                  ball.
                </span>
              </h4>
              <h4>
                <strong></strong
                ><span>
                  Under 1st/last– Ball number 1 to 37 is considered an Under
                  ball.
                </span>
              </h4>
              <h5>
                <strong>Example: </strong
                ><span>
                  Ball number 30 was drawn as the last ball during the game
                  therefore ‘Under’ bet wins.
                </span>
              </h5>
              <h4>
                <strong>3.28.3.3.</strong
                ><span> Odd/Even, First ball or last ball drawn </span>
              </h4>
              <h4>
                <strong></strong
                ><span>
                  The Company will offer odds on the number of 1st ball or last
                  ball drawn being “odd” or “even.”
                </span>
              </h4>
              <h4>
                <strong></strong
                ><span>
                  Odd – Odd numbered bingo ball is considered as the Odd ball.
                </span>
              </h4>
              <h4>
                <strong></strong
                ><span>
                  Even – Even numbered bingo ball is considered as the Even
                  ball.
                </span>
              </h4>
              <h5>
                <strong>Example:</strong
                ><span>
                  Ball number 30 was drawn as the first ball during the game
                  therefore ‘Even’ bet wins.
                </span>
              </h5>
              <h4>
                <strong>3.28.3.4.</strong
                ><span> Odd/Even FT (sum of three balls drawn) </span>
              </h4>
              <h4>
                <strong></strong
                ><span>
                  The Company will offer odds on the sum of three balls drawn
                  being an odd or even number.
                </span>
              </h4>
              <h4>
                <strong></strong
                ><span>
                  Odd (FT) – The sum of three balls drawn is an odd number.
                </span>
              </h4>
              <h4>
                <strong></strong
                ><span>
                  Even (FT) – The sum of three balls drawn is an even number.
                </span>
              </h4>
              <h5>
                <strong>Example:</strong
                ><span>
                  The balls numbered 07, 13 and 20 are drawn, and the sum of the
                  numbers of these balls is 40. 40 is an even number, therefore
                  the “Even (FT)” bet wins.
                </span>
              </h5>
              <h4>
                <strong>3.28.3.5.</strong
                ><span>
                  Warrior, comparing Second ball drawn or Third ball drawn
                </span>
              </h4>
              <h5>
                <strong>3.28.3.5.1.</strong
                ><span>
                  This game will require three balls to be drawn. This game
                  starts by drawing the 1st ball and is played by comparing the
                  2nd ball drawn and the 3rd ball drawn, whereby the ball drawn
                  with highest number considered win.
                </span>
              </h5>
              <h6>
                <strong>3.28.3.5.2.</strong
                ><span>
                  Number 1 will be the lowest number and number 75 will be the
                  highest number. </span
                ><br />
              </h6>
              <h6>
                <strong>3.28.3.5.3.</strong
                ><span>
                  The Company will offer odds for this bet type before the 1st
                  ball is drawn. The player may place bets at any time but
                  before the 1st ball drawn. </span
                ><br />
              </h6>
              <h4>
                <strong>Example: </strong
                ><span>
                  If the 2nd ball drawn is number 45 and 3rd ball drawn is
                  number 60, then the player that bets on 3rd ball will win the
                  game.
                </span>
              </h4>
              <h3><strong>3.28.4.</strong><span> Running Game </span></h3>
              <h4>
                <strong>3.28.4.1.</strong
                ><span>
                  There are five (5) bet types available for running bet types:
                </span>
              </h4>
              <h5>
                <span>
                  A. Next Odd/Even<br />
                  B. Next Over/Under<br />
                  C. Next Hi/Lo<br />
                  D. Next Combo<br />
                  E. Number Wheel
                </span>
              </h5>
              <h4><strong>3.28.4.2.</strong><span> Next Odd/Even </span></h4>
              <h5>
                <strong>3.28.4.2.1.</strong
                ><span>
                  After each ball is drawn, the Company will offer odds on
                  whether the next ball drawn will be “odd” or “even” numbered.
                  The player may place bets at any time after the game starts.
                  The game ends when a certain number of balls have been drawn,
                  at the discretion of the Company.
                </span>
              </h5>
              <h4><strong>3.28.4.3.</strong><span> Next Over/Under </span></h4>
              <h5>
                <strong>3.28.4.3.1.</strong
                ><span>
                  After each ball is drawn, the Company will offer odds on
                  whether the next ball drawn will be “Over” or “Under”. The
                  player may place bets at any time after the game starts.<br />
                  Ball numbers 1 to 37 are considered as “Under.”<br />
                  Ball numbers 38 to 75 are considered as “Over.”<br />
                  The game ends when a certain number of balls have been drawn,
                  at the discretion of the Company.
                </span>
              </h5>
              <h4><strong>3.28.4.4.</strong><span> Next High/Lo </span></h4>
              <h5>
                <strong>3.28.4.4.1.</strong
                ><span>
                  After each ball is drawn, the Company will offer odds on
                  whether the next ball drawn will have a higher or lower
                  number. The player may place bets at any time after the game
                  starts. The game ends when a certain number of balls have been
                  drawn, at the discretion of the Company.
                </span>
              </h5>
              <h3>
                <strong>3.28.5.</strong
                ><span>
                  Warrior, comparing Second ball drawn or Third ball drawn
                </span>
              </h3>
              <h4>
                <strong>3.28.5.1.</strong
                ><span>
                  This game will require three balls to be drawn. This game
                  starts by drawing after the 1st ball and is played by
                  comparing the 2nd ball drawn and the 3rd ball drawn.
                </span>
              </h4>
              <h5>
                <strong>3.28.5.1.1.</strong
                ><span>
                  Number 1 will be the lowest number and number 75 will be the
                  highest number. </span
                ><br />
              </h5>
              <h5>
                <strong>3.28.5.1.2.</strong
                ><span>
                  The Company will offer odds for this bet type before the 1st
                  ball is drawn. The player may place bets at any time but
                  before the 1st ball drawn. </span
                ><br />
              </h5>
              <h5>
                <strong>Example:</strong
                ><span>
                  If the 2nd ball drawn is number 45 and 3rd ball drawn is
                  number 60, then the player that bets on 3rd ball will win the
                  game.
                </span>
              </h5>
              <h3><strong>3.28.6.</strong><span> Next Combo </span></h3>
              <h4>
                <strong>3.28.6.1.</strong
                ><span>
                  After each ball is drawn, the Company will offer odds on
                  whether the next ball drawn will be Over/Odd, Under/Odd,
                  Over/Even and Under/Even. The player may place bets at any
                  time after the game starts. The game ends when a certain
                  number of balls have been drawn, at the discretion of the
                  Company.
                </span>
              </h4>
              <h5>
                <strong>Example 1：</strong
                ><span>
                  Ball number 30 was drawn therefore the “Next Under/Even” bet
                  wins. </span
                ><br />
              </h5>
              <h5>
                <strong>Example 2：</strong
                ><span>
                  Ball number 47 was drawn therefore the “Next Over/Odd” bet
                  wins. </span
                ><br />
              </h5>
              <h3><strong>3.28.7.</strong><span> Number Wheel </span></h3>
              <h4>
                <strong>3.28.7.1.</strong
                ><span>
                  Trò chơi này đặt cược vào một con số hoặc một nhóm các con số.
                  Công ty sẽ cung cấp tỷ lệ cược trước khi rút thăm quả bóng đầu
                  tiên. Người chơi có thể đặt cược bất kỳ lúc nào sau khi trò
                  chơi bắt đầu.
                </span>
              </h4>
              <h4>
                <strong>3.28.7.2.</strong
                ><span>
                  Người chơi cũng có thể đặt cược nhiều con số và nhiều nhóm các
                  con số.
                </span>
              </h4>
              <h4>
                <strong>3.28.7.3.</strong
                ><span>
                  Người chơi có thể nhìn thấy tỷ lệ cược được cung cấp trên một
                  con số duy nhất được đặt bên trong mỗi quả bóng có ghi số.
                </span>
              </h4>
              <h4>
                <strong>3.28.7.4.</strong
                ><span> Players can bet in several groups of numbers: </span>
              </h4>
              <h5>
                <strong>3.28.7.4.1.</strong
                ><span> Five (5)-numbers bet type </span>
              </h5>
              <h5>
                <span
                  >These are the odds offered to bet on a group with 5 numbers
                  which are arranged horizontally in a row. These are group of
                  numbers labeled as:</span
                >
              </h5>
              <h5>
                <span
                  >Group 1-1 (numbers from 1-5), Group 1-2 (numbers from 6-10),
                  Group 1-3 (numbers from 11-15), Group 1-4 (numbers from
                  16-20), Group 1-5 (numbers from 21-25), Group 1-6 (numbers
                  from 26-30), Group 1-7 (numbers from 31-35), Group 1-8
                  (numbers from 36-40), Group 1-9 (numbers from 41-45), Group
                  1-10 (numbers from 46-50), Group 1-11 (numbers from 51-55),
                  Group 1-12 (numbers from 56-60), Group 1-13 (numbers from
                  61-65), Group 1-14 (numbers from 66-70), and Group 1-15
                  (numbers from 71-75).</span
                >
              </h5>
              <h5>
                <strong>3.28.7.4.2.</strong
                ><span> Fifteen (15)-numbers bet type </span>
              </h5>
              <h5>
                <span
                  >These are the odds offered to bet in a group with 15 numbers
                  either in a row or in a column based on the layout.</span
                >
              </h5>
              <h5>
                <strong>3.28.7.4.2.1</strong
                ><span>
                  These are the 15-numbers bet type which is located in a row,
                  labeled as:
                </span>
              </h5>
              <h5>
                <span
                  >Group 2-1 (numbers from 1-15), Group 2-2 (numbers from
                  16-30), Group 2-3 (numbers from 31-45), Group 2-4 (numbers
                  from 46-60), and Group 2-5 (numbers from 61-75).</span
                >
              </h5>
              <h5>
                <strong>3.28.7.4.2.2</strong
                ><span>
                  Also, these are the 15-numbers bet type which is located in a
                  column, labeled as:
                </span>
              </h5>
              <h5>
                <span
                  >Group 4-1 (numbers 1,6, 11, 16, 21, 26, 31, 36, 41, 46, 51,
                  56, 61, 66, 71), Group 4-2 (numbers 2, 7, 12, 17, 22, 27, 32,
                  37, 42, 47, 52, 57, 62, 67, 72), Group 4-3 (numbers 3, 8, 13,
                  18, 23, 28, 33, 38, 43, 48, 53, 58, 63, 68, 73), Group 4-4
                  (numbers 4, 9, 14, 19, 24, 29, 34, 39, 44, 49, 54, 59, 64, 69,
                  74), and Group 4-5 (numbers 5, 10, 15, 20, 25, 30, 35, 40, 45,
                  50, 55, 60, 65, 70, 75).</span
                >
              </h5>
              <h5>
                <strong>3.28.7.4.3.</strong
                ><span> Twenty five (25)-numbers bet type </span>
              </h5>
              <h5>
                <span
                  >These are the odds offered to bet on a group with 25 numbers.
                  These are group of numbers labeled as:</span
                >
              </h5>
              <h5>
                <span
                  >Group 3-1 (numbers from 1-25), Group 3-2 (numbers from
                  26-50), and Group 3-3 (numbers from 51-75).</span
                >
              </h5>
              <h3><strong>3.28.8.</strong><span> Additional Rules </span></h3>
              <h4>
                <strong>3.28.8.1.</strong
                ><span>
                  The balls drawn from the bingo machine are read by an
                  automated scanner. The Company takes all reasonable steps and
                  precautions with respect to the accuracy of the scanner.
                  Notwithstanding such steps and precautions, you accept that
                  the nature of the game is such that it is very fast-paced and
                  reliant on the quick results produced by the automated
                  scanner. Therefore, in the remote case of discrepancy between
                  the ball number shown on streaming video and the ball number
                  as read by the automated scanner, the Company reserves the
                  right to rely on the automated scanner as the final result.
                  You accept that the Company’s decision is final and binding in
                  this regard.
                </span>
              </h4>
              <h4>
                <strong>3.28.8.2.</strong
                ><span>
                  All Running games shall be settled after each ball is drawn.
                </span>
              </h4>
              <h4>
                <strong>3.28.8.3.</strong
                ><span>
                  You acknowledge that at the sole determination and discretion
                  of the Company, the Company has the absolute right to change,
                  cancel, suspend, remove, modify or restart any game, or refuse
                  or cancel any wagers, by reason of fortuitous event, acts of
                  war, natural disaster, power outage, human error or fault or
                  negligence by Company employees in violation of the industry
                  standards of work, software malfunction, and any other
                  analogous event. The Company's decision is final and binding.
                </span>
              </h4>
              <h3><strong>3.28.9.</strong><span> Betting Timer </span></h3>
              <h4>
                <strong>3.28.9.1.</strong
                ><span>
                  The betting timer shows the amount of time within which a
                  player can place a bet. The betting timer will be displayed as
                  a graphic in the current event, and the duration of the
                  betting timer will be at the sole and exclusive determination
                  of the Company. Any bet placed before the betting timer ends
                  will be accepted and considered valid. A player will not be
                  allowed to place bets after the expiration of the betting
                  timer. Bets place after the expiration of the betting duration
                  shall be deemed invalid or void.
                </span>
              </h4>
              <h4>
                <strong>3.28.9.2.</strong
                ><span>
                  Player acknowledges that while the Company uses best efforts
                  to ensure the accuracy of the betting timer at all times, the
                  betting timer may still be subject to disruptions, connection
                  lags, and other technical issues. Any and all bets placed
                  using the betting timer is entirely at the player’s own risk,
                  and the Company shall have the final decision to accept or
                  reject such bets.
                </span>
              </h4>
              <h3><strong>3.28.10.</strong><span> Scorecard Feature </span></h3>
              <h4>
                <strong>3.28.10.1.</strong
                ><span>
                  The number game scorecard is a feature available in live
                  streaming and designated by the scorecard button. The number
                  game scorecard replaces the game results feature in live
                  streaming.
                </span>
              </h4>
              <h4>
                <strong>3.28.10.2.</strong
                ><span>
                  This number game scorecard shows the results for every ball
                  drawn from the machine and for every bet type in the
                  scorecard. For results in last ball o/u, o/e and warrior or FT
                  o/e, it will appear once the 3rd ball is drawn. On mouseover,
                  each result of “u” or “o” will show the result displayed as a
                  combination of numbers showing the ball number drawn and game,
                  separated by a comma.
                </span>
              </h4>
              <h5>
                <strong>Example:</strong
                ><span>
                  Result of “44,01234” means ball number “44” drawn in game no.
                  “01234”.
                </span>
              </h5>
              <h4>
                <span
                  >This feature is intended to be used as reference of Players
                  only to help them determine possible result of the next draw
                  based on the results of the previous draws.</span
                >
              </h4>

              <h2><a name="R329" id="R329"></a>3.29. Olympic Games</h2>
              <h3><strong>3.29.1.</strong><span> General </span></h3>
              <h4>
                <strong>3.29.1.1.</strong
                ><span>
                  All markets will be settled on the official result declared by
                  the IOC.
                </span>
              </h4>
              <h4>
                <strong>3.29.1.2.</strong
                ><span>
                  The original medal ceremony will be the official result for
                  which all bets are settled. Any subsequent disqualifications
                  or amendments to the result will not be considered for betting
                  purposes.
                </span>
              </h4>
              <h4>
                <strong>3.29.1.3.</strong
                ><span>
                  All bets will be considered valid if the match or event is
                  completed within the official period of the Olympic Games,
                  irrespective of the actual start time (except Soccer). Should
                  a match or event not be completed, and no official result is
                  given, then all bets will be considered void and will be
                  refunded.
                </span>
              </h4>
              <h4>
                <strong>3.29.1.4.</strong
                ><span>
                  Any bets that are accepted in error after an event has
                  actually started (except for live betting) will be considered
                  void and will be refunded.
                </span>
              </h4>
              <h4>
                <strong>3.29.1.5.</strong
                ><span>
                  If a team or competitor does not start an event then all bets
                  placed on that selection (including outright (to win Gold
                  Medal) markets) will be considered void and will be refunded.
                  If a named team or competitor in a Money Line (head to head)
                  market does not start an event then all bets on that market
                  will be considered void and will be refunded.
                </span>
              </h4>
              <h4>
                <strong>3.29.1.6.</strong
                ><span>
                  Should a Dead Heat occur in outright (to win Gold Medal)
                  markets then, half the stake is applied to the selection at
                  the full odds, while the other half of the stake is lost.
                  Should a Dead Heat occur in Money Line (head to head) markets
                  then the result will be a tie and bets on both competitors
                  will be refunded.
                </span>
              </h4>
              <h3><strong>3.29.2.</strong><span> Olympic Medals </span></h3>
              <h4>
                <strong>3.29.2.1.</strong
                ><span>
                  Markets will be offered on the number of medals won by
                  individuals or countries at the 2012 Olympic Games.
                </span>
              </h4>
              <h4>
                <strong>3.29.2.2.</strong
                ><span>
                  These markets will refer to either Gold Medals only, or to
                  Total Medals (Gold, Silver and Bronze medals combined).
                </span>
              </h4>
              <h4>
                <strong>3.29.2.3.</strong
                ><span>
                  All markets will be settled by the official medal tables
                  released by the IOC immediately after the end of the Olympic
                  Games. Any subsequent changes to the medal table will not be
                  considered for betting purposes.
                </span>
              </h4>
              <h3>
                <strong>3.29.3.</strong><span> Moneyline (Head to Head) </span>
              </h3>
              <h4>
                <strong>3.29.3.1.</strong
                ><span>
                  Which competitor or team will win a match or be placed higher
                  in an event?
                </span>
              </h4>
              <h4>
                <strong>3.29.3.2.</strong
                ><span>
                  The final placing is decided by the competitor who progresses
                  furthest in the event. If both competitors are eliminated at
                  the same stage then the competitor with the higher official
                  ranking is declared the winner. Should both competitors be
                  eliminated at the same stage but neither competitor is given
                  an official ranking then bets will be considered void.
                </span>
              </h4>

              <h2><a name="R330" id="R330"></a>3.30. Politics</h2>
              <h3>
                <strong>3.30.1.</strong><span> US Presidential Election </span>
              </h3>
              <h4>
                <strong>3.30.1.1.</strong
                ><span>
                  Which Political Party will win the 2012 US Presidential
                  Election? Candidate names are given for reference only. Should
                  neither the Republicans nor Democrats win the election, then
                  all bets will be void and will be refunded.
                </span>
              </h4>

              <h2><a name="R331" id="R331"></a>3.31. Giải đua thuyền buồm</h2>
              <h3>
                <strong>3.31.1.</strong
                ><span>
                  Tranh cúp America’s Cup (trong đó có Louis Vuitton Cup)
                </span>
              </h3>
              <h4>
                <strong>3.31.1.1.</strong
                ><span>
                  Tất cả các thị trường sẽ thực hiện theo kết quả chính thức do
                  trang web chính thức của giải đua thuyền công bố sau mỗi cuộc
                  đua. Bất kỳ việc hủy hay sửa kết quả sau đó sẽ không được xem
                  xét cho mục đích cá cược.
                </span>
              </h4>
              <h4>
                <strong>3.31.1.2.</strong
                ><span>
                  Nếu cuộc đua bị hoãn hay hủy bỏ thì tất cả mọi khoản cược sẽ
                  được xem là hợp lệ trong trường hợp cuộc đua được tổ chức lại
                  trong thời hạn 48 giờ sau đó.
                </span>
              </h4>

              <h2><a name="R332" id="A332"></a>3.32. Đua xe đạp</h2>
              <h3>
                <strong>3.32.1.</strong
                ><span>
                  Tất cả các thị trường sẽ thống nhất theo kết quả chính thức
                  được công bố trên trang web chính thức sau mỗi cuộc đua. Các
                  tuyên bố không hợp lệ hoặc sửa đổi bất kỳ về kết quả sau khi
                  có thông báo chính thức sẽ không được xem xét cho mục đích cá
                  cược.
                </span>
              </h3>
              <h3>
                <strong>3.32.2.</strong
                ><span>
                  Nếu một cuộc đua bị hoãn hoặc bị hủy bỏ thì tất cả các đặt
                  cược được xem là hợp lệ nếu cuộc đua được tiếp tục lại trong
                  thời hạn 24 giờ.
                </span>
              </h3>
              <h3>
                <strong>3.32.3.</strong
                ><span>
                  Trong phiên cược Đối đầu, cả hai tay đua phải bắt đầu cuộc đua
                  thì đặt cược mới hợp lệ. Tay đua có vị trí kết thúc tốt hơn sẽ
                  được tuyên bố là người chiến thắng. Nếu cả hai tay đua không
                  hoàn thành cuộc đua thì đặt cược sẽ được tuyên bố vô hiệu và
                  sẽ được hoàn trả.
                </span>
              </h3>

              <h2><a name="R333" id="A333"></a>3.33. Cầu mây</h2>
              <h3>
                <strong>3.33.1.</strong>
                <span
                  >Cược theo Dòng tiền là cược người chiến thắng trận đấu hoặc
                  ván đấu cụ thể đã được qui định. Cược theo kèo chấp được dựa
                  trên các ván đấu hoặc điểm (vui lòng tham khảo tên loại cược);
                  Cược Trên / Dưới và Chẵn / Lẻ dựa trên điểm (trừ khi có quy
                  định khác).</span
                >
              </h3>
              <h3>
                <strong>3.33.2.</strong>
                <span
                  >Nếu một đội rút khỏi trận đấu hoặc bị loại trong trận đấu thì
                  tất cả cược sẽ bị vô hiệu, trừ các cược đã được xác định vô
                  điều kiện.</span
                >
              </h3>
              <h3>
                <strong>3.33.3.</strong>
                <span
                  >Nếu trận đấu bị hoãn hoặc bị ngừng thì tất cả các cược vẫn
                  được coi là hợp lệ nếu trận đấu tiếp tục lại trước khi kết
                  thúc thời hạn mười hai giờ.</span
                >
              </h3>
              <h3>
                <strong>3.33.4.</strong>
                <span
                  >Người Chiến thắng Ván Đầu tiên (Người Chiến thắng Ván Thứ
                  hai, Thứ ba, vân vân) đề cập đến kết quả của ván đã được quy
                  định cụ thể. Tất cả cược sẽ bị hủy nếu ván đã quy định không
                  được hoàn thành.</span
                >
              </h3>
              <h3>
                <strong>3.33.5.</strong>
                <span
                  >Tỷ số sẽ không được cập nhật cho cá độ Cầu mây trực
                  tiếp.</span
                >
              </h3>

              <h2>
                <a name="R335" id="A335"></a>3.35. Thể thao điện tử (E-Sports)
              </h2>
              <h3>
                <strong>3.35.1.</strong>
                <span>
                  Dòng Tiền dùng dể chỉ đội hay cá nhân đánh bại đối thủ của
                  mình hoặc được đặt ở cửa trên trong trận đấu sắp diễn ra. Cược
                  Người Chiến Thắng Trận Đấu dùng để chỉ số bản đồ.</span
                >
              </h3>
              <h3>
                <strong>3.35.2.</strong>
                <span>
                  Thị trường Trực tiếp chỉ khoảng thời gian sau thời điểm chính
                  thức bắt đầu trận đấu, nhưng trước thời điểm người chơi xuất
                  hiện trên bản đồ. Tất cả cược trực tiếp được thực hiện trong
                  giai đoạn chọn người được coi là hợp lệ. Thị trường đặt cược
                  Trong Trận chỉ khoảng thời gian sau khi quá trình chọn người
                  đã diễn ra và người chơi đã xuất hiện trên bản đồ.</span
                >
              </h3>
              <h3>
                <strong>3.35.3.</strong>
                <span>
                  Người chiến thắng giải thi đấu chỉ đội hay đối thủ cá nhân
                  chiến thắng trong trận chung kết của giải vô địch Thể thao
                  Điện tử.</span
                >
              </h3>
              <h3>
                <strong>3.35.4.</strong>
                <span>
                  Tất cả mọi thị trường sẽ được thanh toán dựa trên kết quả
                  chính thức do hiệp hội hay cơ quan tổ chức thi đấu bộ môn Thể
                  thao Điện tử liên quan công bố, có thể đăng trên trang web
                  chính thức của hiệp hội hay cơ quan này. Mọi sự truất quyền
                  thi đấu hay thay đổi kết quả sẽ không được xem xét để phục vụ
                  cho mục đích cá cược.</span
                >
              </h3>
              <h3>
                <strong>3.35.5.</strong>
                <span>
                  Nếu trận đấu đối kháng bị hoãn thì mọi khoản cược đều sẽ vẫn
                  được xem là có hiệu lực nếu trận đấu được nối lại trong vòng
                  12 giờ so với lịch thi đấu ban đầu.</span
                >
              </h3>
              <h3>
                <strong>3.35.6.</strong>
                <span>
                  Nếu một cặp đấu đã bắt đầu và kết quả chính thức được công bố,
                  tất cả đặt cược đều được coi là hợp lệ, bất kể tính không sẵn
                  sàng, ngắt kết nối của người chơi hay sự cố mất điện. Nếu một
                  cặp đấu không bắt đầu hoặc bắt đầu nhưng không được hoàn thành
                  và kết quả chính thức không được công bố, tất cả đặt cược sẽ
                  mất hiệu lực, ngoại trừ những đặt cược đã được xác định một
                  cách vô điều kiện.</span
                >
              </h3>
              <h3>
                <strong>3.35.7.</strong>
                <span>
                  Đối với Cược Trực tiếp, nếu dự đoán kết quả trận đấu được khởi
                  động lại nhưng người chơi vẫn được chọn tương tự (khởi động
                  lại); tất cả cược trong trận sẽ được hoàn lại, tất cả cược
                  trực tiếp trong quá trình chọn người đều hợp lệ. Nếu trận đấu
                  được khởi động lại và toàn bộ trận thi đấu được chơi lại có
                  bao gồm quá trình chọn người mới (làm lại), tất cả cược trực
                  tiếp và cược trong trận sẽ mất hiệu lực ngoại trừ cược trong
                  những thị trường đã được xác định vô điều kiện.</span
                >
              </h3>
              <h3>
                <strong>3.35.8.</strong>
                <span>
                  Nếu số vòng đấu/bản đồ được thay đổi so với số trong tiêu đề
                  thị trường, các cược chấp (handicap) và cược trên/dưới sẽ mất
                  hiệu lực trong khi các cược theo dòng tiền (moneyline) vẫn
                  được coi là hợp lệ.</span
                >
              </h3>
              <h3>
                <strong>3.35.9.</strong>
                <span>
                  Các thị trường Mạng chết Đầu tiên / Bên đạt 5, 10, 15 hay 20
                  lượt tiêu diệt trước dùng để chỉ đội đạt được số lượt tiêu
                  diệt trước. Chỉ những lượt tiêu diệt bởi nhóm/người chơi đối
                  thủ được tính.</span
                >
              </h3>
              <h3>
                <strong>3.35.10.</strong>
                <span>
                  Các thị trường Tháp canh/Trụ/Trại lính Đầu tiên dùng để chỉ
                  đội có được tháp canh/trụ/trại lính trước. Các cược hợp lệ
                  ngay cả khi nó bị phá hủy bởi creep hay bị từ chối.</span
                >
              </h3>
              <h3>
                <strong>3.35.11.</strong>
                <span>
                  Thị trường Khoảng thời gian dùng để chỉ tổng số phút chơi được
                  thể hiện trên bảng điểm khi kết thúc trận đấu. Đối với Dota 2,
                  khoảng thời gian trước đợt creep đầu tiên không được tính vào
                  tổng thời gian.</span
                >
              </h3>
              <h3>
                <strong>3.35.12.</strong>
                <span>
                  Thị trường Roshan/Baron/Rồng Đầu tiên chỉ đội tiêu diệt được
                  creep trung lập mạnh nhất trước.</span
                >
              </h3>
              <h3>
                <strong>3.35.13.</strong>
                <span>
                  Cược Lần Tiêu diệt (Cược đội thắng, Hòa, Trên/Dưới &amp;
                  Chẵn/Lẻ) là số lần tiêu diệt do đội đối thủ hay cá nhân cạnh
                  tranh thực hiện được vào thời điểm trận đấu chính thức kết
                  thúc. Nếu có đội đầu hàng trước khi trận đấu kết thúc, toàn bộ
                  lượt tiêu diệt đặt sau khi chiêu hàng sẽ được tính cho cược
                  này. LƯU Ý: Số lần Tiêu diệt từ tháp và nhóm lính được tính
                  vào tổng số chính thức như được minh họa trong bảng điểm. Số
                  lần Chết do tự giết, tự tử và lính trung lập không được tính
                  vào tổng số chính thức.</span
                >
              </h3>
              <h3>
                <strong>3.35.14.</strong>
                <span>
                  Cược Người thắng Vòng đấu là người thắng chính thức của vòng
                  đấu hoặc một tập hợp các vòng đấu. Nó chỉ được áp dụng cho
                  những ván đấu trong đó bản đồ được chia thành các vòng đấu
                  riêng biệt</span
                >
              </h3>
              <h3>
                <strong>3.35.15.</strong>
                <span>
                  Cược Số Lần Tiêu Diệt Đối Thủ dùng để chỉ tổng số lượt tiêu
                  diệt đối thủ của một người chơi cụ thể trong một Trận Đấu, bản
                  đồ hay vòng đấu cụ thể.</span
                >
              </h3>
              <h3>
                <strong>3.35.16.</strong>
                <span>
                  Cược Dòng Tiền, Cược Chấp, Cược Trên/Dưới &amp; Chẵn/lẻ trong
                  Counter Strike dùng để chỉ số vòng đấu chiến thắng và sẽ được
                  thanh toán khi có kết quả cuối cùng, bao gồm cả thời gian bù
                  giờ (trừ khi được quy định khác).</span
                >
              </h3>

              <h2><a name="R336" id="A336"></a>3.36. Quyền Thái</h2>
              <h3>
                <strong>3.36.1.</strong>
                <span>
                  Màu Đỏ hay Xanh của các võ sĩ hoàn toàn chỉ nhằm mục đích tham
                  thảo. Tất cả các cược sẽ được xem là có hiệu lực bất kể sự
                  thay đổi về màu sắc của võ sĩ.</span
                >
              </h3>
              <h3>
                <strong>3.36.2.</strong>
                <span>
                  Cược chấp là cược dự đoán võ sĩ chiến thắng trong trận đấu.
                  Tất cả các cược Chấp sẽ bị hủy bỏ của trận đấu được tuyên bố
                  là hòa.</span
                >
              </h3>
              <h3>
                <strong>3.36.3.</strong>
                <span>
                  Cược Trên/Dưới là cược số hiệp đấu đã hoàn thành trong trận
                  đấu. Vì vậy, nếu một trận đấu kết thúc trong vòng 5 hiệp đấu,
                  lúc ấy số hiệp đã hoàn thành là 4. Nếu trận đấu được quyết
                  định bởi trọng tài, lúc ấy, số hiệp đấu đã hoàn thành là
                  5.</span
                >
              </h3>
              <h3>
                <strong>3.36.4.</strong>
                <span>
                  Nếu một hay cả hai võ sĩ được trọng tài tuyên bố không đủ tư
                  cách chiến thắng, lúc ấy, tất cả các cược sẽ được tuyên bố là
                  hủy bỏ.</span
                >
              </h3>
              <h3>
                <strong>3.36.5.</strong>
                <span>
                  Nếu một trận đấu bị hoãn lại và không bắt đầu trong vòng 12
                  giờ kể từ thời gian bắt đầu đã lên lịch, lúc ấy tất cả các
                  cược được xem là hủy bỏ.</span
                >
              </h3>
              <h3>
                <strong>3.36.6.</strong>
                <span>
                  Một trận đấu được xem là đã bắt đầu khi chuông reo khi bắt đầu
                  hiệp đấu đầu tiên. Nếu một hoặc cả hai võ sĩ không thể bắt đầu
                  trận đấu, lúc ấy tất cả các cược sẽ bị hủy bỏ.</span
                >
              </h3>
              <h3>
                <strong>3.36.7.</strong>
                <span>
                  Tất cả các cược sẽ được xác định dựa trên tiếng chuông quyết
                  định chính thức ngay sau trận đấu. Các thay đổi sau đó về kết
                  quả sẽ không được công nhận là dành cho mục đích đặt
                  cược.</span
                >
              </h3>

              <h2><a name="R337" id="A337"></a>3.37. Entertainment</h2>
              <h3>
                <strong>3.37.1.</strong>
                <span>
                  Sing! China – Which team/player will advance furthest in the
                  show.</span
                >
              </h3>
              <h3>
                <span>
                  Two teams/players will be paired for betting selection,
                  teams/players belonging to different groups may also be paired
                  for betting selection.</span
                >
              </h3>
              <h3>
                <span>
                  If the two teams/players paired for betting selection qualify
                  for the next stage, then all bets for both teams/players will
                  be carried over to the succeeding stage.</span
                >
              </h3>
              <h3>
                <span>
                  Bets will be considered as draw if the two teams/players are
                  eliminated at the same stage.</span
                >
              </h3>

              <!-- End Entertainment -->
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script scoped>
export default {
  data() {
    return {
      showTop: false,
    };
  },
  methods: {
    // eslint-disable-next-line
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop != 0) {
        this.showTop = true;
      } else {
        this.showTop = false;
      }
      // console.log(scrollTop);
      // console.log(clientHeight);
      // console.log(scrollHeight);
      // if (scrollTop + clientHeight >= scrollHeight) {
      //   console.log("scrollHeight");

      // }
    },
    scrollTop() {
      document.getElementById('containerDiv').scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    },
  },
};
</script>

<style scoped>
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  line-height: 1.025rem;
  margin-bottom: 0.75rem !important;
  letter-spacing: 0.18px;
  text-align: left;
  color: white;
}

h1 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 0px;
  letter-spacing: 0.18px;
}

h2 {
  font-weight: 200;
  font-size: 14px;
  font-weight: bold;
  padding-left: 25px;
  letter-spacing: 0.18px;
}

h3 {
  font-weight: 200;
  font-size: 12px;
  font-weight: normal;
  padding-left: 50px;
  letter-spacing: 0.18px;
}

h4 {
  font-weight: 200;
  font-size: 12px;
  font-weight: normal;
  padding-left: 50px;
  letter-spacing: 0.18px;
}

h5 {
  font-weight: 200;
  font-size: 12px;
  font-weight: normal;
  padding-left: 50px;
  letter-spacing: 0.18px;
}

.button span {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  background-color: #907339;
  border-radius: 5px;
  line-height: 20px;
  width: 105px;
  height: 20px;
  display: block;
  text-align: center;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
}

.button:hover span {
  color: #000;
}

.container1 {
  text-align: center;
  max-width: 1200px;
  height: 200px;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  background-color: #1c3452;
  padding: 0px;
}

.centered {
  font-size: 2em;
  text-shadow: 2px 2px #000;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrapper {
  color: #191002;
  margin: 0 auto;
  max-width: 1200px;
  max-height: 36rem;
  text-align: justify;
  padding: 20px;
  overflow: auto;
  overflow-y: scroll;

  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.18px;
  text-align: left;
  margin-right: 20px;
}
.main-img {
  max-width: 1200px;
  height: 200px;
  background-image: url('../../../../../assets/images/rules.jpg');
  background-size: cover;
  background-position: center;
}
b,
strong {
  font-weight: 700 !important;
}
span {
  font-weight: 700 !important;
}

.termsList {
  margin-top: 1.25rem;
}

.returnTop {
  position: absolute;
  width: 40px;
  right: 20px;
  top: 0;
  cursor: pointer;
}
</style>
