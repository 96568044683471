//banner import
import en from './../icons/lang/icn-flag-en.png';
import cn from './../icons/lang/icn-flag-cn.png';
//import vn from './../icons/lang/icn-flag-vn.png';

var languageList = [
  //banner  image import
  {
    id: 0,
    name: 'ENGLISH',
    code: 'EN-US',
    icn: en,
  },
  {
    id: 1,
    name: '中文（简体)',
    code: 'ZH-CN',
    icn: cn,
  },
  // {
  //   id: 3,
  //   name: 'Tiếng Việt',
  //   code: 'EN-IE',
  //   icn: vn,
  // },
];

export default languageList;
