export default {
  setSession(state, payload) {
    state.session = payload;
  },
  setSelectedHeaderIndex(state, payload) {
    state.selectedHeaderIndex = payload;
  },
  setSelectedGame(state, payload) {
    state.selectedGame = payload;
  },
  setMiniGameUrl(state, payload) {
    state.miniGameUrl = payload;
  },
  setTransferGameProvider(state, payload) {
    state.transferGameProvider = payload;
  },
  setIsWalletTransfer(state, payload) {
    state.isWalletTransfer = payload;
  },
  setTransferError(state, payload) {
    state.transferError = payload;
  },
};
