<template>
  <div id="home-body">
    <div class="row mx-0">
      <desktop-header></desktop-header>
      <!--- page content --->

      <div class="col-12 px-0 py-5 info-cover">
        <div class="container clear-both">
          <div class="row px-0">
            <!--- page header --->
            <!--- end page header --->
            <!----card section --->

              <div class="col-lg-3 about-menu-container">
                <div class="sticky-menu">
                  <template v-for="(tab, index) in tabList" :key="tab.name">
                    <router-link :to="tab.path" @click="scrollTopCard" class="btn-about" :class="[ selectedTabIndex == index ? 'btn-active-tab' : 'btn-inactive-tab']">

                        &nbsp;{{ $t(tab.name) }}&nbsp;
                    </router-link>
                  </template>
                  </div>
              </div>
              <!----main statement content---->
              <div class="col-lg-9">
                  <div id="cardInside">
                      <AboutTab v-if="currentPath == '/about'" />
                      <FaqTab v-if="currentPath == '/faq'" />
                      <RulesTab v-if="currentPath == '/rules'" />
                      <WhyTab v-if="currentPath == '/why-us'" />
                      <ContactTab v-if="currentPath == '/contact'" />
                  </div>
              </div>

            <!---end card section -->
          </div>
        </div>
      </div>

      <!----end page comtent --->
      <!-- Footer-->
      <desktop-footer></desktop-footer>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
// import CustomHttp from '../../../../CustomHttp';
// import i18n from '../../../../i18n';
// const { t } = i18n.global;
import AboutTab from "./AboutTab.vue";
import FaqTab from "./FaqTab.vue";
import RulesTab from "./RulesTab.vue";
import WhyTab from "./Why.vue";
import ContactTab from "./Contact.vue";
//Vue export
export default {
  components: { AboutTab, FaqTab, RulesTab, WhyTab, ContactTab },
  mounted() {
    this.computedHighlightedPath();
  },
  data() {
    return {
      tabList: [
        {
          name: "AboutUs2",
          path: "/about",
        },
        {
          name: "WhyUs2",
          path: "/why-us",
        },
        {
          name: "FAQ2",
          path: "/faq",
        },
        {
          name: "BettingRules2",
          path: "/rules",
        },
        {
          name: "ContactUs2",
          path: "/contact",
        },
      ],
      selectedTabIndex: 0,
    };
  },
  methods: {
    computedHighlightedPath() {
      let index = this.tabList.findIndex((t) => t.path == this.$route.path);
      this.selectedTabIndex = index;
    },
    scrollTopCard(){
      document.getElementById("cardInside").scrollTop = 0;
    }
  },
  computed: {
    computedLangId() {
      return this.$store.getters.langId;
    },
    currentPath() {
      return this.$route.path;
    },
  },
  watch: {
    // eslint-disable-next-line
    currentPath(newCount, oldCount) {
      this.computedHighlightedPath();
    },
    // eslint-disable-next-line
    computedLangId(newCount, oldCount) {
      this.computedHighlightedPath();
      // Our fancy notification (2).
      // this.isLoading = true;
      // window.location.reload();
      // this.$i18n.locale = this.$store.getters.langId.toString();
    },
  },
};
</script>

<style scoped>
.sticky-menu{
  position: sticky; 
  top:100px
}
  .dice-bottom{
    position: absolute;
    right: -20px;
    bottom: -20px;
  }
  .info-cover{
    background: url('../../../../assets/images/main-bg.jpg') #e4eafa no-repeat 0 0 / 100% 100%;
  }
.btn-about{
  width: 100%;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.34);
  font-size: large;
  background: #2f4798;
  margin-bottom: 0.5em;
  padding: 20px 20px;
  font-family: 'frank';
  font-weight: normal;
}

.card {
  border: 0px;
  position: relative;
  background: none;
}
.card-body {
  padding: 0 1em;
}
.bg-light-grey {
  background-color: #f7f7f7;
}

.bg-light-gold-gradient {
  background: linear-gradient(to left, rgba(241, 215, 152, 0), #f5b50a);
  border-radius: 5px;
}

.menu-text {
  margin-top: 4px;
  font-size: 12px;
  white-space: nowrap;
}

.account-text {
  font-weight: 600;
  opacity: 0.9;
  color: #36342f;
  padding-top: 3px;
  padding-bottom: 3px;
  /* font-family: SegoeUI; */
  font-size: 16px;
}

.account-btn {
  text-align: center;
  flex-wrap: wrap;
  padding: 7px 10px;
}

.left-card {
  color: #2f4798;
}

.account-tab-active {
  background-image: linear-gradient(to bottom, #36342f, #5c4812);
}

.account-tab {
  background-color: #fff;
}

/* .account-tab-active:hover,
.account-tab:hover {
  opacity: 1;
} */

.tab-label-active {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.tab-label {
  color: #908d84;
  font-size: 14px;
  font-weight: 600;
}

.img-tab {
  width: 18px;
  height: 18px;
  margin-right: 5px !important;
}

.tab-border {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.btn-inactive {
  font-size: 12px;
  font-weight: 600;
  color: #b5b5b5;
  /* font-family: SegoeUI; */
  border: solid 1px #ddd;
  background-color: #fff;
  border-radius: 10px;
}

.btn-active {
  /* font-family: SegoeUI; */
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ddd;
  background-image: linear-gradient(to bottom, #050202, #574115) !important;
}

.btn-active-tab {
  color: black;
  background: #ff9611;
}

.small,
small {
  font-size: 80% !important;
  font-weight: 400;
}

.container-min-height {
  min-height: 85vh;
}

.btn-default {
  border: 0px solid #f5f5f5;
}

.radius-top-left {
  border-radius: 5px 0 0 0;
}

.radius-top-right {
  border-radius: 0 5px 0 0;
}

.last-tab {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.fa-plus {
  position: relative;
  top: -1.5px;
}

.fa-minus {
  position: relative;
  top: -1.5px;
}
/*Scrollbar for Vsport */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 0px;
  background: blue;
  background: linear-gradient(#4a4a4a, #4a4a4a) no-repeat center/2px 100%;
  margin-top: 50px;
  margin-bottom: 150px;
}
::-webkit-scrollbar-thumb {
  width: 3px;
  height: 77px;
  border-radius: 10px;
  background-color: #4a4a4a;
}
::-webkit-scrollbar-thumb:window-inactive {
  width: 3px;
  height: 77px;
  border-radius: 10px;
  background-color: #4a4a4a;
}
</style>
