import gameImport from './../game';

import fachai from './../../images/game/fishing/img-fachai-fish-d.png';
import jili from './../../images/game/fishing/img-jili-fish-d.png';
import spadefish from './../../images/game/fishing/img-spadegaming-fish-d.png';
import play8 from './../../images/game/fishing/img-play8-fish-d.png';

var gameMenu = {
  name: 'FISHING',
  items: [
    {
      name: 'Spade Gaming',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-302',
      img: [{ langId: 0, src: spadefish }],
      img2: [{ langId: 0, src: spadefish }],
    },
    {
      name: 'Jili',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-305',
      img: [{ langId: 0, src: jili }],
      img2: [{ langId: 0, src: jili }],
    },
    {
      name: 'Fa Chai',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-304',
      img: [{ langId: 0, src: fachai }],
      img2: [{ langId: 0, src: fachai }],
    },
    {
      name: 'Play 8',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-307',
      img: [{ langId: 0, src: play8 }],
      img2: [{ langId: 0, src: play8 }],
    },
  ],
};

export default gameMenu;
