<template>
  <span
    class="preload-img-span"
    v-for="p in preloadArr"
    :key="p.src"
    :style="'background:url(' + p.src + ') no-repeat; background-size: 0;'"
  >
  </span>
  <div id="home-body">
    <div class="mx-0">
      <desktop-header ref="desktopHeader"></desktop-header>
      <!----promo banner---->
      <!--
      <div class="clear-both col-md-12 px-0">
        <div class="container px-0 py-3">
          <div class="col-12 px-0">
            <div class="row">
              <div
                class="col-md-4 py-1 px-2 banner-hover"
                v-for="banner in banners"
                :key="banner.imgUrl"
              >
                <img
                  class="d-block w-100 content-header-banner cursor-pointer"
                  :src="banner.imgUrl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  -->
      <!-- banner -->
      <div class="banner-wrap">
      <div class="container banner-container">
        <!--
        <div class="banner-left" data-target="#desktopBanner" data-slide="prev"></div>
        <div class="banner-right" data-target="#desktopBanner" data-slide="next"></div>
      -->
      
        <div
          id="desktopBanner"
          data-interval="3500"
          class="carousel slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li class="carousel-dot active" data-target="#desktopBanner" data-slide-to="0"></li>
            <li v-for="(banner, idx) in desktopBanners.length - 1 " class="carousel-dot" data-target="#desktopBanner" :data-slide-to="idx + 1" :key="idx"></li>
          </ol>
          <div class="carousel-inner">
            <div
              class="carousel-item"
              v-for="(banner, idx) in desktopBanners"
              :key="idx"
              :class="{ active: idx == 0 }"
            >
              <img
                :src="getLangBanner(banner)"
                alt="banner"
                class="d-block content-header-banner"
                @click="onClickBanner(banner)"
              />
            </div>
          </div>
        </div>
      
      </div>
      </div>
      <!----system message ---->
      <div
        class="clear-both px-0"
        v-if="isLoggedIn && systemMessage != ''"
      >
        <div class="container">
          <router-link to="/message">
            <div class="announcements col-center">
                <img src="./../../assets/icons/icn-speaker.png" class="img-annoucement" />
                <div class="an-mask">
                    <div class="ml-4 small announcement-scroll">{{ systemMessage }} </div>
                </div>
            </div>
          </router-link>
        </div>
      </div>
      <!----page content--->
      <div class="hotgames-container">
        <div class="container clear-both">
          <div class="full-center py-3">
            <img src="./../../assets/icons/hot-title.svg" width="24" />
            <h3 class="hot-title">{{$t('HOT GAMES')}}</h3>
          </div>
          <div>
            <!---Menu card--->
              <div class="row padding-tablet">
                  <div
                    class="col-3 game-card-wrap menu-hover"
                    v-for="(game, index) in games"
                    :key="game.name"
                    @click="onClickGameIcon(index)"
                  >
                    <div class="game-card">
                      <img
                        class="card-img-md"
                        :src="getLangImg(game)"
                        alt="game img"
                      />
                    </div>
                    <div class="game-title">
                      {{game.name}}
                    </div>
                  </div>
            </div>

            <!----second menu card--->
            <!-- <div class="col-12 mt-4">
              <div class="row">
                <div class="col-12 py-0">
                  <div
                    class="menu-btn d-flex align-items-center col-5 bg-gold-gradient py-0"
                  >
                    <img
                      class="float-left menu-img-lg"
                      src="./../../assets/icons/menu/menu-jackpot.png"
                    />
                    <div class="menu-text pl-1 text-white pt-2">
                      <h6>
                        <strong>GRAND JACKPOT</strong>
                        FOR YOU
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

    
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6 mt-3 pr-0">
                  <div class="col-12 pl-0">
                    <div class="game-card menu-hover">
                      <img
                        class="card-img-mini"
                        :src="habaGame.img"
                        alt="game img"
                      />
                      <div class="game-footer game-footer-card col-12 py-3">
                        <div class="row">
                          <div class="col-10 pl-4">
                            <div class="row">
                              <div class="float-left text-dark pt-1 col-12">
                                <strong> {{ habaGame.name }}</strong>
                              </div>
                              <div class="float-left text-dark pt-1 col-12">
                                {{ habaGame.name2 }}
                              </div>
                            </div>
                          </div>

                          <div class="col-2 pt-2">
                            <img
                              class="icon-play cursor-pointer"
                              src="../../assets/icons/icn-gold-play.png"
                              alt="play icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 col-lg-9 col-sm-6 px-0">
                  <div class="col-12">
                    <div class="row">
                      <div
                        class="col-lg-2 col-md-4 mt-3 game-card-wrap pl-2 pr-1 menu-hover"
                        v-for="game in miniGames"
                        :key="game.name"
                      >
                        <div class="game-card mt-1">
                          <img
                            class="card-img-sm"
                            :src="game.img"
                            alt="game img"
                          />
                          <div class="game-footer game-footer-mini col-12">
                            <span class="float-left text-dark pt-1">
                              <strong>{{ game.name }}</strong>
                            </span>
                            <span class="float-right">
                              <img
                                class="icon-play-outline cursor-pointer"
                                src="../../assets/icons/icn-play-outline.png"
                                alt="play icon"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!--Bottom card-->

             <div class="mb-3 mt-3">
              <div class="row">
                <template v-for="(game, index) in gamesBanner" :key="game.name">
                  <div class="col-12 col-lg-6 mt-5 tablet-card">
                    <div
                      class="catalog-card menu-hover"
                      @click="onClickGameBanner(index)"
                    >
                      <img
                        class="catalog-img"
                        :src="game.imgUrl"
                        alt="Card image"
                      />
                      <div class="">
                        <div
                          class="game-lg-title"
                          :class="game.textClass"
                        >
                          {{ $t(game.title) }}
                        </div>
                        <div class="pb-1 pt-1">
                          <span
                            class="game-desc2"
                            v-html="$t(game.desc)"
                          >
                          </span>
                        </div>
                        <div>
                          <button
                            class="btn-game-lg"
                            :class="game.btnClass"
                          >
                            <img src="../../assets/icons/highlight-play.svg" />
                            <span>{{ $t('PLAYNOW') }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            

          </div>
        </div>
      </div>

      <!-- Footer-->
      <desktop-footer></desktop-footer>
    </div>

    <!--- login modal--->
    <login-modal
      ref="loginModal"
      :errMessage="loginPanelErr"
      @toggle-danger="toggleDangerAlert"
      @set-err-messsage="setErrMessage"
    ></login-modal>
    <!---game  modal --->
    <game-modal ref="gameModal"></game-modal>
    <!---end game  modal --->

    <loading-spinner v-if="isLoading"></loading-spinner>
    <transition name="fadeIn">
      <div v-if="showSuccessAlert">
        <success-alert :message="successMessage"></success-alert>
      </div>
    </transition>
    <transition name="fadeIn">
      <div v-if="showErrorAlert">
        <danger-alert :message="errorMessage"></danger-alert>
      </div>
    </transition>
  </div>
</template>

<script>
// import i18n from "./../../../i18n";
// const { t } = i18n.global;
import desktopImport from '../../assets/script/desktop.js';
import desktopB from '../../assets/script/desktopBanner.js';
// import CustomHttp from '../../CustomHttp';
import hotGameMenu from '../../assets/script/catalog/hot';

// import bannerImport from "../../assets/script/gameBanner";
// import desktopPreload from "../../assets/script/desktopPreload";
import loginBackgroundSrc from '../../assets/images/icn-login-panel.png';
//import $ from 'jquery';

// import arr from '../../assets/icons/arrow-down.png';
//Vue export
export default {
  beforeMount() {
    //banner of game page
    this.preloadArr = [];
    // this.preloadArr = [...bannerImport.banners];

    this.preloadArr.push({ desc: '', src: loginBackgroundSrc });

    this.preloadArr = [
      ...this.preloadArr,
      // ...desktopPreload.img.items,
      // ...desktopPreload.img2.items,
    ];

    //this.preloadArr = bannerImport.banners;
  },
  mounted() {
    this.isLoading = false;
    this.onLoadValidate();
    setTimeout(()=>{
      eval("$('#desktopBanner').carousel({interval: 3500});");
    },400);
    setTimeout(()=>{
      //fixing weird carousel issue with safari
      eval(`
        $('#desktopBanner').carousel(0);
        $('.carousel-dot').removeClass('active');
        $('.carousel-dot:first').addClass('active');
        `);
    },500)
  },
  data() {
    return {
      inputUsername: '',
      inputPassword: '',
      showLoginModal: false,
      selectedMenuIndex: 0,
      isLoading: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      successMessage: '',
      errorMessage: '',
      loginPanelErr: '',
      isDropdownOpen: false,
      desktopBanners : desktopB.banner,
      banners: [
        { imgUrl: desktopImport.bannerUrl1 },
        { imgUrl: desktopImport.bannerUrl2 },
        { imgUrl: desktopImport.bannerUrl3 },
      ],
      menuIcons: [
        {
          name: 'HOT',
          isActive: false,
          iconUrl: desktopImport.icnHot,
          iconUrlActive: desktopImport.icnHotActive,
        },
        {
          name: 'SPORTSBOOK',
          isActive: false,
          iconUrl: desktopImport.sb,
          iconUrlActive: desktopImport.sbActive,
        },
        {
          name: 'LIVE CASINO',
          isActive: false,
          iconUrl: desktopImport.lc,
          iconUrlActive: desktopImport.lcActive,
        },
        {
          name: 'SLOT',
          isActive: false,
          iconUrl: desktopImport.slot,
          iconUrlActive: desktopImport.slotActive,
        },
        {
          name: 'ITP SLOT',
          isActive: false,
          iconUrl: desktopImport.itp,
          iconUrlActive: desktopImport.itpActive,
        },
        {
          name: 'CARDS',
          isActive: false,
          iconUrl: desktopImport.cards,
          iconUrlActive: desktopImport.cardsActive,
        },
        {
          name: 'FISHING',
          isActive: false,
          iconUrl: desktopImport.fishing,
          iconUrlActive: desktopImport.fishingActive,
        },
        {
          name: 'LOTTO',
          isActive: false,
          iconUrl: desktopImport.lotto,
          iconUrlActive: desktopImport.lottoActive,
        },
        {
          name: 'eSPORT',
          isActive: false,
          iconUrl: desktopImport.esport,
          iconUrlActive: desktopImport.esportActive,
        },
      ],
      sportsbookGame: {
        name: 'Sportsbook',
        img: [
          { langId: 0, src: desktopImport.gameSportsbook },
          {
            langId: 2,
            src: desktopImport.gameSportsbookTh,
          },
        ],
      },
      habaGame: {
        name: 'HABANERO SLOT',
        name2: 'ITP',
        img: desktopImport.miniHabanero,
      },
      gamesBanner: [
        {
          title: 'Sportsbook',
          desc: 'hot_sportsbook',
          desc2: '',
          imgUrl: desktopImport.gameLgSportsbook,
          textClass: '',
          btnClass: '',
          url: '/sportsbook',
        },
        {
          title: 'Slot',
          desc: 'hot_slot',
          desc2: '',
          imgUrl: desktopImport.gameLgSlot,
          textClass: '',
          btnClass: '',
          url: '/slot',
        },
        {
          title: 'LiveCasino',
          desc: 'hot_live',
          desc2: '',
          imgUrl: desktopImport.gameLgLive,
          textClass: '',
          btnClass: '',
          url: '/live-casino',
        },
        {
          title: 'Fishing',
          desc: 'hot_fish',
          desc2: '',
          imgUrl: desktopImport.gameLgFishing,
          textClass: '',
          btnClass: '',
          url: '/fishing',
        },
      ],
      miniGames: [
        {
          name: 'Fa Chai',
          img: desktopImport.miniFachai,
        },
        {
          name: 'PTS SLOT',
          img: desktopImport.miniPtS,
        },
        {
          name: 'SPADE GAMING',
          img: desktopImport.miniSpade,
        },
        {
          name: 'JOKER',
          img: desktopImport.miniJoker,
        },
        {
          name: 'JILI',
          img: desktopImport.miniJili,
        },
        {
          name: 'RED TIGER',
          img: desktopImport.miniRedTiger,
        },
        {
          name: "PLAY'N GO SLOT",
          img: desktopImport.miniPlayAndGo,
        },
        {
          name: 'NEXTSPIN SLOT',
          img: desktopImport.miniNextSpin,
        },
        {
          name: 'KING MAKER',
          img: desktopImport.miniKing,
        },
        {
          name: 'CQ9 SLOT',
          img: desktopImport.miniCQ9,
        },
        {
          name: 'AE GAMING SLOT',
          img: desktopImport.miniAEG,
        },
        {
          name: 'GIOCO PLUS SLOT',
          img: desktopImport.miniGioco,
        },
      ],
      games: hotGameMenu.items,
      preloadArr: null,
    };
  },
  methods: {
    bannerRoute(url){
      this.$router.replace(url)
    },
    onClickGameBanner(i) {
      const bannerUrl = this.gamesBanner[i].url;
      this.$router.replace(bannerUrl);
    },
    async onLoadValidate() {
      const loginUser = this.$route.query.username;
      const loginSession = this.$route.query.sessionId;
      if (!loginUser || !loginSession) {
        return;
      }
      const json = {
        username: loginUser,
        sessionId: loginSession,
      };
      this.isLoading = true;
      try {
        await this.$store.dispatch('userValidate', json);
        this.isLoading = false;
        this.$router.replace(window.location.origin);
      } catch (error) {
        this.isLoading = false;
        this.toggleDangerAlert(error);
        this.$router.replace(window.location.origin);
      }
    },
    toggleSuccessAlert(message) {
      this.successMessage = message;
      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 1500);
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1500);
    },
    onClickGameIcon(i) {
      if(i == 0){
        //only for sportsbook
        this.goToSportsbook2();
        return false;
      }
      if (!this.$store.getters.isLoggedIn) {
        //not logged in
        this.$refs.desktopHeader.onClickGameIcon(i);
      } else {
        const selectedGame = this.games[i];
        this.$store.dispatch('setSelectedGame', selectedGame);

        this.$refs.desktopHeader.toOpenGameModal();

        // this.$refs.gameModal.showModal();
        // this.$refs.gameModal.getGameDetails();
      }
    },
    setErrMessage(errMessage) {
      this.loginPanelErr = errMessage.toString();
    },
   async goToSportsbook() {
      this.$refs.desktopHeader.goToSportsbook();
    },
    async goToSportsbook2() {
      this.$refs.desktopHeader.goToSportsbook2();
    },
    getLangImg(obj) {
      let imgSrc = obj.img[0].src;
      let tempSrc = obj.img.find((i) => i.langId == this.$store.getters.langId);
      if (tempSrc) {
        imgSrc = tempSrc.src;
      }
      return imgSrc;
    },
    getLangBanner(obj) {
      let imgSrc = obj.imgUrl[0].src;
      let tempSrc = obj.imgUrl.find(
        (i) => i.langId == this.$store.getters.langId
      );
      if (tempSrc) {
        imgSrc = tempSrc.src;
      }
      return imgSrc;
    },
    onClickBanner(obj) {
      
      if (!this.$store.getters.isLoggedIn) {
        //not logged in
        this.$refs.desktopHeader.onClickGameIcon(null);
      } else {
        if(obj.game == ''){
          return false;
        }
        const selectedGame = obj.game;
        // console.log('selectedGame');
        // console.log(selectedGame);

        if(obj.game.target =='sportsbook'){
          this.$refs.desktopHeader.goToSportsbook2(true);
        }else{
          this.$store.dispatch('setSelectedGame', selectedGame);
          this.$refs.desktopHeader.toOpenGameModal();
        }

      }
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    systemMessage() {
      let message;
      message = this.$store.getters.systemMessage;
      return message.replace(/(<([^>]+)>)/gi, '');
      // return message;
    },
    langId() {
      return this.$store.getters.langId;
    },
    isTimeout() {
      return this.$store.getters.isTimeout;
    },
  },
  watch: {
    // eslint-disable-next-line
    // langId(newCount, oldCount) {
    // },
    // eslint-disable-next-line
    systemMessage(newCount, oldCount) {
      
      if(newCount.replace(/[\n\r\s\t]+/g, '') != oldCount.replace(/[\n\r\s\t]+/g, ''))
      {
       this.isLoading = true;
       window.location.reload();
      }

      // this.isLoading = true;
      // Our fancy notification (2).
      //window.location.reload();
      // console.log(this.systemMessage);
      // this.$i18n.locale = this.$store.getters.langId.toString();
    },
    /* eslint-disable */
    // isTimeout(newValue, oldValue) {
    //   if (newValue) {
    //     this.toggleDangerAlert(t("Session timed out. Please login again."));
    //     this.logUserOut();
    //   }
    // },
    /* eslint-enable */
  },
};
</script>

<style scoped lang="css" src="../../assets/css/desktop.css"></style>
