<template>
      <div class="card-body">
      <h4 class="frank header">Contact</h4>
      <hr style="border-color:#1c327e">

      <div class="row">
        <div class="col-6 mb-5" v-for="d in dataList" :key="d.seq">
          
          <img class="my-4" v-bind:src="d.src" :width="d.width"/>

          <div>
              <div class="title"> {{ d.title }}</div>
              <div class="desc">
                <a :href="d.link" :target="d.target"> {{ d.desc }} </a>

              </div>
              <div v-if="d.link2">
                <a :href="d.link2" :target="d.target"> {{ d.desc2 }} </a>
              </div>
          </div>




        </div>
      </div>
    </div>
</template>

<script>
import contact1 from "./../../../../assets/icons/information/contact1.svg";
import contact2 from "./../../../../assets/icons/information/contact2.svg";
import contact3 from "./../../../../assets/icons/information/contact3.svg";
import contact4 from "./../../../../assets/icons/information/contact4.svg";

export default {
  components: {},
  data() {
    return {
      dataList: [
        {
          seq: 1,
          width: 40,
          title: "Mobile Betting",
          src: contact1,
          desc: "mob.YBET99.com",
          desc2: "wap.YBET99.com",
          link: "https://mob.YBET99.com",
          link2: "https://wap.YBET99.com",
          target: "_blank",

        },
        {
          seq: 2,
          width : 40,
          title: "Agent Mobile",
          src: contact2,
          desc: "aw.YBET99.com",
          link: "https://aw.YBET99.com",
          target: "_blank",
        },
         {
          seq: 3,
          width : 64,
          title: "Smart Betting",
          src: contact3,
          desc: "smart.YBET99.com",
          link: "https://smart.YBET99.com",
          target: "_blank",
        },
        {
          seq: 4,
          width : 64,
          title: "Customer Service",
          src: contact4,
          desc: "YBET99sup@gmail.com",
          link: "mailto:support@YBET99sup@gmail.com",
          target: "_top",
        },
      ],
    };
  },
  methods: {},
  computed: {
    computedLangId() {
      return this.$store.getters.langId;
    },
  },
};
</script>

<style scoped>
.header{
  color: #ff9611;
}
.card-body {
  padding: 0 1em;
  color: #2f4798;
}

.card-text {
  font-size: 12px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  color: #36342f;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 1.1rem;
  opacity: 0.85;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
  color: #fff;
}

.icn-round {
  width: 30px;
  height: 30px;
  padding: 5px 10px 5px 10px;
  border-radius: 28px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.24px;
  text-align: left;
  color: #735505;
  white-space: nowrap;
}

.title {
  font-size: x-large;
  text-align: left;
  color: #ff9611;
  font-family: 'frank';
}
.desc {
  letter-spacing: 0.18px;
  text-align: left;
}

a {
  text-decoration: none;
}

a:focus,
a:hover {
  text-decoration: underline;
}

a:active,
a:hover {
  outline: 0;
}
</style>
