<template>
  <!-- Page Content -->

  <!--- enter lobby loading pop out -->
  <transition name="fadeIn">
    <template v-if="isWalletTransfer">
      <TransferModal @set-isWalletTransfer="setIsWalletTransfer" />
    </template>
  </transition>
  <div id="page-wrapper mobile-body-font">
    <loading-spinner v-if="isLoading"></loading-spinner>
    <div class="container-fluid">
      <div class="row">
        <!----promo banner --->
        <div class="content-header-container">
          <!-- data-interval="500" -->
          <div
            id="promoBanner"
            data-interval="3500"
            class="carousel slidepl-0"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div
                class="carousel-item"
                v-for="(banner, idx) in banners"
                :key="banner.imgUrl"
                :class="{ active: idx == 0 }"
              >
                <img
                  :src="getLangBanner(banner)"
                  alt="banner"
                  class="d-block w-100 content-header-banner"
                  @click="onClickBanner(banner)"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- end banner -->
        <!---start game manu section--->

          <div class="col-center menu-top-btn-container">
            <div
              class="menu-overlay"
              :class="{ active: item.isActive }"
              v-for="(item, index) in menuIcons"
              :key="item.message"
              @click="onClickMenuIcon(index)"
            >
              <div
                class="menu-left-btn"
              >
                <img
                  :class="[
                    item.isActive ? 'menu-icon-active active' : 'menu-icon',
                  ]"
                  :src="
                    item.isActive ? item.iconUrlActive : item.iconUrl
                  "
                />
                <div class="menu-text">{{ $t(item.name) }}</div>
              </div>
            </div>
        </div>

            <!--game card --->
            <div
              class="col-12 animated flash game-card-wrap pb-2"
            >
              <div
                @click="onClickMenuIcon(null)"
                class="parent-label"
                v-if="selectedGameParent != ''"
              >
                <img
                  src="./../../../assets/icons/icn-back-gold.png"
                  width="22"
                />
                {{ selectedGameParent }}
              </div>

              <template
                v-for="(gameCard, index) in menuIcons[selectedMenuIndex].games"
                :key="gameCard.name + gameCard.code"
              >
                <div
                  class="game_card_container slide px-0 py-0"
                  :class="[gameCard.isFullWidth ? 'col-12' : 'col-4']"
                >
                  <div class="game_card" @click="onClickGameIcon(index)">
                    <img
                      v-if="gameCard.isNew"
                      class="new-label"
                      src="../../../assets/icons/icn-new-label.png"
                      alt="new_game"
                    />
                    <img class="game_image" :src="getLangImg(gameCard)" />
                    <div class="game-title">{{gameCard.name}}</div>
                  </div>
                </div>
              </template>
            </div>

           <div class="col-12" v-if="isLoggedIn && systemMessage != ''">
            <div class="announcements col-center py-2">
                <img src="./../../../assets/icons/icn-speaker.png" class="img-annoucement" />
                <div class="an-mask">
                    <div class="ml-4 small announcement-scroll">{{ systemMessage }} </div>
                </div>
            </div>
          </div>

            <section class="trending-section container">
              <div class="trending-header">
                <img src="./../../../assets/icons/icon-trending.svg"/>
                {{$t('trend_title')}}
              </div>

              <div>
                <div class="col-center trending-game" v-for="(i,index) in trendingGames[$store.getters.langId]" :key="i.name">
                  <img class="col-flex" :src="i.image" width="70" />
                  <div class="col">
                    <div class="trend-game-header">{{i.name}}</div>
                    <div class="trend-game-text">{{i.desc}}</div>
                    <div class="trend-play" @click="openTrendGame(index)">
                      {{$t('trend_play')}}
                    </div>
                  </div>
                </div>
              </div>


            </section> 
          <!--end game card --->
        <!-----end game menu--->
      </div>
      <!----pop out modal -->
      <MobileGameModal
        @set-selected-game="setSelectedGame"
        @reset-game="resetSelectedGame"
        @set-component="setActiveComponent"
        :selectedGame="selectedGame"
        ref="gameModal"
      />
      <!---- end pop out modal -->
    </div>
    <!-- /.container-fluid -->
    <transition name="fadeIn">
      <div v-if="showErrorAlert">
        <danger-alert-mobile :message="errorMessage"></danger-alert-mobile>
      </div>
    </transition>
  </div>
</template>

<script>
import mobileImport from '../../../assets/script/mobile.js';
import mobileBanner from '../../../assets/script/mobileBanner.js';
// import GameModal from './../ui-elements/GameModal.vue';
import CustomHttp from '../../../CustomHttp';
import CustomStorage from '../../../CustomStorage';
import languageList from '../../../assets/script/languageList';
import i18n from '../../../i18n';
// import CustomStorage from "../../../CustomStorage.js";
import MobileGameModal from '../ui-elements/GameModal.vue';
import TransferModal from '../../global/elements/TransferModal.vue';
const { t } = i18n.global;
import GameImport from '../../../assets/script/game';
const gameUrl = process.env.VUE_APP_GAME_URL;
const gameUrl2 = process.env.VUE_APP_GAME_URL2;

import trend1 from './../../../assets/images/trending1.png';
import trend2 from './../../../assets/images/trending2.png';

import trend1icon from './../../../assets/images/trending1icon.png';
import trend2icon from './../../../assets/images/trending2icon.png';


//Vue export
export default {
  name: 'Landing',
  emits: ['toggle-login', 'set-component'],
  components: { MobileGameModal, TransferModal },
  mounted() {
    setTimeout(()=>{
      eval("$('#promoBanner').carousel({interval: 3500});");
    },400);
    this.onClickMenuIcon(0);
  },
  data() {
    return {
      banners: mobileBanner.banner,
      selectedMenuIndex: 0,
      selectedGame: null,
      menuIcons: JSON.parse(JSON.stringify(mobileImport.menuIcons)),
      //game modal
      isLoading: false,
      languageList: languageList,
      selectedGameParent: '',
      errorMessage: '',
      showErrorAlert: false,
      trendingGames: {
        0 : [{name : '5 Fortune Dragons', desc: 'One of the best slot game to win on with the features of 5 reels 243 ways to win.', image: trend1, target: ''},
            {name : 'Fishing God', desc: 'Top fishing shooting game let you win twice the rewards!', image: trend2, }],
        1 : [{name : '5 Fortune Dragons', desc: '具有 5 个卷轴 243 种获胜方式等您来赢取！', image: trend1, target: ''},
            {name : 'Fishing God', desc: '顶级钓鱼射击游戏让你赢取双倍奖励。', image: trend2, target: ''}],
      },
      trendData : [
        {
          name: '5 Fortune Dragons',
          provider: 'Game Hall',
          icon: GameImport.find((x) => x.code.toString() === '39').icon,
          code: '39-112',
          img: [{ langId: 0, src: trend1icon }],
          img2: [{ langId: 0, src: trend1icon }],
        },
        {
          name: 'Fishing God',
          provider: 'Game Hall',
          icon: GameImport.find((x) => x.code.toString() === '39').icon,
          code: '39-111',
          img: [{ langId: 0, src: trend2icon }],
          img2: [{ langId: 0, src: trend2icon }],
        },
      ]
    };
  },
  created() {
    //listen to initial game tab message
    // window.addEventListener(
    //   'storage',
    //   (event) => {
    //     console.log('event');
    //     if (event.key == 'event-transferGameProvider') {
    //       let tempProvider = localStorage.getItem('event-transferGameProvider');
    //       this.$store.dispatch('setTransferGameProvider', tempProvider);
    //       // localStorage.removeItem('event-transferGameProvider');
    //     }
    //     if (event.key == 'event-isWalletTransfer') {
    //       let tempIsTransfer = localStorage.getItem('event-isWalletTransfer');
    //       this.$store.dispatch('setIsWalletTransfer', tempIsTransfer == 'true');
    //       console.log(this.isWalletTransfer);
    //       localStorage.removeItem('event-isWalletTransfer');
    //     }
    //     if (event.key == 'event-setTransferError') {
    //         let tempTransferError = localStorage.getItem(
    //           'event-setTransferError'
    //         );
    //         this.toggleDangerAlert(t(tempTransferError));
    //         this.$store.dispatch('setIsWalletTransfer', false);
    //     }
    //   },
    //   false
    // );
  },
  methods: {
    openTrendGame(index){
      if (!this.$store.getters.isLoggedIn) {
        this.selectedGame = null;
        this.$emit('toggle-login');
      }else{
        this.selectedGame = this.trendData[index];
        this.onClickGameIcon(null);
      }
    },
    setIsWalletTransfer(flag) {
      this.$store.dispatch('setIsWalletTransfer', flag);
      //user click close on transfer modal
      localStorage.removeItem('event-closeTransferModal');
      if (flag == false) {
        console.log(flag);
        localStorage.setItem('event-closeTransferModal', true);
      }
    },
    isIpadOS() {
      return (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
      );
    },
    isIOS() {
      if (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        /iPad|iPhone|iPod/.test(navigator.userAgent) ||
        this.isIpadOS()
      ) {
        return true;
      } else {
        return false;
      }
    },
    async withdrawAllFromGame() {
      this.$store.dispatch('setTransferGameProvider', '');
      this.$store.dispatch('setIsWalletTransfer', true);
      // url 1
      let gameImport = JSON.parse(JSON.stringify(GameImport));
      gameImport = gameImport.filter((x) => x.gameUrl == gameUrl);
      // gameImport = gameImport.filter((x) => x.code != providerId);
      let idListString = '';
      gameImport.forEach((item, index) => {
        if (gameImport.length == index + 1) {
          idListString += item.code;
        } else {
          idListString += item.code + ',';
        }
      });
      //get balance from all game provider
      let response = [];
      if (!this.isWalletTransfer) {
        return;
      }
      let startTime = new Date();
      try {
        response = await CustomHttp.getAllGame(idListString, gameUrl, 5);
      } catch (err) {
        this.toggleDangerAlert(t('Please try again'));
        this.$store.dispatch('setIsWalletTransfer', false);
        return;
      }
      //url 2
      gameImport = JSON.parse(JSON.stringify(GameImport));
      gameImport = gameImport.filter((x) => x.gameUrl == gameUrl2);
      // gameImport = gameImport.filter((x) => x.code != providerId);
      idListString = '';
      gameImport.forEach((item, index) => {
        if (gameImport.length == index + 1) {
          idListString += item.code;
        } else {
          idListString += item.code + ',';
        }
      });
      //get balance from all game provider
      let response2 = [];
      if (!this.isWalletTransfer) {
        return;
      }
      let endDate = new Date();
      let seconds = (endDate.getTime() - startTime.getTime()) / 1000;
      seconds = 5 - seconds;
      startTime = new Date();
      console.log('seconds');
      console.log(seconds);
      try {
        if (seconds < 0) {
          throw new Error('Please try again.');
        }
        response2 = await CustomHttp.getAllGame(
          idListString,
          gameUrl2,
          seconds
        );
      } catch (err) {
        this.toggleDangerAlert(t('Please try again.'));
        this.$store.dispatch('setIsWalletTransfer', false);
        return;
      }
      //handle login in other site message
      if (response.data.ErrText != '' || response2.data.ErrText != '') {
        this.toggleDangerAlert(t(response.data.ErrText));
        this.$store.dispatch('setIsWalletTransfer', false);
        return;
      }
      let responseData = [];
      let gameImportUrl = GameImport.filter((x) => x.gameUrl == gameUrl);
      let gameImportUrl2 = GameImport.filter((x) => x.gameUrl == gameUrl2);
      //handle api return null
      let gameList = response.data.game_list;
      gameList = gameList.filter((x) => x != null);
      gameImportUrl.forEach((item) => {
        const tempRecord = gameList.find((l) => l.GameId == item.code);
        responseData.push(tempRecord);
      });
      //handle api return null
      let gameList2 = response2.data.game_list;
      gameList2 = gameList2.filter((x) => x != null);
      gameImportUrl2.forEach((item) => {
        const tempRecord = gameList2.find((l) => l.GameId == item.code);
        responseData.push(tempRecord);
      });
      let gameProviderWithBalance = [];
      console.log(responseData);
      responseData.forEach((item) => {
        if (item.GameBalance != 0) {
          gameProviderWithBalance.push({
            GameId: item.GameId,
            GameBalance: item.GameBalance,
          });
        }
      });
      //start withdraw
      endDate = new Date();
      seconds = (endDate.getTime() - startTime.getTime()) / 1000;
      seconds = 5 - seconds;
      startTime = new Date();
      console.log('seconds');
      console.log(seconds);
      if (gameProviderWithBalance.length >= 1) {
        console.log('enter withdraw');
        for (let i = 0; i < gameProviderWithBalance.length; i++) {
          let gameProviderName = GameImport.find(
            (g) => g.code == gameProviderWithBalance[i].GameId
          ).name;
          this.$store.dispatch('setTransferGameProvider', gameProviderName);
          if (!this.isWalletTransfer) {
            return;
          }
          //console.log('withdraw from');
          // console.log(gameProviderWithBalance[i].GameId);

          try {
            if (seconds < 0) {
              throw new Error('Please try again.');
            }
            await CustomHttp.withDrawFromGame(
              gameProviderWithBalance[i].GameId,
              gameProviderWithBalance[i].GameBalance,
              seconds
            );
            endDate = new Date();
            seconds = (endDate.getTime() - startTime.getTime()) / 1000;
            seconds = 5 - seconds;
            startTime = new Date();
            console.log('seconds after withdraw');
            console.log(seconds);
            return seconds;
          } catch (err) {
            this.toggleDangerAlert(t('Please try again.'));
            this.$store.dispatch('setIsWalletTransfer', false);
            return;
          }
          //handle possible error
          //   if (response.errtext[0] == '') {
          //   }
        }
      } else {
        return seconds;
      }
    },
    async processWalletTransfer(gameObj, ismini = false) {
      console.log(ismini == false);
      console.log(this.isIOS() == true);
      if (ismini == false && this.isIOS() == true) {
        localStorage.setItem('IOS-transfer', JSON.stringify(gameObj.code));
        window.open('/initializing');
        return;
      }
      const gameCode = gameObj.code;
      const gameCode2 = gameObj.code2;
      let remainingTime = await this.withdrawAllFromGame();
      console.log('remainingTime');
      console.log(remainingTime);
      //check for game status
      try {
        if (remainingTime < 0) {
          throw new Error('Please try again.');
        }
        await CustomHttp.getOneGame(gameCode, remainingTime);
      } catch (err) {
        console.log(err.message);
        let tempMessage = err.message;
        tempMessage = tempMessage.replace('Error:', '');
        this.toggleDangerAlert(t(tempMessage));
        this.$store.dispatch('setIsWalletTransfer', false);
      }
      // get latest balance
      if (!this.isWalletTransfer) {
        return;
      }
      let creditResult = await CustomHttp.post('/api/v1/member/get', {
        username: this.$store.getters.username,
        withTotalRemainingCreditLimit: true,
      });
      let totalRemainingCreditLimit =
        creditResult.data.data.totalRemainingCreditLimit;
      //deposit latest balance to elected game
      if (ismini) {
        //start mini
        this.$refs.gameModal.startMiniGame(
          gameCode2,
          totalRemainingCreditLimit
        );
      } else {
        // start game
        this.$store.dispatch('setTransferGameProvider', '');
        CustomStorage.setItem('gameCode', gameCode);
        CustomStorage.setItem('tempAmount', totalRemainingCreditLimit);
        if (navigator.userAgent.match(/line/i)) {
          window.location.assign('/initializing');
        } else {
          window.open('/initializing', '_blank');
        }
        //window.open('/initializing');
        //   this.$store.dispatch('setOpenNewTab', true);
      }
      this.$store.dispatch('setIsWalletTransfer', false);
    },
    resetSelectedGame() {
      this.selectedGame = null;
    },
    setSelectedGame(game) {
      this.selectedGame = game;
    },
    onClickBanner(obj) {
      if(obj.game.target == "sportsbook_mobile"){
        if (!this.$store.getters.isLoggedIn) {
          this.selectedGame = null;
          this.$emit('toggle-login');
        }else{
          this.goToMobileSportsbook(true);
        }
        return false;
      }
      this.selectedGame = obj.game;
      this.onClickGameIcon(null);
    },
    getLangBanner(obj) {
      let imgSrc = obj.imgUrl[0].src;
      let tempSrc = obj.imgUrl.find(
        (i) => i.langId == this.$store.getters.langId
      );
      if (tempSrc) {
        imgSrc = tempSrc.src;
      }
      return imgSrc;
    },
    setActiveComponent(componentName) {
      this.$emit('set-component', componentName);
    },
    getDisplayIcon(index) {
      if (this.menuIcons[index].isActive) {
        return this.menuIcons[index].iconUrlActive;
      } else {
        return this.menuIcons[index].iconUrl;
      }
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1500);
    },
    onClickMenuIcon(index) {
      this.menuIcons = JSON.parse(JSON.stringify(mobileImport.menuIcons));
      this.selectedGameParent = '';

      if (index == undefined || index == null) {
        index = this.selectedMenuIndex;
      }
      this.menuIcons[index].isActive = true;
      // this.menuIcons[index].isActive = !this.menuIcons[index].isActive;
      //unselect other item
      if (this.menuIcons[index].isActive) {
        this.menuIcons.forEach((item, i) => {
          if (index != i) {
            this.menuIcons[i].isActive = false;
          }
        });
      }
      // seleted which card group to show
      this.selectedMenuIndex = index;
      //filter out child menu
      this.menuIcons[this.selectedMenuIndex].games = this.menuIcons[
        this.selectedMenuIndex
      ].games.filter(
        (x) =>
          x.parentName.toUpperCase() == this.selectedGameParent.toUpperCase()
      );
    },
    onClickGameIcon(index, isMini = false) {
      //check if user logged in
      if (!this.$store.getters.isLoggedIn) {
        this.selectedGame = null;
        this.$emit('toggle-login');
      } else {
        let tempGame;
        if (index != null) {
          tempGame = this.menuIcons[this.selectedMenuIndex].games[index];
        } else {
          //for user click on promo banner
          tempGame = this.selectedGame;
        }
        this.menuIcons = JSON.parse(JSON.stringify(mobileImport.menuIcons));
        //check if selected game has child
        if (tempGame.labelName != '' && tempGame.labelName != null) {
          //prevent show game modal
          this.$emit('set-game', null);

          this.selectedGameParent = tempGame.labelName;
          this.menuIcons[this.selectedMenuIndex].games = this.menuIcons[
            this.selectedMenuIndex
          ].games.filter(
            (x) =>
              x.parentName.toUpperCase() ==
              this.selectedGameParent.toUpperCase()
          );
          this.menuIcons[this.selectedMenuIndex].isActive = true;
          return;
        } else {
          //filter out child menu
          this.menuIcons[this.selectedMenuIndex].games = this.menuIcons[
            this.selectedMenuIndex
          ].games.filter(
            (x) =>
              x.parentName.toUpperCase() ==
              this.selectedGameParent.toUpperCase()
          );
        }
        this.menuIcons[this.selectedMenuIndex].isActive = true;
        if (tempGame.target && tempGame.target == 'sportsbook') {
          this.goToSportsbook();
        } else if (tempGame.target && tempGame.target == 'sportsbook_mobile') {
          this.goToMobileSportsbook();
        } else {
          //check if user is auto wallet transfer
          if (this.autoTransferWallet) {
            //prevent modal show
            this.selectedGame = null;

            this.processWalletTransfer(tempGame, isMini);
          } else if (this.miniGameClicked && isMini) {
            //mini game second and above clicked
            this.selectedGame = null;
            this.$refs.gameModal.startMiniGame(tempGame.code2, 0);
          } else if (isMini) {
            //first time click mini game
            this.selectedGame = tempGame;
            this.$refs.gameModal.getGameDetails2(tempGame);
          } else {
            this.selectedGame = tempGame;
            this.$refs.gameModal.getGameDetails2(tempGame);
          }
        }
      }
    },
    async isSportsbookMaintenance() {
      const response = await CustomHttp.post(
        'api/v1/system/get-sb-maintenance-status'
      );
      return response.data.data.isMaintenance;
    },
    async goToMobileSportsbook(wc = false) {
      if (!this.$store.getters.isLoggedIn) {
        //no logged in
        this.loginPanelErr = '';
        this.$refs.loginModal.showModal();
      } else {
        if (this.autoTransferWallet) {
          if (this.isIOS() == true) {
            this.$store.dispatch('setIsWalletTransfer', false);
            localStorage.setItem('IOS-transfer-sb', true);
            window.open('/initializing');
            return;
          }

          await this.withdrawAllFromGame();
          this.$store.dispatch('setTransferGameProvider', '');
          this.$store.dispatch('setIsWalletTransfer', false);
        }

        window.open('/initializing');

        localStorage.setItem('sb-mobile', true);

        if(wc == true){
          localStorage.setItem('sb-mobile-wc', true);
        }
        
      }
    },
    async goToSportsbook() {
      if (!this.$store.getters.isLoggedIn) {
        //no logged in
        this.loginPanelErr = '';
        this.$refs.loginModal.showModal();
      } else {
        this.isLoading = true;
        //check
        const isMaintenance = await this.isSportsbookMaintenance();
        if (isMaintenance) {
          this.isLoading = false;
          this.toggleDangerAlert(t('Sportsbook is under maintenance'));
          return;
        }

        if (this.autoTransferWallet) {
          await this.withdrawAllFromGame();
          this.$store.dispatch('setTransferGameProvider', '');
          this.$store.dispatch('setIsWalletTransfer', false);
        }
        const json = {
          username: this.$store.getters.username,
          langCode: this.languageList.find(
            (l) => l.id == this.$store.getters.langId
          ).code,
          logout_url: window.location.origin,
        };
        const response = await CustomHttp.post(
          'api/v1/member/login-old-site',
          json
        );
        const memberUrl =
          process.env.VUE_APP_MEMBER_SITE + response.data.data.url;
        window.location.href = memberUrl;
        //window.open(memberUrl, '_blank');
        this.isLoading = false;
      }
    },
    onClickCloseModal() {
      this.selectedGame = null;
    },
    getLangImg(obj) {
      let imgSrc = obj.img[0].src;
      let tempSrc = obj.img.find((i) => i.langId == this.$store.getters.langId);
      if (tempSrc) {
        imgSrc = tempSrc.src;
      }
      return imgSrc;
    },
    getLangImg2(obj) {
      if (obj.img2) {
        let imgSrc = obj.img2[0].src;
        let tempSrc = obj.img2.find(
          (i) => i.langId == this.$store.getters.langId
        );
        if (tempSrc) {
          imgSrc = tempSrc.src;
        }
        return imgSrc;
      }
    },
  },
  computed: {
    systemMessage() {
      let message;
      message = this.$store.getters.systemMessage;
      return message.replace(/(<([^>]+)>)/gi, '');
      // return message;
    },
    getIconClass(index) {
      return {
        'menu-icon': !this.menuIcons[index].iconUrlActive,
        'menu-icon-active active': this.menuIcons[index].iconUrlActive,
      };
    },
    suspend() {
      return this.$store.getters.suspend;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    totalRemainingCreditLimit() {
      // const current = new Date();
      return this.$store.getters.totalRemainingCreditLimit;
    },
    miniGameUrl() {
      return this.$store.getters.miniGameUrl;
    },
    autoTransferWallet() {
      return this.$store.getters.autoTransferWallet;
    },
    transferError() {
      return this.$store.getters.transferError;
    },
    isWalletTransfer() {
      return this.$store.getters.isWalletTransfer;
    },
  },
};
</script>
<style scoped lang="css" src="../../../assets/css/landing.css"></style>
