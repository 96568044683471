<template>
  <div class="card-body">
    <h4 class="frank header">Why Choose Us?</h4>
    <hr style="border-color:#1c327e">

    <div class="row">
      <div class="col-6 why-block mb-4" v-for="d in dataList" :key="d.seq">
        <img class="why-img" :src="d.src" :width="d.width"/>
        <div class="title"> {{ d.title }}</div>
        <div class="desc" v-html="d.desc"> </div>
      </div>
    </div>

  </div>
</template>

<script>
import why1 from '../../../../assets/images/why1.svg';
import why2 from '../../../../assets/images/why2.svg';
import why3 from '../../../../assets/images/why3.svg';
import why4 from '../../../../assets/images/why4.svg';
import why5 from '../../../../assets/images/why5.svg';
import why6 from '../../../../assets/images/why6.svg';


export default {
  mounted() {
    this.setDataList();
  },
  data() {
    return {
      fullList: [
        {
          langId: '0', //english
          item: [
            {
              title: 'Best Prices',
              src: why1,
              width: 120,
              desc:
                'YBET99 offers the most competitively priced odds among the major sportsbooks worldwide.',
            },
            {
              title: 'Fastest Payout',
              src: why2,
              width: 135,
              desc:
                'Payment effected within 24 hours of receipt of your funds withdrawal request.',
            },
            {
              title: 'Access Anytime Anywhere',
              src: why3,
              width: 95,
              desc:
                'With YBET99 Wireless Application Protocol (WAP) and iPhone service, you can bet whilst sitting in the comfort of the stadium while enjoying the game.',
            },
            {
              title: 'Wide Browser Compatibility',
              src: why4,
              width: 120,
              desc:
                'YBET99 supports a wide array of popular browsers like Microsoft Internet Explorer, Mozilla Firefox, Apple Safari and Opera. There is no need to change your favorite browser because of us.',
            },
            {
              title: 'Easy Payment Methods',
              src: why5,
              width: 90,
              desc:
                'Through our tie-ups with various payment solution providers such as Moneybookers and NETELLER, settlement of accounts has never been easier. Find out the payment methods you can use to deposit funds into your YBET99 account.',
            },
            {
              title: 'Widest Selections Of Events',
              src: why6,
              width: 140,
              desc:
                'From over 500 sporting events every week in Sportsbook, an extensive range of casino games in Casino, a wide variety of international horse racing events in Racebook and exclusive games, YBET99 is a one-stop shop offering a complete suite of gaming products. Bet on your favorite sport, team or selection with us. Find out more about YBET99 Sportsbook and YBET99 Games.',
            } 
          ],
        },
        {
          langId: '2', //thai
          item: [
            {
              title: 'ราคาดีที่สุด',
              src: why1,
              desc:
                'V-Sport นำเสนอราคาออดซ์ (ราคาต่อรอง) ที่ดีที่สุดในกลุ่มผู้นำสปอร์ตบุ๊คทั่วโลก',
            },
            {
              title: 'จ่ายเงินเร็วที่สุด',
              src: why2,
              desc:
                'การจ่ายเงินจะใช้เวลาภายใน 24 ชั่วโมงหลังจากได้รับคำร้องขอถอนเงินจากท่าน อ่านคู่มือและเรียนรู้วิธีการส่งคำร้องขอถอนเงิน: เมื่อต้องการถอนเงินจากบัญชีควรทำอย่างไร?',
            },
            {
              title: 'มีเกมส์การแข่งขันให้เลือกหลากหลายที่สุด',
              src: why3,
              desc:
                'V-Sport เป็นศูนย์รับวางพนันที่มีผลิตภัณฑ์แบบครบวงจรที่เดียวเบ็ดเสร็จ เพราะเราให้บริการเกมส์การแข่งขันกีฬามากกว่า 500 เกมส์ทุกสัปดาห์ในสปอร์ตบุ๊ค นอกจากนี้ยังมีเกมส์คาสิโน, รายการม้าแข่งนานาชาติในเรซบุ๊ค และเกมส์อื่นๆอีกมากมาย เริ่มต้นวางพนันกีฬา ทีมหรือเกมส์โปรดของท่านกับเราได้แล้ววันนี้ <br/> เรียนรู้เพิ่มเติมเกี่ยวกับ V-Sport สปอร์ตบุ๊ค และV-Sport Games',
            },
            {
              title: 'เข้าระบบได้ทุกที่ทุกเวลา',
              src: why4,
              desc:
                'ด้วยบริการ V-Sport ผ่าน WAP (เว็บไซต์บนโทรศัพท์มือถือ) และบริการเว็บไซต์ V-Sport ผ่านโทรศัพท์มือถือ ท่านสามารถวางพนันได้ แม้ขณะที่กำลังดูการแข่งขันอยู่ในสนามกีฬา เรียนรู้เพิ่มเติมเกี่ยวกับ บริการ V-Sport ผ่าน WAP มือถือ และ บริการเว็บไซต์ V-Sport ผ่านมือถือ',
            },
            {
              title: 'วิธีการจ่ายเงินอย่างง่ายๆ',
              src: why5,
              desc:
                'ด้วยระบบการชำระเงินที่ผูกกับผู้ให้บริการในการจ่ายเงินชั้นนำอย่าง Skrill (Moneybookers) และ NETELLER การลงทะเบียนเริ่มต้นใช้บริการเป็นไปได้อย่างง่ายดาย',
            },
            {
              title: 'ใช้ได้กับหลายบราวเซอร์',
              src: why6,
              desc:
                'V-Sport สามารถใช้ได้กับบราวเซอร์ที่มีชื่อเสียงต่างๆ อาทิเช่น Microsoft Internet Explorer, Mozilla Firefox, Apple Safari และ Opera ท่านไม่จำเป็นต้องเปลี่ยนบราวเซอร์เมื่อใช้ V-Sport <br/> เรียนรู้เว็บเบราว์เซอร์ที่ V-Sport รองรับ: ฉันจะใช้เว็บเบราว์เซอร์ที่มีอยู่เข้าเว็บไซต์ V-Sport ได้หรือไม่?',
            },
          ],
        },
        {
          langId: '3', //vn
          item: [
            {
              title: 'Giá Tốt Nhất',
              desc:
                'V-Sport cung cấp tỷ lệ cược giá cạnh tranh nhất trong các nhà cái thể thao trên thế giới.',
            },
            {
              title: 'Thanh Toán Nhanh Nhất',
              desc:
                'Thanh toán có hiệu lực trong vòng 24giờ sau khi nhận yêu cầu rút tiền của bạn. Hãy đọc tài liệu hướng dẫn để hiểu làm sao bạn có thể gởi yêu cầu rút tiền: Làm sao tôi rút tiền từ tài khoản của tôi?Thanh toán có hiệu lực trong vòng 24giờ sau khi nhận yêu cầu rút tiền của bạn',
            },
            {
              title: 'Nhiều Sự Kiện Phong Phú Nhất',
              desc:
                'Hơn 500 sự kiện thể thao mỗi tuần trong Sportsbook , đa dạng các môn chơi bài trong Sòng Bài và các trò chơi độc quyền trong Các Trò Chơi, V-Sport là Một Nhà Cái Tổng Hợp cung cấp đầy đủ các sản phẩm các cược. Hãy đặt cược vào đội, môn thể thao bạn thích nhất với chúng tôi.',
            },
            {
              title: 'Truy Cập Mọi Lúc Mọi Nơi',
              desc:
                'Với V-Sport WAP và dịch vụ iPhone, bạn có thể cá cược ngay khi bạn đang ngồi thưởng thức trận đấu tại sân vận động',
            },
            {
              title: 'Phương Thức Thanh Tóan Đơn Giản',
              desc:
                'Với nhiều nhà cung cấp giải pháp thanh toán khác nhau như Skrill (Moneybookers) và NETELLER, thanh tóan chưa bao giờ đơn giản hơn thế. <br/>Tìm hiểu thêm về phương thức thanh toán bạn có thể sử dụng để gởi tiền vào tài khỏan V-Sport của bạn: Tôi cần phải sử dụng cái gì để gởi tiền vào tài khoản của tôi?',
            },
            {
              title: 'Nhiều Công Cụ Tìm Kiếm',
              desc:
                'V-Sport hỗ trợ các phương tiện tìm kiếm phổ biến như Microsoft Internet Explorer, Mozilla FireFox, Apple Safari và Opera. Vì vậy bạn không cần phải thay đổi công cụ tìm kiếm mà bạn yêu thích.',
            },
          ],
        },
      ],
      dataList: null,
    };
  },
  methods: {
    setDataList() {
      const dataList = this.fullList.find(
        (x) => x.langId.toString() == this.$store.getters.langId.toString()
      );
      if (dataList == undefined) {
        this.dataList = this.fullList[0].item;
      } else {
        this.dataList = dataList.item;
      }
    },
  },
  computed: {
    langId() {
      return this.$store.getters.langId;
    },
  },
  watch: {
    // eslint-disable-next-line
    langId(newCount, oldCount) {
      this.setDataList();
    },
  },
};
</script>

<style scoped>
.card-body{
  color: #2f4798;
}
  .why-img{
    margin-bottom: 1em;
  }
.header{
  color: #ff9611;
}
.card-text {
  font-size: 12px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  color: #36342f;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 1.1rem;
  opacity: 0.85;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
  color: #fff;
}

.icn-round {
  font-weight: bold;
  text-align: left;
  color: #16c60c;
  white-space: nowrap;
}

.title {
  font-family: 'frank';
  text-align: left;
  color: #ff9611;
  font-size: x-large;
}
.desc {
  letter-spacing: 0.18px;
  text-align: left;
}
</style>
