export default {
  session(state) {
    return state.session;
  },
  selectedHeaderIndex(state) {
    return state.selectedHeaderIndex;
  },
  selectedGame(state) {
    return state.selectedGame;
  },
  miniGameUrl(state) {
    return state.miniGameUrl;
  },
  transferGameProvider(state) {
    return state.transferGameProvider;
  },
  isWalletTransfer(state) {
    return state.isWalletTransfer;
  },
  transferError(state) {
    return state.transferError;
  },
};
