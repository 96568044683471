<template>
  <div class="card-max">
    <h4 class="frank header">Betting Rules & Regulations</h4>
    <hr style="border-color:#1c327e">
    <p style="color: #2f4798">
      Some Events, and Markets have different rules and these are listed below in the Specific Event/Market Betting Rules for each specific Event or Market/bet type in this Website. The following are the general rules of betting applicable to all Events and Markets/bet types, full compliance is mandatory. Where applicable, provisions and definitions set out in the Terms and Conditions published in the Website shall apply to these Betting Rules and Regulations.

    </p>
    <EnRules v-if="langId == '0'" />
    <CnRules v-if="langId == '1'" />
    <ThRules v-if="langId == '2'" />
    <VnRules v-if="langId == '3'" />
  </div>
</template>

<script>
import EnRules from './Rules/EnRules.vue';
import ThRules from './Rules/ThRules.vue';
import VnRules from './Rules/VnRules.vue';
import CnRules from './Rules/CnRules.vue';
//Vue export
export default {
  components: { EnRules, ThRules, VnRules, CnRules},

  data() {
    return {};
  },
  methods: {},
  computed: {
    langId() {
      return this.$store.getters.langId;
    },
  },
};
</script>

<style scoped>
.card-max{
  max-height: 800px;
  overflow-y:auto;
}
.header{
  color: #ff9611;
  font-size: x-large;
}
.card-text {
  font-size: 12px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  color: #36342f;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 1.1rem;
  opacity: 1;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
}

.body-wrap {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 100%;
  font-family: 'Ubuntu';
  background-color: #000;
  /* height: 823px; */
}

h1 {
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin-top: 0px;
}

h2 {
  font-weight: 200;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

h3 {
  font-weight: 200;
  font-size: 14px;
  font-weight: normal;
}

h4 {
  font-weight: 200;
  font-size: 14px;
  font-weight: normal;
}

h5 {
  font-weight: 200;
  font-size: 14px;
  font-weight: normal;
}

.button span {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  background-color: #907339;
  border-radius: 5px;
  line-height: 20px;
  width: 105px;
  height: 20px;
  display: block;
  text-align: center;
  font-size: 12px;
  text-decoration: none;
}

.button:hover span {
  color: #000;
}

.container1 {
  text-align: center;
  max-width: 1200px;
  height: 200px;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  background-color: #1c3452;
  padding: 0px;
}

.centered {
  font-size: 2em;
  text-shadow: 2px 2px #000;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
b,
strong {
  font-weight: 700 !important;
}
</style>
