var resultList = [
  { value: 'S,S,p1,g1', desc: 'Soccer' },
  { value: 'S,SY,p1,g1', desc: 'Soccer - More Bets' },
  { value: 'S,SO,p3,g3', desc: 'Soccer - Outright' },
  { value: 'B,B,p1,g1', desc: 'Basketball' },
  { value: 'S,BO,p3,g3', desc: 'Basketball - Outright' },
  { value: 'S,BB,p1,g1', desc: 'Baseball' },
  { value: 'S,BBO,p3,g3', desc: 'Baseball - Outright' },
  { value: 'S,K,p2,g2', desc: 'Pool/Snooker' },
  { value: 'S,KO,p3,g3', desc: 'Pool/Snooker - Outright' },
  { value: 'B,T,p2,g2', desc: 'Tennis' },
  { value: 'B,TO,p3,g3', desc: 'Tennis - Outright' },
  { value: 'B,N,p1,g1', desc: 'American Football' },
  { value: 'S,NO,p3,g3', desc: 'American Football - Outright' },
  { value: 'S,H,p1,g1', desc: 'Ice Hockey' },
  { value: 'S,HO,p3,g3', desc: 'Ice Hockey - Outright' },
  { value: 'B,R,p1,g1', desc: 'Rugby' },
  { value: 'S,RO,p3,g3', desc: 'Rugby - Outright' },
  { value: 'S,MSH,p2,g2', desc: 'Motor Sports' },
  { value: 'S,MSO,p3,g3', desc: 'Motor Sports - Outright' },
  { value: 'S,G,p2,g2', desc: 'Golf' },
  { value: 'S,GO,p3,g3', desc: 'Golf - Outright' },
  { value: 'B,HB,p1,g1', desc: 'Handball' },
  { value: 'S,HBO,p3,g3', desc: 'Handball - Outright' },
  { value: 'B,BD,p2,g2', desc: 'Badminton' },
  { value: 'B,BDO,p3,g3', desc: 'Badminton - Outright' },
  { value: 'B,BS,p1,g1', desc: 'Beach Soccer' },
  { value: 'S,BSO,p3,g3', desc: 'Beach Soccer - Outright' },
  { value: 'S,V,p2,g2', desc: 'Volleyball' },
  { value: 'S,VO,p3,g3', desc: 'Volleyball - Outright' },
  { value: 'S,X,p2,g2', desc: 'Boxing' },
  { value: 'S,F,p2,g2', desc: 'Financials' },
  { value: 'S,NVO,p3,g3', desc: 'Entertainment - Outright' },
  { value: 'B,OL,p1,g1', desc: 'Olympic' },
  { value: 'S,OLO,p3,g3', desc: 'Olympic - Outright' },
  { value: 'S,M,p2,g2', desc: '4D Specials' },
  { value: '1,S,p2,g2', desc: '1D Game' },
  { value: '2,S,p2,g2', desc: '2D Game' },
  { value: 'B,OT,p1,g1', desc: 'Other Sports' },
  { value: 'B,OTO,p3,g3', desc: 'Other Sports - Outright' },
  { value: 'S,MT,p2,g2', desc: 'MuayThai' },
];

export default resultList;
