<template>
  <div class="rg-container">
  <transition name="fadeIn">
    <div v-if="showSuccessAlert">
      <success-alert :message="successMessage"></success-alert>
    </div>
  </transition>
    <nav class="rg-top">
      <router-link to="/">
      <img src="../../../../assets/images/logo.png" width="140" />
      </router-link>
    </nav>
    <section class="rg-background">
      <div class="rg-card">
          <div class="float-right d-none d-md-block">
            <div class="lang-dropdown">
            <i class="fa fa-globe mr-1" aria-hidden="true"></i>
            <div class="dropdown ">
               <div id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                 {{ selectedLanguage.name }}&nbsp;<i class="ml-2 fa fa-chevron-down"></i>
               </div>
               <ul class="dropdown-menu dropdown-menu-end">
                 <li v-for="(lang, index) in languageList" :key="lang.name"><a class="dropdown-item" @click="onClickLanguageTab(index)">{{ lang.name }}</a></li>
               </ul>
            </div>
            </div>
          </div>
        <br>
        <div class="rg-wrap clear-both">

          <h5 class="b mt-4 d-none d-md-block text-uppercase">{{$t('reg_registration')}}</h5>

          <div class="col-spaced d-md-none">
            <h5 class="b mt-2">{{$t('reg_registration')}}</h5>

            <div class="lang-dropdown">
            <div class="dropdown ">
               <div id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                 {{ selectedLanguage.name }}&nbsp;<i class="ml-2 fa fa-chevron-down"></i>
               </div>
               <ul class="dropdown-menu dropdown-menu-end">
                 <li v-for="(lang, index) in languageList" :key="lang.name"><a class="dropdown-item" @click="onClickLanguageTab(index)">{{ lang.name }}</a></li>
               </ul>
            </div>
            </div>
          </div>
          <div class="mt-4 mb-3 col-spaced">
            <span class="rg-tab left cursor-pointer"
            :class="(current_page == 'basic') ? 'active' : ''"
            @click="backToBasic"
            >{{$t('reg_accinfo')}}</span>
            <span class="rg-tab right" 
            :class="(current_page == 'personal') ? 'active' : ''"
            >{{$t('reg_perinfo')}}</span>
          </div>
          <div class="text-left">
            <template v-if="current_page == 'basic'">
              <div>{{$t('UserName2')}}<span style="color: red">*</span></div>
              <div class="rg-input-group">
                <img class="rg-form-icon" src="../../../../assets/icons/reg-username.png"/>
                <input class="form-control" type="text" v-model.trim="username" :placeholder="$t('UserName2')" maxlength="20" tabindex="1" @input="restrictUsername"/>
              </div>
              <div class="input-info col-center">
                <i class="fa fa-exclamation-circle mr-2"></i>
                  {{$t('reg_userinfo')}}
              </div>
              <div class="input-error col-center" v-if="error_username">
                <i class="fa fa-exclamation-circle mr-2"></i>
                  {{error_username}}
              </div>
              <br>
              <div>{{$t('Password2')}}<span style="color: red">*</span></div>
              <div class="rg-input-group">
                <img class="rg-form-icon" src="../../../../assets/icons/reg-password.png"/>
                <div class="password-eye"
                @click="showPassword" v-if="showPwd == true ">
                  <i class="rg-form-icon-right far fa-eye"></i>
                </div>
                <div class="password-eye"
                @click="hidePassword"  v-if="showPwd == false">
                  <i class="rg-form-icon-right far fa-eye-slash"></i>
                </div>
                <input id="pwd-field" class="form-control" type="password" v-model="password" :placeholder="$t('Password2')" @input="onChangePassword" :disabled="username.length == 0" maxlength="50" tabindex="2"/>
              </div>
              <template v-if="password.length > 0">
             <div class="validate-wrap mt-2">
               <div
                 class="col-center"
                 :class="[validateFlag1 ? 'text-success' : 'text-muted']"
               >
                 <i class="fas fa-check-circle"></i>
                 <span class="password-info pl-2">
                   {{ $t('Password_RemarkLength') }}
                 </span>
               </div>
             </div>
             <div class="validate-wrap">
               <div
                 class="col-center"
                 :class="[validateFlag2 ? 'text-success' : 'text-muted']"
               >
                 <i class="fas fa-check-circle"></i>
                 <span class="password-info pl-2">
                   {{ $t('Password_RemarkInclude') }}
                 </span>
               </div>
             </div>
             <div class="validate-wrap">
               <div
                 class="col-center"
                 :class="[validateFlag3 ? 'text-success' : 'text-muted']"
               >
                 <i class="fas fa-check-circle"></i>
                 <span class="password-info pl-2">
                   {{ $t('Password_RemarkExclude') }}
                 </span>
               </div>
             </div>
             </template>
            </template>
            <template v-else>
              <div>{{ $t('info_fullname') }}<span style="color: red">*</span></div>
              <div class="rg-input-group">
                <img class="rg-form-icon" src="../../../../assets/icons/reg-username.png"/>
                <input class="form-control" type="text" v-model.trim="name" :placeholder="$t('info_fullname')" @input="restrictName"/>
              </div>
              <div class="input-info col-center">
                <i class="fa fa-exclamation-circle mr-2"></i>
                  {{ $t('reg_nameinfo') }}
              </div>
              <br>
              <div class="input-error col-center" v-if="error_name">
                <i class="fa fa-exclamation-circle mr-2"></i>
                  {{error_name}}
              </div>
              <div>{{$t('reg_phone')}}<span style="color: red">*</span></div>
              <div class="rg-input-group col-center form-control">

                <img class="rg-form-icon" src="../../../../assets/icons/reg-phone.png"/>

                <div class="rg-select">
                  <div class="dropdown-toggle" data-toggle="dropdown">
                    {{calling_code}}
                  </div>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" v-for="p in phone_list" :key="p" @click="calling_code = p.number">{{p.name}}</a>
                  </div>
                </div>
                <div class="rg-seperator">&nbsp;</div>
                <input class="rg-phone" type="text" v-model="phone_number" placeholder="123456789" @input="restrictInputPhone"/>
              </div>
              <div class="input-error col-center" v-if="error_number">
                <i class="fa fa-exclamation-circle mr-2"></i>
                  {{error_number}}
              </div>
              <div class="input-error col-center" v-if="error_server">
                <i class="fa fa-exclamation-circle mr-2"></i>
                  {{error_server}}
              </div>
            </template>
          </div>
          <button v-if="current_page == 'basic'" class="rg-btn" @click="verifyUsername()" 
          :disabled="!validateFlag1 ||!validateFlag2 ||!validateFlag3 || username.length < 1">{{$t('reg_continue')}}</button>

          <button class="rg-btn" disabled v-if="current_page == 'personal' && loading_createUser">
            <i class="fas fa-spinner fa-spin"></i>
          </button>

          <button class="rg-btn" disabled v-if="current_page == 'personal' && loading_redirecting">
            {{$t('reg_redirect')}}
          </button>

          <button v-if="current_page == 'personal' && !loading_createUser && !loading_redirecting" class="rg-btn" @click="createUser()" 
          :disabled="name.length < 5 || phone_number.length < 3">{{$t('reg_create')}}</button>

          <router-link to="/" class="rg-back" v-if="!loading_createUser && !loading_redirecting">{{$t('reg_back')}}</router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import CustomHttp from "../../../../CustomHttp";
import languageList from "../../../../assets/script/languageList";

const phonelist = [
  {name : '+66 Thailand', number: '+66'},
  {name : '+62 Indonesia', number: '+62'},
  {name : '+60 Malaysia', number: '+60'},
  {name : '+84 Vietnam', number: '+84'},
  {name : '+65 Singapore', number: '+65'},
  {name : '+855 Cambodia', number: '+855'},
]

export default {
  components: {},
  mounted() {
  },
  data() {
  return{
    username : '',
    error_username : '',
    password : '',
    name : '',
    error_name : '',
    calling_code: '+60',
    phone_number : '',
    error_number : '',
    current_page : 'basic',
    error_server : '',
    validateFlag1: false,
    validateFlag2: false,
    validateFlag3: false,
    phone_list:phonelist,
    showSuccessAlert : false,
    successMessage : '',
    languageList: languageList,
    loading_createUser : false,
    loading_redirecting : false,
    showPwd : true,
  }

  },
  methods: {
  backToBasic(){
    if(!this.loading_createUser && !this.loading_redirecting){
      this.current_page = 'basic';
    }
  },
  restrictName(){
    this.name = this.name.replace(/[^a-z ]/gi,'');
  },
  restrictUsername(){
    this.username = this.username.toLowerCase();
  },
  restrictInputPassword(){
    this.password = this.password.replace(/(\r\n|\n|\r)/gm,"");
  },
  restrictInputPhone(){
    this.phone_number = this.phone_number.replace(/(\r\n|\n|\r)/gm,"");
  },
  toggleSuccessAlert(message) {
    this.successMessage = message;
    this.showSuccessAlert = true;
    setTimeout(() => {
      this.showSuccessAlert = false;
    }, 4000);
  },
  showPassword(){
    document.getElementById('pwd-field').type='text';
    this.showPwd = false;
  },
  hidePassword(){
    document.getElementById('pwd-field').type='password';
    this.showPwd = true;
  },
  async createUser(){
    this.loading_createUser = true;
    this.error_name = '';
    this.error_phone = '';
    this.error_server = '';
    if(this.name.length < 5 || this.name.length > 20){
      this.error_name = this.$t('reg_name_length_err');
      this.loading_createUser = false;
      return false;
    }
    this.error_number = '';
    if(this.phone_number.length < 3 || !/^[0-9]+$/.test(this.phone_number)){
      this.error_number = this.$t('reg_phone_valid_err');
      this.loading_createUser = false;
      return false;
    }
    if(this.calling_code == '' ){
      this.error_number = this.$t('reg_phone_valid_err');
      this.loading_createUser = false;
      return false;
    }

    let json = { 
      username: this.username,
      pw: this.password,
      fullname: this.name,
      contact: this.calling_code.replace('+','') + this.phone_number,
      email: "",
      compType: 19, 
      brandTypeId: 0
    };
      try {
        let response = await CustomHttp.register(json);

        if(response.data.error !=null){
          this.error_server = this.$t('reg_server_err');
          if(response.data.error.message == 'contact is exists')
          this.error_server = this.$t('reg_phone_used_err');
          this.loading_createUser = false;
        }else{
          if(response.success == false){
            this.error_server = this.$t('reg_server_err');
            this.loading_createUser = false;
            return
          }

          //successfully created user
          this.loading_createUser = false;
          this.loading_redirecting = true;
          this.toggleSuccessAlert(this.$t('reg_success'));
            setTimeout(async() => {
              this.showSuccessAlert = false;
              const json = {
                username: this.username,
                password: this.password,
              };
              try{
                await this.$store.dispatch('userLogin', json);
                window.location.href = '/agreement';
              }
              catch(error){
                this.$router.replace('/');
              }

            }, 4000);
        }

      } catch (error) {
          this.error_server = this.$t('reg_server_err');
          this.loading_createUser = false;
        return;
      }

  },
  async verifyUsername(){
    this.error_username = '';

    if(this.username == ''){
      this.error_username = this.$t('reg_name_empty');
      return false;
    }
    if(this.username.length < 5 || this.username.length > 20 ){
      this.error_username = this.$t('reg_name_length_err');
      return false;
    }
    if (!this.username.match(/^[0-9a-z]+$/)){
      this.error_username = this.$t('reg_name_aplha_err');
      return false;
    }

    let json = { 
      nickname: this.username
    };
      try {
        let response = await CustomHttp.checkUser(json);

        if(response.data.data.exists == true){
          this.error_username = this.$t('reg_user_duplicate');
        }
        if(response.data.data.exists == false){
          this.current_page = 'personal';
        }

      } catch (error) {
          this.error_username = this.$t('reg_server_err');
        return;
      }
  },
    onChangePassword() {
      this.restrictInputPassword();

      this.validateFlag1 = false;
      this.validateFlag2 = false;
      this.validateFlag3 = false;
      if (this.password.length >= 8 && this.password.length <= 15) {
        this.validateFlag1 = true;
      }
      // eslint-disable-next-line
      // const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      //format.test(this.inputNew) &&
      // if (/\d/.test(this.inputNew)) {
      //   this.validateFlag2 = true;
      // }
      // eslint-disable-next-line
      if (/^(?=.*[A-Za-z])(?=.*\d)/.test(this.password)) {
        this.validateFlag2 = true;
      }
      // const nickname = this.$store.getters.nickname;
      if (
        !this.password.toLowerCase().includes(this.username.toLowerCase()) &&
        !this.password.toLowerCase().includes(this.username.toLowerCase())
      ) {
        this.validateFlag3 = true;
      }
    },
   onClickLanguageTab(index) {
      const tempLangId = this.languageList[index].id;
      this.$store.dispatch("setLangId", tempLangId);
    },
  },
  computed: {
    selectedLanguage() {
      let selectedLanguage = languageList.find((l) => l.id == this.$store.getters.langId);
      if (selectedLanguage) {
        return selectedLanguage;
      } else {
        return languageList[0];
      }
    },
    selectedLanguageId() {
      return this.$store.getters.langId;
    },
  },
  watch: {
  },
};



</script>

<style scoped>
.rg-container{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: url('../../../../assets/images/main-bg.jpg') no-repeat 0 0 / 100% 100%;
}
.rg-top{
    background: linear-gradient(to bottom, #2f4798, #1b3472);
    text-align: center;
    padding-top: 2em;
    flex: 0.2;
    padding-bottom: 2em;
}
.rg-background{
    
    text-align: center;
    min-height: 80vh;

}
.rg-forms{
  padding: 0 3em;
}
.rg-card{
  background: white;
  box-shadow: 0 12px 30px 0 rgba(47, 71, 152, 0.47);
  border-radius: 15px;
  color: #2f4798;
  text-align: center;
  padding: 10px;
  min-width: min(520px,100%);
  margin-top: -1em;
  font-size: small;
  display: inline-block;
}
.rg-tab{
  background: #bccbe6;
  color: #dee5f3;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: 600;
  position: relative;
  width: 46%;
}
.rg-tab:first-child::after{
  content:" ";
  position: absolute;
  height: 1px;
  background: #bccbe6;
  top: 50%;
  left: 100%;
  right: -100%;
  bottom: 0;
}
.rg-tab.active:first-child::after{
  content:" ";
  position: absolute;
  height: 1px;
  background: #2f4798;
  top: 50%;
  left: 100%;
  right: -100%;
  bottom: 0;
}
.rg-tab.active{
  background: #2f4798;
  color: white;
}
.rg-wrap{
  width: 80%;
  margin-left: 10%;
}
.rg-btn{
  border: none;
  background: #ff9611;
  border-radius: 5px;
  padding: 1em;
  width: 100%;
  color: white;
  text-transform: uppercase;
  margin-top: 2em;
  font-weight: bold;
}
.rg-back{
  text-transform: uppercase;
  margin-top: 1em;
  padding: 10px;
  font-weight: bold;
  display: block;
}
.rg-btn:disabled{
  background: #a1adc2;
}
.rg-input-group{
  position: relative;
  display: flex;
  align-items: center;

}
.rg-input-group .form-control{
  padding-left: 2em;
  color: #2f4798;
  border: solid 1px #7786c3;
  white-space: nowrap;
  font-size: small;
  padding-left: 2.5em;
  display: flex;
  height: 40px;
}

.rg-form-icon{
  position: absolute;
  left: 10px;
  top: 29%;
  width: 16px;
}
.rg-form-icon-right{
  position: absolute;
  right: 10px;
  top: 25%;
  width: 16px;
  cursor: pointer;
}
.input-error{
  background: #fee6e6;
  color: #ef0b0b;
  padding: 8px 10px;
  margin: 10px 0;
  border-radius: 5px;
}
.input-info{
  background: #f5f5f5;
  color: #8d98ab;
  padding: 8px 10px;
  margin: 10px 0;
  border-radius: 5px;
  max-width: 500px;
  font-size: x-small;
}
.rg-phone{
  border: none;
  padding-left: 10px;
  position: relative;
  color: #2f4798;
  background: none;
}
.rg-seperator{
  height: 70%;
  border-left: 1px solid #919ec7;
}
.rg-phone:focus{
  outline: none;
}
.rg-select{
  background: transparent;
  border: none;
  margin-left: 2em;
  border-radius: 0;
  color: #2f4798;
  position: relative;
}
.lang-dropdown{
  background: #f5f5f5;
  display: inline-block;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.password-eye{
  position: absolute;
  right: 0;
  top: 2px;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 768px){
  .rg-top{
    background: #2f4798;
    text-align: left;
    padding: 1em;
    flex: 0;
  }
  .rg-card{
    width: 100%;
    margin-top: 0;
    background: url(../../../../assets/images/login-backdrop-old.png) no-repeat 0 0 / 100% 900px;
    box-shadow: none;
    height: 95vh;
    border-radius: 0;
  }
  .rg-tab{
    width: 50%;
    font-weight: normal;
  }
  .rg-tab.active{
    font-weight: 600;
  }
  .rg-tab.left{
    border-radius: 10px 0 0 10px;
  }
  .rg-tab.right{
    border-radius: 0 10px 10px 0;
  }
  .lang-dropdown{
    background: #e5eaf6;
    font-weight: 600;
    border: solid 1px #a6bce5;
    padding: 5px 15px;
  }
  .rg-card{
    font-size: medium;
  }
}
.rg-select .dropdown-toggle{
  width: 60px;
}
.dropdown-toggle::after{
  right: 10px;
}
</style>