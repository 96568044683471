<template>
  <button
    class="display-none"
    ref="showGameModalBtn"
    data-toggle="modal"
    data-target="#gameModal"
  ></button>
  <div
    class="modal fade text-center"
    style="top: 4em;"
    id="gameModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <loading-spinner v-if="isLoading"></loading-spinner>
        <div class="modal-body">
          <img
            :src="getLangImg2(selectedGame)"
            class="modal-img"
            :class="(selectedGame.large == true) ? 'modal-img-large' : ''"
          />

              <div class="transfer-logo col-12">
                <img :src="selectedGame.icon" />
              </div>
              <div class="col-12 full-center">
                <div class="text-right">
                  <div class="transfer-balance-header"> </div>
                  <p class="mt-2">
                    <span v-if="showProgress">
                      <i class="fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-if="!showProgress">
                      {{ $t('GameBalanceModal') }}:
                      {{ $filters.currency(gameBalance) }}
                    </span>
                  </p>
                </div>
              
            </div>
          <section class="transfer-bottom-section">
          <div class="col-12">
              
            <!----input area-->
            <div class="transfer-modal" v-if="!isErr">
              <div class="full-center b">
                {{ $t('TransferIn') }}
              </div>
              <input
                class="transfer-input"
                :class="{
                  red: errorMessage != '',
                }"
                type="text"
                min="0"
                placeholder="100"
                v-model="inputAmount"
                @input="filterInput"
                :disabled="showProgress"
                v-on:input="onChangeAmount()"
              />
              <div class="red smaller pt-1" v-if="errorMessage != ''">
                <span> &nbsp;</span>
                {{ errorMessage }}
              </div>
                <div class="transfer-credit mb-1">
                  {{ $t('CreditLabel') }}:
                  {{ $filters.currency(totalRemainingCreditLimit) }}
                </div>
              <!-- oninput="this.value = Math.abs(this.value)" -->
              <button
                class="transfer-start"
                :disabled="showProgress"
                @click="onClickStartGame"
              >
                {{ $t('StartGame') }}
                <img class="ml-3" src="./../../../assets/icons/icn-play.png" width="48" />
              </button>
            </div>
            <!---error area -->

            <div class="transfer-modal" v-if="isErr">
            <img
              v-if="!showProgress"
              class="mb-1"
              src="./../../../assets/icons/warning.png"
              width="36"
              alt="Game Error"
            />
            <template v-if="errorType == 'account_disabled'">
              <div class="b mb-3">{{$t('maintain_disabled')}}</div>
            </template>
            <template v-else-if="errorType == 'currency_not_support'">
              <div class="b mb-3">{{$t('maintain_currency')}}</div>
            </template>
            <template v-else-if="errorType == 'call_freq'">
              <div class="b mb-3">{{$t('maintain_freq')}}</div>
            </template>
            <template v-else-if="errorType == 'session_timeout'">
              <div class="b mb-3">{{$t('Session timed out. Please login again.')}}</div>
            </template>


            <template v-else>
              <template v-if="!showProgress">
                <div class="b">{{$t('maintain_break')}}</div>
                <div class="small">
                  {{$t('maintain_server')}}
                </div>
                <div class="small mb-3">{{$t('maintain_later')}}</div>
              </template>
            </template>
                <button
                class="transfer-start"
                @click="getGameDetails()"
                :disabled="timeoutFlag || suspend"
              >
                {{ $t('TRY AGAIN') }}
                <img class="ml-3" src="./../../../assets/icons/icn-tryagain.png" width="48" />
              </button>
            </div>

            </div>
            <!--- button area--->
            <div class="transfer-modal2">
              <a
                v-show="!suspend"
                class="transfer-cashout cursor-pointer"
                @click="onClickCashOut()"
              >
                <img src="../../../assets/icons/cashout.png" width="18" />
                &nbsp; {{ $t('CashOutT') }}
              </a>
              &nbsp;

              <a
                class="transfer-cashout cursor-pointer"
                @click="onClickViewHistory()"
              >
                <img src="../../../assets/icons/history.png" width="18" />
                &nbsp;{{ $t('ViewHistory') }}
              </a>
            </div>
          </section>
          <div class="transfer-close">
            <span
              class="pointer btn btn-close p-0 m-0"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeBtn"
            >
              <img src="../../../assets/icons/close.png" width="32" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomHttp from '../../../CustomHttp';
import CustomStorage from '../../../CustomStorage';

import i18n from './../../../i18n';
const { t } = i18n.global;
// import i18n from '../../../i18n';
// const { t } = i18n.global;
// import $ from 'jquery';
// $('#inputAmount').blur(function () {
//   this.value = parseFloat(this.value).toFixed(2);
// });

export default {
  // emits: ['toggle-danger'],
  data() {
    return {
      isLoading: false,
      showProgress: false,
      gameBalance: 0,
      inputAmount: null,
      maxInputLenth: 26,
      errorMessage: '',
      isErr: false,
      errorMessage2: '',
      formSubmitted: false,
      timeoutFlag: false,
      errorType : '',
    };
  },
  methods: {
    // This can also prevent copy + paste invalid character
    // remove decimal
    onChangeAmount() {
      if (this.formSubmitted) {
        let tempAmount = (' ' + this.inputAmount.toString()).slice(1);
        this.formSubmitted = true;
        tempAmount = tempAmount.replace(/[^0-9]+/g, '');
        let tempAmount2 = (
          ' ' + this.$store.getters.totalRemainingCreditLimit.toString()
        ).slice(1);
        if (Number(tempAmount) > Number(tempAmount2)) {
          this.errorMessage =
            'Insufficient Balance.';
          let oldAmount = Math.floor(Number(tempAmount));
          this.inputAmount = this.processAmount(oldAmount.toString());
        } else {
          this.errorMessage = '';
        }
      }
    },
    filterInput(e) {
      //remove symbol
      e.target.value = this.processAmount(e.target.value);
    },
    processAmount(value) {
      let result = value;

      result = result.replace(/\/r/, '/');
      //only number, need change
      result = result.replace(/[^0-9]+/g, '');
      //e.target.value = e.target.value.toString();
      // e.target.value = e.target.value.replaceAll('[^a-zA-Z0-9]', '');
      //  e.target.value = Number(e.target.value);
      // e.target.value = Math.abs(e.target.value);
      result = result.toString();
      if (result == '00') {
        result = 0;
      } else if (result.length > 1) {
        result = result.replace(/^0+/, '');
      }
      // add  comma
      result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (result.length > this.maxInputLenth) {
        result = result.slice(0, this.maxInputLenth);
      }
      return result;
    },
    async onClickStartGame() {
      // this.errorMessage =
      //   'Insufficient Balance. Please adjust your amount or reload to start the game.';
      let tempAmount = (' ' + this.inputAmount.toString()).slice(1);
      this.formSubmitted = true;
      // if (tempAmount != '') {
      //   return;
      // }
      //remove comma
      tempAmount = tempAmount.replace(/[^0-9]+/g, '');
      // console.log(Number(tempAmount));
      // console.log(
      //   Math.floor(Number(this.$store.getters.totalRemainingCreditLimit))
      // );

      //amount validation
      let tempAmount2 = (
        ' ' + this.$store.getters.totalRemainingCreditLimit.toString()
      ).slice(1);
      if (Number(tempAmount) > Number(tempAmount2)) {
        this.errorMessage =
          'Insufficient Balance.';
        return;
      }
      let oldAmount = Math.floor(Number(tempAmount));
      this.inputAmount = this.processAmount(oldAmount.toString());

      this.isLoading = true;
      const gameCode = this.$store.getters.selectedGame.code;

      // var windowReference = window.open("/initializing");
      CustomStorage.setItem('gameCode', gameCode);
      CustomStorage.setItem('tempAmount', tempAmount);
      this.$refs.closeBtn.click();
      this.isLoading = false;
      window.open('/initializing');

      // try {
      //   await CustomHttp.depositToGame(gameCode, tempAmount);
      //   const result = await CustomHttp.loginGame(gameCode);
      //   const resultUrl = result.result[0];
      //   await this.$store.dispatch("getMemberBalance");
      //   windowReference.location = resultUrl;
      //   this.$refs.closeBtn.click();
      //   this.isLoading = false;
      // } catch (err) {
      //   windowReference.close();
      //   this.$refs.closeBtn.click();
      //   this.isLoading = false;
      // }
    }, // end onClickStartGame
    onClickCashOut() {
      let w = 780;
      let h = 820;
      let url = '/cash-out';
      let title = 'Cash Out';
      // Fixes dual-screen position                             Most browsers      Firefox
      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

      const systemZoom = width / window.screen.availWidth;
      const left = (width - w) / 2 / systemZoom + dualScreenLeft;
      const top = (height - h) / 2 / systemZoom + dualScreenTop;
      const newWindow = window.open(
        url,
        title,
        `
      scrollbars=yes,   
      toolbar=no,
      location=no,
      status=no,
      menubar=no,
      resizable=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
      );

      if (window.focus) newWindow.focus();
      this.$refs.closeBtn.click();
    },
    onClickViewHistory() {
      console.log('onClickViewHistory');
      this.$refs.closeBtn.click();
      this.$router.replace({ path: 'credit-history' });
      //  this.$router.push('/credit-history');
      // this.$refs.closeBtn.click();
    },
    async getGameDetails() {
      this.errorType = '';
      this.showProgress = true;
      // console.log('this.$store.getters.selectedGame');
      // console.log(this.$store.getters.selectedGame);
      //
      const gameCode = this.$store.getters.selectedGame.code;
      if (this.$store.getters.suspend == true) {
        this.gameBalance = 0;
        this.showProgress = false;
        this.isErr = true;
        this.errorMessage2 = t('AccountSuspended');
        return;
      }
      try {
        //update
        await this.$store.dispatch('getMemberBalanceMobileLanding');
        let tempAmt = Math.floor(
          Number(this.$store.getters.totalRemainingCreditLimit)
        );
        this.inputAmount = this.processAmount(tempAmt.toString());
        const response = await CustomHttp.getOneGame(gameCode);
        // console.log('response');
        // console.log(response);
        // console.log(response.message);
        this.isErr = false;
        this.errorMessage2 = '';
        this.gameBalance = response.credit[0];
        // console.log(this.gameBalance);
        this.showProgress = false;
      } catch (err) {
        this.gameBalance = 0;
        this.showProgress = false;
        let tempMessage = err.message;
        tempMessage = tempMessage.replace('Error:', '');
        if (
          tempMessage.trim() ==
          "Cannot read properties of undefined (reading 'data')"
        ) {
          tempMessage = 'Session Time Out';
          this.$store.dispatch('userLogout');
          this.timeoutFlag = true;
        } else {
          this.timeoutFlag = false;
        }
        this.isErr = true;
        this.errorMessage2 = tempMessage;

        if(err.message.includes('Already login')){
          this.errorType = "already_login";
        }
        if(err.message.includes('Time Out')){
          this.errorType = "session_timeout";
        }
        if(err.message == 'Error: Account Game disabled'){
          this.errorType = "account_disabled";
        }
        if(err.message.includes('[1004]')){
          this.errorType = "currency_not_support";
        }
        if(err.message.includes('Call Too Frequent')){
          this.errorType = "call_freq";
        }
        // if (tempMessage.trim() == 'Game maintenance!') {
        //   this.isErr = true;
        //   this.errorMessage2 = t('GameMaintenance');
        // } else {
        //   this.$swal.fire(tempMessage);
        //   this.$refs.closeBtn.click();
        // }

        //this.$store.dispatch('userLogout');
        // this.$swal.fire('Invalid session, please login again.');
      }
    },
    showModal() {
      this.timeoutFlag = false;
      this.errorMessage = '';
      this.isErr = false;
      this.errorMessage2 = '';
      this.formSubmitted = false;
      //  this.inputAmount = null;
      //let tempAmt = Math.floor(Number(this.$store.getters.totalRemainingCreditLimit));
      // this.inputAmount = this.processAmount(tempAmt.toString());
      this.inputAmount = null;
      this.$refs.showGameModalBtn.click();
    },
    getLangImg2(obj) {
      if (obj.img2) {
        let imgSrc = obj.img2[0].src;
        let tempSrc = obj.img2.find(
          (i) => i.langId == this.$store.getters.langId
        );
        if (tempSrc) {
          imgSrc = tempSrc.src;
        }
        return imgSrc;
      }
    },
  },
  computed: {
    selectedGame() {
      return this.$store.getters.selectedGame;
    },
    totalRemainingCreditLimit() {
      // const current = new Date();
      return this.$store.getters.totalRemainingCreditLimit;
    },
    suspend() {
      return this.$store.getters.suspend;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.btn-close {
  background-color: transparent;
  border: 0px !important;
  /* color: #ffffff !important; */
  font-size: 40px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.border-none {
  border: 0px !important;
}
</style>
<style scoped lang="css" src="../../../assets/css/desktop.css"></style>
