const crypto = require('crypto');
const CryptoJS = require('crypto-js');
const algorithm = 'aes-256-cbc';
let secretKey = process.env.VUE_APP_API_PUBLIC_VAL;
let depositWithdrawalPrivateKey = process.env.VUE_APP_DW_VAL;
let ufaApiKey = process.env.VUE_APP_UF_API_VAL;
let paymentUrl = process.env.VUE_APP_PAYMENT_URL + 'Account';
let hash = CryptoJS.SHA256(secretKey);
let buffer = Buffer.from(hash.toString(CryptoJS.enc.Hex), 'hex'); // create buffer from hash values from secret key
const iv = Buffer.alloc(16); // defaulted IV
const key = buffer;
const returnUrl = process.env.VUE_APP_RETURN_URL;
// SQKp*2?C6g;sT=jjQ(~48?M;duN/

import store from './store/index';
export default {
  encrypt(text) {
    const cipher = crypto.createCipheriv(algorithm, Buffer.from(key), iv);
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return encrypted.toString('base64');
  },
  hashPw(text) {
    return CryptoJS.MD5(secretKey + text)
      .toString()
      .toUpperCase();
  },
  SignAndHash(dataToSign) {
    if (!dataToSign) return;
    // let input = dataToSign + depositWithdrawalPrivateKey;
    let plainText =
      store.getters.username + dataToSign + depositWithdrawalPrivateKey; //assign unix date to hardcoded string for testing
    // let token = crypto.createHash('md5').update(plainText).digest('hex');
    let token = CryptoJS.MD5(plainText).toString();
    return token;
  },
  getPaymentUrl() {
    return paymentUrl.toString();
  },
  getPaymentLanguage() {
    if (store.getters.langId.toString() == '2') {
      return 'th';
    } else {
      return 'en';
    }
  },
  getReturnUrl() {
    return returnUrl;
  },
  getUfaApiKey() {
    return ufaApiKey;
  },
};
