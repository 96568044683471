import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
import CustomStorage from './../../../CustomStorage';

export default {
  state() {
    return {
      username: null,
      nickname: null,
      reqChgPw: null,
      currency: null,
      loginType: null,
      token: null,
      totalRemainingCreditLimit: 0,
      isLoggedIn: false,
      totalOutstanding: 0,
      totalOutstandingCount: 0,
      minBet: 0,
      maxBet: 0,
      creditLimit: 0,
      isTimeout: false,
      langId: CustomStorage.getItem('langId') || 0,
      systemMessage: '',
      systemMessageList: [],
      accId: null,
      userId: null,
      compType: null,
      suspend: false,
      maintenanceRemark: '',
      enablePaymentGateway: false,
      autoTransferWallet: false,
      // messageLogList: [],
    };
  },
  mutations,
  actions,
  getters,
};
