<template>
  <section>
      <div style="display: inline-block; width: 100%" class="container mt-3">
      <div class="dropdown left ddlbtm small" style="width: 62%">
          <div
            class="btn dropdown-toggle text-left"
            href="#"
            role="button"
            id="ddlGType"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style="color: inherit; width: 100%"
          >
            <!-- {{ this.selectedGame.desc }} -->
            <div class=" sports-dropdown">
              {{ selectedGameDesc }}
            </div>
          </div>
          <ul
            class="dropdown-menu scrollable-menu dropdown-menu-min small"
            aria-labelledby="ddlGType"
            x-placement="bottom-start"
          >
            <li v-for="(g, index) in gameList" :key="g.value">
              <a class="dropdown-item" href="#" @click="onClickGame(index)">
                {{ g.desc }}
              </a>
            </li>
          </ul>
        </div>
        <div class="dropdown right ddlbtm small" style="width: 36%">
          <a
            class="btn dropdown-toggle text-left"
            href="#"
            role="button"
            id="ddlDates"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style="color: inherit"
          >
            {{ selectedDate.desc }}
          </a>
          <ul
            class="dropdown-menu small"
            aria-labelledby="ddlDates"
            x-placement="bottom-start"
            style="
              position: absolute;
              transform: translate3d(-23px, 38px, 0px);
              top: 0px;
              left: 0px;
              will-change: transform;
            "
          >
            <li v-for="(d, index) in dateList" :key="d.value">
              <a class="dropdown-item" href="#" @click="onClickDate(index)">
                {{ d.desc }}
              </a>
            </li>
          </ul>
        </div>

        <div style="margin-top: 10px">
          <div v-if="showProgress">
            <div class="col-lg-12 p-1">
              <div class="card d-flex align-items-center">
                <white-progress-spinner></white-progress-spinner>
              </div>
            </div>
          </div>

          <template v-if="!showProgress">
            <div id="divModules" class="dropdown ddlbtm small" style="width: 100%">
              <a
                class="btn dropdown-toggle text-left"
                href="#"
                role="button"
                id="ddlModules"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style="color: inherit; width: 100%"
              >
                {{ selectedFilter.desc }}
              </a>
              <ul
                class="dropdown-menu scrollable-menu small"
                aria-labelledby="ddlModules"
                x-placement="bottom-start"
                style="
                  position: absolute;
                  transform: translate3d(-4px, 38px, 0px);
                  top: 0px;
                  left: 0px;
                  will-change: transform;
                "
              >
                <li v-for="(x, index) in gameFilterSelection" :key="x.value">
                  <a class="dropdown-item" href="#" @click="onClickGameFilter(index)">
                    {{ x.desc }}
                  </a>
                </li>
              </ul>
            </div>




          </template>

        </div>
      </div>

      <template v-if="!showProgress">
        <div style="display: inline-block; width: 100%; border-top:1px solid #aaa" class="container mt-3 mb-5">
            <P1 v-if="selectedGame.display == 'p1'" :resultList="resultList" />
            <P2 v-if="selectedGame.display == 'p2'" :resultList="resultList" />
            <P3 v-if="selectedGame.display == 'p3'" :resultList="resultList" />
        </div>
      </template>
      <div style="height:100px">&nbsp;</div>
          <input type="hidden" id="gtype" value="S,S,p1,g1" />
          <input type="hidden" id="dates" value="-1" />
          <input type="hidden" id="modules" value="-1" />

  </section>
</template>

<script>
// page component
import gameList from "./../../../../assets/script/gameResult";
import moment from "moment";
import CustomHttp from "../../../../CustomHttp";
import i18n from "./../../../../i18n";
const { t } = i18n.global;
import P1 from "./result/P1";
import P2 from "./result/P2";
import P3 from "./result/P3";
export default {
  components: { P1, P2, P3 },
  mounted() {
    this.gameList.forEach((item, index) => {
      const tempArr = item.value.split(",");
      this.gameList[index].desc = t(this.gameList[index].desc);
      this.gameList[index].type1 = tempArr[0];
      this.gameList[index].type2 = tempArr[1];
      this.gameList[index].display = tempArr[2];
      this.gameList[index].grid = tempArr[3];
    });

    this.selectedGame = this.gameList[0];
    let tempDate = new Date();

    let format = "hh:mm:ss";
    var now = new Date();
    let time = moment(now, format);
    let beforeTime = moment("00:00:00", format);
    let afterTime = moment("12:00:00", format);

    if (time.isBetween(beforeTime, afterTime)) {
      tempDate.setDate(tempDate.getDate() - 1);
    }

    for (let i = 0; i < 8; i++) {
      let tempDesc = moment(tempDate).format("DD/MM/YYYY");
      if (i == 0) {
        tempDesc = t("Today");
      } else if (i == 1) {
        tempDesc = t("Yesterday");
      }
      let tempValue = moment(tempDate).format("YYYY-MM-DD");
      this.dateList.push({ value: tempValue, desc: tempDesc });
      tempDate.setDate(tempDate.getDate() - 1);
    }

    this.selectedDate = this.dateList[0];

    this.getResult();
  },
  data() {
    return {
      gameList: JSON.parse(JSON.stringify(gameList)),
      selectedGame: { value: "", desc: "" },
      dateList: [],
      selectedDate: "",
      resultListSnap: [],
      resultList: [],
      showProgress: false,
      gameFilterSelection: [],
      selectedFilter: { value: "", desc: "" },
    };
  },
  methods: {
    onClickGame(i) {
      this.selectedGame = this.gameList[i];
      this.getResult();
    },
    onClickDate(i) {
      this.selectedDate = this.dateList[i];
      this.getResult();
    },
    getIsOutright() {
      const substring = "Outright";
      return this.selectedGame.desc.toUpperCase().includes(substring.toUpperCase());
    },
    onClickGameFilter(i) {
      this.selectedFilter = this.gameFilterSelection[i];
      if (this.selectedFilter.value == "") {
        this.resultList = this.resultListSnap;
      } else {
        this.resultList = this.resultListSnap.filter(
          (x) => x.moduleId == this.selectedFilter.value
        );
      }
    },
    async getResult() {
      const json = {
        username: this.$store.getters.username,
        langId: this.$store.getters.langId,
        date: this.selectedDate.value,
        type1: this.selectedGame.type1,
        type2: this.selectedGame.type2,
        isOutright: this.getIsOutright(),
      };
      this.showProgress = true;
      const response = await CustomHttp.post("api/v1/odds/result-score-list", json);
      if (response.data.data == null) {
        this.resultList = [];
        this.resultListSnap = [];
      } else {
        let tempResultList = response.data.data.resultList;
        // tempResultList = tempResultList.sort((a, b) =>
        //   a.moduleOrder > b.moduleOrder
        //     ? 1
        //     : b.moduleOrder > a.moduleOrder
        //     ? -1
        //     : 0
        // );
        // tempResultList = tempResultList.sort((a, b) =>
        //   a.moduleTitle > b.moduleTitle
        //     ? 1
        //     : b.moduleTitle > a.moduleTitle
        //     ? -1
        //     : 0
        // );
        // tempResultList = tempResultList.sort((a, b) =>
        //   a.moduleId > b.moduleId ? 1 : b.moduleId > a.moduleId ? -1 : 0
        // );

        this.resultList = JSON.parse(JSON.stringify(tempResultList));
        this.resultListSnap = JSON.parse(JSON.stringify(tempResultList));
      }
      this.gameFilterSelection = [];
      this.gameFilterSelection.push({ value: "", desc: t("All") });
      if (this.resultList.length != 0) {
        // eslint-disable-next-line
        this.resultList.forEach((item, index) => {
          this.gameFilterSelection.push({
            value: item.moduleId,
            desc: item.moduleTitle,
          });
        });
      }

      this.selectedFilter = this.gameFilterSelection[0];
      this.showProgress = false;
      this.onClickGameFilter(0);
    },
  }, //end methods
  computed: {
    // isTimeout() {
    //   return this.$store.getters.isTimeout;
    // },
    langId() {
      return this.$store.getters.langId;
    },
    selectedGameDesc() {
      // if (this.selectedGame.desc.length > 25) {
      //   return this.selectedGame.desc.substring(0, 25) + "...";
      // }
      return this.selectedGame.desc;
    },
  }, //end computed
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sports-dropdown{
  color: inherit;
  text-overflow: ellipsis;
  width: 95%;
  overflow: hidden;
}
a.btnCollapse2.collapsed:before {
  font-weight: 900;
  font-family: "Font Awesome\ 5 Free";
  content: "\f067";
  color: #2f4798;
  padding-left: 7px;
}

a.btnCollapse2:before {
  font-weight: 900;
  font-family: "Font Awesome\ 5 Free";
  content: "\f068";
  color: #2f4798;
  padding-left: 7px;
}

.card {
  background-color: inherit !important;
}

.list-group {
  font-size: 13px !important;
}
.dropdown{
  border: 1px solid #caccd4;
  background: white;
  border-radius: 5px; 
  color: black;
}
.dropdown-menu-min {
  position: absolute;
  transform: translate3d(4px, 38px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
  min-width: 20rem !important;
}

.dropdown-item {
  padding: 0.25rem 1rem;
  font-size: 95%;
}

.dropdown-toggle {
  font-size: 95%;
  width: 100%;
}

.padding {
  height: 20px !important;
}
.list-group-item{
  border-radius: 5px;
}
</style>
