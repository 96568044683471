<template>
  <div id="home-body">
    <div class="row mx-0">
      <desktop-header ref="desktopHeader"></desktop-header>
      <!--- page content --->
      <div class="col-12 px-0 pt-0 pb-0 container-min-height banner-back">
        <!----promo banner---->
          <div class="container">
           <img class="banner w-100" :src="bannerUrl" />
          </div>

        <div class="games-wrap">
          <div class="container">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-6 game-card-wrap menu-hover"
                    v-for="(game, index) in gameList"
                    :key="game.name"
                    @click="onClickGameIcon(index)"
                  >
                    <div class="game-card mt-1">
                      <img
                        class="card-img-sm"
                        :src="getLangImg(game)"
                        alt="game img"
                      />
                    </div>
                    <div class="game-title">{{game.name}}</div>
                    
                  </div>
                </div>
          </div>
        </div>
      </div>
      <!----end page comtent --->
      <!-- Footer-->
      <desktop-footer></desktop-footer>
    </div>
    <!--- login modal--->
    <login-modal
      ref="loginModal"
      :errMessage="loginPanelErr"
      @toggle-danger="toggleDangerAlert"
      @set-err-messsage="setErrMessage"
    ></login-modal>
    <!---game  modal --->
    <game-modal ref="gameModal"></game-modal>
    <!---end game  modal --->
    <!--globa  element component  --->
    <loading-spinner v-if="isLoading"></loading-spinner>
  </div>
</template>

<script>
import GameBanner from '../../../../assets/images/game-banner/cockft.png';
// import desktopImport from '../../../../assets/script/desktop.js';
import cockftt from '../../../../assets/script/catalog/cockft.js';

// import CustomHttp from '../../../CustomHttp';
export default {
  components: {},
  created() {},
  data() {
    return {
      bannerUrl: GameBanner,
      isLoading: false,
      gameList: cockftt.items,
      loginPanelErr: '',
    };
  },
  methods: {
    setErrMessage(errMessage) {
      this.loginPanelErr = errMessage.toString();
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1500);
    },
    onClickGameIcon(i) {
      if (!this.$store.getters.isLoggedIn) {
        //not logged in
        this.$refs.desktopHeader.onClickGameIcon(i);
      } else {
        const selectedGame = this.gameList[i];
        this.$store.dispatch('setSelectedGame', selectedGame);

        this.$refs.desktopHeader.toOpenGameModal();

        // this.$refs.gameModal.showModal();
        // this.$refs.gameModal.getGameDetails();
      }
    },
    getLangImg(obj) {
      let imgSrc = obj.img[0].src;
      let tempSrc = obj.img.find((i) => i.langId == this.$store.getters.langId);
      if (tempSrc) {
        imgSrc = tempSrc.src;
      }
      return imgSrc;
    },
  },
  computed: {},
};
</script>
<style scoped lang="css" src="../../../../assets/css/gamesCatalog.css"></style>