<template>
  <div class="dark-backdrop2 d-block">
    <div class="darkbg">
      <div
        class="close-right-img2 cursor-pointer"
        @click="onClickCloseWalletTransfer"
      >
        <img src="./../../../assets/icons/close.png" width="15" />
      </div>
      <div class="wrapper2">
        <div class="circle circle-1">
          <img
            src="./../../../assets/images/chips/chip2.png"
            width="50"
            height="50"
          />
        </div>
        <div class="circle circle-2">
          <img
            src="./../../../assets/images/chips/chip3.png"
            width="50"
            height="50"
          />
        </div>
        <div class="circle circle-3">
          <img
            src="./../../../assets/images/chips/chip4.png"
            width="50"
            height="50"
          />
        </div>
      </div>
      <h1>{{ $t('Logging into lobby') }}</h1>
      <h2 v-if="transferGameProvider != ''">
        {{ $t('Transferring from') }} {{ transferGameProvider }}
      </h2>

      <!---->
    </div>
  </div>
</template>

<script>
export default {
  emits: ['set-isWalletTransfer'],
  methods: {
    onClickCloseWalletTransfer() {
      this.$emit('set-isWalletTransfer', false);
    },
  }, //end methods
  computed: {
    transferGameProvider() {
      return this.$store.getters.transferGameProvider;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* chips  css */
@import url(https://fonts.googleapis.com/css?family=Oswald);
* {
  box-sizing: border-box;
}

.darkbg {
  background-color: #000;
  width: 80%;
  margin-top: calc(50vh - 50px);
  margin-left: auto;
  margin-right: auto;
  height: 150px;
  border-radius: 15px;
}

.wrapper2 {
  width: 190px;
  height: 50px;
  padding-top: 30px;
  margin: 0 auto;
}
.circle {
  float: left;
  height: 50px;
  margin: 0 5px;
  width: 50px;
}
.circle-1 {
  animation: move1 4s ease-in-out infinite;
}
.circle-2 {
  animation: move2 4s ease-in-out infinite;
}
.circle-3 {
  animation: move3 4s ease-in-out infinite;
}

h1 {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 50px auto 5px auto;
  text-align: center;
}

h2 {
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 5px auto;
  text-align: center;
}

.close-right-img2 {
  padding: 10px 20px;
  float: right;
}

@keyframes move1 {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(60px);
  }

  40% {
    transform: translateX(120px);
    opacity: 1;
  }

  60% {
    transform: translateX(120px);
    opacity: 0;
  }

  80% {
    transform: translateX(0);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes move2 {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(60px);
    opacity: 1;
  }

  40% {
    transform: translateX(60px);
    opacity: 0;
  }

  60% {
    transform: translateX(-60px);
    opacity: 0;
  }

  80% {
    transform: translateX(-60px);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes move3 {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(0);
    opacity: 0;
  }

  40% {
    transform: translateX(-120px);
    opacity: 0;
  }

  60% {
    transform: translateX(-120px);
    opacity: 1;
  }

  80% {
    transform: translateX(-60px);
  }

  100% {
    transform: translateX(0);
  }
}

.dark-backdrop2 {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 200;
}

/* desktop */
@media (min-width: 769px) {
  .darkbg {
    background-color: #000;
    width: 30%;
    margin-top: calc(50vh - 120px);
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    border-radius: 15px;
  }
}

/* mobile */
@media (max-width: 769px) {
  .darkbg {
    background-color: #000;
    width: 80%;
    margin-top: calc(50vh - 50px);
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    border-radius: 15px;
  }
}
</style>
