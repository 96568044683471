import CustomStorage from '../../../CustomStorage';
import CustomHttp from '../../../CustomHttp';
import CustomEncrypt from '../../../CustomEncrypt';
const allowedCompList = process.env.VUE_APP_ALLOWED_COMP_TYPE;
// import axios from 'axios';
// let secretKey = process.env.VUE_APP_SECRET_KEY;
// const CryptoJS = require('crypto-js');

export default {
  async userLogin(context, payload) {
    const username = payload.username;
    const pw = CustomEncrypt.encrypt(payload.password).toString();
    let loginSecret = CustomEncrypt.hashPw(username + pw).toString();
    let json = {
      username: username,
      pw: pw,
      secretkey: loginSecret,
    };

    let response;
    let responseData;

    response = await CustomHttp.post('/api/v1/user/login', json);
    responseData = response.data;

    if (!responseData.success) {
      const error = new Error(
        responseData.error.message || 'Failed to authenticate.'
      );
      throw error;
    }
    const compTypeList = allowedCompList.split(',').map(Number);
    if (!compTypeList.includes(responseData.data.compType)) {
      context.commit('setCompType', responseData.compType);
      const error = new Error('InvalidUsernameOrPassword');
      throw error;
    }

    return await context.dispatch('SetLoginUserState', responseData);
  },
  async userValidate(context, payload) {
    const username = payload.username;
    const sessionId = payload.username;
    let loginSecret = CustomEncrypt.hashPw(username + sessionId).toString();
    let json = {
      username: username,
      sessionId: sessionId,
      secretkey: loginSecret,
    };
    try {
      await context.dispatch('userLogout');
      const response = await CustomHttp.post(
        'api/v1/user/login-by-sessionid',
        json
      );
      const responseData = response.data;

      if (!responseData.success) {
        const error = new Error(
          responseData.error.message ||
            'Failed to authenticate. Check your login data.'
        );
        throw error;
      }

      const compTypeList = allowedCompList.split(',').map(Number);
      if (!compTypeList.includes(responseData.data.compType)) {
        context.commit('setCompType', responseData.compType);
        const error = new Error('InvalidUsernameOrPassword');
        throw error;
      }
      return await context.dispatch('setUserLoginState', true);
    } catch (err) {
      //safari login
      const error = new Error('InvalidUsernameOrPassword');
      throw error;
    }
  },
  async SetLoginUserState(context, payload) {
    localStorage.clear();
    CustomStorage.setItem('username', payload.data.username);
    CustomStorage.setItem('nickname', payload.data.nickname);
    CustomStorage.setItem('reqChgPw', payload.data.reqChgPw);
    CustomStorage.setItem('currency', payload.data.currency);
    CustomStorage.setItem('loginType', payload.data.loginType);
    CustomStorage.setItem('token', payload.data.token);
    CustomStorage.setItem('langId', payload.data.langId);
    CustomStorage.setItem('accId', payload.data.accId);
    CustomStorage.setItem('userId', payload.data.userId);
    CustomStorage.setItem('compType', payload.data.compType);
    CustomStorage.setItem('suspend', payload.data.suspend);
    CustomStorage.setItem(
      'autoTransferWallet',
      payload.data.autoTransferWallet
    );
    CustomStorage.setItem('isLoggedIn', false);

    context.commit('setUser', {
      username: payload.data.username,
      nickname: payload.data.nickname,
      reqChgPw: payload.data.reqChgPw,
      currency: payload.data.currency,
      loginType: payload.data.loginType,
      token: payload.data.token,
      // langId: payload.data.langId,
      langId: context.rootGetters.langId,
      accId: payload.data.accId,
      userId: payload.data.userId,
      compType: payload.data.compType,
      suspend: payload.data.suspend,
      autoTransferWallet: payload.data.autoTransferWallet,
      isLoggedIn: false,
    });

    // uncomment to restore
    context.dispatch('setEnablePaymentGateway', true);
    //await context.dispatch('getUserConfig');
    return await context.dispatch('getMemberBalance');
    // return await context.dispatch('getUserConfig');
  },
  async userLogoutWithApi(context) {
    // await CustomHttp.post('/api/v1/member/logout', json);
    const json = { username: context.rootGetters.username };
    await CustomHttp.logoutUser(json);
    context.dispatch('userLogout');
  },
  async userLogoutWithApi2(context, username) {
    // await CustomHttp.post('/api/v1/member/logout', json);
    // context.dispatch('userLogout');
    const json = { username: username };
    try {
      CustomHttp.logoutUser(json);
      CustomStorage.clear();
      context.dispatch('resetUser');
    } catch (err) {
      // console.log('err mobile logout');
    }
  },
  async userLogout(context) {
    CustomStorage.removeItem('currency');
    CustomStorage.removeItem('reqChgPw');
    CustomStorage.removeItem('nickname');
    CustomStorage.removeItem('compType');
    CustomStorage.removeItem('loginType');
    CustomStorage.removeItem('langId');
    CustomStorage.removeItem('userId');
    CustomStorage.removeItem('accId');
    CustomStorage.removeItem('isLoggedIn');
    CustomStorage.removeItem('username');
    CustomStorage.removeItem('totalRemainingCreditLimit');
    CustomStorage.removeItem('token');
    CustomStorage.removeItem('suspend');
    CustomStorage.removeItem('enablePaymentGateway');
    CustomStorage.removeItem('autoTransferWallet');

    const eventFlag = localStorage.getItem('event-logout');
    if (eventFlag == undefined) {
      localStorage.setItem('event-logout', 'logout');
    }
    context.dispatch('resetUser');
  },
  async getMemberBalance(context) {
    let json = {
      username: context.rootGetters.username,
      withTotalRemainingCreditLimit: true,
    };
    let creditResult;
    let totalRemainingCreditLimit;
    try {
      creditResult = await CustomHttp.post('/api/v1/member/get', json);
      totalRemainingCreditLimit =
        creditResult.data.data.totalRemainingCreditLimit;
    } catch (err) {
      //safari
      const error = new Error('Session Time Out');
      context.dispatch('resetUser');
      throw error;
      // context.dispatch('setTimeoutFlag', true);
    }

    CustomStorage.setItem(
      'totalRemainingCreditLimit',
      totalRemainingCreditLimit
    );
    context.commit('setTotalRemainingCreditLimit', totalRemainingCreditLimit);
  },
  async getMemberBalanceMobileLanding(context) {
    let json = {
      username: context.rootGetters.username,
      withTotalRemainingCreditLimit: true,
    };

    let creditResult;
    let totalRemainingCreditLimit;

    try {
      creditResult = await CustomHttp.getMemberBalanceMobileLanding(json);
      totalRemainingCreditLimit =
        creditResult.data.data.totalRemainingCreditLimit;
      CustomStorage.setItem(
        'totalRemainingCreditLimit',
        totalRemainingCreditLimit
      );
      context.commit('setTotalRemainingCreditLimit', totalRemainingCreditLimit);
    } catch (err) {
      //safari
      const error = new Error('Session Time Out');
      context.dispatch('resetUser');
      throw error;
      // context.dispatch('setTimeoutFlag', true);
    }
  },
  async getMemberAllBalance(context) {
    let json = {
      username: context.rootGetters.username,
      withTotalOutstandingBet: true,
      withTotalRemainingCreditLimit: true, //flag
      withNickname: true,
    };

    let creditResult;
    let totalRemainingCreditLimit;
    let totalOutstandingBet;
    try {
      creditResult = await CustomHttp.post('/api/v1/member/get', json);
      totalRemainingCreditLimit =
        creditResult.data.data.totalRemainingCreditLimit;
      totalOutstandingBet = creditResult.data.data.totalOutstandingBet;
      CustomStorage.setItem(
        'totalRemainingCreditLimit',
        totalRemainingCreditLimit
      );

      context.commit('setTotalOutstandingBet', totalOutstandingBet);
      context.commit('setMemberAccount', creditResult.data.data);
    } catch (err) {
      //safari
      const error = new Error('Session Time Out');
      context.dispatch('resetUser');
      throw error;
      // context.dispatch('setTimeoutFlag', true);
    }
  },
  async getUserConfig(context) {
    let json = {
      username: context.rootGetters.username,
    };
    let response;
    let responseData;
    try {
      response = await CustomHttp.postUfa('/v1/users/get-config', json);
      //TODO REMOVE THIS CODE
      if (response.message) {
        if (response.message.includes('404')) {
          context.dispatch('setEnablePaymentGateway', true);
        }
      }

      if (response.data) {
        responseData = response.data.data;
        context.dispatch(
          'setEnablePaymentGateway',
          responseData.enablePaymentGateway
        );
      }

      return await context.dispatch('getMemberBalance');
    } catch (err) {
      context.dispatch('setEnablePaymentGateway', false);
    }
  },
  setUserLoginState(context, payload) {
    context.commit('setUserLoginState', payload);
    CustomStorage.setItem('isLoggedIn', payload);
  },
  setMemberNickname(context, payload) {
    CustomStorage.setItem('nickname', payload);
    context.commit('setMemberNickname', payload);
  },
  tryLogin(context) {
    const token = CustomStorage.getItem('token');
    const username = CustomStorage.getItem('username');
    const nickname = CustomStorage.getItem('nickname');
    const reqChgPw = CustomStorage.getItem('reqChgPw');
    const currency = CustomStorage.getItem('currency');
    const loginType = CustomStorage.getItem('loginType');
    const langId = CustomStorage.getItem('langId');
    const systemMessage = CustomStorage.getItem('systemMessage');
    const isLoggedIn = CustomStorage.getItem('isLoggedIn');
    const userId = CustomStorage.getItem('userId');
    const accId = CustomStorage.getItem('accId');
    const compType = CustomStorage.getItem('compType');
    const autoTransferWallet = CustomStorage.getItem('autoTransferWallet');
    let suspend = CustomStorage.getItem('suspend');
    let enablePaymentGateway = CustomStorage.getItem('enablePaymentGateway');
    const totalRemainingCreditLimit = CustomStorage.getItem(
      'totalRemainingCreditLimit'
    );
    // const expiresIn = +tokenExpiration - new Date().getTime();
    // if (expiresIn < 0) {
    //   return;
    // }
    if (suspend && suspend != undefined) {
      if (suspend.toString() == 'true') {
        suspend = true;
      } else {
        suspend = false;
      }
    }

    if (enablePaymentGateway && enablePaymentGateway != undefined) {
      if (enablePaymentGateway.toString() == 'true') {
        enablePaymentGateway = true;
      } else {
        enablePaymentGateway = false;
      }
    }
    if (token && isLoggedIn) {
      context.commit('setUser', {
        username: username,
        nickname: nickname,
        reqChgPw: reqChgPw,
        currency: currency,
        loginType: loginType,
        token: token,
        langId: langId,
        accId: accId,
        userId: userId,
        compType: compType,
        isLoggedIn: isLoggedIn,
        autoTransferWallet: autoTransferWallet,
        suspend: suspend,
      });

      context.dispatch('setEnablePaymentGateway', enablePaymentGateway);
      context.commit('setSystemMessage', systemMessage);
      context.commit('setTotalRemainingCreditLimit', totalRemainingCreditLimit);
      //  return await context.dispatch('getSystemMessage');
      const relativePath = window.location.pathname + window.location.search;
      if (relativePath != '/cash-out') {
        context.dispatch('getSystemMessage');
      }
    }
  },
  tryLoginMobile(context) {
    const token = CustomStorage.getItem('token');
    const username = CustomStorage.getItem('username');
    const nickname = CustomStorage.getItem('nickname');
    const reqChgPw = CustomStorage.getItem('reqChgPw');
    const currency = CustomStorage.getItem('currency');
    const loginType = CustomStorage.getItem('loginType');
    const langId = CustomStorage.getItem('langId');
    const systemMessage = CustomStorage.getItem('systemMessage');
    const isLoggedIn = CustomStorage.getItem('isLoggedIn');
    const userId = CustomStorage.getItem('userId');
    const accId = CustomStorage.getItem('accId');
    const compType = CustomStorage.getItem('compType');
    const autoTransferWallet = CustomStorage.getItem('autoTransferWallet');

    let suspend = CustomStorage.getItem('suspend');
    let enablePaymentGateway = CustomStorage.getItem('enablePaymentGateway');
    const totalRemainingCreditLimit = CustomStorage.getItem(
      'totalRemainingCreditLimit'
    );
    // const expiresIn = +tokenExpiration - new Date().getTime();
    // if (expiresIn < 0) {
    //   return;
    // }

    if (suspend && suspend != undefined) {
      if (suspend.toString() == 'true') {
        suspend = true;
      } else {
        suspend = false;
      }
    }
    if (enablePaymentGateway && enablePaymentGateway != undefined) {
      if (enablePaymentGateway.toString() == 'true') {
        enablePaymentGateway = true;
      } else {
        enablePaymentGateway = false;
      }
    }
    if (token && isLoggedIn) {
      context.commit('setUser', {
        username: username,
        nickname: nickname,
        reqChgPw: reqChgPw,
        currency: currency,
        loginType: loginType,
        token: token,
        langId: langId,
        accId: accId,
        userId: userId,
        compType: compType,
        isLoggedIn: isLoggedIn,
        autoTransferWallet: autoTransferWallet,
        suspend: suspend,
      });
      context.dispatch('setEnablePaymentGateway', enablePaymentGateway);
      context.commit('setSystemMessage', systemMessage);
      context.commit('setTotalRemainingCreditLimit', totalRemainingCreditLimit);
      //  return await context.dispatch('getSystemMessage');

      // context.dispatch('getSystemMessage');
    }
  },
  setTimeoutFlag(context, payload) {
    context.commit('setTimeoutFlag', payload);
  },
  async getSystemMessage(context) {
    let json = {
      langId: context.rootGetters.langId,
    };
    let response;
    try {
      response = await CustomHttp.post('api/v1/system/get-message-log', json);
    } catch (err) {
      //context.dispatch('setTimeoutFlag', true);
    }

    let messageLogList = [];
    let latestMessage = '';
    if (response.data.data != null) {
      messageLogList = response.data.data.messageLogList;
      latestMessage = messageLogList[0].msg;
    }
    CustomStorage.setItem('systemMessage', latestMessage);
    context.commit('setSystemMessage', latestMessage);
    context.commit('setSystemMessageList', messageLogList);
  },
  setReqChgPw(context, payload) {
    CustomStorage.setItem('reqChgPw', payload);
    context.commit('setReqChgPw', payload);
  },
  setLangId(context, payload) {
    CustomStorage.setItem('langId', payload);
    context.commit('setLangId', payload);
  },
  resetUser(context) {
    context.commit('setUser', {
      username: null,
      nickname: null,
      reqChgPw: null,
      currency: null,
      loginType: null,
      token: null,
      // not null for header
      langId: context.rootGetters.langId,
      isLoggedIn: false,
      accId: null,
      userId: null,
      compType: null,
      autoTransferWallet: null,
      suspend: false,
      enablePaymentGateway: false,
    });
  },
  setCompType(context, payload) {
    CustomStorage.setItem('compType', payload);
    context.commit('setCompType', payload);
  },
  setMaintenanceRemark(context, payload) {
    context.commit('setMaintenanceRemark', payload);
  },
  setEnablePaymentGateway(context, payload) {
    CustomStorage.setItem('enablePaymentGateway', payload);
    context.commit('setEnablePaymentGateway', payload);
  },
  setActiveComponent(context, payload) {
    context.commit('setActiveComponent', payload);
  },
};
