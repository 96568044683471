<template>
  <loading-spinner v-if="isLoading"></loading-spinner>
  <div class="mobile" style="height: 100%;">
    <MobileTerms
      @click-accept="onClickAccept()"
      @click-reject="onClickReject()"
    />
  </div>

  <div class="desktop">
    <DesktopTerms
      @click-accept="onClickAccept()"
      @click-reject="onClickReject()"
    />
  </div>
</template>

<script scoped>
import MobileTerms from './MobileTerms.vue';
import DesktopTerms from './DesktopTerms.vue';
import CustomStorage from './../../../../CustomStorage';
//Vue export
export default {
  components: {
    MobileTerms,
    DesktopTerms,
  },
  data() {
    return {
      acceptAgreement: false,
      isLoading: false,
      userLangId: 0,
    };
  },
  mounted() {
    this.$store.dispatch('tryLogin');
    this.$store.dispatch('setUserLoginState', false);
    this.$store.dispatch('setLangId', CustomStorage.getItem('langId'));
    this.userLangId = this.$store.getters.langId.toString();
  },
  beforeUnmount() {
    if (!this.acceptAgreement) {
      this.onClickReject();
    }
  },
  methods: {
    async onClickAccept() {
      // console.log("setUserLoginState");
      this.$store.dispatch('setUserLoginState', true);
      this.acceptAgreement = true;

      this.isLoading = true;
      try {
        await this.$store.dispatch('getSystemMessage');
        this.isLoading = false;
        this.$router.replace('/');
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        this.$router.replace('/');
      }
    },
    onClickReject() {
      this.$store.dispatch('userLogout');
      this.$router.replace('/');
    },
  },
};
</script>
<style scoped lang="css" src="../../../../assets/css/agreement.css"></style>
