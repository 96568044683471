//import gameImport from './../game';
import liveCasino from './live.js';
import slot from './slot.js';
import sportsbook from './sportsbook.js';
import cockft from './cockft.js';
import cards from './cards.js';

var gameMenu = {
  name: 'Hot Games',
  items: [
    // cards.items.find((x) => x.code === '80'),
    sportsbook.items.find((x) => x.name.toUpperCase() === 'SPORTSBOOK'),
    liveCasino.items.find((x) => x.code === '39-101'),
    liveCasino.items.find((x) => x.name.toUpperCase() === 'SA GAMING'),
    slot.items.find((x) => x.code === '35'),
    slot.items.find((x) => x.code === '39-208'),
    cockft.items.find((x) => x.name.toUpperCase() === 'SV 388'),
    slot.items.find((x) => x.name.toUpperCase() === 'RED TIGER'), 
    cards.items.find((x) => x.name.toUpperCase() === 'THAI HI-LO'), 
    
  ],
};

export default gameMenu;
