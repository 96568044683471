<template>
  <EnAbout v-if="langId == '0'" />
  <CnAbout v-if="langId == '1'" />
  <ThAbout v-if="langId == '2'" />
  <VnAbout v-if="langId == '3'" />
</template>

<script>
import EnAbout from './about/EnAbout.vue';
import ThAbout from './about/ThAbout.vue';
import VnAbout from './about/VnAbout.vue';
import CnAbout from './about/CnAbout.vue';
//Vue export
export default {
  components: { EnAbout, ThAbout, VnAbout, CnAbout },
  data() {
    return {};
  },
  methods: {},
  computed: {
    langId() {
      return this.$store.getters.langId;
    },
  },
};
</script>

<style scoped>
.card {
  border: 0px;
  position: relative;
}

.card-body {
  padding: 0 1em;
  color: #1c327e;
}

</style>
