<template>
  <div id="divResult" v-for="(r, index) in resultList" :key="r.moduleId">
    <ul class="list-group" style="margin: auto; padding-top: 10px;">
      <li class="list-group-item">
        <a
          class="btnCollapse2 collapsed"
          data-toggle="collapse"
          :href="'#collapse_0' + index"
          role="button"
          aria-expanded="false"
          aria-controls="collapse_1"
        >
          <span class="left" style="font-size: 15px; width: 80%;">
            {{ r.moduleTitle }}
            <span v-if="nInfo != ''"> ({{ nInfo }})</span>
          </span>
        </a>
        <div
          :id="'collapse_0' + index"
          class="multi-collapse px-2 collapse"
          style="width: 100%;"
        >
          <template
            v-for="(r2, index) in r.resultDetailList"
            :key="r2.matchCode"
          >
            <hr v-if="index != 0" />
            <div class="collapse_title mb-1" style="color:#ff9611">{{ r2.matchDate }}</div>
            <div class="container">
              <div class="row row_header">
                <div class="col text-center">{{ $t('Winner') }}</div>
              </div>
              <div class="row border-all">
                <div
                  class="col text-center"
                >
                  {{ r2.home }}
                  <img
                    src="./../../../../../assets/icons/result/FG.gif"
                    v-if="r2.homeFG"
                  />
                  <img
                    src="./../../../../../assets/icons/result/LG.gif"
                    v-if="r2.homeLG"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
//Vue export
export default {
  mounted() {
    let x = this.resultList;
    // x[0].resultDetailList[0].nInfo = 'asdas';
    // eslint-disable-next-line
    x.forEach((item, index) => {
      const flag = item.resultDetailList.find((r) => r.nInfo != '');
      if (flag != undefined) {
        this.nInfo = flag.nInfo;
      }
    });
  },
  props: ['resultList'],
  data() {
    return {
      nInfo: '',
    };
  },
};
</script>
<style scoped>
.border-all{
  border: 1px solid #a1b9f1;
}
.list-group-item{
  color: #2f4798;
  border-radius: 5px;
}
a.btnCollapse2.collapsed:before {
  font-weight: 900;
  font-family: 'Font Awesome\ 5 Free';
  content: '\f067';
  color: #2f4798;
  padding-left: 7px;
}

a.btnCollapse2:before {
  font-weight: 900;
  font-family: 'Font Awesome\ 5 Free';
  content: '\f068';
  color: #2f4798;
  padding-left: 7px;
}

.card {
  background-color: inherit !important;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid #d1c57c;
}
</style>
