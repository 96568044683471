<template>
  <loading-spinner v-if="isLoading"></loading-spinner>
  <transition name="fadeIn">
    <div v-if="showSuccessAlert">
      <success-alert-cashout :message="successMessage"></success-alert-cashout>
    </div>
  </transition>
  <transition name="fadeIn">
    <div v-if="showErrorAlert">
      <danger-alert :message="errorMessage"></danger-alert>
    </div>
  </transition>
  <!---table-dark--->
  <div class="cashout-container">
    <table class="table cashout-table">
      <thead>
        <tr>
          <th style="width: 45%" class="pl-4 border-top-none" colspan="2">
            {{ $t('GameType') }}
          </th>
          <th style="width: 25%" class="pl-0 border-top-none text-right pr-5">
            {{ $t('GameBalance') }}
          </th>
          <th style="width: 30%" class="border-top-none padding-left-none">
            <button
              class="btn  btn-restore"
              :disabled="
                showProgress || isSomeBalanceLaoding || isAllBalanceZero
              "
              @click="onClickRestore()"
            >
              <img
                src="../../../assets/icons/icn-cashout-resotre.png"
                width="16"
              />
              <span class="ml-2">{{ $t('BtnRestore') }}</span>
            </button>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(game, index) in gameImport" :key="game.code">
          <template v-if="game.enabled">
            <td class="p-3 w-25">
              <img class="game-icon" :src="game.icon_white" v-if="game.icon_white"/>
              <img class="game-icon" :src="game.icon" v-else/>
            </td>

            <td class="pt-4">{{ game.name }}</td>

            <td class="pt-4 text-right pr-5">
              <span class="text-danger" v-if="suspend">
                {{ $t('AccountSuspended') }}
              </span>
              <span class="balance" v-else-if="game.isProgress || showProgress">
                <i class="fas fa-spinner fa-spin"></i>
              </span>

              <span v-if="isError">-</span>
              <span
                class="balance cursor-pointer"
                @click="onClickRefreshBalance(index)"
                v-else-if="!game.isProgress && !showProgress"
              >
                {{ $filters.currencyDecimal(game.GameBalance) }}
              </span>
            </td>

            <td class="pt-4">
              <button
                class="btn btn-cashout"
                :disabled="game.GameBalance <= 0 || game.isProgress"
                v-if="game.Reason == ''"
                @click="onClickCashOut(index)"
              >
                <img
                  class="icn-cashout"
                  alt="cashout"
                  src="../../../assets/icons/icn-cashout.png"
                />
                <span class="text-cashout ml-1"> {{ $t('BtnCashOut') }} </span>
              </button>
              <span class="text-danger" v-if="game.Reason != ''">
                {{ game.Reason }}</span
              >
            </td>
          </template>
        </tr>
      </tbody>
      <!-- <tbody v-if="showProgress" class="bg-cashout">
      <tr>
        <td class="border-none" colspan="4">
          <div class="col-lg-12 p-1">
            <white-progress-spinner></white-progress-spinner>
          </div>
        </td>
      </tr>
    </tbody> -->
    </table>
  </div>
</template>

<script>
import CustomHttp from '../../../CustomHttp';
// import CustomEncrypt from '../../../CustomEncrypt';
import GameImport from '../../../assets/script/game';
const gameUrl = process.env.VUE_APP_GAME_URL;

//Vue export
export default {
  mounted() {
    this.gameImport = this.gameImport.filter((x) => x.enabled == true);
    this.gameImport.forEach((item, index) => {
      //  always use gameURl 1 for cashout withdrawal
      this.gameImport[index].gameUrl = gameUrl;
      this.gameImport[index].GameBalance = 0;
      this.gameImport[index].Reason = '';
    });
    this.getUserCashOutDetails();
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      showProgress: false,
      gameImport: JSON.parse(JSON.stringify(GameImport)),
      showSuccessAlert: false,
      showErrorAlert: false,
      successMessage: '',
      errorMessage: '',
    };
  },
  methods: {
    async onClickGameIcon() {
      let tempGame = this.bottomBannerImport[this.currentBannerIndex].game;
      if (this.autoTransferWallet) {
        //prevent modal show
        this.selectedGame = null;
        this.processWalletTransfer(tempGame);
      } else {
        this.selectedGame = tempGame;
        this.$refs.gameModal.getGameDetails2(tempGame);
      }
    },
    async onClickCashOut(i) {
      // this.isLoading = true;
      this.gameImport[i].isProgress = true;
      const selectedRecord = this.gameImport[i];
      const response = await CustomHttp.withDrawFromGame(
        selectedRecord.code,
        selectedRecord.GameBalance,
        10,
        selectedRecord.gameUrl
      );
      this.isLoading = false;
      if (response.errtext[0] == '') {
        this.onClickRefreshBalance(i);
        this.toggleSuccessAlert('Success Withdraw');
      } else {
        this.toggleDangerAlert(response.errtext[0]);
      }
    },
    async onClickRestore() {
      this.isLoading = true;

      try {
        for (let i = 0; i < this.gameImport.length; i++) {
          if (this.gameImport[i].GameBalance != 0) {
            this.gameImport[i].isProgress = true;
          }
        } //end for loop

        for (let i = 0; i < this.gameImport.length; i++) {
          if (this.gameImport[i].GameBalance != 0) {
            const response = await CustomHttp.withDrawFromGame(
              this.gameImport[i].code,
              this.gameImport[i].GameBalance,
              10,
              this.gameImport[i].gameUrl
            );
            if (response.errtext[0] == '') {
              this.onClickRefreshBalance2(i);
            }
          }
        } //end for loop

        this.toggleSuccessAlert('Success Withdraw');
        this.isLoading = false;
      } catch (error) {
        this.toggleDangerAlert('Failed restore credit');
        this.isLoading = false;
      }
    },
    async onClickRefreshBalance(i) {
      this.gameImport[i].isProgress = true;
      const selectedRecord = this.gameImport[i];
      try {
        const response = await CustomHttp.getOneGame(
          selectedRecord.code,
          10,
          selectedRecord.gameUrl
        );
        const responseBalance = response.credit[0];
        this.gameImport[i].GameBalance = responseBalance;
        this.gameImport[i].isProgress = false;
        this.gameImport[i].Reason = '';
      } catch (error) {
        let tempMessage = error.message;
        tempMessage = tempMessage.replace('Error:', '');
        this.gameImport[i].GameBalance = 0;
        this.gameImport[i].Reason = tempMessage;
        this.gameImport[i].isProgress = false;
      }
    },
    async onClickRefreshBalance2(i) {
      this.gameImport[i].isProgress = true;
      const selectedRecord = this.gameImport[i];
      try {
        const response = await CustomHttp.getOneGame2(
          selectedRecord.code,
          selectedRecord.gameUrl
        );
        const responseBalance = response.credit[0];
        this.gameImport[i].GameBalance = responseBalance;
        this.gameImport[i].isProgress = false;
        this.gameImport[i].Reason = '';
      } catch (error) {
        let tempMessage = error.message;
        tempMessage = tempMessage.replace('Error:', '');
        this.gameImport[i].GameBalance = 0;
        this.gameImport[i].Reason = tempMessage;
        this.gameImport[i].isProgress = false;
      }
    },
    async getUserCashOutDetails() {
      if (this.$store.getters.suspend) {
        return;
      }
      this.showProgress = true;
      //  await this.$store.dispatch('getSession');

      let idListString = '';
      // url 1
      const gameImportUrl1 = this.gameImport.filter(
        (x) => x.gameUrl == gameUrl
      );
      gameImportUrl1.forEach((item, index) => {
        if (this.gameImport.length == index + 1) {
          idListString += item.code;
        } else {
          idListString += item.code + ',';
        }
      });

      let response = await CustomHttp.getAllGame(idListString, gameUrl);
      if (!response.data) {
        this.showProgress = false;
        this.showErrorAlert = true;
        this.isError = true;
        this.errorMessage =
          'Error connecting to server. Please try refreshing the page!';

        return false;
      }

      if (response.data.ErrText != '') {
        this.$swal.fire(response.data.ErrText);
        return;
      }
      let responseData = [];
      // eslint-disable-next-line
      gameImportUrl1.forEach((item, index) => {
        const tempRecord = response.data.game_list.find(
          (l) => l.GameId == item.code
        );
        responseData.push(tempRecord);
      });

      // url 2
      //   let gameImportUrl2 = this.gameImport.filter((x) => x.gameUrl == gameUrl2);
      //   idListString = '';
      //   gameImportUrl2.forEach((item, index) => {
      //     if (this.gameImport.length == index + 1) {
      //       idListString += item.code;
      //     } else {
      //       idListString += item.code + ',';
      //     }
      //   });
      //   let response2 = await CustomHttp.getAllGame(idListString, gameUrl2);
      //   if (response2.data.ErrText != '') {
      //     this.$swal.fire(response2.data.ErrText);
      //     return;
      //   }
      //   gameImportUrl2.forEach((item) => {
      //     const tempRecord = response2.data.game_list.find(
      //       (l) => l.GameId == item.code
      //     );
      //     responseData.push(tempRecord);
      //   });
      this.gameImport.forEach((item, index) => {
        const record = responseData.find((r) => r.GameId == item.code);
        this.gameImport[index] = {
          ...this.gameImport[index],
          ...record,
          isProgress: false,
        };
      }); //end for each

      //end get game from details from API
      this.showProgress = false;
      //   const response = await CustomHttp.post(
      //     'api/v1/member/get-preference',
      //     json
      //   );
      //   const responseData = response.data.data;
    }, //end getcashout details
    toggleSuccessAlert(message) {
      this.successMessage = message;
      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 1500);
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1500);
    },
  }, //end methods
  computed: {
    suspend() {
      return this.$store.getters.suspend;
    },
    isAllBalanceZero() {
      const flag = this.gameImport.find((g) => g.GameBalance != 0);
      if (flag == undefined) {
        return true;
      } else {
        return false;
      }
    },
    autoTransferWallet() {
      return this.$store.getters.autoTransferWallet;
    },
    isSomeBalanceLaoding() {
      const flag = this.gameImport.find((g) => g.isProgress == true);
      if (flag == undefined) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.table-dark {
  background-color: #2b2b32;
}
.cashout-backdrop {
  position: absolute;
  left: 0;
  width: 100vw;
}
thead {
  width: 567px;
  height: 36px;
  margin: 0 480px 19.3px 481px;
  padding: 11px 27px 11px 25.6px;
  background-color: #001e3c;
  position: relative;
  z-index: 1;
}

tr td {
  padding: 0.75rem 0rem;
  /* font-family: Helvetica; */
  font-size: 16px;
  line-height: 1.19;
  text-align: left;
  color: #fff;
}

tr th {
  width: 72px;
  height: 16px;
  margin: 0 245.4px 0 0;
  /* font-family: Helvetica; */
  font-size: 14px;
  line-height: 2.5em;
  color: #bccbe6;
  font-weight: 400;
}

.game-icon {
  width: auto;
  height: 35.3px;
  width: 66.2px;
  object-fit: contain;
}

.btn-cashout {
  color: white;
  background: #2f4798;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  top: -5px;
  border-radius: 20px;
  padding: 4px 15px;
}
.btn-cashout:disabled {
  opacity: 0.5;
}
.btn-restore {
  background: linear-gradient(to bottom, #ff9611, #c60);
  min-width: 100px;
  padding: 4px 15px;
  font-size: small;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
}

.btn-warning {
  border-radius: 8px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5) !important;
  text-shadow: 0 1px 1px rgba(254, 235, 203, 0.5);
  line-height: 1.25;
  border: 0px !important;
}

.text-cashout {
  line-height: 1.19;
  /* font-family: Helvetica; */
  font-size: 12px;
  text-align: center;
}

.icn-cashout {
  height: 16px;
  object-fit: contain;
}

.balance {
  line-height: 1.4;
  /* font-family: Helvetica; */
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  color: #fff;
}

.text-danger {
  /* font-family: Helvetica; */
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: #ff6850;
}

.bg-cashout {
  background-color: inherit;
  /* background-color: #2a2a2a; */
}
.cashout-container {
  background: #10192e;
  min-height: 100vh;
}
.cashout-table {
  background: #10192e;
}
.cashout-table thead th {
  border-bottom: 0px !important;
}

.border-top-none {
  border-top: 0px !important;
}

.cashout-table td,
.cashout-table th {
  border-bottom: solid 0.5px #2a334a;
  border-top: 0px !important;
}

.tr th {
  line-height: 1.2 !important;
}

.border-none {
  border: 0px !important;
}

.cashout-table tbody + tbody {
  border-top: 0px !important;
}

.padding-left-none {
  padding-left: 0px;
}
</style>
