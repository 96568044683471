<template>
  <div class="alert alert-success notification-wrap" role="alert">
    <span class="noti-text"> {{ message }}</span>
  </div>
</template>

<script>
//Vue export
export default {
  props: ["message"],
};
</script>

<style scoped>
.notification-wrap {
  position: fixed;
  top: 50px;
  /* left: 41%; */
  z-index: 1040;
  padding: 0.25rem 0.75rem !important;
  border-radius: 5px;
  border: solid 1px #a41d1d;
  background-color: #ffcfcf;
  min-width: 25rem;
  left: calc(50% - 12.5rem);
}

.noti-text {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #a41d1d;
  padding-left: 7px;
}
</style>
