export default {
  setUser(state, payload) {
    (state.username = payload.username),
      (state.nickname = payload.nickname),
      (state.reqChgPw = payload.reqChgPw),
      (state.currency = payload.currency),
      (state.loginType = payload.loginType),
      (state.token = payload.token);
    state.accId = payload.accId;
    state.userId = payload.userId;
    state.langId = payload.langId;
    state.compType = payload.compType;
    state.isLoggedIn = payload.isLoggedIn;
    state.suspend = payload.suspend;
    state.autoTransferWallet = payload.autoTransferWallet;
  },
  setUserLoginState(state, payload) {
    state.isLoggedIn = payload;
  },
  setTotalRemainingCreditLimit(state, payload) {
    state.totalRemainingCreditLimit = payload;
  },
  setMemberAccount(state, payload) {
    state.minBet = payload.minBet;
    state.maxBet = payload.maxBet;
    state.creditLimit = payload.creditLimit;
    state.totalBalance = payload.totalBalance;
    state.totalRemainingCreditLimit = payload.totalRemainingCreditLimit;
    state.nickname = payload.nickname;
  },
  setMemberNickname(state, payload) {
    state.nickname = payload;
  },
  setTotalOutstandingBet(state, payload) {
    state.totalOutstanding = payload.totalOutstanding;
    state.totalOutstandingCount = payload.totalOutstandingCount;
  },
  setTimeoutFlag(state, payload) {
    state.isTimeout = payload;
  },
  setSystemMessage(state, payload) {
    state.systemMessage = payload;
  },
  setReqChgPw(state, payload) {
    state.reqChgPw = payload;
  },
  setSystemMessageList(state, payload) {
    state.systemMessageList = payload;
  },
  setLangId(state, payload) {
    state.langId = payload;
  },
  setCompType(state, payload) {
    state.compType = payload;
  },
  setMaintenanceRemark(state, payload) {
    state.maintenanceRemark = payload;
  },
  setEnablePaymentGateway(state, payload) {
    state.enablePaymentGateway = payload;
  },
  setActiveComponent(state, payload) {
    state.activeComponent = payload;
  },
  setAutoTransferWallet(state, payload) {
    state.autoTransferWallet = payload;
  },
  // setSystemMessage(state, payload) {
  //   state.messageLogList = payload;
  // },
};
