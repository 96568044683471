<template>
  <div class="container deposit-container-top">
      <transition name="fadeIn">
        <div v-if="showSuccessAlert">
          <success-alert :message="successMessage"></success-alert>
        </div>
      </transition>
      <nav class="full-center mt-4">
          <div class="tab-menu active left-radius">{{$t('Btn_Deposit')}}</div>
          <div class="tab-menu right-radius" @click="setActiveComponent('screen-withdraw')">{{$t('dep_deposit')}}</div>
      </nav>
      <br>
      <h5 class="heading">{{$t('dep_header')}}</h5>

      <div class="deposit-container">
        <template v-for="(d,index) in depositOptions" :key="d.name" >
          <div class="deposit-card" :style="{background : d.background}">
            <img class="deposit-bank" :src="d.image"/>
            <div class="dcard-header">{{$t('dep_bankacc')}}</div>
            <div class="dcard-info col-spaced">
              <textarea :id="'bank' + index" class="bankname hollow-text" type="text" :value="d.account" contenteditable readonly style="resize: none;"></textarea>
              <img class="deposit-copy" src="../../../assets/icons/deposit-copy.png"
              @click="copyToClipboard('bank' + index, d.account)"/>
            </div>
            <div class="dcard-header mt-2">{{$t('dep_banknum')}}</div>
            <div class="dcard-info col-spaced">
              <input :id="'number' + index" class="hollow-text" type="text" :value="d.number" contenteditable readonly/>
              <img class="deposit-copy" src="../../../assets/icons/deposit-copy.png"
              @click="copyToClipboard('number' + index, d.number)"/>
            </div>
          </div>
        </template>
      </div>
  </div>
</template>

<script>
//import CustomHttp from '../../../../CustomHttp';
//import deposit1 from '../../../assets/images/deposit1.png';
import deposit2 from '../../../assets/images/deposit2.png';
//import deposit3 from '../../../assets/images/deposit3.png';

/*data for deposit options*/
const depositOptions_in = [
  // {
  //   name : 'Maybank',
  //   account: 'ABC LOREM ENTERPRISE',
  //   number: '1234567ABC',
  //   image: deposit1,
  //   background: 'linear-gradient(to top, #fff8e6, #ffe897)'
  // },
  {
    name : 'CIMB',
    account: 'Mark Cliffon Song Choon Hua',
    number: '7076026883',
    image: deposit2,
    background: 'linear-gradient(to top, #fff2f2, #ffc1c1)'
  },
  // {
  //   name : 'RHB',
  //   account: 'XYZ LOREM LONG NAMESS ENTERPRISE LONG',
  //   number: '1234567ABC',
  //   image: deposit3,
  //   background: 'linear-gradient(to top, #f3fcff, #b7eeff)'
  // },
]

//Vue export
function iosCopyToClipboard(el) {
    var oldContentEditable = el.contentEditable,
        oldReadOnly = el.readOnly,
        range = document.createRange();

    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);

    var s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.activeElement.blur();
    el.blur();

    document.execCommand('copy');
}

export default {
  mounted() {
  },
  data() {
    return {
      depositOptions: depositOptions_in,
      showSuccessAlert: false,
      successMessage : '',
    };
  },
  methods: {
    setActiveComponent(componentName) {
      this.$emit('set-component', componentName);
    },
  toggleSuccessAlert(message) {
    this.successMessage = message;
    this.showSuccessAlert = true;
    setTimeout(() => {
      this.showSuccessAlert = false;
    }, 2000);
  },
    copyToClipboard(input,text){
      if(navigator.userAgent.match(/chrome|chromium|crios/i)){
         const elem = document.createElement('textarea');
         elem.value = text;
         document.body.appendChild(elem);
         elem.select();
         document.execCommand('copy');
         document.body.removeChild(elem);
      }else{
        iosCopyToClipboard(document.getElementById(input));
      }
      this.toggleSuccessAlert(this.$t('dep_copied'));
    },
    async onClickRefreshBalance() {
      this.isLoadingBalance = true;
      try {
        await this.$store.dispatch('getMemberBalance');
        this.isLoadingBalance = false;
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        this.isLoadingBalance = false;
        this.toggleDangerAlert(error);
      }
    },
  },
  computed: {

  },
};
</script>
<style scoped>
.bankname{
  width: 100%;
  overflow: show;
}
.hollow-text{
  background: transparent;
  border: none;
  outline: none;
  overflow: clip;
}
.hollow-text:focus{
  outline: none;
}
.tab-menu{
  padding: 0.5em 1em;
  background: #bccbe6;
  width: 100%;
}
.tab-menu.active{
  background: #2f4798;
}
.left-radius{
  border-radius: 8px 0 0 8px;
}
.right-radius{
  border-radius: 0 8px 8px 0;
}
.heading{
  font-weight: bold;
  color: #2f4798;
}
.account-background{
  background: url(../../../assets/images/main-bg.jpg) #dae2f5 no-repeat 0 0 / 100% 100%;
  min-height: 600px;
}
.deposit-container{
  display: grid;
  grid-template-columns: 100%;
  max-width: 475px;
  grid-gap: 1em;
  margin-top: 1em;
  font-weight: bold;
  padding-bottom: 10em;

}
.deposit-card{
  padding: 1em 0.75em;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.deposit-bank{
  width: 120px;
  margin-bottom: 0.5em;
}
.dcard-header{
  font-size: x-small;
  color: #777;
}
.dcard-info{
  font-size: small;
  color: black;
}
.deposit-copy{
  width: 20px;
  cursor: pointer;
} 
.deposit-container-top{
  margin-top: -20px;
  padding-top: 20px;
  min-height: 90vh;
  background: url(../../../assets/images/login-backdrop-old.png) #dae2f5 no-repeat 0 0 / 100% 100%;
}
</style>
