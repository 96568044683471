<template>
  <div class="alert alert-success notification-wrap" role="alert">
    <span class="noti-text"> {{ message }}</span>
  </div>
</template>

<script>
//Vue export
export default {
  props: ["message"],
};
</script>

<style scoped>
.notification-wrap {
  position: fixed;
  top: 110px;
  /* width: 60%;
  left: 20%; */
  width: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1030;
  padding: 0.25rem 0.75rem !important;
  border-radius: 5px;
  border: solid 1px #a41d1d;
  background-color: #ffcfcf;
}

.noti-text {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #a41d1d;
  padding-left: 7px;
}
</style>
