<template>
  <div class="alert alert-success notification-wrap" role="alert">
    <img
      alt="tick"
      src="../../../assets/icons/icn-correct.png"
      width="16"
      height="16"
    />
    <span class="noti-text"> {{ message }}</span>
  </div>
</template>

<script>
//Vue export
export default {
  props: ['message'],
};
</script>

<style scoped>
.notification-wrap {
  position: fixed;
  top: 50px;
  z-index: 1030;
  padding: 0.25rem 1.25rem !important;
  border-radius: 5px;
  border: solid 1px #1da421;
  background-color: #cfffd1;
  min-width: 15rem;
  left: calc(50% - 12.5rem);
  min-width: 25rem;
}

.noti-text {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #1da421;
  padding-left: 7px;
}
</style>
