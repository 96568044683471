<template>
    <div class="card-body">
      <h4 class="frank header">Frequently Asked Questions</h4>
      <hr style="border-color:#1c327e">
      <div class="col-12 p-0">
        <div class="row pb-4" v-for="f in faqList" :key="f.seq">
          <div>
            <div class="icn-round d-flex justify-content-center text-center">
              {{ f.seq }}.
            </div>
          </div>
          <div class="col-11">
            <div class="row">
              <span class="title"> {{ f.title }}</span>
            </div>
            <div class="row">
              <span class="desc">
                {{ f.desc }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    this.setDataList();
  },
  data() {
    return {
      fullList: [
        {
          langId: '0', //english
          item: [
            {
              seq: '1',
              title: 'How can I become a member?',
              desc:
                'Please contact us to find out how to become a member. Welcome to join us!',
            },
            {
              seq: '2',
              title: 'How can I place a bet?',
              desc:
                'You can place your bet with us via our website on the Internet or Mobile Wap Service and in accordance to our rules and regulations stated.',
            },
            {
              seq: '3',
              title: 'When can I place bet?',
              desc:
                'You are welcome to place your bet with us anytime at your convenience.',
            },
            {
              seq: '4',
              title: 'What is the minimum and maximum bet amount?',
              desc:
                'At YBET99, the minimum and maximum varies for different users and different matches. You will see the acceptable bet range in the place bet panel.',
            },
            {
              seq: '5',
              title: 'How do I deposit money into my YBET99 Account?',
              desc:
                'We offer different methods for depositing money into your account at YBET99. You may contact our Customer Service for further assistance.',
            },
            {
              seq: '6',
              title: 'Can I cancel my bets?',
              desc:
                'You cannot cancel a bet once it has been accepted by system. If you suspect any problem, please contact our customer service immediately.',
            },
            {
              seq: '7',
              title: 'What if I cannot login?',
              desc:
                'If you are certain that you cannot login using the correct username and password, your account may be suspended for various reasons. Please contact our YBET99 Customer Service to resolve the issue.',
            },
            {
              seq: '8',
              title: 'What if I forgotten my password?',
              desc:
                'If forget your username or password, please contact our YBET99 Customer Service for assistance.',
            },
             {
              seq: '7',
              title: 'What if I cannot login?',
              desc:
                'If you are certain that you cannot login using the correct username and password, your account may be suspended for various reasons. Please contact our YBET99 Customer Service to resolve the issue.',
            },
            {
              seq: '8',
              title: 'What if I forgotten my password?',
              desc:
                'If forget your username or password, please contact our YBET99 Customer Service for assistance.',
            },
            {
              seq: '9',
              title: 'Can I let someone else bet with my account?',
              desc:
                'For the security of your account and personal information, we suggest that you do NOT disclose your Username or Password to any other person.',
            },
            {
              seq: '10',
              title: 'Is my personal information protected safely?',
              desc:
                'Sure, all your personal information are securely protected with strict confidentiality which in accordance with our Privacy Policy.',
            },
          ],
        },
{
          langId: '1', //chinese
          item: [
            {
              seq: '1',
              title: 'How can I place a bet?',
              desc:
                'You can ONLY place a bet with us via our website on the internet and in accordance with our Rules & Regulation',
            },
            {
              seq: '2',
              title: 'Can I cancel my bets?',
              desc:
                'No. Once we have accepted your bet, it cannot be cancelled and will be settled according to the Rules & Regulations governing our website. It is important that you are familiar with these rules.',
            },
            {
              seq: '3',
              title: 'Are my personal details safe and kept confidential with ybet?',
              desc:
                'Yes. All your personal details and the information you enter in the site will be kept in the strictest confidence.',
            },
            {
              seq: '4',
              title: 'What do I do if I have forgotten my User ID or Password?',
              desc:
                'Please contact our dedicated customer services team immediately either by Email, Telephone or Fax.',
            },
            {
              seq: '5',
              title: 'What if there are changes to my personal details?',
              desc:
                'Please contact our customer services team and update them on the changes immediately.',
            },
          ],
        },
        {
          langId: '2', //thai
          item: [
            {
              seq: '1',
              title: 'ฉันจะวางเดิมพันได้อย่างไร ?',
              desc:
                'คุณสามารถวางเดิมพันกับเราผ่านทางเวบไวค์โดยระบบอินเตอร์เนตอย่างเดียวเท่านั้นตามที่ระบุไว้ในกฎกติกา',
            },
            {
              seq: '2',
              title: 'ฉันสามารถยกเลิกการเดิมพันได้หรือไม่ ?',
              desc:
                'เมื่อเราได้ยอมรับรายการเดิมพันของคุณแล้ว คุณไม่สามารถยกเลิกได้ กติกานี้ได้ถูกโพสต์ไว้ในกฎและกติกาของเวบไซค์เรียบร้อยแล้ว เป็นหน้าที่ของลูกค้าที่จะต้องทำความคุ้นเคยกับกติกาเหล่านี้',
            },
            {
              seq: '3',
              title:
                'ข้อมูลส่วนตัวของฉันปลอดภัยหรือเปล่าเมื่อเล่นกับเวบ ybet ?',
              desc:
                'ใช่คะ ข้อมูลส่วนตัวของคุณที่ให้ไว้กับทางเวบไซค์จะถูกเก็บไว้อย่างเป็นความลับคะ',
            },
            {
              seq: '4',
              title: 'ฉันจะทำอย่างไรถ้าฉันลืมยูสเซอร์เนม หรือ พาสเวริ์ด ?',
              desc:
                'กรุณาติดต่อฝ่ายบริการลูกค้าของเราโดยด่วนทาง อีเมลล์ โทรศัพท์ หรือ แฟกซ์',
            },
            {
              seq: '5',
              title: 'ถ้าฉันต้องการเปลี่ยนแปลงข้อมูลส่วนตัวของฉัน ?',
              desc:
                'กรุณาติดต่อฝ่ายบริการลูกค้าเพื่ออัพเดทข้อมูลปัจจุบันที่ต้องการเปลี่ยนแปลงโดยด่วน',
            },
          ],
        },
        {
          langId: '3', //Vietnamese
          item: [
            {
              seq: '1',
              title: 'Làm thế nào tôi có thể đặt cược?',
              desc:
                'Bạn CHỈ có thể đặt cược với chúng tôi thông qua trang web của chúng tôi trên internet và tuân theo Quy tắc & Quy định của chúng tôi',
            },
            {
              seq: '2',
              title: 'Tôi có thể hủy cược của mình không?',
              desc:
                'Không. Khi chúng tôi đã chấp nhận đặt cược của bạn, nó không thể bị hủy bỏ và sẽ được giải quyết theo các Quy tắc & Quy định quản lý trang web của chúng tôi. Điều quan trọng là bạn phải quen thuộc với các quy tắc này.',
            },
            {
              seq: '3',
              title: 'Các chi tiết cá nhân của tôi có được an toàn và giữ bí mật với ybet không?',
              desc:
                'Vâng. Tất cả các chi tiết cá nhân của bạn và thông tin bạn nhập vào trang web sẽ được bảo mật một cách nghiêm ngặt nhất.',
            },
            {
              seq: '4',
              title: 'Tôi phải làm gì nếu quên ID người dùng hoặc mật khẩu của mình?',
              desc:
                'Vui lòng liên hệ ngay với nhóm dịch vụ khách hàng tận tâm của chúng tôi qua Email, Điện thoại hoặc Fax.',
            },
            {
              seq: '5',
              title: 'Điều gì sẽ xảy ra nếu có những thay đổi đối với chi tiết cá nhân của tôi?',
              desc:
                'Vui lòng liên hệ với nhóm dịch vụ khách hàng của chúng tôi và cập nhật họ về những thay đổi ngay lập tức.',
            },
          ],
        },
      ],
      faqList: null,
    };
  },
  methods: {
    setDataList() {
      const tempList = this.fullList.find(
        (x) => x.langId.toString() == this.$store.getters.langId.toString()
      );
      if (tempList == undefined) {
        this.faqList = this.fullList[0].item;
      } else {
        this.faqList = tempList.item;
      }
    },
  },
  computed: {
    langId() {
      return this.$store.getters.langId;
    },
  },
  watch: {
    // eslint-disable-next-line
    langId(newCount, oldCount) {
      this.setDataList();
    },
  },
};
</script>

<style scoped>
.header{
  color: #ff9611;
}
.card-body {
  padding: 0 1em;
  color: #2f4798;
}
.card-text {
  font-size: 12px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  color: #36342f;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 1.1rem;
  opacity: 0.85;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
  color: #fff;
}

.icn-round {

  width: 30px;
  height: 30px;
  letter-spacing: 0.24px;
  text-align: left;
  color: #ff9611;
  white-space: nowrap;
}

.title {
  letter-spacing: 0.24px;
  text-align: left;
  color: #ff9611;
}
.desc {
  letter-spacing: 0.18px;
  text-align: left;
}
</style>
