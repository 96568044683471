var CryptoJS = require('crypto-js');
// const encryptionKey = 'CMMB';
let Md5Key = 'UFA';
let encPassword = 'UFA';
let decPassword = 'UFA';
// conversionEncryptOutput: string;
// conversionDecryptOutput: string;

export default {
  setItem(key, value) {
    const secureValue = this.encrypt(value);
    localStorage.setItem(this.hashKey(key), secureValue);

    // localStorage.setItem(key, value);
  },
  getItem(key) {
    const result = this.decrypt(localStorage.getItem(this.hashKey(key)));
    return result;

    // return localStorage.getItem(key);
  },
  removeItem(key) {
    localStorage.removeItem(this.hashKey(key));
  },

  encrypt(pt) {
    if (pt == null || pt == undefined) {
      return pt;
    }
    const plainText = String(pt);
    return CryptoJS.AES.encrypt(
      plainText.trim(),
      encPassword.trim()
    ).toString();
  },

  decrypt(pt) {
    if (pt == null || pt == undefined) {
      return pt;
    }
    const ciphertext = String(pt);
    return CryptoJS.AES.decrypt(ciphertext.trim(), decPassword.trim()).toString(
      CryptoJS.enc.Utf8
    );
  },

  clear() {
    localStorage.clear();
  },

  hashKey(text) {
    return CryptoJS.HmacMD5(text, Md5Key).toString(CryptoJS.enc.Hex);
  },
};
