<template>
  <div id="home-body">
  <transition name="fadeIn">
    <div v-if="showSuccessAlert">
      <success-alert :message="successMessage"></success-alert>
    </div>
  </transition>
    <loading-spinner v-if="isLoading"></loading-spinner>
      <desktop-header></desktop-header>
      <!--- page content --->
      <div class="account-background">

        <div class="container container-min-height pt-5">
          <div class="info-header mb-3">{{$t('MyAccount')}}</div>
            <!--- page header --->
            <!--- end page header --->
            <!----card section --->
              <div class="row">
                <div class="col-lg-3">
                  <div class="left-card mb-4">
                <div class="full-center">
                  <img
                    src="../../../../assets/icons/avatar.png"
                    class="img-avatar mt-4"
                  />
                </div>

                <template v-if="!showProgress">
                  <div
                    class="pt-2 pb-0 px-0 full-center"
                  >
                    <span class="username-text"> {{ nickname }}</span>
                  </div>
                  <div
                    class="pt-1 pb-0 px-0 full-center"
                  >
                      <img class="w-100" src="../../../../assets/icons/separator.png"/>
                  </div>

                  <div
                    class="text-center mb-4"
                  >
                    <div class="currency-text">{{ currency }}</div>
                    <span class="credit-text">
                      <span v-if="!isLoadingBalance">
                         {{ $filters.currency(totalRemainingCreditLimit) }}
                      </span>
                      <!-- <span v-if="isLoadingBalance">
                        <i class="fas fa-spinner fa-pulse"></i>
                      </span> -->
                    </span>
                    <span v-if="!isLoadingBalance">
                      <img
                        src="../../../../assets/icons/icn-refresh.png"
                        class="img-responsive ml-2 cursor-pointer"
                        alt="Responsive image"
                        style="margin-top:-7px"
                        width="22"
                        height="22"
                        @click="onClickRefreshBalance"
                      />
                    </span>
                    <span v-if="isLoadingBalance">
                      <i
                        class="fas fa-spinner fa-pulse fa-lg text-dark my-2 font-weight-bold"
                      ></i>
                    </span>

                    </div>
                  
                </template>
                      <a class="btn-active-tab"
                      >
                        <img
                          class="img-tab mx-4" src="../../../../assets/icons/icn-deposit.png"
                        />
                        {{$t('Deposit')}}
                      </a>
                      <router-link to="/withdraw" class="btn-inactive-tab">
                        <img
                          class="img-tab mx-4" src="../../../../assets/icons/icn-withdrawal.png"
                        />
                        {{$t('dep_withdraw')}}
                      </router-link>
                    </div>
                </div>
                <!----main statement content---->
                <div class="col-lg-9 px-lg-3">
                    <!-- <template v-if="showProgress">
                      <div class="col-lg-12 p-0">
                        <div class="card d-flex align-items-center p-0">
                          <progress-spinner></progress-spinner>
                        </div>
                      </div>
                    </template> -->
                    <div class="right-card">
                      <div class="b">{{$t('dep_header')}}</div>
                      <div class="deposit-container">
                        <template v-for="(d,index) in depositOptions" :key="d.name" >
                          <div class="deposit-card" :style="{background : d.background}">
                            <img class="deposit-bank" :src="d.image"/>
                            <div class="dcard-header">{{$t('dep_bankacc')}}</div>
                            <div class="dcard-info col-spaced">
                              <textarea :id="'bank' + index" class="bankname hollow-text" type="text" :value="d.account" contenteditable readonly style="resize: none;"></textarea>
                              <img class="deposit-copy" src="../../../../assets/icons/deposit-copy.png"
                              @click="copyToClipboard('bank' + index, d.account)"/>
                            </div>
                            <div class="dcard-header mt-2">{{$t('dep_banknum')}}</div>
                            <div class="dcard-info col-spaced">
                              <input :id="'number' + index" class="hollow-text" type="text" :value="d.number" contenteditable readonly/>
                              <img class="deposit-copy" src="../../../../assets/icons/deposit-copy.png"
                              @click="copyToClipboard('number' + index, d.number)"/>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                </div>
              </div>

            <!---end card section -->
        </div>
    </div>
      <!----end page comtent --->
      <!-- Footer-->
      <desktop-footer></desktop-footer>
  </div>
</template>

<script>
//import CustomHttp from '../../../../CustomHttp';
//import deposit1 from '../../../../assets/images/deposit1.png';
import deposit2 from '../../../../assets/images/deposit2.png';
//import deposit3 from '../../../../assets/images/deposit3.png';

/*data for deposit options*/
const depositOptions_in = [
  // {
  //   name : 'Maybank',
  //   account: 'ABC LOREM ENTERPRISE',
  //   number: '1234567ABC',
  //   image: deposit1,
  //   background: 'linear-gradient(to top, #fff8e6, #ffe897)'
  // },
  {
    name : 'CIMB',
    account: 'Mark Cliffon Song Choon Hua',
    number: '7076026883',
    image: deposit2,
    background: 'linear-gradient(to top, #fff2f2, #ffc1c1)'
  },
  // {
  //   name : 'RHB',
  //   account: 'XYZ LOREM LONG NAMESS ENTERPRISE LONG',
  //   number: '1234567ABC',
  //   image: deposit3,
  //   background: 'linear-gradient(to top, #f3fcff, #b7eeff)'
  // },
]



//Vue export
function iosCopyToClipboard(el) {
    var oldContentEditable = el.contentEditable,
        oldReadOnly = el.readOnly,
        range = document.createRange();

    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);

    var s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.activeElement.blur();
    el.blur();

    document.execCommand('copy');
}

export default {
  components: {},
  mounted() {
  },
  data() {
    return {
      showProgress: false,
      selectedTabIndex: 0,
      depositOptions: depositOptions_in,
      isLoadingBalance: false,
      showSuccessAlert: false,
      successMessage : '',
    };
  },
  methods: {
  toggleSuccessAlert(message) {
    this.successMessage = message;
    this.showSuccessAlert = true;
    setTimeout(() => {
      this.showSuccessAlert = false;
    }, 2000);
  },
    copyToClipboard(input,text){
      //for chrome
      if(navigator.userAgent.match(/chrome|chromium|crios/i)){
        navigator.clipboard.writeText(text);
      }else{
        iosCopyToClipboard(document.getElementById(input));
      }
      this.toggleSuccessAlert(this.$t('dep_copied'))
    },
    async onClickRefreshBalance() {
      this.isLoadingBalance = true;
      try {
        await this.$store.dispatch('getMemberBalance');
        this.isLoadingBalance = false;
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        this.isLoadingBalance = false;
        this.toggleDangerAlert(error);
      }
    },
  },
  computed: {
    nickname() {
      return this.$store.getters.nickname;
    },
    username() {
      return this.$store.getters.username;
    },
    totalRemainingCreditLimit() {
      return this.$store.getters.totalRemainingCreditLimit;
    },
    currency() {
      return this.$store.getters.currency;
    },
    computedLangId() {
      return this.$store.getters.langId;
    },
  },
  watch: {
    // eslint-disable-next-line
    computedLangId(newCount, oldCount) {
      // Our fancy notification (2).
      // this.isLoading = true;
      // window.location.reload();
      // console.log(this.systemMessage);
      // this.$i18n.locale = this.$store.getters.langId.toString();
    },
  },
};
</script>

<style scoped>
.bankname{
  width: 100%;
  overflow: show;
}
.hollow-text{
  background: transparent;
  border: none;
  outline: none;
  overflow: clip;
}
.hollow-text:focus{
  outline: none;
}
.menu-text {
  margin-top: 4px;
  font-size: 12px;
  white-space: nowrap;
}

.account-text {
  font-weight: 600;
  opacity: 0.9;
  color: #36342f;
  padding-top: 3px;
  padding-bottom: 3px;
  /* font-family: SegoeUI; */
  font-size: 16px;
}

.account-btn {
  text-align: center;
  flex-wrap: wrap;
  padding: 7px 10px;
}

.left-card {
  background:  white;
  color: #2f4798;
    box-shadow: 0 9px 9px 0 rgba(141, 155, 181, 0.2);
}

.img-avatar {
  object-fit: contain;
  width: 60px;
}

.img-separator {
  object-fit: contain;
  width: 100%;
}
.username-text {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.21px;
  text-align: center;
  color: #2f4798;
}

.currency-text {
  font-size: 12px;
  text-align: center;
  color: #a1a1a1;
}

.credit-text {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.account-tab-active {
  background-image: linear-gradient(to bottom, #36342f, #5c4812);
}

.account-tab {
  background-color: #fff;
}

/* .account-tab-active:hover,
.account-tab:hover {
  opacity: 1;
} */

.tab-label-active {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.tab-label {
  color: #908d84;
  font-size: 14px;
  font-weight: 600;
}

.img-tab {
  width: 24px;
  margin-right: 5px !important;
}

.tab-border {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.btn-inactive {
  font-size: 12px;
  font-weight: 600;
  color: black;
  /* font-family: SegoeUI; */
  border: solid 1px #ddd;
  background: linear-gradient(to bottom, #00edff, #22a4dd);
  border-radius: 8px;
  opacity: 0.65;
  border: none;
}

.btn-active {
  /* font-family: SegoeUI; */
  font-size: 12px;
  font-weight: 600;
  color: black;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background: linear-gradient(to bottom, #00edff, #22a4dd);
}

.btn-inactive-tab {
  width: 100%;
  display: block;
  background-color: #fff;
  font-size: 12px;
  text-align: left;
  color: #908d84;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
}

.btn-active-tab {
  display: block;
  width: 100%;
  background-color: #2f4798;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #fff !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
}

.border-radius-19 {
  border-radius: 19px !important;
}

.container-min-height {
  min-height: 85vh;
}

.Pagination{
  margin-top: 1.5em;
  padding: 0 4px;
  border-radius: 8px;
  border: 1px solid white;
}
.credit-welcome{
  font-family: cclip;
  text-align: center;
  color: #00edff;
  text-transform: uppercase;
  margin: 0.25em 0 1em;
  -webkit-text-stroke: 0.5px #00edff;
}
.right-card {
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: white;
  min-height: 440px;
  overflow-y: auto;
  color: black;
  padding: 1.5em;
} 
.account-background{
  background: url(../../../../assets/images/main-bg.jpg) #dae2f5 no-repeat 0 0 / 100% 100%;
  min-height: 600px;
}
.deposit-container{
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: min(525px,calc(100% - 0.75em));
  grid-gap: 1em;
  margin-top: 1em;
  font-weight: bold;
}
.deposit-card{
  padding: 1em 0.75em;
  border-radius: 5px;
}
.deposit-bank{
  width: 120px;
  margin-bottom: 0.5em;
}
.dcard-header{
  font-size: x-small;
  color: #777;
}
.dcard-info{
  font-size: small;

}
.deposit-copy{
  width: 20px;
  cursor: pointer;
}

</style>
