export default {
  isLoggedIn(state) {
    return state.isLoggedIn;
  },
  username(state) {
    return state.username;
  },
  token(state) {
    return state.token;
  },
  reqChgPw(state) {
    return state.reqChgPw;
  },
  currency(state) {
    return state.currency;
  },
  totalRemainingCreditLimit(state) {
    return state.totalRemainingCreditLimit;
  },
  nickname(state) {
    return state.nickname;
  },
  totalOutstanding(state) {
    return state.totalOutstanding;
  },
  totalOutstandingCount(state) {
    return state.totalOutstandingCount;
  },
  minBet(state) {
    return state.minBet;
  },
  maxBet(state) {
    return state.maxBet;
  },
  creditLimit(state) {
    return state.creditLimit;
  },
  totalBalance(state) {
    return state.totalBalance;
  },
  isTimeout(state) {
    return state.isTimeout;
  },
  langId(state) {
    return state.langId;
  },
  systemMessage(state) {
    return state.systemMessage;
  },
  systemMessageList(state) {
    return state.systemMessageList;
  },
  accId(state) {
    return state.accId;
  },
  userId(state) {
    return state.userId;
  },
  compType(state) {
    return state.compType;
  },
  suspend(state) {
    return state.suspend;
  },
  maintenanceRemark(state) {
    return state.maintenanceRemark;
  },
  enablePaymentGateway(state) {
    return state.enablePaymentGateway;
  },
  autoTransferWallet(state) {
    return state.autoTransferWallet;
  },
  activeComponent(state) {
    return state.activeComponent;
  },
};
