<template>
  <div id="home-body">
    <loading-spinner v-if="isLoading"></loading-spinner>
      <desktop-header></desktop-header>
      <!--- page content --->
      <div class="account-background">

        <div class="container container-min-height pt-5">
          <div class="row">
            <div class="col-flex info-header mb-4">{{$t('History')}}</div>
            <div class="col col-end mb-4">
              <button
                class="btn mr-3 p-2"
                :class="[
                  isSelectThisWeek ? 'btn-inactive' : 'btn-active',
                ]"
                @click="onClickLastWeek"
              >
                {{ $t('LastWeek') }}
              </button>
              <button
                class="btn btn-default p-2"
                :class="[
                  isSelectLastWeek ? 'btn-inactive' : 'btn-active',
                ]"
                @click="onClickThisWeek"
              >
                {{ $t('ThisWeek') }}
              </button>
            </div>
          </div>
            <!--- page header --->
            <!--- end page header --->
            <!----card section --->
              <div class="row">
                <div class="col-lg-3 px-0">
                  <div class="left-card">
                    <template v-for="(tab, index) in tabList" :key="tab.name">
                      <button
                        :class="[
                          selectedTabIndex == index
                            ? 'btn-active-tab'
                            : 'btn-inactive-tab',
                          index == 0 ? 'first-tab' : null,
                          index == tabList.length - 1 ? 'last-tab' : null,
                        ]"
                        @click="onClickTab(index)"
                      >
                        <img
                          :src="
                            selectedTabIndex == index
                              ? tab.iconUrlActive
                              : tab.iconUrl
                          "
                          class="img-tab ml-2"
                        />
                        &nbsp;{{ tab.name }}&nbsp;
                      </button>
                    </template>
                    </div>
                </div>
                <!----main statement content---->
                <div class="col-lg-9 px-md-0 px-lg-3">
                    <!-- <template v-if="showProgress">
                      <div class="col-lg-12 p-0">
                        <div class="card d-flex align-items-center p-0">
                          <progress-spinner></progress-spinner>
                        </div>
                      </div>
                    </template> -->
                    <div class="right-card mb-4">
                    <div class="float-right mb-3">

                    </div>

                      <table class="table mb-0">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              style="width: 30%;"
                            >
                              {{ $t('Date') }}
                            </th>
                            <th scope="col" style="width: 30%;">
                              {{ $t('Description') }}
                            </th>
                            <th scope="col" style="width: 20%;">
                              {{ $t('Status') }}
                            </th>
                            <th
                              class="text-right"
                              scope="col"
                              style="width: 20%;"
                            >
                              {{ $t('Amount') }}
                            </th>
                          </tr>
                        </thead>

                        <tbody v-if="showProgress">
                          <tr>
                            <td colspan="4">
                              <div
                                class="col-12 py-3 d-flex justify-content-center"
                              >
                                <progress-spinner></progress-spinner>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <!--- show transfer record --->
                        <tbody
                          v-if="
                            historyList.length >= 1 &&
                            selectedTabIndex == 0 &&
                            !showProgress
                          "
                        >
                          <tr
                            v-for="transfer in historyList"
                            :key="transfer.desc"
                          >
                            <td>
                              {{ getDateString(transfer.date) }}
                              ({{ getWeekDay(transfer.date) }})
                            </td>
                            <td>
                              <span class="trasfer-text mr-2">{{ getTransferDesc(transfer.desc) }}</span>

                              <span class="small">
                                {{ getTransferDesc2(transfer.desc) }}
                              </span>
                            </td>
                            <td
                              :class="[
                                transfer.status == 'Success'
                                  ? 'text-success'
                                  : 'text-danger',
                              ]"
                            >
                              <strong v-if="transfer.status == 'Success'">
                                {{ $t('Successful') }}
                              </strong>
                              <strong v-if="transfer.status != 'Success'">
                                {{ $t('Reject') }}
                              </strong>
                            </td>
                            <td
                              class="text-right"
                              :class="[
                                getTransferDesc3(transfer.desc) ==
                                'Transfer Out'
                                  ? 'text-danger'
                                  : 'text-success',
                              ]"
                            >
                              <strong>
                                <span
                                  class="small"
                                  v-if="
                                    getTransferDesc3(transfer.desc) ==
                                    'Transfer Out'
                                  "
                                >
                                  <i class="fa fa-minus fa-xs"></i>
                                </span>
                                <span
                                  class="small"
                                  v-if="
                                    getTransferDesc3(transfer.desc) !=
                                    'Transfer Out'
                                  "
                                >
                                  <i class="fa fa-plus fa-xs"></i>
                                </span>
                                {{ $filters.currency(transfer.amt) }}
                              </strong>
                            </td>
                          </tr>
                        </tbody>

                        <!--- show W/D record --->

                        <tbody
                          v-if="
                            historyList.length >= 1 &&
                            selectedTabIndex == 1 &&
                            !showProgress
                          "
                        >
                          <tr v-for="wd in historyList" :key="wd.desc">
                            <td>
                              {{ getDateString(wd.date) }}
                              {{ getWeekDay(wd.date) }}
                            </td>
                            <td>{{ $t(wd.desc) }}</td>
                            <td
                              :class="wd_status[wd.status]"
                            >
                              <strong> {{ $t(wd.status) }}</strong>
                            </td>
                            <td
                              class="text-right"
                              :class="[
                                wd.desc == 'Withdrawal'
                                  ? 'text-danger'
                                  : 'text-success',
                              ]"
                            >
                              <strong>
                                <span
                                  class="small"
                                  v-if="wd.desc == 'Withdrawal'"
                                >
                                  <i class="fa fa-minus fa-xs"></i>
                                </span>
                                <span
                                  class="small"
                                  v-if="wd.desc != 'Withdrawal'"
                                >
                                  <i class="fa fa-plus fa-xs"></i>
                                </span>
                                {{ $filters.currency(getAbsNumber(wd.amt)) }}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                        <!--- show not found icon --->
                        <tbody v-if="historyList.length == 0 && !showProgress">
                          <tr>
                            <td colspan="4">
                              <div
                                class="col-12 px-0 d-flex justify-content-center"
                              >
                                <img
                                  src="../../../../assets/icons/search-empty.png"
                                  class="img-not-found mx-auto d-block"
                                />
                              </div>
                              <div
                                class="col-12 py-3 d-flex justify-content-center"
                              >
                                <span
                                  class="not-found-text"
                                  v-if="selectedTabIndex == 0"
                                >
                                  {{ $t('No transfer history yet') }}
                                </span>

                                <span
                                  class="not-found-text"
                                  v-if="selectedTabIndex == 1"
                                >
                                  {{ $t('No withdrawal/deposit history yet') }}
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <!---pagination --->
                        <tbody v-if="historyList.length != 0">
                          <tr>
                            <td colspan="4" class="pb-0 pr-3">
                              <ul class="pagination justify-content-end">
                                <v-pagination
                                  v-model="currentPage"
                                  :pages="totalPage"
                                  :range-size="4"
                                  active-color="#ff9611"
                                  @update:modelValue="updateHandler"
                                />
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                        <!---end pagination --->
                      </table>
                    </div>
                </div>
              </div>

            <!---end card section -->
        </div>
    </div>
      <!----end page comtent --->
      <!-- Footer-->
      <desktop-footer></desktop-footer>
  </div>
</template>

<script>
import moment from 'moment';
import CustomHttp from '../../../../CustomHttp';
import transferIcn from '../../../../assets/icons/transferhistory.png';
import transferIcnActive from '../../../../assets/icons/transferhistory.png';
import withdrawDepositIcn from '../../../../assets/icons/withdrawaldeposit.png';
import withdrawDepositIcnActive from '../../../../assets/icons/withdrawaldeposit.png';
import i18n from '../../../../i18n';
const { t } = i18n.global;

//Vue export
export default {
  components: {},
  mounted() {
    this.isSelectThisWeek = true;
    this.getMemberHistory();

    this.tabList = [
      {
        name: t('Transfer'),
        iconUrlActive: transferIcnActive,
        iconUrl: transferIcn,
      },
      {
        name: t('Btn_Withdraw'),
        iconUrlActive: withdrawDepositIcnActive,
        iconUrl: withdrawDepositIcn,
      }
    ];
  },
  data() {
    return {
      showProgress: false,
      isSelectThisWeek: false,
      isSelectLastWeek: false,
      isSelectTransfer: false,
      isSelectWithdrawDeposit: false,
      pageSize: 10,
      currentPage: 1,
      totalPage: 1,
      tabList: [],
      selectedTabIndex: 0,
      historyList: [],
      wd_status : {
        'Approved' : 'text-success',
        'Approve' : 'text-success',
        'Pending' : 'text-warning',
        'Rejected' : 'text-danger',
        'Reject' : 'text-danger',
        'In Review' : 'text-primary'
      }
    };
  },
  methods: {
    updateHandler(p) {
      this.onClickPagination(p);
    },
    getAbsNumber(num) {
      return Math.abs(num);
    },
    onClickNext() {
      if (this.currentPage == this.totalPage) {
        return;
      }
      this.currentPage += 1;
      this.getMemberHistory();
    },
    onClickPrevious() {
      if (this.currentPage == 1) {
        return;
      }
      this.currentPage -= 1;
      this.getMemberHistory();
    },
    onClickPagination(idx) {
      // if (this.currentPage == idx) {
      //   return;
      // }
      this.currentPage = idx;
      this.getMemberHistory();
    },
    getTransferDesc(desc) {
      if (desc.includes('Transfer Out')) {
        return t('TransferOut');
      } else if (desc.includes('Transfer In')) {
        return t('TransferIn');
      } else {
        return 'Transfer';
      }
    },
    getTransferDesc2(desc) {
      let result = '';
      if (desc.includes('Transfer Out')) {
        result = desc.replace('Transfer Out', '');
      } else if (desc.includes('Transfer In')) {
        result = desc.replace('Transfer In', '');
      } else {
        result = 'Transfer';
      }
      return result.trim();
    },
    getTransferDesc3(desc) {
      let result = '';
      if (desc.includes('Transfer Out')) {
        result = 'Transfer Out';
      } else if (desc.includes('Transfer In')) {
        result = 'Transfer In';
      } else {
        result = 'Transfer';
      }
      return result.trim();
    },
    getDateString(date) {
      const tempDate = new Date(date);
      return moment(tempDate.getTime())
        .format('DD MMM YYYY | h:mm A')
        .toString();
    },
    getWeekDay(date) {
      let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const tempDate = new Date(date);
      return weekday[tempDate.getDay()];
    },
    onClickThisWeek() {
      this.isSelectThisWeek = true;
      this.isSelectLastWeek = false;
      this.currentPage = 1;
      this.getMemberHistory();
    },
    onClickLastWeek() {
      this.isSelectThisWeek = false;
      this.isSelectLastWeek = true;
      this.currentPage = 1;
      this.getMemberHistory();
    },
    onClickTab(index) {
      if (this.selectedTabIndex != index) {
        this.selectedTabIndex = index;
        this.historyList = [];
        this.onClickThisWeek();
      }
    },
    async getMemberHistory() {
      let startDate;
      let endDate;

      startDate = moment().startOf('week').toDate();
      endDate = moment().endOf('week').toDate();
      startDate.setDate(startDate.getDate() + 1);
      endDate.setDate(endDate.getDate() + 1);
      if (this.isSelectLastWeek) {
        startDate.setDate(startDate.getDate() - 7);
        endDate.setDate(endDate.getDate() - 7);
      }
      let fromDate = startDate;
      let toDate = endDate;

      const todayDate = new Date();

      if (toDate > todayDate) {
        toDate = new Date();
      }
      //handle sunday error
      if (todayDate.getDay() == 0 && this.isSelectThisWeek) {
        fromDate.setDate(fromDate.getDate() - 7);
        toDate = new Date();
      } else if (todayDate.getDay() == 0 && this.isSelectLastWeek) {
        fromDate.setDate(toDate.getDate() - 13);
        toDate = new Date();
        toDate.setDate(toDate.getDate() - 7);
      }
      const json = {
        username: this.$store.getters.username,
        fromDate: moment(fromDate.getTime()).format('YYYY-MM-DD'),
        toDate: moment(toDate.getTime()).format('YYYY-MM-DD'),
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      };

      if (this.selectedTabIndex == 0) {
        this.getTransferRecord(json);
      } else if (this.selectedTabIndex == 1) {
        let input = new URLSearchParams(Object.entries(json)).toString();
        this.getWithdraw(input);
      } else {
        console.log('selected tab index out of range');
      }
    },
    async getTransferRecord(json) {
      this.showProgress = true;
      const response = await CustomHttp.post(
        'api/v1/member/history-transfer',
        json
      );
      if (response.data.data == null) {
        this.totalPage = 1;
        this.historyList = [];
      } else {
        console.log(response.data.data.historyTransferList);
        this.historyList = response.data.data.historyTransferList;
        this.totalPage = Math.ceil(
          response.data.data.totalRecord / this.pageSize
        );
      }

      this.showProgress = false;
    },
    async getWithdrawDeposit(json) {
      this.showProgress = true;
      const response = await CustomHttp.post(
        'api/v1/member/history-withdrawal-deposit',
        json
      );
      if (response.data.data == null) {
        this.totalPage = 1;
        this.historyList = [];
      } else {
        this.historyList = response.data.data.historyWithdrawalDepositList;
        this.totalPage = Math.ceil(
          response.data.data.totalRecord / this.pageSize
        );
      }

      this.showProgress = false;
    },
    async getWithdraw(json) {
      this.showProgress = true;
      //new payment api
      const response = await CustomHttp.postPayment(
        'api/v1/withdraw/payment-history',
        json
      );
      let temp = response.data.data.historyWithdrawalList;

      for(let i of temp){
        i.desc = i.description;
        i.amt = i.amount
      }

      if (response.data.data == null) {
        this.totalPage = 1;
        this.historyList = [];
      } else {
        console.log(temp);
        this.historyList = temp;
        this.totalPage = Math.ceil(
          response.data.data.totalRecord / this.pageSize
        );
      }
      this.showProgress = false;
    }
  },
  computed: {
    computedLangId() {
      return this.$store.getters.langId;
    },
  },
  watch: {
    // eslint-disable-next-line
    computedLangId(newCount, oldCount) {
      // Our fancy notification (2).
      // this.isLoading = true;
      // window.location.reload();
      // console.log(this.systemMessage);
      // this.$i18n.locale = this.$store.getters.langId.toString();
    },
  },
};
</script>

<style scoped>
.card {
  background:none;
}

.bg-light-grey {
  background-color: #f7f7f7;
}

.bg-light-gold-gradient {
  background: linear-gradient(to left, rgba(241, 215, 152, 0), #f5b50a);
  border-radius: 5px;
}

.menu-text {
  margin-top: 4px;
  font-size: 12px;
  white-space: nowrap;
}

.account-text {
  font-weight: 600;
  opacity: 0.9;
  color: #36342f;
  padding-top: 3px;
  padding-bottom: 3px;
  /* font-family: SegoeUI; */
  font-size: 16px;
}

.account-btn {
  text-align: center;
  flex-wrap: wrap;
  padding: 7px 10px;
}

.left-card {
  background:  white;
  color: #2f4798;
    box-shadow: 0 9px 9px 0 rgba(141, 155, 181, 0.2);
}

.img-avatar {
  object-fit: contain;
  width: 60px;
}

.img-separator {
  object-fit: contain;
  width: 100%;
}
.username-text {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.21px;
  text-align: center;
  color: #2f4798;
}

.currency-text {
  font-size: 12px;
  text-align: center;
  color: #a1a1a1;
}

.credit-text {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.account-tab-active {
  background-image: linear-gradient(to bottom, #36342f, #5c4812);
}

.account-tab {
  background-color: #fff;
}

/* .account-tab-active:hover,
.account-tab:hover {
  opacity: 1;
} */

.tab-label-active {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.tab-label {
  color: #908d84;
  font-size: 14px;
  font-weight: 600;
}

.img-tab {
  width: 24px;
  margin-right: 5px !important;
}

.tab-border {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.btn-inactive {
  font-size: 12px;
  font-weight: 600;
  color: #2f4798;
  /* font-family: SegoeUI; */
  background: transparent;
  border-radius: 0px;
  border: solid 1px #2f4798;
  min-width: 110px;
}

.btn-active {
  /* font-family: SegoeUI; */
  font-size: 12px;
  font-weight: 600;
  color: white;
  border-radius: 0px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background: #2f4798;
  min-width: 110px;
}

.btn-inactive-tab {
  background-color: #fff;
  font-size: 12px;
  text-align: left;
  color: #908d84;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  border: none;
  width: 100%;
}

.btn-active-tab {
  width: 100%;
  background-color: #2f4798;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
}

.border-radius-19 {
  border-radius: 19px !important;
}

.img-not-found {
  width: 60px;
  margin-top: 2em;
}

tr th {
  font-size: small;
  text-align: left;
  font-weight: normal;
  /* font-family: SegoeUI; */
  border-top: 0;
  background: #2f4798;
  color: #ffffff;
}

tr td {
  /* font-family: SegoeUI; */
  font-size: 12px;
  letter-spacing: 0.18px;
  text-align: left;
  font-weight: bold;
  border-color: #b5b5b5;
}

.small,
small {
  font-size: 80% !important;
  font-weight: 400;
}

.container-min-height {
  min-height: 85vh;
}
.radius-top-left {
  border-radius: 5px 0 0 0;
}

.radius-top-right {
  border-radius: 0 5px 0 0;
}

.not-found-text {
  /* font-family: SegoeUI; */
  font-size: 12px;
  color: #b5b5b5;
}

table {
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
  max-width: 100%;
}

.table td,
.table th {
  padding-right: 1rem !important;
}
.Pagination{
  margin-top: 1.5em;
  padding: 0 4px;
  border-radius: 8px;
  border: 1px solid white;
}

/* .PaginationControl {
  border: 1px solid #dedede !important;
  border-radius: 15px !important;
  padding: 2px 0;
} */
/*
.pagination > li > a,
.pagination > li > span {
  border-radius: 50% !important;
  margin: 0 0px;
  color: #b5b5b5;
  font-family: SegoeUI;
  font-size: 12px;
  letter-spacing: 0.18px;
  text-align: center;
} */

/* .pagination > li > a:focus,
.pagination > li > span:focus, */
/* .pagination > li > a:hover,
.pagination > li > span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a {
  color: white;
  background-color: #ffc939 !important;
  border: solid 1px #ffc939 !important;
}

.pagination > .active > a:hover {
  background-color: #ffc939 !important;
  border: solid 1px #ffc939;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(255 201 57 / 15%);
} */

.fa-plus {
  position: relative;
  top: -1.5px;
}

.fa-minus {
  position: relative;
  top: -1.5px;
}
.credit-welcome{
  font-family: cclip;
  text-align: center;
  color: #00edff;
  text-transform: uppercase;
  margin: 0.25em 0 1em;
  -webkit-text-stroke: 0.5px #00edff;
}
.right-card {
  border-radius: spx;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: white;
  min-height: 440px;
  overflow-y: auto;
} 
.trasfer-text {
  font-weight: bold;
}
.account-background{
  background: url(../../../../assets/images/main-bg.jpg) #dae2f5 no-repeat 0 0 / 100% 100%;
  min-height: 600px;
}
</style>
