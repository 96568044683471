<template>
  <section class="mobile">
    <MobileHome />
  </section>
  <section class="desktop">
    <DesktopHome />
  </section>

</template>

<script>
import MobileHome from './mobile/MobileHome';
import DesktopHome from './desktop/DesktopHome';

export default {
  components: { MobileHome, DesktopHome },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
