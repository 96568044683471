import liveCasino from './live.js';
import slot from './slot.js';

import sb from './../../images/game2/hot/sb.png';
//import sbmb from './../../images/game2/hot/sb-mobile.png';
import sbNew from './../../images/game2/hot/sb.png';
import sbNewTh from './../../images/game2/hot/sb.png';

// import cards from './cards.js';
// import sb from './sportsbook.js';
var gameMenu = {
  name: 'Hot Games',
  items: [
    //   sb.items.find((x) => x.name.toUpperCase() === 'SPORTSBOOK'),
    {
      name: 'Sportsbook',
      isFullWidth: false,
      target: 'sportsbook',
      provider: '',
      icon: sb,
      code: '2',
      img: [
        { langId: 0, src: sbNew },
        { langId: 2, src: sbNewTh },
      ],
      img2: [
        { langId: 0, src: sbNew },
        { langId: 2, src: sbNewTh },
      ],
      isNew: true,
      labelName: '',
      parentName: '',
    },
    liveCasino.items.find((x) => x.code == '39-101'),
    liveCasino.items.find((x) => x.code == '31'),
    slot.items.find((x) => x.code === '35'),
    slot.items.find((x) => x.code === '39-208'),
  ],
};

export default gameMenu;
