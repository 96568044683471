import gameImport from './../game';
import cockft from './../../images/game2/cockft/sv388.png';

var gameMenu = {
  name: 'COCKFT',
  items: [
    {
      name: 'SV 388',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-103',
      img: [{ langId: 0, src: cockft }],
      img2: [{ langId: 0, src: cockft }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
  ],
};

export default gameMenu;
