<template>
  <div class="col-lg-9">
      <!-- auto wallet -->
    <div class="right-card">
      <div class="info-text mb-3">
        {{ $t("Setting") }}
      </div>
      <div class="col-center">
        <div class="small">{{$t('setting_wallet')}}</div>
        <div class="wallet " @click="toggleWallet()" :class="(autoTransferWallet) ? 'wallet-on' : 'wallet-off'">
          <template v-if="autoTransferWallet">{{$t('setting_on')}}</template>
          <template v-else>{{$t('setting_off')}}</template>
          <span class="wallet-circle"></span>
        </div>
      </div>
    </div>
    <div class="right-card" hidden>
      <div class="info-text">
        {{ $t("NickNameLabel2") }} <span style="color:red">(this section will be removed in prelive)</span>
      </div>
      <template v-if="!showProgress">
        <div class=" nickname-row" :class="[nickname == '' ? 'py-3' : 'pt-3']">
          <div class="row">
            <div class="col-3 profile-label d-flex align-items-center">
              {{ $t("NickNameLabel") }}
            </div>
            <div class="col-4 pl-0">
              <input
                type="text"
                class="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                v-model.trim="inputNickname"
                :disabled="nickname != ''"
                v-on:input="onChangeNickname()"
              />
            </div>
            <div class="col-5 pl-2">
              <button
                v-if="nickname == ''"
                class="btn-check"
                @click="onClickCheckNickname"
                :disabled="disableCheckNickName || inputNickname == ''"
              >
                &nbsp;
                {{ $t("CheckAvailableBtn") }}
                &nbsp;
              </button>
            </div>
          </div>

          <div class="row" v-if="errNickname != ''">
            <div class="offset-3 col-7 px-0">
              <span class="text-danger small" v-html="$t(errNickname)"> </span>
            </div>
          </div>
          <div class="row" v-if="successNickname != ''">
            <div class="offset-3 col-7 px-0">
              <span class="text-success small">
                {{ $t(successNickname) }}
              </span>
            </div>
          </div>
        </div>
        <div class="pb-3">
            <div class="mt-3 profile-label" v-if="nickname == ''">
              <button
                class="btn-save"
                @click="onClickSaveNickname"
                :disabled="errNickname != '' || inputNickname == ''"
              >
                &nbsp;{{ $t("SaveBtn") }}&nbsp;
              </button>
            </div>
        </div>
      </template>
    </div>
    <div class="right-card mb-5">

      <template v-if="showProgress">
        <div>
          <progress-spinner></progress-spinner>
        </div>
      </template>
    

    <div class="mt-2">
      <div class="info-text ">
        {{ $t("Preferences") }}
      </div>
      <template v-if="showProgress">
        <div>
          <progress-spinner></progress-spinner>
        </div>
      </template>
      <template v-if="!showProgress">
        <div class=" preference-row mt-3">
          <div class="row">
            <div class="col-3 profile-label d-flex align-items-center">
              {{ $t("LanguageLabel") }}
            </div>
            <div class="col-4 pl-0">
              <VueMultiselect
                class="form-control padding-none"
                :searchable="false"
                :show-labels="false"
                track-by="value"
                :allow-empty="false"
                v-model="langId"
                label="remark"
                :options="languageList"
              >
              </VueMultiselect>

              <!-- <select class="form-control" v-model="langId">
                <option
                  v-for="lang in languageList"
                  :key="lang.value"
                  :value="lang.value"
                  >{{ lang.desc }}</option
                >
              </select> -->
            </div>
          </div>
        </div>
        <div class=" preference-row">
          <div class="row">
            <div class="col-3 profile-label d-flex align-items-center">
              {{ $t("OddsType") }}
            </div>
            <div class="col-4 pl-0">
              <VueMultiselect
                class="form-control padding-none"
                :searchable="false"
                :show-labels="false"
                track-by="value"
                :allow-empty="false"
                v-model="oddsType"
                label="desc"
                :options="oddTypeList"
              >
              </VueMultiselect>
              <!-- <select class="form-control" v-model="oddsType">
                <option
                  v-for="type in oddTypeList"
                  :key="type.value"
                  :value="type.value"
                  >{{ type.desc }}</option
                >
              </select> -->
            </div>
          </div>
        </div>
        <div class=" preference-row">
          <div class="row">
            <div class="col-3 profile-label d-flex align-items-center">
              {{ $t("OddsView") }}
            </div>
            <div class="col-4 pl-0">
              <VueMultiselect
                class="form-control padding-none"
                :searchable="false"
                :show-labels="false"
                track-by="value"
                :allow-empty="false"
                v-model="oddsView"
                label="desc"
                :options="oddViewList"
              />
              <!-- <select class="form-control" v-model="oddsView">
                <option
                  v-for="view in oddViewList"
                  :key="view.value"
                  :value="view.value"
                  >{{ view.desc }}</option
                >
              </select> -->
            </div>
          </div>
        </div>
        <div class=" preference-row">
          <div class="row">
            <div class="col-3 profile-label d-flex align-items-center">
              {{ $t("DefaultStake") }}
            </div>
            <div class="col-4 pl-0">
              <VueMultiselect
                class="form-control padding-none"
                :searchable="false"
                :show-labels="false"
                track-by="value"
                :allow-empty="false"
                v-model="defaultStake"
                label="desc"
                :options="defaultStakeList"
                @close="onChangeDefaultStake()"
              />
              <!-- <select
                @change="onChangeDefaultStake()"
                class="form-control"
                v-model="defaultStake"
              >
                <option
                  v-for="stake in defaultStakeList"
                  :key="stake.value"
                  :value="stake.value"
                  >{{ stake.desc }}</option
                >
              </select> -->
            </div>
            <div
              class="col-md-2 pr-0 pl-2 profile-label d-flex align-items-center label-curency"
            >
              <strong> {{ currency }}</strong>

              <span class="pl-2 pr-2">
                <input
                  v-show="!isDefaultStakeZero"
                  name="quickBetAmt"
                  type="text"
                  @input="filterInput"
                  maxlength="10"
                  class="form-control label-curency"
                  v-model="quickBetAmt"
                />

                <input
                  v-show="isDefaultStakeZero"
                  name="quickBetAmt"
                  type="number"
                  class="form-control"
                  :disabled="true"
                  v-model="zero"
                />
              </span>
            </div>
            <!-- <div class="col-md-2 pl-0">
              <input
                v-show="!isDefaultStakeZero"
                name="quickBetAmt"
                type="number"
                class="form-control"
                v-model.number="quickBetAmt"
              />
              <input
                v-show="isDefaultStakeZero"
                name="quickBetAmt"
                type="number"
                class="form-control"
                :disabled="true"
                v-model.number="zero"
              />
            </div> -->
          </div>
        </div>
        <div class=" preference-row">
          <div class="row">
            <div class="col-3 profile-label d-flex align-items-center">
              {{ $t("AcceptBetterOdds") }}
            </div>
            <div class="col-4 pl-0">
              <VueMultiselect
                class="form-control padding-none"
                :searchable="false"
                :show-labels="false"
                track-by="value"
                :allow-empty="false"
                v-model="acceptBetterOdds"
                label="desc"
                :options="acceptBetterOddsList"
              />

              <!-- <select class="form-control" v-model="acceptBetterOdds">
                <option
                  v-for="better in acceptBetterOddsList"
                  :key="better.value"
                  :value="better.value"
                  >{{ better.desc }}</option
                >
              </select> -->
            </div>
          </div>
        </div>
        <div class=" preference-row">
          <div class="row">
            <div class="col-3 profile-label d-flex align-items-center">
              {{ $t("SortBy") }}
            </div>
            <div class="col-4 pl-0">
              <VueMultiselect
                class="form-control padding-none"
                :searchable="false"
                :show-labels="false"
                track-by="value"
                :allow-empty="false"
                v-model="sortBy"
                label="desc"
                :options="sortByList"
              />
              <!-- <select class="form-control" v-model="sortBy">
                <option
                  v-for="sort in sortByList"
                  :key="sort.value"
                  :value="sort.value"
                  >{{ sort.desc }}</option
                >
              </select> -->
            </div>
          </div>
        </div>
        <div class="preference-row">
          <div class="row">
            <div class="col-3 profile-label d-flex align-items-center">
              {{ $t("MatchDisplayTime") }}
            </div>
            <div class="col-8 pl-0">
              <VueMultiselect
                class="form-control padding-none timezoneDropdown"
                :searchable="false"
                :show-labels="false"
                track-by="value"
                :allow-empty="false"
                v-model="timezone"
                label="desc"
                :options="timeZoneList"
              />
              <!-- <select class="form-control" v-model="sortBy">
                <option
                  v-for="sort in sortByList"
                  :key="sort.value"
                  :value="sort.value"
                  >{{ sort.desc }}</option
                >
              </select> -->
            </div>
          </div>
        </div>
        <div class=" pb-3 mt-4 py-4 preference-row">
          <div class="row pl-2">
            <div class="col-3 profile-label pl-3">
              <button
                class="btn-save"
                :disabled="isInputSame"
                @click="onClickSavePreference"
              >
                &nbsp;{{ $t("SaveBtn") }}&nbsp;
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
    </div>
    <loading-spinner v-if="isLoading"></loading-spinner>
    <transition name="fadeIn">
      <div v-if="showSuccessAlert">
        <success-alert :message="successMessage"></success-alert>
      </div>
    </transition>
    <transition name="fadeIn">
      <div v-if="showErrorAlert">
        <danger-alert :message="errorMessage"></danger-alert>
      </div>
    </transition>
  </div>
</template>

<script>
import CustomHttp from "../../../../CustomHttp";
import PreferenceImport from "../../../../assets/script/preference";
import VueMultiselect from "vue-multiselect";
import _ from "lodash";
import i18n from "../../../../i18n";
const { t } = i18n.global;

const capitalize = ([firstLetter, ...restOfWord]) =>
  firstLetter.toUpperCase() + restOfWord.join("");

//Vue export
export default {
  components: { VueMultiselect },
  mounted() {
    this.inputNickname = this.$store.getters.nickname;
    this.getUserPreference();
  },
  data() {
    return {
      zero: 0,
      isDefaultStakeZero: 0,
      selected: null,
      options: ["list", "of", "options"],
      isLoading: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      successMessage: "",
      errorMessage: "",
      inputNickname: "",
      errNickname: "",
      successNickname: "",
      disableCheckNickName: false,
      showProgress: false,
      //preference input parameter
      langId: "0",
      oddsType: 0,
      oddsView: 0,
      defaultStake: 0,
      acceptBetterOdds: 0,
      quickBetAmt: 10,
      sortBy: 1,
      timezone: 29,
      // selection
      // languageList: JSON.parse(JSON.stringify(PreferenceImport.Language)),
      // oddTypeList: JSON.parse(JSON.stringify(PreferenceImport.OddsType)),
      // oddViewList: JSON.parse(JSON.stringify(PreferenceImport.OddsView)),
      // defaultStakeList: JSON.parse(JSON.stringify(PreferenceImport.DefaultStake)),
      // acceptBetterOddsList: JSON.parse(JSON.stringify(PreferenceImport.AcceptBetterOdds)),
      // sortByList: JSON.parse(JSON.stringify(PreferenceImport.SortBy)),
      // timeZoneList: JSON.parse(JSON.stringify(PreferenceImport.TimeZone)),
      languageList: [],
      oddTypeList: [],
      oddViewList: [],
      defaultStakeList: [],
      acceptBetterOddsList: [],
      sortByList: [],
      timeZoneList: [],
      lastQuickBetAmt: 0,
      inputJsonSnap: {},
      maxInputLenth: 10,
      autoTransferWallet: false,
    };
  },
  methods: {
    async toggleWallet(){
      if(this.autoTransferWallet == false){
        this.autoTransferWallet = true;

        let json = { username: this.$store.getters.username, autoTransfer: true };
        let response = await CustomHttp.post("api/v1/member/update-autotransfer", json);

        if(response.data.success == true){
          this.toggleSuccessAlert("Main Wallet Auto Transfer Successfully Updated");
          this.$store.dispatch("setAutoTransferWallet", true);
        }

      }else{
        this.autoTransferWallet = false;

        let json = { username: this.$store.getters.username, autoTransfer: false };
        let response = await CustomHttp.post("api/v1/member/update-autotransfer", json);

        if(response.data.success == true){
          this.toggleSuccessAlert("Main Wallet Auto Transfer Successfully Updated");
          this.$store.dispatch("setAutoTransferWallet", false);
        }

      }
    },
    async translateOption() {
      this.showProgress = true;

      //option translationg

      this.languageList = JSON.parse(JSON.stringify(PreferenceImport.Language));
      this.oddTypeList = JSON.parse(JSON.stringify(PreferenceImport.OddsType));
      this.oddViewList = JSON.parse(JSON.stringify(PreferenceImport.OddsView));
      this.defaultStakeList = JSON.parse(JSON.stringify(PreferenceImport.DefaultStake));
      this.acceptBetterOddsList = JSON.parse(
        JSON.stringify(PreferenceImport.AcceptBetterOdds)
      );
      this.sortByList = JSON.parse(JSON.stringify(PreferenceImport.SortBy));
      this.timeZoneList = JSON.parse(JSON.stringify(PreferenceImport.TimeZone));

      this.oddTypeList = this.oddTypeList.map((item) => {
        item.desc = t(item.desc);
        return {
          ...item,
        };
      });

      this.oddViewList = this.oddViewList.map((item) => {
        item.desc = t(item.desc);
        return {
          ...item,
        };
      });

      this.defaultStakeList = this.defaultStakeList.map((item) => {
        item.desc = t(item.desc);
        return {
          ...item,
        };
      });

      this.acceptBetterOddsList = this.acceptBetterOddsList.map((item) => {
        item.desc = t(item.desc);
        return {
          ...item,
        };
      });

      this.sortByList = this.sortByList.map((item) => {
        item.desc = t(item.desc);
        return {
          ...item,
        };
      });

      this.showProgress = false;
    },
    filterInput(e) {
      //remove symbol
      e.target.value = this.processAmount(e.target.value);
    },
    processAmount(value) {
      let result = value.toString();

      result = result.replace(/\/r/, "/");
      //only number, need change
      result = result.replace(/[^0-9]+/g, "");
      //e.target.value = e.target.value.toString();
      // e.target.value = e.target.value.replaceAll('[^a-zA-Z0-9]', '');
      //  e.target.value = Number(e.target.value);
      // e.target.value = Math.abs(e.target.value);
      result = result.toString();
      if (result == "00") {
        result = 0;
      } else if (result.length > 1) {
        result = result.replace(/^0+/, "");
      }
      // add  comma
      result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (result.length > this.maxInputLenth) {
        result = result.slice(0, this.maxInputLenth);
      }
      return result;
    },
    async getUserPreference() {
      this.showProgress = true;
      const json = { username: this.$store.getters.username };
      const response = await CustomHttp.post("api/v1/member/get-preference", json);

      const responseData = response.data.data;
      if (responseData.langId != this.$store.getters.langId) {

        this.onClickSavePreference(false);


        //console.log(responseData.langId,this.$store.getters.langId);
        //this.setLanguage(responseData.langId);

        this.getUserPreference();
      }

      await this.translateOption();

      // section for multi select
      this.langId = this.languageList.find((item) => item.value ==this.$store.getters.langId);
      this.oddsType = this.oddTypeList.find(
        (item) => item.value == responseData.oddsType
      );
      this.oddsView = this.oddViewList.find(
        (item) => item.value == responseData.oddsView
      );
      this.defaultStake = this.defaultStakeList.find(
        (item) => item.value == responseData.defaultStake
      );
      this.acceptBetterOdds = this.acceptBetterOddsList.find(
        (item) => item.value == responseData.acceptBetterOdds
      );

      this.autoTransferWallet = responseData.autoTransfer;
      this.sortBy = this.sortByList.find((item) => item.value == responseData.sortBy);
      this.quickBetAmt = this.processAmount(responseData.quickBetAmt);
      if (Number(this.defaultStake.value) == 0) {
        this.isDefaultStakeZero = true;
      } else {
        this.isDefaultStakeZero = false;
      }

      //handle dont have 0 in list
      if (responseData.timezone.toString() == "0") {
        this.timezone = { value: null, desc: null };
      } else {
        this.timezone = this.timeZoneList.find(
          (item) => item.value == responseData.timezone
        );
      }

      this.inputJsonSnap = {
        username: this.$store.getters.username,
        langId: this.langId.value,
        oddsType: Number(this.oddsType.value),
        oddsView: Number(this.oddsView.value),
        defaultStake: this.defaultStake.value,
        acceptBetterOdds: Number(this.acceptBetterOdds.value),
        sortBy: Number(this.sortBy.value),
        quickBetAmt: this.processAmount(this.quickBetAmt),
        timezone: Number(this.timezone.value),
      };

      //end new section
      this.showProgress = false;
    },
    async onClickSavePreference(showMsg = true) {
      if (!this.timezone.value) {
        if(showMsg == true){
          this.toggleDangerAlert(t("MatchDisplayTimeCannotEmpty"));
        }
        return;
      }
      if (Number(this.defaultStake.value) == 0) {
        this.quickBetAmt = 0;
      }
      //check default stake and quick bet value
      let tempAmount = this.quickBetAmt.toString();
      tempAmount = tempAmount.replace(/[^0-9]+/g, "");
      //let oldAmount = Math.floor(Number(tempAmount));
      let tempQuickBetAmt = tempAmount;
      if (Number(this.defaultStake.value) != 0 && Number(tempQuickBetAmt) <= 0) {
        this.toggleDangerAlert(t("QuickBetCannotEmpty"));
        return;
      }
      this.setLanguage(this.langId.value)

      let json = {
        username: this.$store.getters.username,
        langId: this.langId.value,
        oddsType: Number(this.oddsType.value),
        oddsView: Number(this.oddsView.value),
        defaultStake: Number(this.defaultStake.value),
        acceptBetterOdds: Number(this.acceptBetterOdds.value),
        sortBy: Number(this.sortBy.value),
        quickBetAmt: Number(tempQuickBetAmt),
        timezone: Number(this.timezone.value),
      };

      this.isLoading = true;
      //this.showProgress = true;
      const response = await CustomHttp.post("/api/v1/member/update-preference", json);
      // this.showProgress = false;
      this.lastQuickBetAmt = 0;
      this.isLoading = false;
      const responseData = response.data;

      if (responseData.success) {
        if(showMsg == true){
          this.toggleSuccessAlert(t("Preferences is updated"));
        }
        this.getUserPreference();
      } else {
        // this.showProgress = false;
        this.toggleDangerAlert(responseData.error.message);
      }
      // this.getUserPreference();
    },
    onChangeNickname() {
      this.errNickname = "";
      this.successNickname = "";
      // eslint-disable-next-line
      const english = /^([a-zA-Z0-9!#$%^&*@_.()+\-=\[\]{};':"\\|,<>\/?]+)$/;
      //const english = /^[A-Za-z0-9]*$/;
      // eslint-disable-next-line
      const format = /[!#$%^&*()+\-=\[\]{};':"\\|,<>\/?]+/;
      if (this.inputNickname != "" && !english.test(this.inputNickname)) {
        this.errNickname = "Only alphanumeric are allowed";
        this.disableCheckNickName = true;
      } else if (format.test(this.inputNickname)) {
        this.errNickname = "Special characters other";
        this.disableCheckNickName = true;
      } else if (this.inputNickname.length > 50) {
        this.errNickname = "Nick name must be less than 50 characters";
        this.disableCheckNickName = true;
      } else {
        this.disableCheckNickName = false;
      }
    },
    onChangeDefaultStake() {
      if (Number(this.defaultStake.value) == 0) {
        this.isDefaultStakeZero = true;
        // this.lastQuickBetAmt = this.quickBetAmt;
        // this.quickBetAmt = 0;
      } else {
        this.isDefaultStakeZero = false;
        //  this.quickBetAmt = this.lastQuickBetAmt;
      }
    },
    toggleSuccessAlert(message) {
      this.successMessage = message;
      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 1900);
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1900);
    },
    async onClickCheckNickname() {
      this.errNickname = "";
      this.successNickname = "";
      if (this.inputNickname.length < 5) {
        this.errNickname = "NicknameMustBeAtLeast5Characters";
        return;
      }
      const json = { nickname: this.inputNickname };
      this.isLoading = true;
      const response = await CustomHttp.post("api/v1/user/check-nickname", json);
      this.isLoading = false;
      const responseData = response.data.data.exists;
      if (responseData) {
        this.errNickname = "NickNamealreadyexists";
      } else {
        this.successNickname = "NickNameisavailable";
      }
    },
    async onClickSaveNickname() {
      this.errNickname = "";
      this.successNickname = "";
      if (this.inputNickname == "") {
        this.errNickname = "Nickname is required";
        return;
      }

      if (this.inputNickname.length < 5) {
        this.errNickname = "NicknameMustBeAtLeast5Characters";
        return;
      }

      const json = {
        username: this.$store.getters.username,
        nickname: this.inputNickname,
      };
      /// promt confirm

      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          popup: "preference-modal",
          actions: "preference-action",
          container: "preference-container",
          title: "preference-title",
          confirmButton: "preference-confirm",
          cancelButton: "preference-cancel",
          content: "preference-content",
        },
        buttonsStyling: false,
      });
      let result = await swalWithBootstrapButtons.fire({
        title: t("ConfirmNickname"),
        text: t("CannotUndone"),
        showCancelButton: true,
        confirmButtonText: t("Yes2"),
        cancelButtonText: t("NO2"),
      });

      if (!result.isConfirmed) {
        return;
      }

      this.isLoading = true;
      let response;
      try {
        response = await CustomHttp.post("api/v1/member/update-nickname", json);
      } catch (error) {
        this.isLoading = false;
        let tempError = error.message;
        tempError = tempError.replace("Error:", "").trim();
        tempError = t(tempError);
        this.errNickname = tempError;
        return;
      }
      this.isLoading = false;
      const responseData = response.data.error;
      if (responseData != null) {
        //err
        if (responseData.code == -14) {
          this.errNickname = "NickNamealreadyexists";
        } else {
          this.errNickname = capitalize(responseData.message);
        }
        return;
      }
      this.$store.dispatch("setMemberNickname", this.inputNickname);
      //  this.$swal.fire('Nick name is updated');
      this.toggleSuccessAlert("Nick name is updated");
    },
    setLanguage(languageId) {
      this.$store.dispatch("setLangId", languageId);
    },
  },
  computed: {
    nickname() {
      return this.$store.getters.nickname;
    },
    currency() {
      return this.$store.getters.currency;
    },
    isInputSame() {
      const json1 = this.inputJsonSnap;
      const json2 = {
        username: this.$store.getters.username,
        langId: this.langId.value,
        oddsType: Number(this.oddsType.value),
        oddsView: Number(this.oddsView.value),
        defaultStake: this.defaultStake.value,
        acceptBetterOdds: Number(this.acceptBetterOdds.value),
        sortBy: Number(this.sortBy.value),
        quickBetAmt: this.processAmount(this.quickBetAmt),
        timezone: Number(this.timezone.value),
      };
      return _.isEqual(json1, json2);
    },
  },
};
</script>
<style scoped>
  .multiselect__tags{
    background: rgba(169, 249, 255, 0.25) !important;
  }
  .multiselect__input, .multiselect__single{
    background: rgba(169, 249, 255, 0.25) !important;
  }

.info-text {
  font-size: small;
  text-align: left;
  color: #2f4798;
  font-weight: bold;
}

.profile-label {
  font-size: small;
  text-align: left;
  /* font-family: SegoeUI; */
}

.form-control {
  /* font-family: SegoeUI; */
  font-size: small;
}

.profile-info {
  text-align: left;
  font-size: small;
  color: #000;
  font-weight: 700;
}
.right-card {
  color: black;
  border-radius: spx;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: white;
  padding: 20px 25px;
  margin-top: 1em;
}

.btn-save {
  background: #ff9611;
  border: none;
  color: white;
  font-size: small;
  border-radius: 25px;
  padding: 8px 30px;
  font-weight: bold;
}

.btn-save:disabled {
  background: #d0d6e0;
}

.btn-check {
  background: #ff9611;
  border: none;
  color: white;
  font-size: small;
  border-radius: 25px;
  padding: 8px;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.padding-none {
  padding: 0px !important;
}

.preference-row {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-warning {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.label-curency {
  font-weight: 600;
}
.timezoneDropdown{
  border: none;
}
.form-control:focus{
  box-shadow: 0 0 8px #ffffff;
  opacity: 1;
}
.timezoneDropdown:focus{
  box-shadow:none
}
.wallet{
  color: white;
  font-size: small;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 20px;
  min-width: 60px;
  font-size: small;
  font-weight: bold;
  margin-left: 1em;
  cursor: pointer;
}
.wallet-on{
  background: #5aaf4b;
  padding: 2px 4px 4px 10px;
}
.wallet-off{
  background: #bbc4d5;
  padding: 2px 4px 4px 4px;
}
.wallet-circle{
  float: right;
  display: inline-block;
  content: " ";
  width: 20px;
  background: white;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.48);
}
.wallet-off .wallet-circle{
   float: left;
   margin-right: 0.5em;
}
</style>
