<template>
  <footer class="desktop-footer">
    <div class="container">
      <div class="col-spaced footer-container">
          <div>
            <div>
                <img
                  class="footer-logo"
                  alt="logo"
                  src="../../../assets/images/logo-footer.png"
                />
            </div>
            <div class="footer-text mt-3">
              © Copyright 2022 YBET99. <br>All Rights Reserved.
            </div>
          </div>
          <div class="footer-divider">
            &nbsp;
          </div>
          <div>
            &nbsp;
          </div>
          <div>
            <div class="text-white b normal">
                {{ $t('footer_products') }}
            </div>
            <router-link to="/sportsbook" class="footer-menu-text">
                {{ $t('SportsBook') }}
            </router-link>
            <router-link to="/live-casino" class="footer-menu-text">
                {{ $t('LiveCasino') }}
            </router-link>
            <router-link to="/slot" class="footer-menu-text">
                {{ $t('Slot') }}
            </router-link>
            <router-link to="/fishing" class="footer-menu-text">
                {{ $t('Fishing') }}
            </router-link>
            <router-link to="/cards" class="footer-menu-text">
                {{ $t('Cards') }}
            </router-link>
            <router-link to="/cockft" class="footer-menu-text">
                {{ $t('footer_sv') }}
            </router-link>
          </div>
          <div>
            <div class="text-white b normal">
              {{ $t('footer_support') }}
            </div>
            <router-link to="/about" class="footer-menu-text">
                {{ $t('AboutUs') }}
            </router-link>
            <router-link to="/why-us" class="footer-menu-text">
                {{ $t('WhyUs') }}
            </router-link>
            <router-link to="/faq" class="footer-menu-text">
                {{ $t('FAQ') }}
            </router-link>
            <router-link to="/rules" class="footer-menu-text">
                {{ $t('BettingRules') }}
            </router-link>
            <router-link to="/contact" class="footer-menu-text">
                {{ $t('ContactUs2') }}
            </router-link>
          </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="css" src="../../../assets/css/desktop.css"></style>
