//banner import
/*
import bannerEn1 from './../images/banner/banner-1-en.jpg';
import bannerTh1 from './../images/banner/banner-1-th.jpg';
import bannerEn2 from './../images/banner/banner-2-en.jpg';
import bannerTh2 from './../images/banner/banner-2-th.jpg';
import bannerEn3 from './../images/banner/banner-3-en.jpg';
import bannerTh3 from './../images/banner/banner-3-th.jpg';
import bannerEn4 from './../images/banner/banner-4-en.jpg';
import bannerTh4 from './../images/banner/banner-4-th.jpg';
import bannerEn5 from './../images/banner/banner-5-en.jpg';
import bannerTh5 from './../images/banner/banner-5-th.jpg';*/

//import banner1 from './../images/banner/banner1.jpg';
import banner2 from './../images/banner/banner2.jpg';
// import banner3 from './../images/banner/banner3.jpg';
//import banner4 from './../images/banner/banner4.jpg';

import slot from './catalogMobile/slot';
//import live from './catalogMobile/live';
//import sb from './catalogMobile/sportsbook';
/*
import fish from './catalogMobile/fish';

import itp from './catalogMobile/itp';*/
var mobileBanner = {
  //banner  image import
  banner: [
  /*
    {
      imgUrl: [
        { langId: 0, src: bannerEn1 },
        { langId: 2, src: bannerTh1 },
      ],
      game: slot.items.find((x) => x.code === '42'),
    },
    {
      imgUrl: [
        { langId: 0, src: bannerEn2 },
        { langId: 2, src: bannerTh2 },
      ],
      game: slot.items.find((x) => x.code === '77'),
    },
    {
      imgUrl: [
        { langId: 0, src: bannerEn3 },
        { langId: 2, src: bannerTh3 },
      ],
      game: itp.items.find((x) => x.code === '67-105'),
    },
    {
      imgUrl: [
        { langId: 0, src: bannerEn4 },
        { langId: 2, src: bannerTh4 },
      ],
      game: live.items.find((x) => x.code === '67-114'),
    },
    {
      imgUrl: [
        { langId: 0, src: bannerEn5 },
        { langId: 2, src: bannerTh5 },
      ],
      game: fish.items.find((x) => x.code === '67-105'),
    },*/
    //{
    //  imgUrl: [
    //    { langId: 0, src: banner1 },
    //    { langId: 2, src: banner1 },
    //  ],
    //  game: '',
    //},
    // {
    //   imgUrl: [
    //     { langId: 0, src: banner4 },
    //     { langId: 2, src: banner4 },
    //   ],
    //   game: sb.items.find((x) => x.code === '4'),
    // },
    {
      imgUrl: [
        { langId: 0, src: banner2 },
        { langId: 2, src: banner2 },
      ],
      game: slot.items.find((x) => x.code === '35'),
    },
    // {
    //   imgUrl: [
    //     { langId: 0, src: banner3 },
    //     { langId: 2, src: banner3 },
    //   ],
    //   game: live.items.find((x) => x.code === '64'),
    // },
  ],
};

export default mobileBanner;
