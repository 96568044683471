<template>
  <form class="mobile-body-font">
    <div id="_main" class="page" data-v-app="">
      <nav class="account-nav col-spaced">
        <div class="col-center">
          <div class="mr-3">
            <router-link to="/">
                <img src="../../../assets/icons/back.png" width="26"/>
            </router-link>
          </div>
          <router-link to="/">
              <img class="logo-icn mx-auto" src="../../../assets/images/logo.png" width="110" />
          </router-link>
        </div>
        <div style="color:#b6cbff">
          <div class="b smaller text-uppercase text-right">{{ nickname }}</div>
          <div style="font-size:8px">
              {{ currentDate }} {{ currentTime }}
          </div>

        </div>
      </nav>
      <nav class="col-center acc-nav">
        <div class="col text-center">
          <div
            class="acc-tab"
            :class="[isSelectTransfer ? 'acc-tab-active' : '']"
            @click="onChangeHistoryMode('transfer')"
          >
            {{ $t("Transfer") }}
          </div>
        </div>
        <div id="wd" class="col text-center" v-if="enablePaymentGateway">
          <div
            class="acc-tab"
            :class="[isSelectWithdrawDeposit ? 'acc-tab-active' : '']"
            @click="onChangeHistoryMode('deposit')"
          >
            {{ $t("Btn_Withdraw") }}
          </div>
        </div>
      </nav>

      
      <div class="account-wrap">
        <!-- <br v-if="isSelectTransfer" /> -->
        <h6 class="heading my-3" v-if="isSelectWithdrawDeposit">{{ $t("withdraw_history_header") }}</h6>
        <h6 class="heading my-3" v-else>{{ $t("transfer_history_header") }}</h6>
        <div class="uf-dropdown dropdown" v-if="isSelectTransfer">
          <div
            class="dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
          >
            {{ selectedGame.name }}
          </div>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <template v-for="(game, index) in gameList" :key="game.code">
              <a
                class="dropdown-item"
                @click="onSelectGame(index)"
                v-if="game.code != '' && game.enabled == true"
              >
                {{ game.name }}
              </a>
            </template>
          </div>
        </div>

        <section class="content-section aboveBackground">
          <table class="account-table">
            <thead>
              <tr>
                <td>{{ $t("Date") }}</td>
                <td>{{ $t("Description") }}</td>
                <td>{{ $t("Status") }}</td>
                <td>{{ $t("Amount") }}</td>
              </tr>
            </thead>

            <tbody v-if="showProgress">
              <tr class="border-none">
                <td colspan="4">
                  <div class="col-12 py-3 d-flex justify-content-center">
                    <white-progress-spinner></white-progress-spinner>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-if="isSelectWithdrawDeposit && !showProgress">
              <tr class="small" v-for="wd in historyList" :key="wd.desc">
                <td>
                  {{ getDateString(wd.date) }}
                  <br />
                  {{ getDateTime(wd.date) }} ({{ getWeekDay(wd.date) }})
                </td>
                <td>
                  <span> {{ $t(wd.desc) }}</span>
                </td>
                <td>
                  <span class="account-success" :class="wd_status[wd.status]"> {{ $t(wd.status) }}</span>
                </td>
                <td class="b">
                  <span :class="[wd.desc == 'Withdrawal' ? 'text-danger' : null]">
                    {{ $filters.currency(wd.amt) }}
                  </span>
                </td>
              </tr>
            </tbody>

            <tbody v-if="isSelectTransfer && !showProgress">
              <tr
                class="small"
                v-for="transfer in historyList"
                :key="transfer.desc"
              >
                <td>
                  {{ getDateString(transfer.date) }}
                  <br />
                  {{ getDateTime(transfer.date) }} ({{ getWeekDay(transfer.date) }})
                </td>
                <td>
                  <span> {{ getTransferDesc(transfer.desc) }}</span>
                  <br />
                  <span class="smaller-2">
                    {{ getTransferDesc2(transfer.desc) }}
                  </span>
                </td>
                <td>
                  <span>
                    <span class="account-success" v-if="transfer.status == 'Success'">
                      {{ $t("Successful2") }}
                    </span>
                    <span class="account-failed" v-if="transfer.status != 'Success'">
                      {{ $t("Reject") }}
                    </span>
                  </span>
                </td>
                <td class="b">
                  <span
                    :class="[
                      getTransferDesc3(transfer.desc) == 'Transfer Out'
                        ? 'text-danger'
                        : 'text-success',
                    ]"
                  >
                    {{ $filters.currency(transfer.amt) }}

                    <span v-if="getTransferDesc3(transfer.desc) == 'Transfer Out'">
                      -
                    </span>
                    <span v-if="getTransferDesc3(transfer.desc) != 'Transfer Out'">
                      +
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
            <!---pagination --->
            <tbody v-if="historyList.length != 0">
              <tr>
                <td colspan="4" class="pb-0 pr-3">
                  <ul class="pagination justify-content-center">
                    <v-pagination
                      v-model="currentPage"
                      :pages="totalPage"
                      :range-size="4"
                      @update:modelValue="updateHandler"
                    />
                  </ul>
                </td>
              </tr>
            </tbody>
            <!---end pagination --->
          </table>
        </section>
      </div>
    </div>
    <div style="height:150px">&nbsp;</div>
  </form>
</template>

<script>
import moment from "moment";
import CustomHttp from "../../../CustomHttp";
import GameImport from "../../../assets/script/game";
import i18n from "../../../i18n";
const { t } = i18n.global;
//Vue export
export default {
  mounted() {
    const gameProvider = sessionStorage.getItem("provider");
    const overrideProvider = sessionStorage.getItem("providerName");
    if (gameProvider == undefined || gameProvider == '') {

      //this.onChangeHistoryMode("deposit");

      this.gameList.unshift({
        name: t("Please Select A Game"),
        code: "",
        icon: null,
        platform: null,
      });
      this.selectedGame = this.gameList[0];
    } //from landing page game modal
    else {
      let gameCode;
      if (gameProvider.includes("-")) {
        const tempCodeArray = gameProvider.split("-");
        gameCode = tempCodeArray[0];
      } else {
        gameCode = gameProvider;
      }
      this.selectedGame = this.gameList.find((g) => g.code == gameCode);
    }
    if(overrideProvider != '' && overrideProvider != undefined){
      this.selectedGame = this.gameList.find((g) => g.name.toLowerCase() == overrideProvider.toLowerCase());
    }
    this.onChangeHistoryMode("transfer");
    this.updateCurrentTime(); 
  },
  data() {
    return {
      showTrasferDropdown: false,
      isSelectTransfer: false,
      isSelectWithdrawDeposit: false,
      showProgress: false,
      historyList: [],
      pageSize: 10,
      currentPage: 1,
      selectedGame: "",
      gameList: JSON.parse(JSON.stringify(GameImport)),
      wd_status : {
        'Approved' : 'text-success',
        'Approve' : 'text-success',
        'Pending' : 'text-warning',
        'Rejected' : 'text-danger',
        'Reject' : 'text-danger',
        'In Review' : 'text-primary'
      }
    };
  },
  methods: {
    onSelectGame(i) {
      this.selectedGame = this.gameList[i];
      this.getMemberHistory();
    },
    onChangeHistoryMode(mode) {
      this.historyList = [];
      const selectedMode = mode;
      if (selectedMode == "deposit") {
        this.isSelectTransfer = false;
        this.isSelectWithdrawDeposit = true;
        this.getMemberHistory();
      } else if (selectedMode == "transfer") {
        this.isSelectTransfer = true;
        this.isSelectWithdrawDeposit = false;
        this.getMemberHistory();
      }
    },
    toggleTransferDropdown() {
      this.showTrasferDropdown = true;
    },
    // old method
    updateHandler(p) {
      this.onClickPagination(p);
    },
    onClickPagination(idx) {
      // if (this.currentPage == idx) {
      //   return;
      // }
      this.currentPage = idx;
      this.getMemberHistory();
    },
    getAbsNumber(num) {
      return Math.abs(num);
    },
    getTransferDesc(desc) {
      if (desc.includes("Transfer Out")) {
        return t("TransferOut");
      } else if (desc.includes("Transfer In")) {
        return t("TransferIn");
      } else {
        return "Transfer";
      }
    },
    getTransferDesc2(desc) {
      let result = "";
      if (desc.includes("Transfer Out")) {
        result = desc.replace("Transfer Out", "");
      } else if (desc.includes("Transfer In")) {
        result = desc.replace("Transfer In", "");
      } else {
        result = "Transfer";
      }
      return result.trim();
    },
    getTransferDesc3(desc) {
      let result = "";
      if (desc.includes("Transfer Out")) {
        result = "Transfer Out";
      } else if (desc.includes("Transfer In")) {
        result = "Transfer In";
      } else {
        result = "Transfer";
      }
      return result.trim();
    },
    getDateString(date) {
      const tempDate = new Date(date);
      return moment(tempDate.getTime()).format("DD MMM YYYY").toString();
    },
    getDateTime(date) {
      const tempDate = new Date(date);
      return moment(tempDate.getTime()).format("h:mm A").toString();
    },
    getWeekDay(date) {
      let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const tempDate = new Date(date);
      return weekday[tempDate.getDay()];
    },
    async getMemberHistory() {
      let startDate;
      let endDate;

      startDate = moment(Date.now()).toDate();
      endDate = moment(Date.now()).toDate();

      startDate.setDate(startDate.getDate() - 13);
      endDate.setDate(endDate.getDate() + 1);

      //redundant code section, get from desktop
      if (this.isSelectLastWeek) {
        startDate.setDate(startDate.getDate() - 7);
        endDate.setDate(endDate.getDate() - 7);
      }

      let fromDate = startDate;
      let toDate = endDate;

      const todayDate = new Date();

      if (toDate > todayDate) {
        toDate = new Date();
      }
      //   console.log('fromDate');
      //   console.log(fromDate);
      //   console.log('toDate');
      //   console.log(toDate);
      const json = {
        username: this.$store.getters.username,
        fromDate: moment(fromDate.getTime()).format("YYYY-MM-DD"),
        toDate: moment(toDate.getTime()).format("YYYY-MM-DD"),
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      };

      if (this.isSelectTransfer) {
        this.getTransferRecord(json);
      } else if (this.isSelectWithdrawDeposit) {
        let input = new URLSearchParams(Object.entries(json)).toString();
        this.getWithdraw(input);
      } else {
        console.log("selected tab index out of range");
      }
    },
    async getTransferRecord(json) {
      if (this.selectedGame.code == "") {
        return;
      }
      this.showProgress = true;
      const reqBody = {
        ...json,
        gameId: this.selectedGame.code,
      };
      const response = await CustomHttp.post("api/v1/member/history-transfer", reqBody);
      if (response.data.data == null) {
        this.totalPage = 1;
        this.historyList = [];
      } else {
        this.historyList = response.data.data.historyTransferList;
        this.totalPage = Math.ceil(response.data.data.totalRecord / this.pageSize);
      }

      this.showProgress = false;
    },
    async getWithdrawDeposit(json) {
      this.showProgress = true;
      const response = await CustomHttp.post(
        "api/v1/member/history-withdrawal-deposit",
        json
      );
      if (response.data.data == null) {
        this.totalPage = 1;
        this.historyList = [];
      } else {
        this.historyList = response.data.data.historyWithdrawalDepositList;
        this.totalPage = Math.ceil(response.data.data.totalRecord / this.pageSize);
      }
      this.showProgress = false;
      // this.historyList = [
      //   {
      //     date: '2021-10-14T15:35:23.033',
      //     desc: 'Withdrawal',
      //     amt: -5,
      //     status: 'Approved',
      //   },
      // ];
    },
    async getWithdraw(json) {
      this.showProgress = true;
      //new payment api
      const response = await CustomHttp.postPayment(
        'api/v1/withdraw/payment-history',
        json
      );
      let temp = response.data.data.historyWithdrawalList;

      for(let i of temp){
        i.desc = i.description;
        i.amt = i.amount
      }

      if (response.data.data == null) {
        this.totalPage = 1;
        this.historyList = [];
      } else {
        console.log(temp);
        this.historyList = temp;
        this.totalPage = Math.ceil(
          response.data.data.totalRecord / this.pageSize
        );
      }
      this.showProgress = false;
    },
    updateCurrentTime() {
      const today = new Date();
      const date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate();
      const time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const dateTime = date + ' ' + time;
      let tempDate = new Date(dateTime);

      let tempDateGmt = new Date();
      let gmt = Math.abs(tempDateGmt.getTimezoneOffset() / 60);

      tempDate = moment().format('DD-MM-yyyy');
      let tempDate2 =
        moment().format('hh:mm A') + ' (GMT+' + gmt.toString() + ')';
      this.currentDate = tempDate;
      this.currentTime = tempDate2;
    },
  },
  computed: {
    enablePaymentGateway() {
      return this.$store.getters.enablePaymentGateway;
    },
    nickname() {
      return this.$store.getters.nickname;
    },
  },
};
</script>
<style scoped>
.content-section2 {
  margin-top: 10px !important;
}

.border-none {
  border: 0px !important;
}
.logo-icn {
  position: relative;
  top: 2px;
}
.heading{
  color: #4856a7;
}
</style>
