<template>
  <div class="body-wrap">
<!--     <div class="container1">
      <div class="main-img"></div>
      <div class="centered">{{ $t("ContactUs") }}</div>
    </div> -->
    <h1 class="mt-3">Contact Us</h1>
    <hr style="color:#c2c9e6" class="mx-4"><br />

    <!-- Small Thumbnail -->
    <div class="content-wrapper">
      <h3>Mobile Betting</h3>
      <a href="https://mob.YBET99.com" target="_blank">mob.YBET99.com</a><br>
      <a href="https://wap.YBET99.com" target="_blank">wap.YBET99.com</a>
      <br />
      <br />
      <h3>Agent Mobile</h3>
      <a href="https://aw.YBET99.com" target="_top">aw.YBET99.com</a>
      <br />
      <br />
      <h3>Smart Betting</h3>
      <a href="https://smart.ybet99.com" target="_blank">smart.YBET99.com</a>
      <br />
      <br />
      <h3>Customer Service</h3>
      <a href="mailto:YBET99sup@gmail.com" target="_top">YBET99sup@gmail.com</a>

    </div>
  </div>
</template>

<script>
//Vue export
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    langId() {
      return this.$store.getters.langId.toString();
    },
  },
};
</script>
<style scoped>
.body-wrap {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 100%;
  /* height: 823px; */
}

.main-img {
  max-width: 1200px;
  height: 200px;
  background-image: url("../../../../assets/images/contact.jpg");
  background-size: cover;
  background-position: center;
}
* {
  padding: 0px;
  margin: 0px;
}

h1 {
  font-weight: bold;
  color: #ff9611;
  margin-top: 0px;
  font-size: 20px;
  text-align: center;
  font-family: SegoeUI;
  margin-bottom: 1em;
}

h3 {
  font-size: medium;
  font-weight: bold;
  color: #ff9611;
  margin-top: 5px;
}

.container1 {
  text-align: center;
  max-width: 1200px;
  height: 200px;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  background-color: #1c3452;
  padding: 0px;
}

.centered {
  font-size: 2em;
  text-shadow: 2px 2px #000;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrapper {
  font-size: 15px;
  margin: 0 auto;
  max-width: 1200px;
  text-align: center;
  padding: 0 20px;
}

a {
  color: #2f4798;
  text-decoration: none;
}
a:focus,
a:hover {
  color: #2f4798;
  text-decoration: underline;
}

a:active,
a:hover {
  outline: 0;
}
</style>
