import { createI18n } from 'vue-i18n/index';
import store from './store/index';
function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = createI18n({
  locale: store.getters.langId || '0',
  silentTranslationWarn: true,
  fallbackLocale: '0',
  fallbackWarn: false,
  missingWarn: false,
  messages2: loadLocaleMessages(),
  messages: loadLocaleMessages(),
});

export default i18n;
